import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import ILHeaderOrganism from './ILHeaderOrganism';
import { State } from '../../store';
import { useGetMethod } from '../../graphql/method/methodOperations';
import { useGetAuthenticated } from '../../graphql/authenticated/authenticatedOperations';

interface Props {
  displayMenu: boolean;
}

const mapStateToProps = (state: State) => ({
  displayMenu: state.context.displayMenu,
});

export function ILHeaderOrganismContainer({ displayMenu }: Props): ReactElement {
  const method = useGetMethod();
  const { isTeacher, isAuthenticated } = useGetAuthenticated();
  return (
    <ILHeaderOrganism
      method={method}
      isTeacher={isTeacher}
      isAuthenticated={isAuthenticated}
      displayMenu={displayMenu}
    />
  );
}

export default connect(mapStateToProps)(ILHeaderOrganismContainer);
