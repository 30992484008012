import React, { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { TFunction } from 'i18next';
import { getPendingPlans } from '../../../graphql/reviewedPlans/reviewedPlansOperations';
import { Notification } from '../../../models/state/Notification';
import TPNotificationsTableRowAtom from '../TPNotificationsTableRowAtom/index';
import TPNotificationTextAtom from '../TPNotificationsTextAtom/index';
import TPReviewNotificationAtom from '../TPReviewNotificationAtom';

const createLinkForSeeResultStudent = (planId: string): string => `/resultaat/${planId}`;

const createLinkForSeeResultTeacher = (planId: string): string => `/resultaat/alle/${planId}`;

const createLinkForMakeTest = (planId: string, title: string): string =>
  `/toets-maken/${planId}/${encodeURIComponent(title)}`;

const createLinkForReleaseResult = (planId: string): string => `/resultaat/alle/${planId}`;

const getButton = (buttonText: string, buttonLink: string, titleState?: string): JSX.Element => {
  if (buttonText && buttonLink) {
    const to = titleState ? { pathname: buttonLink, state: { title: titleState } } : buttonLink;
    return <NavLink to={to}>{buttonText}</NavLink>;
  }
  return <></>;
};

const getButtonTeacher = (
  notification: Notification,
  t: TFunction,
  isPending: boolean
): JSX.Element => {
  switch (notification.notificationStatus) {
    case 'FinishedNotReviewed': {
      return isPending ? <></> : <TPReviewNotificationAtom planId={notification.planningId} />;
    }
    case 'FinishedNotReleased': {
      return getButton(
        t('notifications:release_result'),
        createLinkForReleaseResult(notification.planningId),
        notification.planningTitle
      );
    }
    case 'FinishedReleased': {
      return getButton(
        t('notifications:see_result'),
        createLinkForSeeResultTeacher(notification.planningId),
        notification.planningTitle
      );
    }
    default:
      return <></>;
  }
};

const getButtonStudent = (notification: Notification, t: TFunction): JSX.Element => {
  switch (notification.notificationStatus) {
    case 'OpenNotStarted': {
      return getButton(
        t('notifications:make_test'),
        createLinkForMakeTest(notification.planningId, notification.planningTitle)
      );
    }
    case 'OpenNotFinished': {
      return getButton(
        t('notifications:resume_test'),
        createLinkForMakeTest(notification.planningId, notification.planningTitle)
      );
    }
    case 'FinishedReleased': {
      return getButton(
        t('notifications:see_result'),
        createLinkForSeeResultStudent(notification.planningId),
        notification.planningTitle
      );
    }
    default:
      return <></>;
  }
};

const setButton = (
  notification: Notification,
  isTeacher: boolean,
  t: TFunction,
  isPending: boolean
): JSX.Element => {
  if (isTeacher) {
    return getButtonTeacher(notification, t, isPending);
  }
  return getButtonStudent(notification, t);
};

const mapContent = (
  notifications: Notification[],
  isTeacher: boolean,
  t: TFunction,
  pending: string[]
): JSX.Element[] => {
  if (notifications && notifications.length) {
    return notifications.map((item: Notification) => {
      const isPending =
        isTeacher && item.notificationStatus === 'FinishedNotReviewed'
          ? pending.includes(item.planningId)
          : false;
      return (
        <TPNotificationsTableRowAtom
          button={setButton(item, isTeacher, t, isPending)}
          key={item.planningId}
        >
          <TPNotificationTextAtom notification={item} isTeacher={isTeacher} isPending={isPending} />
        </TPNotificationsTableRowAtom>
      );
    });
  }
  return [];
};

interface Props {
  notifications: Notification[];
  isTeacher: boolean | undefined;
}

function TPNotificationsTableMolecule({ notifications, isTeacher }: Props): ReactElement {
  const { t } = useTranslation();
  const pendingPlans = getPendingPlans();
  const mappedContent = mapContent(notifications, !!isTeacher, t, pendingPlans);

  return (
    <table className="notifications-table">
      <tbody>{mappedContent}</tbody>
    </table>
  );
}

export default TPNotificationsTableMolecule;
