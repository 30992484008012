import { MenuItem } from './ILMenuMolecule';

export const chapterTestMenuItem: MenuItem = {
  url: '/toetsen/hoofdstuk',
  resourceKey: 'chapter_tests',
  nlpKey: 'chapterTests',
};

export const levelTestMenuItem: MenuItem = {
  url: '/toetsen/niveautoetsen',
  resourceKey: 'level_tests',
  nlpKey: 'levelTests',
};

export const myTestMenuItem: MenuItem = {
  url: '/toetsen/mijn-toetsen',
  resourceKey: 'my_tests',
  nlpKey: 'myTests',
};

export const classResultsItem: MenuItem = {
  url: '/resultaten/klassen',
  resourceKey: 'test_results_classes',
  nlpKey: 'classesOverview',
};

export const studentResultsItem: MenuItem = {
  url: '/resultaten/leerlingen',
  resourceKey: 'test_results_students',
  nlpKey: 'studentsOverview',
};

export const allResultsItem: MenuItem = {
  url: '/resultaten/alle',
  resourceKey: 'test_results_all',
  nlpKey: 'allResults',
};

export const supportItem: MenuItem = {
  url: '/ondersteuning/handleidingen',
  resourceKey: 'manuals',
};

export const newsArchiveItem: MenuItem = {
  url: '/ondersteuning/nieuwsarchief',
  resourceKey: 'newsarchive',
};

export const testAdministration: MenuItem = {
  url: '/toetsafnames',
  resourceKey: 'planned_tests',
  nlpKey: 'plannedTests',
};

export const getTestPagePath = (key?: string): string => {
  switch (key) {
    case levelTestMenuItem.resourceKey: {
      return levelTestMenuItem.url;
    }
    case chapterTestMenuItem.resourceKey: {
      return chapterTestMenuItem.url;
    }
    case myTestMenuItem.resourceKey: {
      return myTestMenuItem.url;
    }
    default: {
      return chapterTestMenuItem.url;
    }
  }
};

export const getTestPageByKey = (key?: string): MenuItem => {
  switch (key) {
    case levelTestMenuItem.resourceKey: {
      return levelTestMenuItem;
    }
    case chapterTestMenuItem.resourceKey: {
      return chapterTestMenuItem;
    }
    case myTestMenuItem.resourceKey: {
      return myTestMenuItem;
    }
    default: {
      return chapterTestMenuItem;
    }
  }
};

const teacherMenu = [
  {
    url: '/',
    resourceKey: 'start',
    matchFullUrl: true,
    nlpKey: 'notifications',
  },
  {
    url: '/toetsen',
    resourceKey: 'tests',
    items: [chapterTestMenuItem, levelTestMenuItem, myTestMenuItem],
  },
  testAdministration,
  {
    url: '/resultaten',
    resourceKey: 'test_results',
    items: [allResultsItem, classResultsItem, studentResultsItem],
  },
  {
    url: '/ondersteuning',
    resourceKey: 'support',
    items: [supportItem, newsArchiveItem],
  },
];

export default teacherMenu;
