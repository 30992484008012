import ReactGA from 'react-ga';
import { initSegment, identifySegment } from '@infinitas/segment-tracking';
import {
  initialize,
  identify as segmentIdentify,
  Environment,
} from '@infinitaslearning/module-data-analyticstracker';
import {
  gaTrackingCode,
  segmentWriteKey,
  segmentEnvironment,
  version,
} from '../api/settingsLoader';
import { getUdbId, isTeacher } from './JwtService';
/* eslint-disable @typescript-eslint/no-explicit-any */
declare const window: any;

// Before adding categories / types, please see https://www.notion.so/infinitaslearning/Product-analytics-4b06d453890f4f8d98a01f17232fd36f for instructions and approved values
export type PageCategory =
  | 'assessment'
  | 'content'
  | 'content-library'
  | 'ebook'
  | 'home'
  | 'planning'
  | 'practice-test'
  | 'result'
  | 'teacher-material'
  | 'unknown';

export type PageType =
  | 'book'
  | 'clone'
  | 'content-unit'
  | 'create'
  | 'dashboard'
  | 'deeplink'
  | 'details'
  | 'drilling'
  | 'edit'
  | 'extra-assignments'
  | 'login'
  | 'manage-bookshelf'
  | 'manage-classes'
  | 'manage-group-favorites'
  | 'manage-access'
  | 'news-article'
  | 'no-license'
  | 'notifications'
  | 'overview'
  | 'page'
  | 'planning-overview'
  | 'practice-test'
  | 'reference-material'
  | 'result-list'
  | 'review'
  | 'take'
  | 'theme'
  | 'view-answers';

const isGuid = (text: string): boolean =>
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(text);

const toSegmentEnvironment = (env: string): Environment => {
  switch (env) {
    case 'Prod': {
      return 'production';
    }
    case 'Acc': {
      return 'acceptance';
    }
    case 'Test': {
      return 'development';
    }
    case 'Dev':
    case 'Local':
    default: {
      return 'local';
    }
  }
};

export const segmentInit = async (): Promise<void> => {
  await initialize({
    platform: 'Toetsplatform',
    environment: toSegmentEnvironment(segmentEnvironment),
    opCo: 'noordhoff',
    platformVersion: version,
  });

  if (segmentWriteKey && segmentEnvironment) {
    initSegment(segmentWriteKey, segmentEnvironment);
  }

  if (gaTrackingCode) {
    ReactGA.initialize(gaTrackingCode);
  }
};

export const setGA = (pathname: string): void => {
  if (gaTrackingCode) {
    ReactGA.set({ page: pathname, anonymizeIp: true }); // Update the user's current page
    ReactGA.pageview(pathname); // Record a pageview for the given page
  }
};

export const identify = (jwt: string, organisationId: string): void => {
  const userId = getUdbId(jwt);
  const isTeacherRole = isTeacher(jwt);

  segmentIdentify({
    id: userId,
    type: isTeacherRole ? 'instructor' : 'learner',
    organisationId,
  });

  if (window.analytics) {
    // identify segment
    identifySegment({
      userId,
      organisationId,
      userRole: isTeacherRole ? 'instructor' : 'learner',
    });
  }
};

export const trackPage = (path: string, methodId: string, methodName: string | undefined): void => {
  if (window.analytics && methodId && segmentEnvironment) {
    try {
      const title = path
        .split('/')
        .filter(p => !isGuid(p))
        .join('/');
      window.analytics.page(title, {
        platform: 'Toetsplatform',
        opco: 'noordhoff',
        title,
        path,
        methodId,
        methodName,
        envType: segmentEnvironment,
      });
    } catch (error) {
      // Continue regardless of error
    }
  }
};
