import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { secondsToTime } from '../../../../../utils/dateTimeFormatter';
import { formatFloatValue } from '../../../../../utils/DataFormatter';
import {
  TableColumn,
  TableDataType,
  TableFilterType,
  TableHeaderType,
} from '../../../../../components/ILTableOrganism/ILTableColumn/createColumns';
import { QuestionResult } from '../../../../../models/dto/QuestionResults';
import { COGNITIVE_LEVEL_ORDER } from '../../../../../constants/TestConstants';
import ILTableOrganismContainer from '../../../../../components/ILTableOrganism';
import './style.scss';

type Props = {
  questionResults: QuestionResult[];
  isRefTest: boolean;
  isLoading: boolean;
};

function TPQuestionResultsTableOrganism({
  questionResults,
  isRefTest,
  isLoading,
}: Props): ReactElement {
  const { t } = useTranslation();

  const scoreColumns: TableColumn[] = [
    {
      key: 'resultItemKey',
      headerTitle: t('resultsPage:question'),
      columnData: TableDataType.Alphanumeric,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.None,
    },
    {
      key: 'answer',
      headerTitle: t('resultsPage:answer'),
      columnData: TableDataType.Custom,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.None,
      customAccessor: (row: QuestionResult) => row.score / row.maxScore,
      customCellRenderer: (rowData: QuestionResult) => {
        if (rowData.score === 0) {
          return <span>{t('resultsPage:wrong')}</span>;
        }
        if (rowData.maxScore === rowData.score) {
          return <span>{t('resultsPage:good')}</span>;
        }
        return (
          <span>
            {`${((rowData.score / rowData.maxScore) * 100).toLocaleString('nl-NL', {
              maximumFractionDigits: 0,
            })}${t('resultsPage:partially')}`}
          </span>
        );
      },
    },
    {
      key: 'score',
      headerTitle: t('resultsPage:points_earned'),
      columnData: TableDataType.Custom,
      header: TableHeaderType.NotSortable,
      filter: TableFilterType.None,
      customCellRenderer: (rowData: QuestionResult) => (
        <span>
          {`${formatFloatValue(rowData.score)} ${t('resultsPage:of')} ${formatFloatValue(
            rowData.maxScore
          )}`}
        </span>
      ),
    },
    {
      key: 'durationSeconds',
      headerTitle: t('resultsPage:time_spent'),
      columnData: TableDataType.Custom,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.None,
      customCellRenderer: (rowData: QuestionResult) => (
        <span>{secondsToTime(rowData.durationSeconds)}</span>
      ),
    },
    {
      key: 'category',
      headerTitle: t('resultsPage:domain'),
      columnData: TableDataType.Text,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.StrictSelectText,
      filterDefaultText: t('resultsPage:all_domains'),
    },
    ...(isRefTest
      ? []
      : [
          {
            key: 'subSkill',
            headerTitle: t('resultsPage:cognitive_level'),
            columnData: TableDataType.Text,
            header: TableHeaderType.Sortable,
            filter: TableFilterType.StrictSelectText,
            filterDefaultText: t('resultsPage:all_levels'),
            filterSortingArray: COGNITIVE_LEVEL_ORDER,
          },
        ]),
  ];

  return (
    <ILTableOrganismContainer
      className="question-results-table"
      data={questionResults}
      isLoading={isLoading}
      columns={scoreColumns}
      id="result-scores"
      sortable={true}
      defaultSorted={[
        {
          id: 'resultItemKey',
          desc: false,
        },
      ]}
      filterable={true}
      shouldReload={false}
      noDataText=""
    />
  );
}

export default TPQuestionResultsTableOrganism;
