import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  chapterTestMenuItem,
  levelTestMenuItem,
} from '../../components/ILMenuMolecule/teacherMenu';
import { useGetMethod } from '../../graphql/method/methodOperations';
import { getData } from '../../components/ILAjaxEther';
import { paraginServiceUrl, webApiUrl } from '../../api/settingsLoader';
import { Origin } from '../../models/enums/Origin';
import { TestDetails } from '../../models/dto/TestDetails';
import { autoDownloadZipFile } from '../../utils/AutoDownload';
import TestOverview from './TestOverview';
import { usePageTracking } from '../../utils/usePageTracking';

function TestOverviewContainer(): ReactElement {
  const method = useGetMethod();
  const location = useLocation();
  const history = useHistory<{ key: string }>();
  const [tests, setTest] = useState<TestDetails[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState<boolean>(false);

  usePageTracking('content', 'overview');

  useEffect(() => {
    if (method?.structureId) {
      getData(`${webApiUrl}content/tests/${method.structureId}`).then((response: TestDetails[]) => {
        setTest(response);
        setIsLoading(false);
      });
    }
  }, [method]);

  const isLevelTest = (): boolean => location.pathname === levelTestMenuItem.url;

  const getPageKey = (): string =>
    isLevelTest() ? levelTestMenuItem.resourceKey : chapterTestMenuItem.resourceKey;

  const openDetailsPage = (id: string, downloadUrl?: string) => {
    history.push(
      `${process.env.PUBLIC_URL}/toets-details/${id}/${Origin.Cdp}${
        downloadUrl ? `?worksheetsDownloadUrl=${downloadUrl}` : ''
      }`
    );
  };

  const planTest = (id: string) => {
    history.push(`${process.env.PUBLIC_URL}/planning/new/${id}`);
  };

  const openTestArrange = () => {
    history.push({
      pathname: `${process.env.PUBLIC_URL}/toets-samenstellen`,
      state: {
        key: getPageKey(),
      },
    });
  };

  const downloadZipFile = (recipeId: string, title: string, type: string) => {
    setIsDownloadModalOpen(true);
    getData(`${paraginServiceUrl}/api/v1/recipes/download/${recipeId}/${type}`)
      .then(response => {
        setIsDownloadModalOpen(false);
        autoDownloadZipFile(response, title);
      })
      .catch(() => setIsDownloadModalOpen(false));
  };

  return (
    <TestOverview
      data={tests}
      isLevelTest={isLevelTest()}
      isLoading={isLoading}
      showChapterDownload={!!method?.showChapterDownload}
      isDownloadModalOpen={isDownloadModalOpen}
      openDetailsPage={openDetailsPage}
      openTestArrange={openTestArrange}
      planTest={planTest}
      downloadFile={downloadZipFile}
      isArrangeTestEnabled={!!method?.arrangeTest}
      closeDownloadModal={() => setIsDownloadModalOpen(false)}
    />
  );
}

export default TestOverviewContainer;
