/* eslint-disable @typescript-eslint/naming-convention */
import moment from 'moment';
import { Caesura, DateRange, Plan, StudentGroups } from '../../models/state/Plan';
import 'moment/locale/nl';

export enum PlanActionType {
  RESET_PLAN = 'RESET_PLAN',
  SET_STUDENT_GROUPS = 'SET_STUDENT_GROUPS',
  SET_SCHEDULE_TYPE = 'SET_SCHEDULE_TYPE',
  SET_DATE_RANGE = 'SET_DATE_RANGE',
  SET_DATE = 'SET_DATE',
  SET_START_TIME = 'SET_START_TIME',
  SET_END_TIME = 'SET_END_TIME',
  SET_GRADE_TYPE = 'SET_RESULT_TYPE',
  SET_CHECK_ANSWER = 'SET_CHECK_ANSWER',
  SET_HAS_PIN = 'SET_HAS_PIN',
  SET_PIN = 'SET_PIN',
  CLEAR_PIN = 'CLEAR_PIN',
  SET_SCORE = 'SET_SCORE',
  SET_GRADE = 'SET_GRADE',
  INIT_PLAN = 'INIT_PLAN',
  LOAD_PLAN = 'LOAD_PLAN',
}

export interface ResetPlanAction {
  type: PlanActionType.RESET_PLAN;
}

export interface SetStudentGroupsAction {
  studentGroups: StudentGroups;
  type: PlanActionType.SET_STUDENT_GROUPS;
}

export interface SetScheduleTypeAction {
  scheduleType: string;
  type: PlanActionType.SET_SCHEDULE_TYPE;
}

export interface SetDateRangeAction {
  dateRange: DateRange;
  type: PlanActionType.SET_DATE_RANGE;
}

export interface SetDateAction {
  date: moment.Moment | null;
  type: PlanActionType.SET_DATE;
}

export interface SetStartTimeAction {
  startTime: string;
  type: PlanActionType.SET_START_TIME;
}

export interface SetEndTimeAction {
  endTime: string;
  type: PlanActionType.SET_END_TIME;
}

export interface SetGradeTypeAction {
  gradeType: string;
  type: PlanActionType.SET_GRADE_TYPE;
}

export interface SetCheckAnswerAction {
  checkAnswer: boolean;
  type: PlanActionType.SET_CHECK_ANSWER;
}

export interface SetHasPinAction {
  type: PlanActionType.SET_HAS_PIN;
}

export interface SetPinAction {
  pin: string;
  type: PlanActionType.SET_PIN;
}

export interface ClearPinAction {
  type: PlanActionType.CLEAR_PIN;
}

export interface SetScoreAction {
  id: number;
  score: string;
  type: PlanActionType.SET_SCORE;
}

export interface SetGradeAction {
  id: number;
  grade: string;
  type: PlanActionType.SET_GRADE;
}

export interface InitPlanAction {
  title: string;
  id: string;
  classification: string;
  caesura: Caesura;
  type: PlanActionType.INIT_PLAN;
}

export interface LoadPlanAction {
  plan: Plan;
  type: PlanActionType.LOAD_PLAN;
}

export const resetPlanAction = (): ResetPlanAction => ({
  type: PlanActionType.RESET_PLAN,
});

export const setStudentGroupAction = (
  studentGroupPayload: StudentGroups
): SetStudentGroupsAction => ({
  studentGroups: studentGroupPayload,
  type: PlanActionType.SET_STUDENT_GROUPS,
});

export const setScheduleTypeAction = (ScheduleTypePayload: string): SetScheduleTypeAction => ({
  scheduleType: ScheduleTypePayload,
  type: PlanActionType.SET_SCHEDULE_TYPE,
});

export const setDateRangeAction = (DateRangePayload: DateRange): SetDateRangeAction => ({
  dateRange: DateRangePayload,
  type: PlanActionType.SET_DATE_RANGE,
});

export const setDateAction = (DatePayload: null | moment.Moment): SetDateAction => ({
  date: DatePayload,
  type: PlanActionType.SET_DATE,
});

export const setStartTimeAction = (StartTimePayload: string): SetStartTimeAction => ({
  startTime: StartTimePayload,
  type: PlanActionType.SET_START_TIME,
});

export const setEndTimeAction = (EndTimePayload: string): SetEndTimeAction => ({
  endTime: EndTimePayload,
  type: PlanActionType.SET_END_TIME,
});

export const setGradeTypeAction = (GradeTypePayload: string): SetGradeTypeAction => ({
  gradeType: GradeTypePayload,
  type: PlanActionType.SET_GRADE_TYPE,
});

export const setCheckAnswerAction = (CheckAnswerPayload: boolean): SetCheckAnswerAction => ({
  checkAnswer: CheckAnswerPayload,
  type: PlanActionType.SET_CHECK_ANSWER,
});

export const setHasPinAction = (): SetHasPinAction => ({
  type: PlanActionType.SET_HAS_PIN,
});

export const setPinAction = (PinPayload: string): SetPinAction => ({
  pin: PinPayload,
  type: PlanActionType.SET_PIN,
});

export const clearPinAction = (): ClearPinAction => ({
  type: PlanActionType.CLEAR_PIN,
});

export const setScoreAction = (IdPayload: number, ScorePayload: string): SetScoreAction => ({
  id: IdPayload,
  score: ScorePayload,
  type: PlanActionType.SET_SCORE,
});

export const setGradeAction = (IdPayload: number, GradePayload: string): SetGradeAction => ({
  id: IdPayload,
  grade: GradePayload,
  type: PlanActionType.SET_GRADE,
});

export const initPlanAction = (
  IdPayload: string,
  TitlePayload: string,
  ClassificationPayload: string,
  CaesuraPayload: Caesura
): InitPlanAction => ({
  id: IdPayload,
  title: TitlePayload,
  classification: ClassificationPayload,
  caesura: CaesuraPayload,
  type: PlanActionType.INIT_PLAN,
});

export const loadPlanAction = (PlanPayload: Plan): LoadPlanAction => ({
  plan: PlanPayload,
  type: PlanActionType.LOAD_PLAN,
});
