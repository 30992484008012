import React, { useEffect, useState, useCallback, ReactElement } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';

import { client } from '../../../graphql/ApolloClientService';
import { GET_QUESTIONS } from '../../../graphql/questions/questionQueries';
import {
  GET_ARRANGED_TEST,
  GET_SELECTED_QUESTION_FILTERS,
} from '../../../graphql/arrangeTest/arrangeTestQueries';
import { GET_FILTERS } from '../../../graphql/arrangeTest/filters/filterQueries';

import { getNewQuestionsList } from '../../../graphql/arrangeTest/arrangeTestOperations';

import { createRequest } from '../../../components/ILAjaxEther';
import { arrangeTestQuestionsCap } from '../../../api/settingsLoader';
import { WEB_API_ARRRANGE_TEST_BASE_URL } from '../../../constants/ArrangeTestContstants';

import { Question, GetQuestionsResult } from '../../../models/state/Question';
import { ArrangedTest, ArrangedTestResult } from '../../../models/state/arrangeTests/ArrangedTest';
import { ArrangeTestRequest } from '../../../models/dto/ArrangeTestRequest';
import { FilterObject, FilterType } from '../../../models/state/arrangeTests/Filters';
import { MethodType } from '../../../components/ILAjaxEther/MethodType';

import { useWizardContext } from '../../../components/ILWizardOrganism/ILWizardOrganism';
import { ILLoadingIndicator } from '../../../components/ILLoadingIndicator';
import SelectQuestionsStep from './SelectQuestionsStep';

import './style.scss';
import { questionFilterOptionsVar } from '../../../graphql/arrangeTest/cache';

interface Props {
  createArrangedTestDto: (
    arrangedTest: ArrangedTest,
    schoolId: string,
    methodId: string,
    finished: boolean
  ) => ArrangeTestRequest;
  categoryIds: number[];
  methodId: string;
  selectedSchoolId: string;
  isVe: boolean;
}

function SelectQuestionsStepContainer({
  createArrangedTestDto,
  categoryIds,
  selectedSchoolId,
  methodId,
  isVe,
}: Props): ReactElement | null {
  const { setReady, setNotReady, canGoNext } = useWizardContext();
  const [numberOfPages, setNumberOfPages] = useState<number>(0);
  const { arrangedTest }: ArrangedTestResult = useQuery(GET_ARRANGED_TEST).data;
  const { selectedQuestionFilters } = useQuery(GET_SELECTED_QUESTION_FILTERS).data;
  const { filters } = useQuery(GET_FILTERS).data;

  const getQuestionArgs = () => {
    let variables = {
      categoryIds,
      isVe,
      page: 1,
      learningLevels: [] as string[],
      learningYears: [] as string[],
      chapters: [] as string[],
    };

    filters.forEach((filter: FilterObject) => {
      if (filter.selectedValues.length > 0) {
        switch (filter.key) {
          case FilterType.Language:
            variables = { ...variables, ...{ language: filter.selectedValues[0] } };
            break;
          case FilterType.Level:
            variables = { ...variables, ...{ learningLevels: filter.selectedValues } };
            break;
          case FilterType.Year:
            variables = { ...variables, ...{ learningYears: filter.selectedValues } };
            break;
          case FilterType.Chapter:
            variables = { ...variables, ...{ chapters: filter.selectedValues } };
            break;
        }
      }
    });
    return variables;
  };

  const [getQuestions, { loading, data, error, fetchMore }] = useLazyQuery(GET_QUESTIONS, {
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    getQuestions({ variables: getQuestionArgs() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (arrangedTest && arrangedTest.currentStep !== 1) {
      client.writeQuery({
        query: GET_ARRANGED_TEST,
        data: {
          arrangedTest: {
            ...arrangedTest,
            currentStep: 1,
          },
        },
      });
    }
  }, [arrangedTest]);

  const setQuestionsList = (oldList: Question[], newList: Question[]): Question[] => {
    let filteredNewList: Question[] = newList;
    oldList.forEach((question: Question) => {
      filteredNewList = filteredNewList.filter(item => item.id !== question.id);
    });
    if (filteredNewList.length > 0) {
      return [...oldList, ...filteredNewList];
    }
    return oldList;
  };

  const checkIfFilterExists = (filterItems: string[], filterItem: string) =>
    filterItems.some((value: string) => value === filterItem);

  const getAvailableFilters = useCallback((questionList: Question[]) => {
    const domainOptions: string[] = [];
    const ktiOptions: string[] = [];
    const questionTypeOptions: string[] = [];
    questionList.forEach((questionItem: Question) => {
      // Check Kti
      if (ktiOptions.length < 3 && questionItem.kti?.length > 0) {
        questionItem.kti.forEach((value: string) => {
          if (!checkIfFilterExists(ktiOptions, value)) {
            ktiOptions.push(value);
          }
        });
      }
      // Check domains
      if (questionItem.domains?.length > 0) {
        questionItem.domains.forEach((value: string) => {
          if (!checkIfFilterExists(domainOptions, value)) {
            domainOptions.push(value);
          }
        });
      }
      // Check open/closed
      if (questionTypeOptions.length < 2) {
        if (questionItem.open) {
          if (!checkIfFilterExists(questionTypeOptions, 'open')) {
            questionTypeOptions.push('open');
          }
        } else if (!checkIfFilterExists(questionTypeOptions, 'closed')) {
          questionTypeOptions.push('closed');
        }
      }
    });
    return {
      domain: domainOptions,
      kti: ktiOptions,
      type: questionTypeOptions,
    };
  }, []);

  const fetchPaginatedQuestions = useCallback(
    async (pageNumber: number) => {
      if (fetchMore) {
        try {
          await fetchMore({
            variables: {
              page: pageNumber,
            },
            updateQuery: (previousResult: GetQuestionsResult, { fetchMoreResult }) => {
              if (!fetchMoreResult?.questions) {
                return previousResult;
              }
              const newList = setQuestionsList(
                previousResult.questions.questionList,
                fetchMoreResult.questions.questionList
              );
              questionFilterOptionsVar(getAvailableFilters(newList));
              return {
                ...previousResult,
                questions: {
                  ...previousResult.questions,
                  questionList: newList,
                },
              };
            },
          });
        } catch (err) {
          // Continue regardless of error
        }
      }
    },
    [fetchMore, getAvailableFilters]
  );

  const createInitialTest = useCallback(() => {
    createRequest(
      MethodType.Save,
      `${WEB_API_ARRRANGE_TEST_BASE_URL}/createRecipe`,
      createArrangedTestDto(arrangedTest, selectedSchoolId, methodId, false)
    ).then(result => {
      client.writeQuery({
        query: GET_ARRANGED_TEST,
        data: {
          arrangedTest: {
            ...arrangedTest,
            mgrRecipeId: result.data,
          },
        },
      });
    });
  }, [arrangedTest, createArrangedTestDto, methodId, selectedSchoolId]);

  useEffect(() => {
    for (let i = 1; i < numberOfPages; i += 1) {
      fetchPaginatedQuestions(i + 1);
    }
  }, [categoryIds, fetchMore, fetchPaginatedQuestions, numberOfPages]);

  useEffect(() => {
    if (arrangedTest.selectedQuestions.length > 0 && !canGoNext) {
      if (!arrangedTest.mgrRecipeId) {
        createInitialTest();
      }
      setReady();
    }
    if (arrangedTest.selectedQuestions.length === 0 && canGoNext) {
      setNotReady();
    }
  }, [
    arrangedTest.mgrRecipeId,
    arrangedTest.selectedQuestions,
    canGoNext,
    createInitialTest,
    setNotReady,
    setReady,
  ]);

  const handleAddOrRemove = (question: Question) => {
    client.writeQuery({
      query: GET_ARRANGED_TEST,
      data: {
        arrangedTest: {
          ...arrangedTest,
          selectedQuestions: getNewQuestionsList(arrangedTest.selectedQuestions, question, isVe),
        },
      },
    });
  };

  const questionContainsFilter = (questionProperty: string[], selectedFilters: string[]): boolean =>
    selectedFilters.some((value: string) => questionProperty.includes(value));

  const hasQuestionPassedFilter = (
    questionProperty: string[],
    selectedFilters: string[]
  ): boolean =>
    selectedFilters.length > 0 ? questionContainsFilter(questionProperty, selectedFilters) : true;

  const hasQuestionType = (isOpen: boolean): boolean => {
    if (selectedQuestionFilters.type.length === 1) {
      const typeValue = isOpen ? 'open' : 'closed';
      return selectedQuestionFilters.type.some((value: string) => value === typeValue);
    }
    return true;
  };

  const filterQuestions = (): Question[] => {
    const newList: Question[] = [];
    if (data && data.questions?.questionList?.length > 0) {
      data.questions.questionList.forEach((questionItem: Question) => {
        const typeFound = hasQuestionType(questionItem.open);
        const ktiFound = hasQuestionPassedFilter(questionItem.kti, selectedQuestionFilters.kti);
        const domainsFound = hasQuestionPassedFilter(
          questionItem.domains,
          selectedQuestionFilters.domain
        );

        if (domainsFound && ktiFound && typeFound) {
          newList.push(questionItem);
        }
      });
      return newList;
    }
    return [];
  };

  if (loading && !data?.questions) {
    return <ILLoadingIndicator fullSize />;
  }

  if (error) {
    // We need to create an elegant solution for error handling.
    // This is a to-do task on the backlog (52537)
  }
  if (data) {
    if (
      numberOfPages === 0 &&
      data.questions?.questionList?.length > 0 &&
      data.questions?.count <= arrangeTestQuestionsCap
    ) {
      setNumberOfPages(Math.ceil(data.questions.count / data.questions.limit));
      questionFilterOptionsVar(getAvailableFilters(data.questions.questionList));
    }
    return (
      <SelectQuestionsStep
        questions={filterQuestions()}
        selectedQuestions={arrangedTest?.selectedQuestions || []}
        handleAddOrRemove={handleAddOrRemove}
        numberOfQuestions={data.questions.count}
      />
    );
  }
  return null;
}
export default SelectQuestionsStepContainer;
