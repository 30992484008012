/* eslint-disable jsx-a11y/label-has-for */
import React, { ReactElement } from 'react';
import moment from 'moment';
import 'moment/locale/nl';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { useTranslation } from 'react-i18next';
import { SingleDatePicker } from 'react-dates';
import '../DatePicker.scss';

interface Props {
  date: moment.Moment | null;
  startTime: string;
  endTime: string;
  isFocused: boolean;
  isDisabled: boolean;
  onFocusChange: (isFocused: boolean | null) => void;
  onDateChange: (newDate: moment.Moment | null) => void;
  onStartTimeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEndTimeChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function ILClassHourSelectMolecule({
  date,
  startTime,
  endTime,
  isFocused,
  isDisabled,
  onFocusChange,
  onDateChange,
  onStartTimeChange,
  onEndTimeChange,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="picker-container">
      <label htmlFor="sdp_id">{t('datePicker:date')}</label>
      <SingleDatePicker
        id="sdp_id"
        date={date}
        onDateChange={newDate => onDateChange(newDate)}
        focused={isFocused}
        onFocusChange={newFocused => onFocusChange(newFocused.focused)}
        hideKeyboardShortcutsPanel={true}
        numberOfMonths={1}
        placeholder={t('datePicker:date')}
        disabled={isDisabled}
      />
      <div className="time-container">
        <label htmlFor="startTime">{t('datePicker:start_time')}</label>
        <input
          id="startTime"
          placeholder={t('datePicker:time_placeholder')}
          name="startTime"
          type="text"
          onChange={event => onStartTimeChange(event)}
          value={startTime}
          readOnly={isDisabled}
        />

      </div>
      <div className="time-container">
        <label htmlFor="endTime">{t('datePicker:end_time')}</label>
        <input
          id="endTime"
          placeholder={t('datePicker:time_placeholder')}
          name="endTime"
          type="text"
          onChange={event => onEndTimeChange(event)}
          value={endTime}
          readOnly={isDisabled}
        />
      </div>
    </div>
  );
}

export default ILClassHourSelectMolecule;
