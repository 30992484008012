import React, { ReactElement } from 'react';
import { Tooltip, withStyles } from '@material-ui/core';
import { ResultGroup } from '../../models/dto/PlanningResult';

import './style.scss';

type Props = {
  groups: ResultGroup[];
};

function TPGroupHoverListMolecule({ groups }: Props): ReactElement {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const HtmlTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: '#fff',
      maxWidth: 300,
      border: '1px solid #ccc',
      borderRadius: '5px',
    },
  }))(Tooltip);

  const getStudentList = (group: ResultGroup) =>
    group.users.length > 0 ? (
      <ul className="tooltip-student-list">
        {group.users.map(user => (
          <li key={user.udbId} className="tooltip-student-item">
            {`${user.firstName}${user.prefix ? ` ${user.prefix} ` : ' '}${user.lastName}`}
          </li>
        ))}
      </ul>
    ) : (
      ''
    );

  return (
    <>
      {groups.map((group, index) => (
        <HtmlTooltip key={group.groupName} placement="right" title={getStudentList(group)}>
          <span>
            {`${group.groupName}${group.users?.length > 0 ? ` (${group.users.length})` : ''}${
              index !== groups.length - 1 ? ', ' : ''
            }`}
          </span>
        </HtmlTooltip>
      ))}
    </>
  );
}

export default TPGroupHoverListMolecule;
