import React, { ReactElement, useState } from 'react';
import ILIconAtom, { IconType } from '../ILIconAtom';
import './style.scss';

interface Props {
  pin: string;
  placeholder?: string;
  isDisabled?: boolean;
  handleInputChange: (input: string) => void;
}

function ILSecretInputAtom({
  pin,
  placeholder,
  isDisabled,
  handleInputChange,
}: Props): ReactElement {
  const [displayPin, setDisplayPin] = useState(false);

  return (
    <div className="pin-container">
      <input
        placeholder={placeholder || ''}
        name="pin"
        type={displayPin ? 'text' : 'password'}
        onChange={event => handleInputChange(event.target.value)}
        value={pin}
        readOnly={isDisabled}
        autoComplete="off"
      />
      <button
        className="tp-button tp-button-icon pin-toggle-button"
        onClick={() => setDisplayPin(!displayPin)}
      >
        <ILIconAtom type={displayPin ? IconType.Hide : IconType.Show} size={18} />
      </button>
    </div>
  );
}

export default ILSecretInputAtom;
