import React, { useEffect, useState, ReactNode } from 'react';
import { configCatClient } from '../../services/ConfigCatService';
import { useGetAuthenticated } from '../../graphql/authenticated/authenticatedOperations';

interface Props {
  name: string;
  children: ReactNode;
  disabledFeatureComponent?: ReactNode;
}

function ILFeatureToggleAtom({
  name,
  children,
  disabledFeatureComponent,
}: React.PropsWithChildren<Props>) {
  const [shouldRender, setShouldRender] = useState(false);
  const { udbId, isAuthenticated } = useGetAuthenticated();

  useEffect(() => {
    let isMounted = true;
    if (isAuthenticated) {
      const userObject = { identifier: udbId };
      configCatClient
        .getValueAsync(name, false, userObject)
        .then(value => {
          if (isMounted) {
            if (value) {
              setShouldRender(true);
            } else {
              setShouldRender(false);
            }
          }
        })
        .catch(() => {
          setShouldRender(false);
        });
    }
    return () => {
      isMounted = false;
    };
  }, [isAuthenticated, udbId, name]);

  return <>{shouldRender ? children : disabledFeatureComponent || <></>}</>;
}

export default ILFeatureToggleAtom;
