import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterObject, FilterType } from '../../../../models/state/arrangeTests/Filters';
import ILSelectOptionMolecule from '../../../../components/ILSelectOptionMolecule';
import { SelectType } from '../../../../models/enums/SelectType';
import './style.scss';

interface FilterSelectProps {
  filter: FilterObject;
  displayName: string;
  selectType: SelectType;
  isLoading: boolean;
  handleFilterSelect: (value: string) => void;
}

function TPFilterSelectMolecule({
  filter,
  displayName,
  selectType,
  isLoading,
  handleFilterSelect,
}: FilterSelectProps): ReactElement {
  const { t } = useTranslation();

  const getClass = (): string => {
    if (isLoading) {
      return ' step-section-loading';
    }
    if (!filter.isEnabled) {
      return ' step-section-disabled';
    }
    return '';
  };

  const getIsSelected = (optionId: string): boolean =>
    filter.isEnabled ? filter.selectedValues.includes(optionId) : false;

  const renderFilterOptions = (): JSX.Element[] => {
    if (filter.values) {
      return filter.values.map((value: string) => (
        <ILSelectOptionMolecule
          key={`${displayName}-${value}`}
          id={`${displayName}-${value}`}
          type={selectType}
          label={
            filter.key === FilterType.Language
              ? t(`filterStep:${value.toLocaleLowerCase()}`)
              : value
          }
          isSelected={filter.isEnabled ? getIsSelected(value) : false}
          handleCheckboxChange={() => handleFilterSelect(value)}
        />
      ));
    }
    return [];
  };

  return (
    <section className={`step-section${getClass()}`}>
      <div className="section-title">
        <h2>{displayName}</h2>
      </div>
      <div className="select-option-container">{renderFilterOptions()}</div>
    </section>
  );
}

export default TPFilterSelectMolecule;
