import React, { ReactElement, useEffect, useState } from 'react';
import { useGetMethod } from '../../graphql/method/methodOperations';
import { getData } from '../../components/ILAjaxEther';
import { ContentItem } from '../../models/dto/ContentItem';
import { webApiUrl } from '../../api/settingsLoader';
import Support from './Support';
import { usePageTracking } from '../../utils/usePageTracking';

function SupportContainer(): ReactElement {
  const method = useGetMethod();
  const [items, setItems] = useState<ContentItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  usePageTracking('teacher-material', 'overview');

  useEffect(() => {
    if (method?.structureId) {
      setIsLoading(true);
      getData(`${webApiUrl}content/support/${method.structureId}`).then(
        (response: ContentItem[]) => {
          setItems(response);
          setIsLoading(false);
        }
      );
    }
  }, [method]);

  return <Support isLoading={isLoading} contentItems={items} />;
}

export default SupportContainer;
