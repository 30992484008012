import { getJwtToken } from '@infinitaslearning/il.authentication.avatar';
import jwtDecode from 'jwt-decode'
import { devKey, devSelectedSchool } from '../api/settingsLoader';

export const getToken = (): Promise<string> => {
  if (process.env.NODE_ENV === 'development') {
    return new Promise((resolve, reject) => {
      if (devKey) {
        resolve(devKey);
      } else {
        reject();
      }
    });
  }
  return new Promise((resolve, reject) => {
    getJwtToken()
      .then(jwt => {
        resolve(jwt);
      })
      .catch(() => {
        reject();
      });
  });
};

const decodeJwt = (jwt: string) => JSON.parse(JSON.stringify(jwtDecode(jwt)));

export function getSelectedSchoolIdFromToken(jwt: string): string {
  const decodedJwt = decodeJwt(jwt);
  return decodedJwt.selectedschool;
}

export const isValidJwt = (jwt: string): boolean => {
  if (jwt === '' || jwt === undefined) {
    return false;
  }
  const decodedJwt = decodeJwt(jwt);
  if (Date.now() >= decodedJwt.exp * 1000) {
    return false;
  }
  return true;
};

export const isTeacher = (jwt: string): boolean => {
  let roles = decodeJwt(jwt).role;

  if (!Array.isArray(roles)) {
    roles = [roles];
  }

  return ['Teachers', 'Extra_Teachers', 'Support_Teachers', 'Editor'].some(r => roles.includes(r));
};

export const getUdbId = (jwt: string) => {
  const decodedJwt = decodeJwt(jwt);
  return decodedJwt.primarysid;
};

export const getSchoolId = (jwt: string): string => {
  if (process.env.NODE_ENV === 'development') {
    return devSelectedSchool || '';
  }
  return getSelectedSchoolIdFromToken(jwt);
};
