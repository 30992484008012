export const sortNumericallyAndParseToString = (arr: string[], separator = ', '): string =>
  arr.sort((a: string, b: string) => +a - +b).join(separator);

export const sortAlphabeticallyAndParseToString = (arr: string[], separator = ', '): string =>
  arr.sort().join(separator);

export const sortTexts = (a?: string, b?: string): number => {
  if (!a || !b) {
    return -1;
  }
  return a.toLowerCase().localeCompare(b.toLowerCase(), 'nl-NL');
};
