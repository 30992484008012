import { IDP } from '@infinitaslearning/il.authentication.avatar';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getSchoolId, getToken } from '../../services/JwtService';
import { idpConfig } from '../../services/AuthenticationService';
import { logout, login } from '../../graphql/authenticated/authenticatedOperations';

function TPAuthentication() {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    getToken().then(res => {
      if (!res) {
        logout();
        if (location.pathname !== '/login') {
          history.push('/login');
        }
      } else {
        const selectedSchoolId = getSchoolId(res);
        login(res, selectedSchoolId);
        if (location.pathname === '/login') {
          history.push('/');
        }
      }
    });
  }, [history, location]);

  if (location.pathname === '/login') return null;

  if (process.env.NODE_ENV === 'development') {
    return <></>;
  }
  return <IDP {...idpConfig} position="absolute" />;
}
export default TPAuthentication;
