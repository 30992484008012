import { gql } from '@apollo/client';

export const GET_NEWS_FLASHES = gql`
  query newsflashes($pageId: String!) {
    newsflashes(pageId: $pageId) {
      pageId
      id
      title
      content
      image
      file
      link
      relatedFeature
    }
  }
`;
