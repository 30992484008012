import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { useIsNlpNavigationEnabled, useNlpRoutes } from '../../utils/nlpNavigation';
import './style.scss';

type Props = RouteComponentProps;

function PageNotFound({ history }: Props): ReactElement {
  const { t } = useTranslation();

  const isNlpNavigationEnabled = useIsNlpNavigationEnabled();
  const nlpNotificationsUrl = useNlpRoutes('notifications');

  const goToStartPage = () => {
    if (isNlpNavigationEnabled) {
      window.location.href = nlpNotificationsUrl;
    } else {
      history.push('/');
    }
  };

  return (
    <div className="main">
      <div className="main__title">
        <h1>{t('notFoundPage:page_not_found')}</h1>
      </div>
      <div>
        <div className="white-message-container">
          <button className="return-button" onClick={goToStartPage}>
            {t('notFoundPage:go_to_start')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default PageNotFound;
