import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ILErrorModalMolecule from '../../../../../components/ILModalMolecule/ILErrorModalMolecule';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
};

function TPCloseErrorModalOrganism({ isOpen, closeModal }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <ILErrorModalMolecule
      isOpen={isOpen}
      onClose={closeModal}
      title={t('resultsPage:close_tests_failed')}
    >
      <p>
        {`${t('resultsPage:send_message')} `}
        <a
          href="https://www.noordhoff.nl/noordhoff/contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('resultsPage:customer_service')}
        </a>
        {` ${t('resultsPage:call_number')} `}
      </p>
    </ILErrorModalMolecule>
  );
}

export default TPCloseErrorModalOrganism;
