import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import 'moment/locale/nl';
import { TFunction } from 'i18next';
import { Notification } from '../../../models/state/Notification';
import {
  beingGradedTextStudent,
  beingGradedTextTeacher,
  canBeMadeDateText,
  canBeMadePeriodText,
  canBeMadeText,
  canBeMadeTextError,
  needsReviewTeacher,
  finishedWithTextTeacher,
  finishedWithTextStudent,
  finishedWithText4RTPStudent,
  unknownStatusText,
  noNotificationsText,
  noLinkedGroupsText,
  noAttachedClassText,
  pendingTextTeacher,
} from './texts';

interface Props {
  notification: Notification;
  isTeacher: boolean;
  isPending: boolean;
}

const setDate = (dateString: string, parseTo: string): string => {
  moment.locale('nl');
  switch (parseTo) {
    case 'DayMonth': {
      return moment(dateString).format('D MMMM');
    }
    case 'LocalizedTime': {
      return moment(dateString).format('LT');
    }
    default: {
      return dateString;
    }
  }
};

const getNotificationTextTeacher = (
  notification: Notification,
  isPending: boolean
): JSX.Element => {
  switch (notification.notificationStatus) {
    case 'NoNotifications':
      return noNotificationsText();
    case 'NoLinkedGroups':
      return noLinkedGroupsText();
    case 'FinishedNotReviewed':
      return isPending
        ? pendingTextTeacher(notification.groupName, notification.planningTitle)
        : needsReviewTeacher(notification.groupName, notification.planningTitle);
    case 'FinishedNotReleased':
      return beingGradedTextTeacher(
        notification.groupName,
        notification.planningTitle,
        setDate(notification.endDate, 'DayMonth')
      );
    case 'FinishedReleased':
      return finishedWithTextTeacher(
        notification.groupName,
        notification.planningTitle,
        setDate(notification.endDate, 'DayMonth')
      );
    default:
      return unknownStatusText(notification.planningTitle);
  }
};

const createTiming = (
  startDate: string,
  endDate: string,
  planningsType: string,
  t: TFunction
): string | JSX.Element => {
  switch (planningsType) {
    case 'Direct': {
      return t('notifications:now');
    }
    case 'Hour': {
      return canBeMadeDateText(
        setDate(startDate, 'DayMonth'),
        setDate(startDate, 'LocalizedTime'),
        setDate(endDate, 'LocalizedTime')
      );
    }
    case 'Period': {
      return canBeMadePeriodText(setDate(startDate, 'DayMonth'), setDate(endDate, 'DayMonth'));
    }
    default: {
      return endDate;
    }
  }
};

const getNotificationTextStudent = (notification: Notification, t: TFunction): JSX.Element => {
  switch (notification.notificationStatus) {
    case 'NoNotifications':
      return noNotificationsText();
    case 'NoLinkedGroups':
      return noAttachedClassText();
    case 'ClosedNotStarted':
    case 'OpenNotStarted':
      return canBeMadeText(
        notification.planningTitle,
        createTiming(notification.startDate, notification.endDate, notification.planningType, t)
      );
    case 'OpenNoLicense':
      return canBeMadeTextError(notification.planningTitle);
    case 'OpenNotFinished':
      return canBeMadeText(
        notification.planningTitle,
        createTiming(notification.startDate, notification.endDate, notification.planningType, t)
      );
    case 'FinishedNotReviewed':
    case 'FinishedNotReleased':
      return beingGradedTextStudent(notification.planningTitle);
    case 'FinishedReleased':
      if (notification.resultType === 'ReferenceTestOverall') {
        return finishedWithText4RTPStudent(notification.planningTitle, notification.displayGrade);
      }
      return finishedWithTextStudent(notification.planningTitle, notification.displayGrade);
    default:
      return unknownStatusText(notification.planningTitle);
  }
};

function TPNotificationTextAtom({ notification, isTeacher, isPending }: Props): ReactElement {
  const { t } = useTranslation();
  if (isTeacher) {
    return getNotificationTextTeacher(notification, isPending);
  }
  return getNotificationTextStudent(notification, t);
}

export default TPNotificationTextAtom;
