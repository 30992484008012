import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertType } from './AlertType';
import ILIconAtom, { IconType } from '../ILIconAtom';
import './style.scss';

interface Props {
  type: AlertType;
  message: string;
  showIcon?: boolean;
  children?: JSX.Element | JSX.Element[];
}

function ILAlertMessageMolecule({ type, message, showIcon, children }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="alert-container">
      <div className={`alert alert-${type}`}>
        <div className="alert-message">
          {showIcon ? (
            <ILIconAtom type={IconType.Alert} size={20} />
          ) : (
            <span className="alert-title">{t(`alert:${type}`)}</span>
          )}
          <span>{message}</span>
        </div>
        {children && <div className="alert-action">{children}</div>}
      </div>
    </div>
  );
}

export default ILAlertMessageMolecule;
