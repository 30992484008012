import { makeVar, ReactiveVar, TypePolicies } from '@apollo/client';
import { DEFAULT_LANGUAGE } from '../../constants/ArrangeTestContstants';
import { QuestionFilters } from '../../models/state/arrangeTests/ArrangedTest';
import { FilterType, FilterObject } from '../../models/state/arrangeTests/Filters';

export const availableFiltersVar: ReactiveVar<FilterType[]> = makeVar<FilterType[]>([]);

export const questionFilterOptionsVar: ReactiveVar<QuestionFilters> = makeVar<QuestionFilters>({
  domain: [],
  kti: [],
  type: [],
});

const setDefaultLanguage = (incoming: FilterObject[]) => {
  if (incoming.length > 0 && incoming[0].key === FilterType.Language) {
    const langFilter = incoming[0];
    const newList = [...incoming];
    newList[0] = { ...langFilter, selectedValues: [DEFAULT_LANGUAGE] };
    return newList;
  }
  return incoming;
};

const enableFilters = (incoming: FilterObject[]) => {
  const newFilters = [...incoming];
  newFilters.forEach((filter: FilterObject, index) => {
    if (filter.key === FilterType.Language || filter.key === FilterType.Level) {
      filter.isEnabled = true;
    } else {
      const previousFilter = newFilters[index - 1];
      filter.isEnabled = previousFilter && previousFilter.selectedValues?.length > 0;
    }
  });
  return newFilters;
};

const getExistingFilterObject = (
  existingFilters: FilterObject[],
  filterKey: FilterType
): FilterObject | undefined =>
  existingFilters.find((filter: FilterObject) => filter.key === filterKey);

const hasSelectedLanguageChanged = (
  oldFilter: FilterObject,
  incomingFilter: FilterObject
): boolean =>
  incomingFilter.key === FilterType.Language &&
  oldFilter.selectedValues &&
  incomingFilter.selectedValues &&
  oldFilter.selectedValues[0] !== incomingFilter.selectedValues[0];

const shouldUpdateFilterValues = (
  oldFilter: FilterObject,
  incomingFilter: FilterObject,
  shouldReset: boolean
): boolean => oldFilter.selectedValues?.length === 0 && incomingFilter.values && !shouldReset;

const filterFilters = (filters: FilterObject[]): FilterObject[] => {
  const availableFilters = availableFiltersVar();
  if (availableFilters?.length > 0) {
    return filters.filter(
      (filter: FilterObject) =>
        filter.key === FilterType.Language ||
        availableFilters.some((filterType: FilterType) => filterType === filter.key)
    );
  }
  return filters;
};

export const arrangeTestCache: TypePolicies = {
  FilterObject: {
    fields: {
      isEnabled: {
        read(isEnabled = false) {
          return isEnabled;
        },
      },
      selectedValues: {
        read(selectedValues = []) {
          return selectedValues;
        },
      },
    },
  },
  Query: {
    fields: {
      availableFilters: {
        read() {
          return availableFiltersVar();
        },
      },
      arrangedTest: {
        read(
          arrangedTest = {
            title: '',
            mgrRecipeId: '',
            currentStep: 0,
            selectedQuestions: [],
          }
        ) {
          return arrangedTest;
        },
      },
      filters: {
        keyArgs: false,
        merge(existing, incoming) {
          const filteredIncoming = filterFilters(incoming);
          let filters: FilterObject[] = [];
          let resetSelectedFilters = false;
          if (existing) {
            filteredIncoming.forEach((incomingFilter: FilterObject) => {
              const oldFilter = getExistingFilterObject(existing, incomingFilter.key);
              if (oldFilter) {
                // Reset filters if previous one is updated
                if (resetSelectedFilters) {
                  filters.push({
                    ...oldFilter,
                    selectedValues: [],
                    values: incomingFilter.values,
                  });
                }

                // Handle new selected values
                else if (incomingFilter.selectedValues) {
                  if (hasSelectedLanguageChanged(oldFilter, incomingFilter)) {
                    resetSelectedFilters = true;
                  }
                  if (incomingFilter.selectedValues?.length !== oldFilter.selectedValues?.length) {
                    resetSelectedFilters = true;
                  }
                  filters.push({ ...oldFilter, selectedValues: incomingFilter.selectedValues });
                }

                // Handle new values from server
                else if (
                  shouldUpdateFilterValues(oldFilter, incomingFilter, resetSelectedFilters)
                ) {
                  filters.push({ ...oldFilter, values: incomingFilter.values });
                } else {
                  filters.push({ ...oldFilter });
                }
              } else {
                filters = filteredIncoming;
              }
            });
          } else {
            filters = setDefaultLanguage(filteredIncoming);
          }
          return enableFilters(filters);
        },
      },
      questionFilterOptions: {
        read() {
          return questionFilterOptionsVar();
        },
      },
      selectedQuestionFilters: {
        read(
          selectedFilters = {
            kti: [],
            domain: [],
            type: [],
          }
        ) {
          return selectedFilters;
        },
      },
    },
  },
};
