import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from './Status';
import './style.scss';

type Props = {
  status: Status;
  started?: number;
  completed?: number;
};

function TPStatusAtom({ status, started, completed }: Props): ReactElement {
  const { t } = useTranslation();
  const getIndicatorClass = (): string => {
    switch (status) {
      case Status.Planned:
        return 'planned-indicator';
      case Status.Started:
        return 'started-indicator';
      case Status.Completed:
        return 'completed-indicator';
      case Status.NotReviewed:
        return 'not-reviewed-indicator';
      case Status.NotReleased:
        return 'not-released-indicator';
      case Status.Released:
        return 'released-indicator';
    }
  };

  return (
    <span className={getIndicatorClass()}>
      {t(`status:${status}`)}
      {status === Status.Completed && completed && started && (
        <span className="completed-status">{`(${completed} ${t('status:from')} ${started})`}</span>
      )}
    </span>
  );
}

export default TPStatusAtom;
