import React, { ReactElement, ReactNode } from 'react';

interface TPNotificationsTableRowAtomProps {
  children: ReactNode;
  button: JSX.Element;
}

function TPNotificationsTableRowAtom({
  children,
  button,
}: TPNotificationsTableRowAtomProps): ReactElement {
  return (
    <tr>
      {children}
      <td className="text-right">{button}</td>
    </tr>
  );
}

export default TPNotificationsTableRowAtom;
