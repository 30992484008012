import { cache } from '../cache';
import { Question, QuestionProperty } from '../../models/state/Question';
import { SelectedQuestion } from '../../models/state/arrangeTests/ArrangedTest';
import { questionFilterOptionsVar } from './cache';

export const resetArrangedTest = (): void => {
  cache.evict({ fieldName: 'arrangedTest' });
  cache.evict({ fieldName: 'selectedQuestionFilters' });
  questionFilterOptionsVar({
    kti: [],
    type: [],
    domain: [],
  });
  cache.gc();
};

const getQuestionProperty = (question: Question, filterKey: string): string[] => {
  const filter = question.properties.find(
    (property: QuestionProperty) => property.key === filterKey
  );
  return filter ? filter.value : [];
};

export const resetSelectedQuestionFilters = (): void => {
  cache.evict({ fieldName: 'selectedQuestionFilters' });
  cache.gc();
};

const mapQuestionToSelectedQuestion = (question: Question, isVe: boolean): SelectedQuestion => ({
  id: question.id,
  maxScore: question.maxScore,
  level: getQuestionProperty(question, isVe ? 'niveau' : 'niveau_vo'),
  year: isVe ? [] : getQuestionProperty(question, 'leerjaar_vo'),
  chapter: getQuestionProperty(question, 'hoofdstuknummer'),
  kti: getQuestionProperty(question, 'kti'),
  domain: getQuestionProperty(question, 'domein'),
  open: question.open,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  __typename: 'SelectedQuestion',
});

export const getNewQuestionsList = (
  oldQuestionList: SelectedQuestion[],
  question: Question,
  isVe: boolean
): SelectedQuestion[] =>
  oldQuestionList.some((item: SelectedQuestion) => item.id === question.id)
    ? oldQuestionList.filter((item: SelectedQuestion) => item.id !== question.id)
    : [...oldQuestionList, mapQuestionToSelectedQuestion(question, isVe)];
