import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterObject, FilterType } from '../../../../models/state/arrangeTests/Filters';
import { SelectType } from '../../../../models/enums/SelectType';
import TPFilterSelectMolecule from './TPFilterSelectMolecule';

type Props = {
  filter: FilterObject;
  isLoading: boolean;
  handleFilterChange: (filterType: FilterType, values: string[]) => void;
};

function TPFilterSelectMoleculeContainer({ filter, isLoading, handleFilterChange }: Props): ReactElement {
  const { t } = useTranslation();

  const getInputType = (filterType: FilterType) => {
    if (filterType === FilterType.Language) {
      return SelectType.Single;
    }
    return SelectType.Multiple;
  };

  const handleFilterSelect = (optionId: string) => {
    if (filter.isEnabled) {
      if (getInputType(filter.key) === SelectType.Single) {
        handleFilterChange(filter.key, [optionId]);
      } else {
        const newValues = filter.selectedValues.some((value: string) => value === optionId)
          ? filter.selectedValues.filter((value: string) => value !== optionId)
          : [...filter.selectedValues, optionId];
        handleFilterChange(filter.key, newValues);
      }
    }
  };

  const getFilterName = (type: FilterType) => {
    switch (type) {
      case FilterType.Language:
        return t('filterStep:language');
      case FilterType.Level:
        return t('filterStep:levels');
      case FilterType.Year:
        return t('filterStep:year');
      case FilterType.Chapter:
        return t('filterStep:chapters');
    }
  };

  return (
    <TPFilterSelectMolecule
      filter={filter}
      displayName={getFilterName(filter.key)}
      selectType={getInputType(filter.key)}
      isLoading={isLoading}
      handleFilterSelect={handleFilterSelect}
    />
  );
}

export default TPFilterSelectMoleculeContainer;
