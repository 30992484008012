import { useParams } from 'react-router';
import { nlpUrl } from '../api/settingsLoader';

export interface NlpContentMatchParams {
  themeId?: string;
  bookId?: string;
}

export type NLPLocationKey =
  | 'notifications'
  | 'chapterTests'
  | 'levelTests'
  | 'myTests'
  | 'plannedTests'
  | 'allResults'
  | 'classesOverview'
  | 'classResults'
  | 'studentsOverview'
  | 'studentResults';

export const useNlpRoutes = (
  locationKey: NLPLocationKey | undefined,
  resultOwnerId?: string
): string => {
  const { themeId, bookId } = useParams<NlpContentMatchParams>();
  const assessmentUrl = `${nlpUrl}assessment`;

  if (themeId || bookId) {
    switch (locationKey) {
      case 'chapterTests':
        return themeId
          ? `${assessmentUrl}/theme/${themeId}/tests/chapter`
          : `${assessmentUrl}/book/${bookId}/tests/chapter`;
      case 'levelTests':
        return themeId
          ? `${assessmentUrl}/theme/${themeId}/tests/level`
          : `${assessmentUrl}/book/${bookId}/tests/level`;
      case 'myTests':
        return themeId
          ? `${assessmentUrl}/theme/${themeId}/tests/arranged`
          : `${assessmentUrl}/book/${bookId}/tests/arranged`;
      case 'plannedTests':
        return themeId
          ? `${assessmentUrl}/theme/${themeId}/planned-tests`
          : `${assessmentUrl}/book/${bookId}/planned-tests`;
      case 'allResults': {
        return themeId
          ? `${assessmentUrl}/theme/${themeId}/results/all`
          : `${assessmentUrl}/book/${bookId}/results/all`;
      }
      case 'classesOverview': {
        return themeId
          ? `${assessmentUrl}/theme/${themeId}/results/classes`
          : `${assessmentUrl}/book/${bookId}/results/classes`;
      }
      case 'classResults': {
        // eslint-disable-next-line no-nested-ternary
        return resultOwnerId
          ? themeId
            ? `${assessmentUrl}/theme/${themeId}/results/class/${resultOwnerId}`
            : `${assessmentUrl}/book/${bookId}/results/class/${resultOwnerId}`
          : '';
      }
      case 'studentsOverview': {
        return themeId
          ? `${assessmentUrl}/theme/${themeId}/results/students`
          : `${assessmentUrl}/book/${bookId}/results/students`;
      }
      case 'studentResults': {
        // eslint-disable-next-line no-nested-ternary
        return resultOwnerId
          ? themeId
            ? `${assessmentUrl}/theme/${themeId}/results/student/${resultOwnerId}`
            : `${assessmentUrl}/book/${bookId}/results/student/${resultOwnerId}`
          : '';
      }
      case 'notifications':
      default:
        return themeId
          ? `${assessmentUrl}/theme/${themeId}/notifications`
          : `${assessmentUrl}/book/${bookId}/notifications`;
    }
  }
  return `${nlpUrl}home/overview`;
};

export const useNlpGroupDashboardRoutes = (
  planId: string,
  isPending: boolean,
  resultOwnerId?: string
) => {
  const { themeId, bookId } = useParams<NlpContentMatchParams>();
  const assessmentUrl = `${nlpUrl}assessment`;

  if (themeId || bookId) {
    if (resultOwnerId) {
      return themeId
        ? `${assessmentUrl}/theme/${themeId}/plan/${planId}/result-dashboard?groupId=${resultOwnerId}${
            isPending ? '&pending=true' : ''
          }`
        : `${assessmentUrl}/book/${bookId}/plan/${planId}/result-dashboard?groupId=${resultOwnerId}${
            isPending ? '&pending=true' : ''
          }`;
    }
    return themeId
      ? `${assessmentUrl}/theme/${themeId}/plan/${planId}/result-dashboard${
          isPending ? '?pending=true' : ''
        }`
      : `${assessmentUrl}/book/${bookId}/plan/${planId}/result-dashboard${
          isPending ? '?pending=true' : ''
        }`;
  }
  return `${nlpUrl}home/overview`;
};

export const useNlpStudentDashboardRoutes = (planId: string, studentId: string) => {
  const { themeId, bookId } = useParams<NlpContentMatchParams>();
  const assessmentUrl = `${nlpUrl}assessment`;

  if (themeId || bookId) {
    const pageRoute = `/plan/${planId}/result-dashboard/student/${studentId}`;
    return themeId
      ? `${assessmentUrl}/theme/${themeId}${pageRoute}`
      : `${assessmentUrl}/book/${bookId}${pageRoute}`;
  }
  return `${nlpUrl}home/overview`;
};

export const useIsNlpNavigationEnabled = (): boolean => {
  const { themeId, bookId } = useParams<NlpContentMatchParams>();
  return !!themeId || !!bookId;
};
