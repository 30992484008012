import React, { useState, useEffect, useCallback, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import ILViewAnswersTopbarMolecule from './ILViewAnswersTopbarMolecule';
import {
  QuestionWithResult,
  ResultItem,
  ResultResponseItem,
  ScoringItem,
} from '../../models/dto/QuestionWithResult';
import ILQuestionItemMolecule from '../ILQuestionItemMolecule';
import { QuestionType, QuestionBlock } from '../../models/state/Question';
import { MenuItem } from './ILViewAnswersTopbarMolecule/ILViewAnswersTopbarMolecule';
import { formatFloatValue } from '../../utils/DataFormatter';
import ILQuestionStats from './ILQuestionStats/ILQuestionStats';

interface Props {
  questionsWithResults: QuestionWithResult[];
  handleQuestionChange?: (questionId: string) => void;
  showTotalStats?: boolean;
  averageScore?: number;
  averageDuration?: number;
}

function ILViewAnswersOrganism({
  questionsWithResults,
  handleQuestionChange,
  averageDuration,
  averageScore,
  showTotalStats,
}: Props): ReactElement {
  const { t } = useTranslation();

  const [mappedQuestions, setMappedQuestions] = useState<JSX.Element[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<number>(0);

  const checkAnswerByScoring = (
    givenAnswer: string,
    scoring: ScoringItem[],
    key: string
  ): boolean => {
    const filteredScoring = scoring.filter((scoringItem: ScoringItem) => scoringItem.key === key);
    let isCorrect = false;
    filteredScoring.forEach((scoringItem: ScoringItem) => {
      if (scoringItem.caseSensitive) {
        if (scoringItem.keys.some((answer: string) => answer === givenAnswer)) {
          isCorrect = true;
        }
      } else if (
        scoringItem.keys.some(
          (answer: string) => answer.toLowerCase() === givenAnswer.toLowerCase()
        )
      ) {
        isCorrect = true;
      }
    });
    return isCorrect;
  };

  const setTextEntry = useCallback(
    (html: HTMLCollection, result: ResultItem, scoring: ScoringItem[]) => {
      const htmlResponses: NodeListOf<HTMLElement> = html[0].querySelectorAll('.qti-interaction');
      htmlResponses.forEach(response => {
        if (result?.questionResultResponses) {
          const responseItem = result.questionResultResponses.find(
            (item: ResultResponseItem) =>
              item.key.toLowerCase() === response.dataset.responseIdentifier?.toLowerCase()
          );
          const candidateResponse = response.querySelector('.qti-candidateResponse');
          const qtiPoints = response.querySelector('.qti-points');
          if (qtiPoints && qtiPoints.textContent) {
            qtiPoints.textContent = qtiPoints.textContent.replace('(juist)', '');
          }
          if (
            responseItem &&
            candidateResponse &&
            checkAnswerByScoring(candidateResponse.textContent || '', scoring, responseItem.key)
          ) {
            // Create element to show that it is correct
            const elem = document.createElement('div');
            elem.classList.add('correct-answer');
            response.classList.add('correct-response');
            if (candidateResponse.parentNode) {
              candidateResponse.parentNode.insertBefore(elem, candidateResponse.nextSibling);
            }
          } else if (candidateResponse?.parentElement) {
            candidateResponse.parentElement.classList.add('incorrect-answer');
          }
        }
      });
      return html;
    },
    []
  );

  const setInlineChoice = useCallback(
    (html: HTMLCollection, result: ResultItem): HTMLCollection => {
      if (result?.questionResultResponses) {
        Object.values(result.questionResultResponses).forEach(
          (resultResponseItem: ResultResponseItem) => {
            // As the data is not clear enough for this questiontype to determine which block contains interactions,
            // we check if the block contains a response identifier key to work with.
            const responseElem = html[0].querySelector(
              `[data-response-identifier="${resultResponseItem.key.toUpperCase()}"]`
            );

            if (responseElem) {
              const correctAnswer = responseElem?.querySelector('.qti-correctResponse');
              if (resultResponseItem && correctAnswer) {
                if (
                  resultResponseItem.correctResponse.some(
                    response => resultResponseItem.candidateResponse.indexOf(response) >= 0
                  )
                ) {
                  const points = correctAnswer?.querySelector('.qti-points');
                  if (points && correctAnswer) {
                    correctAnswer.innerHTML = '';
                    correctAnswer.appendChild(points);
                    if (correctAnswer.parentElement) {
                      correctAnswer.parentElement.classList.add('correct-answer');
                    }
                  }
                } else if (correctAnswer.parentElement) {
                  correctAnswer.parentElement.classList.add('incorrect-answer');
                }
              }
            }
          }
        );
      }
      return html;
    },
    []
  );

  const getCorrectResponses = (result: ResultItem, identifier: string): string[] => {
    const correctResponses: string[] = [];
    Object.values(result.questionResultResponses).forEach((resultResponse: ResultResponseItem) => {
      if (resultResponse.key.toLowerCase() === identifier.toLowerCase()) {
        Object.values(resultResponse.correctResponse).forEach((response: string) => {
          correctResponses.push(response);
        });
      }
    });
    return correctResponses;
  };

  const setExtendedTextEntry = useCallback(
    (html: HTMLCollection, result: ResultItem, identifier: string): HTMLCollection => {
      // Remindo sends media as interaction, causing the correctResponse to be displayed twice.
      // Block should not be altered when it is interaction
      if (html[0].querySelector('.qti-mediaplayer')) {
        return html;
      }
      const correctResponses = getCorrectResponses(result, identifier);
      const candidateResponse = html[0].querySelector('.qti-candidateResponse');
      if (candidateResponse && correctResponses.length > 0) {
        correctResponses.forEach((response: string) => {
          candidateResponse.insertAdjacentHTML('afterend', response);
        });
        if (result.maxScore === result.score) {
          candidateResponse.classList.add('correct-answer');
        } else {
          candidateResponse.classList.add('incorrect-answer');
        }
        // text entry needs some extra classes here because otherwise it will mess up the combined questions.
        candidateResponse.classList.add('extended-text-candidate-response');
        const correctResponseElem = html[0].querySelector('.qti-correctResponse');
        if (correctResponseElem) {
          correctResponseElem.classList.add('extended-text-feedback');
        }
      }
      return html;
    },
    []
  );

  const setFeedback = useCallback((result: ResultItem): string => {
    const html = document.createElement('div');
    Object.values(result.questionResultResponses).forEach((response: ResultResponseItem) => {
      if (response.feedback) {
        response.feedback.forEach((feedbackItem: string) => {
          const feedbackDiv = document.createElement('div');
          feedbackDiv.classList.add('feedback-item');
          feedbackDiv.innerHTML = feedbackItem;
          html.append(feedbackDiv);
        });
      }
    });
    return html.innerHTML;
  }, []);

  const setOrderQuestion = (html: HTMLCollection, result: ResultItem): HTMLCollection => {
    const givenAnswers = Object.values(result.questionResultResponses)[0]?.candidateResponse;
    const correctAnswers = Object.values(result.questionResultResponses)[0]?.correctResponse;
    const elements = html[0].querySelectorAll(
      '.qti-candidateResponse .qti-simpleOrder-answerList .qti-simpleOrder-block'
    );
    // Add extra classes to fix view of questions outside this component
    const givenAnswerElem = html[0].querySelector('.qti-candidateResponse');
    if (givenAnswerElem) {
      givenAnswerElem.classList.add('given-answer-content');
    }
    const answerElem = html[0].querySelector('.qti-correctResponse');
    if (answerElem) {
      answerElem.classList.add('answer-content');
    }

    if (elements.length > 0) {
      for (let i = 0; i < correctAnswers.length; i += 1) {
        if (elements[i]) {
          if (givenAnswers[i] && givenAnswers[i] === correctAnswers[i]) {
            elements[i].classList.add('correct-answer');
          } else {
            elements[i].classList.add('incorrect-answer');
          }
        }
      }
    }
    return html;
  };

  const parseHtml = useCallback(
    (
      htmlString: string,
      result: ResultItem,
      scoring: ScoringItem[] | null,
      type: QuestionType,
      identifier: string
    ) => {
      const html = new DOMParser().parseFromString(htmlString, 'text/html').body.children;
      if (html && html[0]) {
        switch (type) {
          case QuestionType.TextEntry:
            if (scoring) {
              return setTextEntry(html, result, scoring)[0].innerHTML;
            }
            break;
          case QuestionType.Order:
            return setOrderQuestion(html, result)[0].innerHTML;
          case QuestionType.ExtendedText:
            return setExtendedTextEntry(html, result, identifier)[0].innerHTML;
          case QuestionType.InlineChoice:
            return setInlineChoice(html, result)[0].innerHTML;
          default:
            return htmlString;
        }
      }
      return htmlString;
    },
    [setExtendedTextEntry, setInlineChoice, setTextEntry]
  );

  const shouldParseAllBlocks = (type: QuestionType): boolean =>
    type === QuestionType.InlineChoice ||
    type === QuestionType.TextEntry ||
    type === QuestionType.PositionObject;

  const mapQuestionsWithResults = useCallback(() => {
    setMappedQuestions(
      questionsWithResults.map((item: QuestionWithResult, key) => {
        const newBlocks: QuestionBlock[] = [];
        item.blocks.forEach((block: QuestionBlock) => {
          if (block.type === 'interaction' || shouldParseAllBlocks(item.type)) {
            newBlocks.push({
              identifier: block.identifier,
              type: block.type,
              html: parseHtml(block.html, item.result, item.scoring, item.type, block.identifier),
            });
          } else {
            newBlocks.push(block);
          }
        });
        newBlocks.push({
          identifier: 'feedback',
          type: 'feedback',
          html: setFeedback(item.result),
        });
        return (
          <div key={item.id}>
            <h3>{`${t('viewAnswers:question')} ${key + 1}`}</h3>
            <div className="question-details">
              <p>
                <strong>{`${t('viewAnswers:duration')} `}</strong>
                {`${item.result.duration} ${t('viewAnswers:seconds')}`}
              </p>
              <p>
                <strong>{`${t('viewAnswers:score')} `}</strong>
                {`${formatFloatValue(item.result.score)} ${t('viewAnswers:of')}
                ${formatFloatValue(item.result.maxScore)} ${
                  item.result.maxScore === 1 ? t('viewAnswers:point') : t('viewAnswers:points')
                }`}
              </p>
            </div>
            <ILQuestionItemMolecule type={item.type} blocks={newBlocks} />
          </div>
        );
      })
    );
  }, [parseHtml, questionsWithResults, setFeedback, t]);

  useEffect(() => {
    if (questionsWithResults && questionsWithResults.length > 0) {
      mapQuestionsWithResults();
    }
  }, [mapQuestionsWithResults, parseHtml, questionsWithResults]);

  useEffect(() => {
    if (handleQuestionChange) {
      handleQuestionChange(questionsWithResults[selectedQuestion].id);
    }
  }, [handleQuestionChange, questionsWithResults, selectedQuestion]);

  const mapMenuItems = () => {
    const menuList: MenuItem[] = [];
    questionsWithResults.forEach((questionItem: QuestionWithResult) => {
      menuList.push({
        isPassed: questionItem.result.score === questionItem.result.maxScore,
        id: questionItem.id,
      });
    });
    return menuList;
  };

  return (
    <div className="answer-viewer-wrapper">
      <ILViewAnswersTopbarMolecule
        handleChange={setSelectedQuestion}
        currentSelected={selectedQuestion}
        menuItems={mapMenuItems()}
      />
      {showTotalStats && (
        <ILQuestionStats
          maxScore={questionsWithResults[selectedQuestion].maxScore}
          averageDuration={averageDuration || 0}
          averageScore={averageScore || 0}
        />
      )}
      <div className="question-result-wrapper">{mappedQuestions[selectedQuestion]}</div>
    </div>
  );
}

export default ILViewAnswersOrganism;
