import React, { ReactElement } from 'react';
import { Filter, ReactTableFunction } from 'react-table';

interface Props {
  filter: Filter;
  onChange: ReactTableFunction;
  placeholder: string;
}

function ILTableFilterTextAtom({ filter, onChange, placeholder }: Props): ReactElement {
  return (
    <input
      type="text"
      placeholder={placeholder}
      onChange={event => onChange(event.target.value)}
      value={filter ? filter.value : ''}
    />
  );
}

export default ILTableFilterTextAtom;
