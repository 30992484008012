import React, { ReactElement, useState } from 'react';
import { Collapse } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { StudentFeedback } from '../../../../models/dto/ReviewTest';
import ILIconAtom, { IconType } from '../../../../components/ILIconAtom';
import TPReviewFeedbackModalOrganism from '../TPReviewFeedbackModalOrganism';

type Props = {
  title: string;
  isReviewed: boolean;
  giveFeedbackEnabled: boolean;
  studentFeedback?: StudentFeedback;
  children: JSX.Element;
};

function TPReviewExpandableBlockOrganism({
  title,
  isReviewed,
  giveFeedbackEnabled,
  studentFeedback,
  children,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);

  return (
    <Collapse className="review-block" in={isOpen} collapsedHeight="60px">
      <div className="review-block-header" onClick={() => setIsOpen(!isOpen)}>
        <h3>{title}</h3>
        <div className="header-info">
          <span
            className={`review-status ${isReviewed ? 'review-status-done' : 'review-status-todo'}`}
          >
            {isReviewed ? t('reviewPage:reviewed') : t('reviewPage:not_reviewed')}
          </span>
          <ILIconAtom type={isOpen ? IconType.Collapse : IconType.Expand} />
        </div>
      </div>
      <div className="review-block-content">
        {children}
        {giveFeedbackEnabled && (
          <>
            <div className="review-feedback">
              <button
                className="tp-button tp-button-primary tp-button-with-icon tp-button-thin"
                onClick={() => setIsFeedbackModalOpen(true)}
              >
                <ILIconAtom type={IconType.Feedback} size={16} />
                {t('reviewPage:give_feedback')}
              </button>
            </div>
            <TPReviewFeedbackModalOrganism
              isOpen={isFeedbackModalOpen}
              studentFeedback={studentFeedback}
              closeModal={() => setIsFeedbackModalOpen(false)}
            />
          </>
        )}
      </div>
    </Collapse>
  );
}

export default TPReviewExpandableBlockOrganism;
