import React, { ReactElement } from 'react';
import { MenuItem } from '../ILMenuMolecule/ILMenuMolecule';
import ILBreadCrumbsMolecule from './ILBreadCrumbsMolecule';
import './style.scss';

interface Props {
  title?: string;
  menuItem: MenuItem;
}

function TPBlueHeaderOrganism({ title, menuItem }: Props): ReactElement {
  return (
    <>
      <section className="blue-header">
        <section className="blue-header-container">
          <section className="blue-header__content">
            <ILBreadCrumbsMolecule menuItem={menuItem} />
          </section>
          <section className="blue-header__title">{title ? <span>{title}</span> : ''}</section>
        </section>
      </section>
    </>
  );
}

export default TPBlueHeaderOrganism;
