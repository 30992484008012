import React, { ReactElement } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import ReactMinimalPieChart from 'react-minimal-pie-chart';

interface Props {
  knowledgeSum: number;
  applicationSum: number;
  insightSum: number;
}

function TPKtiPanelMolecule({
  knowledgeSum,
  applicationSum,
  insightSum,
}: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <div>
      <h2 className="panel-title">{t('arrangedTestDetails:kti_summary')}</h2>
      <div className="chart-container">
        <div className="pie-chart">
          <ReactMinimalPieChart
            data={[
              {
                color: '#E6E6E6',
                title: `${t('arrangedTestDetails:kti_summary')}`,
                value: knowledgeSum + applicationSum + insightSum === 0 ? 1 : 0,
              },
              {
                color: '#FFCC00',
                title: `${t('arrangedTestDetails:knowledge')}`,
                value: knowledgeSum,
              },
              {
                color: '#7FBEE8',
                title: `${t('arrangedTestDetails:application')}`,
                value: applicationSum,
              },
              {
                color: '#F52814',
                title: `${t('arrangedTestDetails:insight')}`,
                value: insightSum,
              },
            ]}
            startAngle={270}
            label={false}
            lineWidth={55}
          />
        </div>
        <ul className="kti-details">
          <li className="knowledge">
            {t('arrangedTestDetails:knowledge')}
            <span>{`(${knowledgeSum})`}</span>
          </li>
          <li className="application">
            {t('arrangedTestDetails:application')}
            <span>{`(${applicationSum})`}</span>
          </li>
          <li className="insight">
            {t('arrangedTestDetails:insight')}
            <span>{`(${insightSum})`}</span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default TPKtiPanelMolecule;
