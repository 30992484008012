import { PlanningResult, ResultGroup } from '../models/dto/PlanningResult';
import { Option } from '../components/ILTableOrganism/ILTableColumn/createColumns';
import { mapTextToCustomFilterOption } from '../components/ILTableOrganism/utils/rowFiltering';

export const parseArrayToString = (arr: string[] | number[], formatted = false): string => {
  let parsedString = '';

  for (let i = 0; i < arr.length; i += 1) {
    parsedString += arr[i];
    if (i !== arr.length - 1) {
      parsedString += formatted ? ', ' : ',';
    }
  }
  return parsedString;
};

export const mapGroupsToString = (groups: ResultGroup[]): string =>
  groups?.map(group => group.groupName).join(',');

export const mapDistinctClassToTableOption = (data: PlanningResult[]): Option[] => {
  const distinctArray = Array.from(
    new Set(
      data.reduce<string[]>(
        (accumulator, actualPlanning) => [
          ...accumulator,
          ...actualPlanning.groups.map(group => group.groupName),
        ],
        []
      )
    )
  ).sort((a: string, b: string) => a?.toLowerCase().localeCompare(b?.toLowerCase()));
  return distinctArray.map((item: string) => mapTextToCustomFilterOption(item));
};
