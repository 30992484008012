import React, { ReactElement, useEffect } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Provider } from 'react-redux';
import i18next from 'i18next';
import { store } from './store';
import { useGetMethod } from './graphql/method/methodOperations';
import './App.scss';

// components
import TPTeacherAccessForPage from './components/TPTeacherAccessForPage';
import ILAnalyticsAtom from './components/ILAnalyticsAtom';
import TPAuthentication from './components/TPAuthentication';
import { TPRoute } from './components/TPRoutes/TPRoute';
import { TPLayout } from './components/TPLayout/TPLayout';
import { reactPlugin } from './components/ApplicationInsightsWrapper/ApplicationInsightsWrapper';

// Pages
import Login from './pages/Login';
import Home from './pages/Home';
import TakeTest from './pages/TakeTest';
import CloseRemindo from './pages/CloseRemindo';
import TestOverview from './pages/TestOverview';
import MyTests from './pages/MyTests';
import TestDetails from './pages/TestDetails';
import Planning from './pages/Planning';
import ArrangeTest from './pages/ArrangeTest';
import TestAdministrationContainer from './pages/TestAdministration';
import AllResults from './pages/Results/AllResults';
import ClassesOverview from './pages/Results/ClassResults/ClassesOverview';
import ClassAllResults from './pages/Results/ClassResults/ClassAllResults';
import ResultDashboard from './pages/Results/ResultDashboards/GroupResultDashboard';
import StudentsOverview from './pages/Results/StudentResults/StudentsOverview';
import StudentAllResults from './pages/Results/StudentResults/StudentAllResults';
import StudentResultDashboard from './pages/Results/ResultDashboards/StudentResultDashboard';
import ResultsPerQuestion from './pages/ResultsPerQuestion';
import ViewAnswers from './pages/ViewAnswers';
import Support from './pages/Support';
import NewsArchive from './pages/NewsArchive';
import PageNotFound from './pages/PageNotFound';
import ReviewWrapper from './pages/TestReview/ReviewWrapper';

function App(): ReactElement {
  const method = useGetMethod();

  useEffect(() => {
    // eslint-disable-next-line camelcase
    if (method?.didactic_level) {
      i18next.changeLanguage(`nl_${method.didactic_level}`);
    }
  }, [method]);

  return (
    <Provider store={store}>
      <HashRouter>
        <ILAnalyticsAtom />
        <TPAuthentication />
        <Switch>
          <TPRoute exact={true} path="/" component={Home} />
          <TPRoute exact={true} path="/theme/:themeId" component={Home} />
          <TPRoute exact={true} path="/book/:bookId" component={Home} />

          <TPRoute path="/login" component={Login} />
          <TPRoute path="/toets-maken/:planId/:title" component={TakeTest} />
          <TPRoute path="/close-remindo" component={CloseRemindo} />
          <TPRoute path="/mijn-resultaten" component={StudentAllResults} />
          <TPRoute exact={true} path="/resultaat/:planId" component={StudentResultDashboard} />
          <TPRoute path="/404" component={PageNotFound} />
          <TPRoute path="/theme/:themeId/404" component={PageNotFound} />
          <TPRoute path="/book/:bookId/404" component={PageNotFound} />
          <TPTeacherAccessForPage>
            {/* Classes overview */}
            <TPRoute exact={true} path="/resultaten/klassen" component={ClassesOverview} />
            <TPRoute
              exact={true}
              path="/theme/:themeId/resultaten/klassen"
              component={ClassesOverview}
            />
            <TPRoute
              exact={true}
              path="/book/:bookId/resultaten/klassen"
              component={ClassesOverview}
            />

            {/* Class results */}
            <TPRoute exact={true} path="/resultaten/klas/:classId" component={ClassAllResults} />
            <TPRoute
              exact={true}
              path="/theme/:themeId/resultaten/klas/:classId"
              component={ClassAllResults}
            />
            <TPRoute
              exact={true}
              path="/book/:bookId/resultaten/klas/:classId"
              component={ClassAllResults}
            />

            {/* Class result dashboard */}
            <TPRoute
              exact={true}
              path="/resultaat/klas/:classId/:planId"
              component={ResultDashboard}
            />
            <TPRoute
              exact={true}
              path="/theme/:themeId/resultaat/klas/:classId/:planId"
              component={ResultDashboard}
            />
            <TPRoute
              exact={true}
              path="/book/:bookId/resultaat/klas/:classId/:planId"
              component={ResultDashboard}
            />

            {/* View answers */}
            <TPRoute
              exact={true}
              path="/antwoorden-bekijken/:planId/:studentId"
              component={ViewAnswers}
            />
            <TPRoute
              exact={true}
              path="/theme/:themeId/antwoorden-bekijken/:planId/:studentId"
              component={ViewAnswers}
            />
            <TPRoute
              exact={true}
              path="/book/:bookId/antwoorden-bekijken/:planId/:studentId"
              component={ViewAnswers}
            />

            {/* Student overview */}
            <TPRoute exact={true} path="/resultaten/leerlingen" component={StudentsOverview} />
            <TPRoute
              exact={true}
              path="/theme/:themeId/resultaten/leerlingen"
              component={StudentsOverview}
            />
            <TPRoute
              exact={true}
              path="/book/:bookId/resultaten/leerlingen"
              component={StudentsOverview}
            />

            {/* Student results */}
            <TPRoute
              exact={true}
              path="/resultaten/leerling/:studentId"
              component={StudentAllResults}
            />
            <TPRoute
              exact={true}
              path="/theme/:themeId/resultaten/leerling/:studentId"
              component={StudentAllResults}
            />
            <TPRoute
              exact={true}
              path="/book/:bookId/resultaten/leerling/:studentId"
              component={StudentAllResults}
            />

            {/* Student result dashboard */}
            <TPRoute
              exact={true}
              path="/resultaat/leerling/:studentId/:planId"
              component={StudentResultDashboard}
            />
            <TPRoute
              exact={true}
              path="/theme/:themeId/resultaat/leerling/:studentId/:planId"
              component={StudentResultDashboard}
            />
            <TPRoute
              exact={true}
              path="/book/:bookId/resultaat/leerling/:studentId/:planId"
              component={StudentResultDashboard}
            />

            {/* All result overview */}
            <TPRoute exact={true} path="/resultaten/alle" component={AllResults} />
            <TPRoute exact={true} path="/theme/:themeId/resultaten/alle" component={AllResults} />
            <TPRoute exact={true} path="/book/:bookId/resultaten/alle" component={AllResults} />

            {/* All result dashboard */}
            <TPRoute exact={true} path="/resultaat/alle/:planId" component={ResultDashboard} />
            <TPRoute
              exact={true}
              path="/theme/:themeId/resultaat/alle/:planId"
              component={ResultDashboard}
            />
            <TPRoute
              exact={true}
              path="/book/:bookId/resultaat/alle/:planId"
              component={ResultDashboard}
            />

            {/* All result dashboard with review modal open */}
            <Route
              exact={true}
              path="/resultaat/alle/nakijken/:planId"
              render={() => (
                <TPLayout>
                  <ResultDashboard isReviewOpen />
                </TPLayout>
              )}
            />
            <Route
              exact={true}
              path="/theme/:themeId/resultaat/alle/nakijken/:planId"
              render={() => (
                <TPLayout>
                  <ResultDashboard isReviewOpen />
                </TPLayout>
              )}
            />
            <Route
              exact={true}
              path="/book/:bookId/resultaat/alle/nakijken/:planId"
              render={() => (
                <TPLayout>
                  <ResultDashboard isReviewOpen />
                </TPLayout>
              )}
            />

            {/* Chapter test overview */}
            <TPRoute path="/toetsen/hoofdstuk" component={TestOverview} />
            <TPRoute path="/theme/:themeId/toetsen/hoofdstuk" component={TestOverview} />
            <TPRoute path="/book/:bookId/toetsen/hoofdstuk" component={TestOverview} />

            {/* Level test overview */}
            <TPRoute path="/toetsen/niveautoetsen" component={TestOverview} />
            <TPRoute path="/theme/:themeId/toetsen/niveautoetsen" component={TestOverview} />
            <TPRoute path="/book/:bookId/toetsen/niveautoetsen" component={TestOverview} />

            {/* Planned tests */}
            <TPRoute path="/toetsafnames" component={TestAdministrationContainer} />
            <TPRoute path="/theme/:themeId/toetsafnames" component={TestAdministrationContainer} />
            <TPRoute path="/book/:bookId/toetsafnames" component={TestAdministrationContainer} />

            {/* Test details */}
            <TPRoute path="/toets-details/:testId/:origin" component={TestDetails} />
            <TPRoute path="/theme/:themeId/toets-details/:testId/:origin" component={TestDetails} />
            <TPRoute path="/book/:bookId/toets-details/:testId/:origin" component={TestDetails} />

            {/* Test details close remindo */}
            <TPRoute
              path="/toets-details/:testId/:origin/closeRemindoPlayer"
              component={CloseRemindo}
            />
            <TPRoute
              path="/theme/:themeId/toets-details/:testId/:origin/closeRemindoPlayer"
              component={CloseRemindo}
            />
            <TPRoute
              path="/book/:bookId/toets-details/:testId/:origin/closeRemindoPlayer"
              component={CloseRemindo}
            />

            {/* Review per question */}
            <TPRoute path="/toets-nakijken-per-vraag/:planId" component={ReviewWrapper} />
            <TPRoute
              path="/theme/:themeId/toets-nakijken-per-vraag/:planId"
              component={ReviewWrapper}
            />
            <TPRoute
              path="/book/:bookId/toets-nakijken-per-vraag/:planId"
              component={ReviewWrapper}
            />

            {/* Review per student */}
            <TPRoute path="/toets-nakijken-per-leerling/:planId" component={ReviewWrapper} />
            <TPRoute
              path="/theme/:themeId/toets-nakijken-per-leerling/:planId"
              component={ReviewWrapper}
            />
            <TPRoute
              path="/book/:bookId/toets-nakijken-per-leerling/:planId"
              component={ReviewWrapper}
            />

            <TPRoute path="/toets-nakijken-per-student/:planId" component={ReviewWrapper} />
            <TPRoute
              path="/theme/:themeId/toets-nakijken-per-student/:planId"
              component={ReviewWrapper}
            />
            <TPRoute
              path="/book/:bookId/toets-nakijken-per-student/:planId"
              component={ReviewWrapper}
            />

            {/* Create planning */}
            <TPRoute path="/planning/new/:testId/:origin?" component={Planning} />
            <TPRoute path="/book/:bookId/planning/new/:testId/:origin?" component={Planning} />
            <TPRoute path="/theme/:themeId/planning/new/:testId/:origin?" component={Planning} />

            {/* Edit planning */}
            <TPRoute path="/planning/edit/:planId/:origin/:status" component={Planning} />
            <TPRoute
              path="/book/:bookId/planning/edit/:planId/:origin/:status"
              component={Planning}
            />
            <TPRoute
              path="/theme/:themeId/planning/edit/:planId/:origin/:status"
              component={Planning}
            />

            {/* Support */}
            <TPRoute path="/ondersteuning/handleidingen" component={Support} />
            <TPRoute path="/theme/:themeId/ondersteuning/handleidingen" component={Support} />
            <TPRoute path="/book/:bookId/ondersteuning/handleidingen" component={Support} />

            {/* News archive */}
            <TPRoute path="/ondersteuning/nieuwsarchief" component={NewsArchive} />
            <TPRoute path="/theme/:themeId/ondersteuning/nieuwsarchief" component={NewsArchive} />
            <TPRoute path="/book/:bookId/ondersteuning/nieuwsarchief" component={NewsArchive} />

            {/* Arrange test */}
            <TPRoute path="/toets-samenstellen" component={ArrangeTest} />
            <TPRoute path="/book/:bookId/toets-samenstellen" component={ArrangeTest} />
            <TPRoute path="/theme/:themeId/toets-samenstellen" component={ArrangeTest} />

            {/* My tests overview */}
            <TPRoute path="/toetsen/mijn-toetsen" component={MyTests} />
            <TPRoute path="/theme/:themeId/toetsen/mijn-toetsen" component={MyTests} />
            <TPRoute path="/book/:bookId/toetsen/mijn-toetsen" component={MyTests} />

            {/* Results per question */}
            <TPRoute
              path="/resultaten-per-vraag/:planId/:classId?"
              component={ResultsPerQuestion}
            />
            <TPRoute
              path="/book/:bookId/resultaten-per-vraag/:planId/:classId?"
              component={ResultsPerQuestion}
            />
            <TPRoute
              path="/theme/:themeId/resultaten-per-vraag/:planId/:classId?"
              component={ResultsPerQuestion}
            />
          </TPTeacherAccessForPage>
        </Switch>
      </HashRouter>
    </Provider>
  );
}

export default withAITracking(reactPlugin, App, 'App', 'App');
