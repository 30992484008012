import React from 'react';
import { PlanningResult } from '../../../models/dto/PlanningResult';
import { Status } from '../../../components/TPStatusAtom/Status';
import { GradeType } from '../../../models/enums/GradeType';
import { AssignmentType } from '../../../models/enums/AssignmentType';
import { StudentResult } from '../../../models/dto/StudentResult';
import { GroupResult } from '../../../models/dto/GroupResult';
import { PlanningResultStudent } from '../../../models/dto/PlanningResultStudent';
import TPStatusAtom from '../../../components/TPStatusAtom';
import ILScoreMeterAtom from '../../../components/ILScoreMeterAtom';
import '../style.scss';

export const renderStatusColumn = (rowData: PlanningResult): JSX.Element => (
  <TPStatusAtom
    status={rowData.status as Status}
    completed={rowData.completed}
    started={rowData.participants}
  />
);

export const renderScoresColumn = (rowData: PlanningResult, isTeacher: boolean): JSX.Element => (
  <ILScoreMeterAtom
    value={rowData.score}
    isPercentage={true}
    hideValue={isTeacher ? rowData.status === Status.Started : rowData.status !== Status.Released}
  />
);

export const renderGradesColumn = (rowData: PlanningResult): JSX.Element => (
  <ILScoreMeterAtom
    value={rowData.grade}
    caesura={5.5}
    isPercentage={false}
    hideValue={
      rowData.status !== Status.Released || rowData.assignmentType === AssignmentType.ReferenceTest
    }
    notAvailable={rowData.gradeType === GradeType.Alphabetic}
  />
);

export const renderStudentGradesColumn = (rowData: PlanningResultStudent): JSX.Element => (
  <ILScoreMeterAtom
    value={rowData.gradeType === GradeType.Alphabetic ? rowData.alphabeticGrade : rowData.grade}
    caesura={5.5}
    isPercentage={false}
    hideValue={
      rowData.status !== Status.Released || rowData.assignmentType === AssignmentType.ReferenceTest
    }
    isAlphabetic={rowData.gradeType === GradeType.Alphabetic}
  />
);

export const renderMomentColumn = (rowData: StudentResult | GroupResult): JSX.Element => (
  <div className="centered-column">{rowData.numberOfPlannings}</div>
);
