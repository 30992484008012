import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/nl';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { State } from '../../../store';
import { DateRange } from '../../../models/state/Plan';
import ILDateRangeSelectMolecule from './ILDateRangeSelectMolecule';
import { setDateRangeAction } from '../../../store/planning/actions';
import { usePlanningContext } from '../../../pages/Planning/PlanningContext';

interface Props {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  dispatchSetDateRangeAction: (date: DateRange) => void;
}

const mapStateToProps = (state: State) => ({
  startDate: state.plan.startDate,
  endDate: state.plan.endDate,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchSetDateRangeAction: (dateRange: DateRange) => {
    dispatch(setDateRangeAction(dateRange));
  },
});

function ILDateRangeSelectMoleculeContainer ({
  startDate,
  endDate,
  dispatchSetDateRangeAction,
}: Props) {
  const { isEditEnabled } = usePlanningContext();

  const [rangeStartDate, setRangeStartDate] = useState<moment.Moment | null>(startDate || null);

  const [rangeEndDate, setRangeEndDate] = useState<moment.Moment | null>(endDate || null);

  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);

  const handleDateRangeChange = (newRange: DateRange) => {
    if (isEditEnabled) {
      setRangeStartDate(newRange.startDate);
      setRangeEndDate(newRange.endDate);
      dispatchSetDateRangeAction(newRange);
    }
  };

  return (
    <ILDateRangeSelectMolecule
      focusedInput={focusedInput}
      onDateRangeChange={handleDateRangeChange}
      onFocusChange={setFocusedInput}
      rangeEndDate={rangeEndDate}
      rangeStartDate={rangeStartDate}
      disabled={!isEditEnabled}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ILDateRangeSelectMoleculeContainer);
