import { SelectedQuestion, ArrangedTest } from '../../../models/state/arrangeTests/ArrangedTest';
import { ArrangeTestRequest } from '../../../models/dto/ArrangeTestRequest';
import { arrangeTestStudyId } from '../../../api/settingsLoader';

export const getSelectedLevel = (arrangedTest: ArrangedTest): string[] => {
  const filters: string[] = [];
  arrangedTest.selectedQuestions.forEach((question: SelectedQuestion) => {
    question.level.forEach((value: string) => {
      if (!filters.includes(value)) {
        filters.push(value);
      }
    });
  });
  return filters;
};

export const getSelectedYear = (arrangedTest: ArrangedTest): string[] => {
  const filters: string[] = [];
  arrangedTest.selectedQuestions.forEach((question: SelectedQuestion) => {
    question.year.forEach((value: string) => {
      if (!filters.includes(value)) {
        filters.push(value);
      }
    });
  });
  return filters;
};

export const getSelectedChapter = (arrangedTest: ArrangedTest): string[] => {
  const filters: string[] = [];
  arrangedTest.selectedQuestions.forEach((question: SelectedQuestion) => {
    question.chapter.forEach((value: string) => {
      if (!filters.includes(value)) {
        filters.push(value);
      }
    });
  });
  return filters;
};

export const getOpenQuestionCount = (questions: SelectedQuestion[]): number => {
  let sum = 0;
  questions.forEach((question: SelectedQuestion) => {
    if (question.open) {
      sum += 1;
    }
  });
  return sum;
};

export const createArrangedTestDto = (
  arrangedTest: ArrangedTest,
  schoolId: string,
  methodId: string,
  finalized: boolean
): ArrangeTestRequest => ({
  schoolId,
  methodId,
  name: arrangedTest.title || '',
  levels: getSelectedLevel(arrangedTest),
  years: getSelectedYear(arrangedTest),
  chapters: getSelectedChapter(arrangedTest),
  questionCount: arrangedTest.selectedQuestions.length,
  status: finalized ? 'approved' : 'concept',
  studyId: arrangeTestStudyId,
  openQuestionCount: getOpenQuestionCount(arrangedTest.selectedQuestions),
});

export const createQuestionsPayload = (arrangedTest: ArrangedTest): number[] =>
  arrangedTest.selectedQuestions.map(question => question.id);
