import React, { useState, useRef, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '../ILViewAnswersTopbarMolecule/ILViewAnswersTopbarMolecule';
import ILIconAtom, { IconType } from '../../ILIconAtom';
import './style.scss';

interface Props {
  menuItems: MenuItem[];
  selectedItem: number;
  handleChange: (selected: number) => void;
}

function ILQuestionSelector({ menuItems, selectedItem, handleChange }: Props): ReactElement {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const selectorRef = useRef(null);

  const handleOutsideClick = (event: MouseEvent) => {
    if (selectorRef?.current !== event.target) {
      setIsExpanded(false);
      document.removeEventListener('click', handleOutsideClick);
    }
  };

  const handleSelectorExpand = () => {
    if (!isExpanded) {
      setIsExpanded(true);
      document.addEventListener('click', handleOutsideClick);
    } else {
      setIsExpanded(false);
      document.removeEventListener('click', handleOutsideClick);
    }
  };

  const mapQuestionOverviewItems = () =>
    menuItems.map((menuItem: MenuItem, key) => (
      <div
        onClick={() => handleChange(key)}
        className={`question-overview-item ${!menuItem.isPassed ? 'overview-item-incorrect' : ''} ${
          key === selectedItem ? 'overview-item-selected' : ''
        }`}
        key={menuItem.id}
      >
        {key + 1}
      </div>
    ));

  return (
    <div className="question-selector" ref={selectorRef} onClick={() => handleSelectorExpand()}>
      {t('questionSelector:go_to_question')}
      <ILIconAtom type={isExpanded ? IconType.Collapse : IconType.Expand} />
      <div className={`question-overview ${isExpanded ? 'question-overview-collapsed' : ''}`}>
        {mapQuestionOverviewItems()}
      </div>
    </div>
  );
}

export default ILQuestionSelector;
