import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, withStyles } from '@material-ui/core';
import './style.scss';

import { formatLastName } from '../../../utils/NameFormatter';
import ILScoreMeterAtom from '../../../components/ILScoreMeterAtom';
import { StudentSelectorItem } from './TPStudentResultSelectorContainer';

interface Props {
  studentItems: StudentSelectorItem[];
  handleChange: (resultId: string) => void;
}

function TPStudentResultSelector({ studentItems, handleChange }: Props): ReactElement {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const HtmlTooltip = withStyles(() => ({
    tooltip: {
      backgroundColor: '#fff',
      boxShadow: '0px 2px 11px rgba(169, 179, 189, 0.5)',
      color: '#111',
      padding: '8px',
      fontSize: '12px',
    },
  }))(Tooltip);

  const { t } = useTranslation();
  const mapStudentResults = () =>
    studentItems.map((result: StudentSelectorItem) => (
      <HtmlTooltip
        key={result.resultId}
        placement="bottom"
        title={`${formatLastName(result.lastName, result.prefix)}, ${result.firstName}`}
      >
        <tr
          className={`selector-item ${!result.isLoaded ? 'selector-item-disabled' : ''} ${
            result.isSelected ? 'selector-item-selected' : ''
          }`}
          onClick={result.isLoaded ? () => handleChange(result.resultId) : undefined}
        >
          <td>
            <span className="student-name-wrapper">
              {`${formatLastName(result.lastName, result.prefix)}, ${result.firstName}`}
            </span>
          </td>
          <td className="score-cell">
            <ILScoreMeterAtom value={result.score} isPercentage />
          </td>
        </tr>
      </HtmlTooltip>
    ));

  return (
    <div className="student-selector">
      <table>
        <thead>
          <tr>
            <th>{t('studentResultSelector:student')}</th>
            <th className="score-cell">{t('studentResultSelector:score')}</th>
          </tr>
        </thead>
        <tbody>{mapStudentResults()}</tbody>
      </table>
    </div>
  );
}

export default TPStudentResultSelector;
