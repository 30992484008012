export enum PlanErrorTypes {
  NoClassOrStudent = 'no_class_or_student',
  NoSchedule = 'no_schedule',
  NoStartDate = 'no_start_date',
  NoEndDate = 'no_end_date',
  StartBeforeToday = 'start_before_today',
  StartAfterEndOfSchoolYear = 'start_after_end_of_school_year',
  EndBeforeTomorrow = 'end_before_tomorrow',
  EndAfterEndOfSchoolYear = 'end_after_end_of_school_year',
  EndBeforeStartDate = 'end_before_start_date',
  NoDateTime = 'no_date_time',
  DateBeforeToday = 'date_before_today',
  DateAfterEndOfSchoolYear = 'date_after_end_of_school_year',
  EndBeforeStartTime = 'end_before_start_time',
  InvalidPin = 'invalid_pin',
  CaesuraHasEmptyField = 'caesura_has_empty_field',
  InvalidPercentage = 'invalid_percentage',
  InvalidGrade = 'invalid_grade',
  GradesNotAscending = 'grades_not_ascending',
  ScoresNotAscending = 'scores_not_ascending',
}
