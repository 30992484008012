import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { client } from '../../../../graphql/ApolloClientService';
import { GET_STUDENT_FEEDBACK_FRAGMENT } from '../../../../graphql/reviewTest/reviewTestQuery';
import { webApiUrl } from '../../../../api/settingsLoader';
import { StudentFeedback } from '../../../../models/dto/ReviewTest';
import { createRequest } from '../../../../components/ILAjaxEther';
import { MethodType } from '../../../../components/ILAjaxEther/MethodType';
import { ILLoadingIndicator } from '../../../../components/ILLoadingIndicator';
import ILModalMolecule from '../../../../components/ILModalMolecule';
import TPFeedbackMolecule from '../../../../components/TPFeedbackMolecule';
import './style.scss';

type Props = {
  isOpen: boolean;
  studentFeedback: StudentFeedback | undefined;
  closeModal: () => void;
};

function TPReviewFeedbackModalOrganism({
  isOpen,
  studentFeedback,
  closeModal,
}: Props): ReactElement {
  const [isSaving, setIsSaving] = useState(false);
  const { t } = useTranslation();

  const saveFeedback = (newFeedback: string) => {
    if (studentFeedback) {
      setIsSaving(true);
      client.writeFragment({
        id: `StudentFeedback:{"resultId":"${studentFeedback.resultId}"}`,
        fragment: GET_STUDENT_FEEDBACK_FRAGMENT,
        data: {
          givenFeedback: newFeedback,
        },
      });
      createRequest(MethodType.Update, `${webApiUrl}result/feedback`, {
        resultIds: [studentFeedback.resultId],
        value: newFeedback,
      })
        .then(() => {
          setIsSaving(false);
          closeModal();
        })
        .catch(() => setIsSaving(false));
    }
  };

  return (
    <ILModalMolecule isOpen={isOpen} title={t('reviewPage:give_feedback')} handleClose={closeModal}>
      <div className="feedback-modal">
        {isSaving ? (
          <div className="feedback-loading">
            <ILLoadingIndicator />
          </div>
        ) : (
          <TPFeedbackMolecule
            feedback={studentFeedback?.givenFeedback || studentFeedback?.feedback || ''}
            sendFeedback={saveFeedback}
            onCancel={closeModal}
            isVertical
          />
        )}
      </div>
    </ILModalMolecule>
  );
}

export default TPReviewFeedbackModalOrganism;
