import React, { ReactElement } from 'react';
import { useQuery } from '@apollo/client';
import { SelectedQuestion, ArrangedTestResult } from '../../models/state/arrangeTests/ArrangedTest';
import TPArrangedTestDetailsSideOrganism from './TPArrangedTestDetailsSideOrganism';
import { parseArrayToString } from '../../utils/DataObjectMapper';
import {
  getOpenQuestionCount,
  getSelectedChapter,
  getSelectedLevel,
  getSelectedYear,
} from '../../pages/ArrangeTest/utils/mapArrangeTest';
import TPArrangedTestDetailsTopOrganism from './TPArrangedTestDetailsTopOrganism';
import { GET_ARRANGED_TEST } from '../../graphql/arrangeTest/arrangeTestQueries';
import { client } from '../../graphql/ApolloClientService';

interface Props {
  topBar?: boolean;
}

function TPArrangedTestDetailsOrganismContainer({ topBar = false }: Props): ReactElement | null {
  const { arrangedTest }: ArrangedTestResult = useQuery(GET_ARRANGED_TEST).data;

  if (arrangedTest) {
    const getScoresSum = (): string => {
      let sum = 0;
      arrangedTest.selectedQuestions.forEach((question: SelectedQuestion) => {
        sum += question.maxScore;
      });
      return sum.toString().replace('.', ',');
    };

    const getKTISum = (key: string) => {
      let sum = 0;
      arrangedTest.selectedQuestions.forEach((question: SelectedQuestion) => {
        if (question.kti.includes(key)) {
          sum += 1;
        }
      });
      return sum;
    };

    const getSelectedDomains = (): Map<string, number> => {
      const domainMapping = new Map();
      arrangedTest.selectedQuestions.forEach((question: SelectedQuestion) => {
        question.domain.forEach((domain: string) => {
          if (domainMapping.has(domain)) {
            domainMapping.set(domain, domainMapping.get(domain) + 1);
          } else {
            domainMapping.set(domain, 1);
          }
        });
      });
      return new Map([...domainMapping.entries()].sort());
    };

    const setTitle = (title: string) => {
      // REGEX: new line characters (linefeed, carriage return)
      client.writeQuery({
        query: GET_ARRANGED_TEST,
        data: {
          arrangedTest: {
            ...arrangedTest,
            title: title.replace(/[\n\r]+/g, ' '),
          },
        },
      });
    };

    return topBar ? (
      <TPArrangedTestDetailsTopOrganism
        title={arrangedTest.title}
        level={parseArrayToString(getSelectedLevel(arrangedTest), true)}
        year={parseArrayToString(getSelectedYear(arrangedTest), true)}
        chapters={parseArrayToString(getSelectedChapter(arrangedTest), true)}
        numberOfPoints={getScoresSum()}
        numberOfQuestions={arrangedTest.selectedQuestions.length}
        numberOfOpenQuestion={getOpenQuestionCount(arrangedTest.selectedQuestions)}
        domains={getSelectedDomains()}
        getKTISum={getKTISum}
        handleTitleChange={setTitle}
      />
    ) : (
      <TPArrangedTestDetailsSideOrganism
        level={parseArrayToString(getSelectedLevel(arrangedTest), true)}
        year={parseArrayToString(getSelectedYear(arrangedTest), true)}
        chapters={parseArrayToString(getSelectedChapter(arrangedTest), true)}
        numberOfPoints={getScoresSum()}
        numberOfQuestions={arrangedTest.selectedQuestions.length}
        title={arrangedTest.title}
        getKTISum={getKTISum}
        handleTitleChange={setTitle}
      />
    );
  }
  return null;
}

export default TPArrangedTestDetailsOrganismContainer;
