import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { DomainResult } from '../../../../../models/dto/DashboardResult';
import { AssignmentType } from '../../../../../models/enums/AssignmentType';
import { sortDomainResult } from '../../../../../utils/Sorting';
import ILTabMolecule from '../../../../../components/ILTabsOrganism/ILTabMolecule';
import ILTabsOrganism from '../../../../../components/ILTabsOrganism';
import ILScoreMeterAtom from '../../../../../components/ILScoreMeterAtom';
import './style.scss';

type Props = {
  domainResults: DomainResult[];
  cognitiveResults: DomainResult[];
  assignmentType: AssignmentType;
};

function TPDomainAverageOrganism({
  domainResults,
  cognitiveResults,
  assignmentType,
}: Props): ReactElement {
  const { t } = useTranslation();

  const renderAveragesTable = (questionGroups: DomainResult[], isKti: boolean): JSX.Element =>
    questionGroups?.length > 0 ? (
      <div className="result-domains-table">
        <table className="tp-table">
          <tbody>
            {sortDomainResult(questionGroups, isKti).map((group: DomainResult) => (
              <tr key={group.domain}>
                <td className="score-meter-column">
                  <ILScoreMeterAtom isPercentage={true} value={group.score} caesura={55} />
                </td>
                <td className="domain-name-column">
                  {isKti ? t(`resultsPage:${group.domain}`) : group.domain}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ) : (
      <></>
    );

  return (
    <ILTabsOrganism>
      <ILTabMolecule key="domains" name={t('resultsPage:domains')}>
        {renderAveragesTable(domainResults, false)}
      </ILTabMolecule>
      {assignmentType !== AssignmentType.ReferenceTest ? (
        <ILTabMolecule key="kti" name={t('resultsPage:cognitive_level')}>
          {renderAveragesTable(cognitiveResults, true)}
        </ILTabMolecule>
      ) : (
        <></>
      )}
    </ILTabsOrganism>
  );
}

export default TPDomainAverageOrganism;
