import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { sortTexts } from '../../../../../utils/Sorting';
import { ResultGroupUser } from '../../../../../models/dto/PlanningResult';
import { SelectType } from '../../../../../models/enums/SelectType';
import ILSelectOptionMolecule from '../../../../../components/ILSelectOptionMolecule';
import ILModalButtonsMolecule from '../../../../../components/ILModalMolecule/ILModalButtonsMolecule';
import ILModalMolecule from '../../../../../components/ILModalMolecule';
import { formatName } from '../../../../../utils/NameFormatter';
import './style.scss';

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  notFinishedStudents: ResultGroupUser[];
  selectedStudents: string[];
  handleSelection: (id: string) => void;
  handleSelectAll: () => void;
  closeModal: () => void;
  closeTest: () => void;
};

function TPCloseTestModalOrganism({
  isOpen,
  isLoading,
  notFinishedStudents,
  selectedStudents,
  handleSelection,
  handleSelectAll,
  closeModal,
  closeTest,
}: Props): ReactElement {
  const { t } = useTranslation();

  const renderStudentList = (): JSX.Element[] =>
    notFinishedStudents
      .sort((a, b) => sortTexts(a.firstName, b.firstName))
      .map((student: ResultGroupUser) => (
        <ILSelectOptionMolecule
          key={student.udbId}
          id={student.udbId}
          label={formatName(student.firstName, student.lastName, student.prefix)}
          type={SelectType.Multiple}
          isSelected={selectedStudents.some(value => value === student.udbId)}
          handleCheckboxChange={handleSelection}
        />
      ));

  return (
    <ILModalMolecule
      isOpen={isOpen}
      className="close-test-modal"
      title={t('resultsPage:close_tests')}
      subText={t('resultsPage:close_test_sub')}
      handleClose={closeModal}
    >
      <div className="close-test-modal-content">
        <ILSelectOptionMolecule
          id="all"
          label={`[${t('resultsPage:select_all_student')}]`}
          type={SelectType.Multiple}
          isSelected={selectedStudents.length === notFinishedStudents.length}
          handleCheckboxChange={handleSelectAll}
        />
        <div className="not-finished-list">{renderStudentList()}</div>
      </div>
      <ILModalButtonsMolecule
        isDisabled={isLoading}
        isAcceptDisabled={selectedStudents.length === 0}
        acceptText={t('resultsPage:close_tests')}
        onAccept={closeTest}
        onCancel={closeModal}
      />
    </ILModalMolecule>
  );
}

export default TPCloseTestModalOrganism;
