import { Actions, ReceiveNotificationsAction } from './actions';
import { Notification } from '../../models/state/Notification';

export default (state: Notification[] = [], action: ReceiveNotificationsAction): Notification[] => {
  switch (action.type) {
    case Actions.RECEIVE_NOTIFICATIONS: {
      return action.notifications;
    }
    default:
      return state;
  }
};
