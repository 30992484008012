import { createContext, useContext } from 'react';

export interface PlanningContextValue {
  isEdit: boolean;
  isEditEnabled: boolean;
}

const planningContextValueInitial: PlanningContextValue = {
  isEdit: false,
  isEditEnabled: true,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const PlanningContext = createContext<PlanningContextValue>(planningContextValueInitial);

export const usePlanningContext = (): PlanningContextValue => useContext(PlanningContext);

export default PlanningContext;
