import React, { ReactElement } from 'react';
import { SelectType } from '../../models/enums/SelectType';
import './ILSelectOptionMolecule.scss';

interface SelectOptionProps {
  id: string;
  label: string;
  type: SelectType;
  subLabel?: string;
  children?: JSX.Element;
  isSelected: boolean;
  disabled?: boolean;
  handleCheckboxChange: (optionId: string) => void;
}

function ILSelectOptionMolecule({
  id,
  label,
  type,
  subLabel,
  isSelected,
  disabled,
  handleCheckboxChange,
  children,
}: SelectOptionProps): ReactElement {
  return (
    <div className={`option-container${disabled ? ' option-disabled' : ''}`}>
      <div className="select-container">
        <label htmlFor={id} className="select-input">
          <input
            id={id}
            type={type}
            name={label}
            checked={isSelected}
            onChange={() => handleCheckboxChange(id)}
          />

          <div className="select-label-wrapper">
            <span className="select-label">{label}</span>
            <p className="select-sub">{subLabel}</p>
          </div>

          <span className={`${type}-checkmark`} />
        </label>
      </div>
      {isSelected && children ? children : null}
    </div>
  );
}

export default ILSelectOptionMolecule;
