export enum QuestionType {
  Choice = 'choice',
  TextEntry = 'text_entry',
  Order = 'order',
  ExtendedText = 'extended_text',
  InlineChoice = 'inline_choice',
  SelectPoint = 'select_point',
  PositionObject = 'position_object',
  Combined = 'combined',
  Match = 'match',
  GraphicAssociate = 'graphic_associate',
}

export interface Question {
  id: number;
  binId: number;
  identifier: string;
  snippet: string;
  maxScore: number;
  type: string;
  open: boolean;
  language: string;
  properties: QuestionProperty[];
  blocks: QuestionBlock[];
  isSelected?: boolean;
  kti: string[];
  domains: string[];
}

export interface QuestionBlock {
  identifier: string;
  type: string;
  html: string;
}

export interface LinkKeyValue {
  rel: string;
  link: string;
}

export interface QuestionProperty {
  key: string;
  value: string[];
}

export interface GetQuestionsResult {
  questions: {
    count: number;
    limit: number;
    questionList: Question[];
  };
}
