import React, { ReactElement } from 'react';
import { TestMessage } from '../../models/dto/TPIframeMessage';
import ILIframeViewerMolecule from '../ILIframeViewerMolecule/index';
import './style.scss';

interface Props {
  src: string;
  title?: string;
  onClose: () => void;
  handleFrameMessage?: (data: TestMessage) => void;
}

function ILRemindoPlayerOrganism({
  onClose,
  src,
  title,
  handleFrameMessage,
}: Props): ReactElement | null {
  if (src) {
    return (
      <div id="remindoPlayer" className="remindo-player">
        <div className="remindo-header">
          <div className="remindo-header-content">
            <h3>{title ? decodeURIComponent(title) : title}</h3>
            <div
              className="remindo-close-button"
              role="button"
              tabIndex={-1}
              onClick={() => onClose()}
            >
              <svg
                fill="#fff"
                height="30"
                viewBox="0 0 24 24"
                width="30"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                <path d="M0 0h24v24H0z" fill="none" />
              </svg>
            </div>
          </div>
        </div>
        <ILIframeViewerMolecule handleFrameMessage={handleFrameMessage} url={src} disableLoading />
      </div>
    );
  }
  return null;
}
export default ILRemindoPlayerOrganism;
