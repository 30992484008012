import React, { ReactElement, useEffect } from 'react';
import { TPIframeMessage } from '../../models/dto/TPIframeMessage';
import { CLOSE_REMINDO } from '../../constants/IFrameMessageConstants';

function CloseRemindo(): ReactElement {
  const message: TPIframeMessage = {
    type: CLOSE_REMINDO,
  };

  useEffect(() => window.parent.postMessage(JSON.stringify(message), '*'));
  return <div className="main" />;
}

export default CloseRemindo;
