import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { State } from '../../store';
import { fetchNotifications } from '../../store/notifications/actions';
import { Notification } from '../../models/state/Notification';
import { useGetMethod } from '../../graphql/method/methodOperations';
import TPNotificationsOrganism from './TPNotificationsOrganism';
import { useGetAuthenticated } from '../../graphql/authenticated/authenticatedOperations';

type Props = {
  notifications: Notification[];
  dispatchFetchNotifications: (methodId: string, selectedSchoolId: string) => void;
};

const mapStateToProps = (state: State) => ({
  notifications: state.notifications,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<State, void, Action>) => ({
  dispatchFetchNotifications: (methodId: string, selectedSchoolId: string) =>
    dispatch(fetchNotifications(methodId, selectedSchoolId)),
});

function TPNotificationsOrganismContainer({ notifications, dispatchFetchNotifications }: Props) {
  const method = useGetMethod();
  const { selectedSchoolId, isTeacher } = useGetAuthenticated();

  useEffect(() => {
    if (method?.structureId) {
      dispatchFetchNotifications(method.structureId, selectedSchoolId);
    }
  }, [dispatchFetchNotifications, method, selectedSchoolId]);

  return (
    <TPNotificationsOrganism
      notifications={notifications}
      isTeacher={isTeacher}
      reloadNotifications={() =>
        dispatchFetchNotifications(method?.structureId || '', selectedSchoolId)
      }
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TPNotificationsOrganismContainer);
