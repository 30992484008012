import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetMethod } from '../../../../graphql/method/methodOperations';
import { useGetAuthenticated } from '../../../../graphql/authenticated/authenticatedOperations';
import { createRequest, getData } from '../../../../components/ILAjaxEther';
import { webApiUrl } from '../../../../api/settingsLoader';
import { ResultDashboardStudent } from '../../../../models/dto/DashboardResult';
import { StudentsFeedback } from '../../../../models/dto/StudentsFeedback';
import { MethodType } from '../../../../components/ILAjaxEther/MethodType';
import { ILLoadingIndicator } from '../../../../components/ILLoadingIndicator';
import StudentResultDashboard from './StudentResultDashboard';
import { usePageTracking } from '../../../../utils/usePageTracking';

interface StudentDashboardParams {
  planId: string;
  studentId: string;
}

function StudentResultDashboardContainer(): ReactElement | null {
  const method = useGetMethod();
  const { isTeacher, selectedSchoolId } = useGetAuthenticated();
  const { planId, studentId } = useParams<StudentDashboardParams>();
  const [result, setResult] = useState<ResultDashboardStudent>();
  const [isLoading, setIsLoading] = useState(false);
  const [isFeedbackEditorOpen, setIsFeedbackEditorOpen] = useState(false);

  usePageTracking('result', 'dashboard', planId);

  const fetchDashboardData = useCallback(() => {
    if (selectedSchoolId && method && planId && (!isTeacher || studentId)) {
      setIsLoading(true);
      getData(
        `${webApiUrl}dashboard/user/${selectedSchoolId}/${method.structureId}/${planId}${
          isTeacher ? `/${studentId}` : ''
        }`
      )
        .then((response: ResultDashboardStudent) => {
          setResult(response);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [isTeacher, planId, studentId, method, selectedSchoolId]);

  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  const saveFeedback = (feedback: string, resultId?: string) => {
    if (resultId) {
      const feedbackData: StudentsFeedback = {
        resultIds: [resultId],
        value: feedback,
      };
      createRequest(MethodType.Update, `${webApiUrl}result/feedback`, feedbackData).then(() => {
        fetchDashboardData();
        setIsFeedbackEditorOpen(false);
      });
    }
  };

  return (
    <div className="main">
      {!isLoading && result ? (
        <StudentResultDashboard
          result={result}
          isTeacher={isTeacher}
          planId={planId}
          studentId={studentId}
          saveFeedback={saveFeedback}
          isFeedbackEditorOpen={isFeedbackEditorOpen}
          toggleFeedbackEditor={() => setIsFeedbackEditorOpen(!isFeedbackEditorOpen)}
        />
      ) : (
        <ILLoadingIndicator isLoading fullSize />
      )}
    </div>
  );
}

export default StudentResultDashboardContainer;
