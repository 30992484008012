import { LogoType } from '../models/enums/LogoType';

import LogoBuitenland from '../images/logos/buitenland-logo.svg';
import LogoChemie from '../images/logos/chemie-logo.svg';
import LogoChemieOveral from '../images/logos/chemie-overal-logo.svg';
import LogoGeschiedenisWerkplaats from '../images/logos/geschiedeniswerkplaats-logo.svg';
import LogoGetalEnRuimte from '../images/logos/getal_ruimte-logo.svg';
import LogoGrandesLignesOnderbouw from '../images/logos/grandes-lignes-onderbouw-logo.svg';
import LogoGrandesLignes from '../images/logos/grandes-lignes-logo.svg';
import LogoModerneWiskunde from '../images/logos/moderne-wiskunde-logo.svg';
import LogoNectar from '../images/logos/nectar-logo.svg';
import LogoNeueKontakte6e from '../images/logos/neue-kontakte-logo-6e-editie.svg';
import LogoNeueKontakte from '../images/logos/neue-kontakte-logo-versie-X.svg';
import LogoNieuwNederlands from '../images/logos/nieuw-nederlands-logo.svg';
import LogoNuBurgerschap from '../images/logos/NU-Burgerschap.svg';
import LogoNuCommercieel from '../images/logos/NU-Commercieel.svg';
import LogoNuEngels from '../images/logos/NU-Engels.svg';
import LogoNuFinancieel from '../images/logos/NU-Financieel.svg';
import LogoNuJuridisch from '../images/logos/NU-Juridisch.svg';
import LogoNuNederlands from '../images/logos/NU-Nederlands.svg';
import LogoNuPedagogisch from '../images/logos/NU-Pedagogischwerk.svg';
import LogoNuRekenen from '../images/logos/NU-Rekenen.svg';
import LogoNuRetail from '../images/logos/NU-Retail.svg';
import LogoOveral from '../images/logos/overal-logo.svg';
import LogoPincode from '../images/logos/pincode-logo.svg';
import LogoPulsar from '../images/logos/pulsar-logo.svg';
import LogoSteppingStonesOnderbouw from '../images/logos/stepping-stones-onderbouw.svg';
import LogoTrabitour from '../images/logos/trabitour-logo.svg';
import LogoNoordhoff from '../images/logos/nh-logo.svg';
import LogoPleinM from '../images/logos/Plein-M.svg';
import LogoPrepzone from '../images/logos/Prepzone.svg';
import LogoTechniekOpMaat from '../images/logos/Techniek-op-maat-2-0.svg';
import LogoTechnologisch from '../images/logos/technologisch-logo.svg';

export const getLogo = (logoType: LogoType) => {
  switch (logoType) {
    case LogoType.Buitenland:
      return LogoBuitenland;
    case LogoType.Chemie:
      return LogoChemie;
    case LogoType.ChemieOveral:
      return LogoChemieOveral;
    case LogoType.GeschiedenisWerkplaats:
      return LogoGeschiedenisWerkplaats;
    case LogoType.GetalEnRuimte:
      return LogoGetalEnRuimte;
    case LogoType.GrandesLignes:
      return LogoGrandesLignes;
    case LogoType.GrandesLignesOnderbouw:
      return LogoGrandesLignesOnderbouw;
    case LogoType.ModerneWiskunde:
      return LogoModerneWiskunde;
    case LogoType.Nectar:
      return LogoNectar;
    case LogoType.NeueKontakte6e:
      return LogoNeueKontakte6e;
    case LogoType.NeueKontakte:
      return LogoNeueKontakte;
    case LogoType.NieuwNederlands:
      return LogoNieuwNederlands;
    case LogoType.NuBurgerschap:
      return LogoNuBurgerschap;
    case LogoType.NuCommercieel:
      return LogoNuCommercieel;
    case LogoType.NuEngels:
      return LogoNuEngels;
    case LogoType.NuFinancieel:
      return LogoNuFinancieel;
    case LogoType.NuJuridisch:
      return LogoNuJuridisch;
    case LogoType.NuNederlands:
      return LogoNuNederlands;
    case LogoType.NuPedagigischWerk:
      return LogoNuPedagogisch;
    case LogoType.NuRekenen:
      return LogoNuRekenen;
    case LogoType.NuRetail:
      return LogoNuRetail;
    case LogoType.Overal:
      return LogoOveral;
    case LogoType.Pincode:
      return LogoPincode;
    case LogoType.PleinM:
      return LogoPleinM;
    case LogoType.Pulsar:
      return LogoPulsar;
    case LogoType.SteppingStones:
      return LogoSteppingStonesOnderbouw;
    case LogoType.Trabitour:
      return LogoTrabitour;
    case LogoType.Prepzone:
      return LogoPrepzone;
    case LogoType.TechniekOpMaat:
      return LogoTechniekOpMaat;
    case LogoType.Technologisch:
      return LogoTechnologisch;
    default:
      return LogoNoordhoff;
  }
};
