import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ILIconAtom, { IconType } from '../../../../components/ILIconAtom';
import './style.scss';
import '../../../../assets/theme/tp-buttons.scss';
import ILLoadingStatusIndicator, {
  LoadingState,
} from '../../../../components/ILLoadingStatusIndicator/ILLoadingStatusIndicator';

type Props = {
  isFirst: boolean;
  isLast: boolean;
  loadingState: LoadingState;
  isStudentReview: boolean;
  goBack: () => void;
  goNext: () => void;
  exitReview: () => void;
};

function TPReviewHeaderOrganism({
  isFirst,
  isLast,
  loadingState,
  isStudentReview,
  goBack,
  goNext,
  exitReview,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <div className="review-header">
        <div className="review-header__side">
          <span className="nav-link" onClick={() => exitReview()}>
            <ILIconAtom type={IconType.NavigationBack} size={13} />
            {` ${t('reviewPage:back')}`}
          </span>
          <ILLoadingStatusIndicator loadingState={loadingState} />
        </div>
        <div className="review-header__navigation">
          <button
            className="tp-button tp-button-primary-dark tp-button-with-icon review-button review-back-button"
            onClick={() => goBack()}
            disabled={isFirst}
          >
            <ILIconAtom type={IconType.Up} size={14} />
            <span>
              {t(isStudentReview ? 'reviewPage:previous_student' : 'reviewPage:previous_question')}
            </span>
          </button>
          <button
            className="tp-button tp-button-primary-dark tp-button-with-icon review-button review-next-button"
            onClick={() => goNext()}
            disabled={isLast}
          >
            <span>
              {t(isStudentReview ? 'reviewPage:next_student' : 'reviewPage:next_question')}
            </span>
            <ILIconAtom type={IconType.Up} size={14} />
          </button>
        </div>
      </div>
    </>
  );
}

export default TPReviewHeaderOrganism;
