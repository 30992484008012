import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetMethod } from '../../graphql/method/methodOperations';
import { useGetAuthenticated } from '../../graphql/authenticated/authenticatedOperations';
import { getData } from '../../components/ILAjaxEther';
import { webApiUrl } from '../../api/settingsLoader';
import { ResultDashboardStudent } from '../../models/dto/DashboardResult';
import { ILLoadingIndicator } from '../../components/ILLoadingIndicator';
import ViewAnswers from './ViewAnswers';
import { usePageTracking } from '../../utils/usePageTracking';

interface RouteParams {
  planId: string;
  studentId: string;
}

function ViewAnswersContainer(): ReactElement | null {
  const method = useGetMethod();
  const { planId, studentId } = useParams<RouteParams>();
  const { selectedSchoolId } = useGetAuthenticated();
  const [result, setResult] = useState<ResultDashboardStudent>();
  const [isLoading, setIsLoading] = useState(false);

  usePageTracking('result', 'view-answers', planId);

  useEffect(() => {
    if (selectedSchoolId && method && planId && studentId) {
      setIsLoading(true);
      getData(
        `${webApiUrl}dashboard/user/${selectedSchoolId}/${method.structureId}/${planId}/${studentId}`
      )
        .then((response: ResultDashboardStudent) => {
          setResult(response);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [planId, studentId, method, selectedSchoolId]);

  if (!isLoading && result) {
    return <ViewAnswers result={result} />;
  }
  if (isLoading) {
    return <ILLoadingIndicator isLoading fullSize />;
  }
  return null;
}

export default ViewAnswersContainer;
