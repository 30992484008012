import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectType } from '../../../models/enums/SelectType';
import { GradeType } from '../../../models/enums/GradeType';
import ILSelectOptionMolecule from '../../../components/ILSelectOptionMolecule';

interface Props {
  selectedGradeType: string;
  handleSelection: (id: string) => void;
}

function TPPlanningResultOrganism({ selectedGradeType, handleSelection }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <ILSelectOptionMolecule
        key={0}
        id={GradeType.Numeric}
        type={SelectType.Single}
        label={t(`planning:grade_${GradeType.Numeric}`)}
        isSelected={selectedGradeType === GradeType.Numeric}
        handleCheckboxChange={() => handleSelection(GradeType.Numeric)}
      />
      <ILSelectOptionMolecule
        key={1}
        id={GradeType.Alphabetic}
        type={SelectType.Single}
        label={t(`planning:grade_${GradeType.Alphabetic}`)}
        isSelected={selectedGradeType === GradeType.Alphabetic}
        handleCheckboxChange={() => handleSelection(GradeType.Alphabetic)}
      />
    </>
  );
}

export default TPPlanningResultOrganism;
