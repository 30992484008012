import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { State } from '../../../store';
import { TestType } from '../../../models/enums/TestType';
import TPPlanningOptionsOrganism from './TPPlanningOptionsOrganism';
import {
  clearPinAction,
  setCheckAnswerAction,
  setHasPinAction,
} from '../../../store/planning/actions';
import { usePlanningContext } from '../PlanningContext';

interface Props {
  checkAnswer: boolean;
  hasPin: boolean;
  isRefTest: boolean;
  dispatchSetCheckAnswerAction: (check: boolean) => void;
  dispatchClearPinAction: () => void;
  dispatchSetHasPinAction: () => void;
}

const mapStateToProps = (state: State) => ({
  checkAnswer: state.plan.checkAnswer,
  hasPin: state.plan.hasPin,
  isRefTest: state.plan.classification === TestType.Ref,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchSetCheckAnswerAction: (check: boolean) => {
    dispatch(setCheckAnswerAction(check));
  },
  dispatchClearPinAction: () => {
    dispatch(clearPinAction());
  },
  dispatchSetHasPinAction: () => {
    dispatch(setHasPinAction());
  },
});

function TPPlanningOptionsOrganismContainer({
  checkAnswer,
  hasPin,
  dispatchSetCheckAnswerAction,
  dispatchClearPinAction,
  dispatchSetHasPinAction,
  isRefTest,
}: Props) {
  const { isEditEnabled } = usePlanningContext();
  const handlePinSelection = () => {
    if (isEditEnabled) {
      if (hasPin) {
        dispatchClearPinAction();
      } else {
        dispatchSetHasPinAction();
      }
    }
  };

  const handleCheckAnswerSelection = (check: boolean) => {
    if (isEditEnabled) {
      dispatchSetCheckAnswerAction(check);
    }
  };

  return (
    <TPPlanningOptionsOrganism
      checkAnswerSelected={checkAnswer}
      pinSelected={hasPin}
      handleCheckAnswerSelection={handleCheckAnswerSelection}
      handlePinSelection={handlePinSelection}
      isRefTest={isRefTest}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TPPlanningOptionsOrganismContainer);
