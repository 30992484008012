import { idpUrl } from '../api/settingsLoader';

const getIdpKey = (): string => {
  const pathArray = window.location.host.split('.');
  return `${pathArray[0]}/TP`;
};

export const idpConfig = {
  clientId: getIdpKey(),
  scope:
    'openid profile email role unique_name primarysid actOrgId offline_access selectedschool license accountbarlogin',
  idpUrl,
};
