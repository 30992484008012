import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useParams, useHistory } from 'react-router';
import { getData } from '../../../../components/ILAjaxEther';
import { useGetMethod } from '../../../../graphql/method/methodOperations';
import { formatName } from '../../../../utils/NameFormatter';
import { BreadcrumbPart } from '../../../../components/Breadcrumb/Breadcrumb';
import { PlanningResultStudent } from '../../../../models/dto/PlanningResultStudent';
import { webApiUrl } from '../../../../api/settingsLoader';
import { useNlpRoutes } from '../../../../utils/nlpNavigation';
import TPStudentAllResultsTable from './TPStudentAllResultsTable';
import Breadcrumb from '../../../../components/Breadcrumb';
import { useGetAuthenticated } from '../../../../graphql/authenticated/authenticatedOperations';
import { usePageTracking } from '../../../../utils/usePageTracking';

type MatchParams = {
  studentId: string;
};

function StudentAllResults(): ReactElement {
  const { studentId } = useParams<MatchParams>();
  const location = useLocation<{ name: string }>();
  const history = useHistory();
  const { selectedSchoolId, isTeacher } = useGetAuthenticated();
  const nlpStudentsOverviewUrl = useNlpRoutes('studentsOverview');

  const method = useGetMethod();
  const { t } = useTranslation();
  const [results, setResults] = useState<PlanningResultStudent[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);

  usePageTracking('result', 'result-list', isTeacher ? studentId : undefined);

  useEffect(() => {
    const baseApiUrl = `${webApiUrl}result/student/`;
    if (method?.structureId && selectedSchoolId) {
      const requestUrl = isTeacher
        ? `${baseApiUrl}${method.structureId}/${selectedSchoolId}/${studentId}`
        : `${baseApiUrl}${method.structureId}/${selectedSchoolId}`;
      getData(requestUrl).then((response: PlanningResultStudent[]) => {
        setResults(response);
        setIsLoadingData(false);
      });
    }
  }, [isTeacher, method, selectedSchoolId, studentId]);

  const setPageTitle = (): string => {
    if (isTeacher) {
      if (location.state?.name) {
        return `${t('resultsPage:results')} ${location.state.name}`;
      }
      if (results && results.length > 0) {
        return `${t('resultsPage:results')} ${formatName(
          results[0].firstName,
          results[0].lastName,
          results[0].prefix
        )}`;
      }
      return t('resultsPage:test_results_students');
    }
    return t('resultsPage:my_results');
  };

  const openResult = (planId: string) => {
    const pathname = isTeacher
      ? `/resultaat/leerling/${studentId}/${planId}`
      : `/resultaat/${planId}`;
    history.push(pathname);
  };

  const setBreadcrumbs = (): BreadcrumbPart[] => [
    {
      name: t('resultsPage:test_results_students'),
      to: '/resultaten/leerlingen',
      nlpUrl: nlpStudentsOverviewUrl,
    },
    {
      name:
        location.state?.name ||
        (results[0] && formatName(results[0].firstName, results[0].firstName, results[0].prefix)),
    },
  ];

  return (
    <div className="main">
      {isTeacher && <Breadcrumb crumbs={setBreadcrumbs()} />}
      <h1>{setPageTitle()}</h1>
      <TPStudentAllResultsTable
        tableData={results}
        openResult={openResult}
        isLoading={isLoadingData}
        isTeacher={isTeacher}
      />
    </div>
  );
}

export default StudentAllResults;
