import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectType } from '../../../models/enums/SelectType';
import ILSelectOptionMolecule from '../../../components/ILSelectOptionMolecule';
import PinInput from './TPPinInputAtom';

interface Props {
  checkAnswerSelected: boolean;
  pinSelected: boolean;
  isRefTest: boolean;
  handleCheckAnswerSelection: (check: boolean) => void;
  handlePinSelection: () => void;
}

export enum OptionTypes {
  CheckAnswers = 'check_answers',
  Pin = 'pin',
}

function TPPlanningOptionsOrganism({
  checkAnswerSelected,
  pinSelected,
  isRefTest,
  handleCheckAnswerSelection,
  handlePinSelection,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      {!isRefTest && (
        <ILSelectOptionMolecule
          key={0}
          id={OptionTypes.CheckAnswers}
          type={SelectType.Multiple}
          label={t(`planning:${OptionTypes.CheckAnswers}`)}
          isSelected={checkAnswerSelected}
          handleCheckboxChange={() => handleCheckAnswerSelection(!checkAnswerSelected)}
        />
      )}
      <ILSelectOptionMolecule
        key={1}
        id={OptionTypes.Pin}
        type={SelectType.Multiple}
        label={t(`planning:${OptionTypes.Pin}`)}
        isSelected={pinSelected}
        handleCheckboxChange={handlePinSelection}
      >
        <PinInput />
      </ILSelectOptionMolecule>
    </>
  );
}

export default TPPlanningOptionsOrganism;
