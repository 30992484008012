import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useGetMethod } from '../../../graphql/method/methodOperations';
import { DidacticLevel } from '../../../models/enums/DidacticLevel';
import ILModalMolecule from '../../../components/ILModalMolecule';
import reviewPerQuestionIcon from '../../../images/review-per-question.png';
import reviewPerStudentIcon from '../../../images/review-per-student.png';
import { useContextNavigation } from '../../../utils/useContextNavigation';
import './style.scss';

type Props = {
  isOpen: boolean;
  planId: string;
  closeModal: () => void;
};

function TPTestReviewModalOrganism({ isOpen, planId, closeModal }: Props): ReactElement | null {
  const { t } = useTranslation();
  const history = useHistory();
  const method = useGetMethod();

  const reviewPerQuestionRoute = useContextNavigation(`/toets-nakijken-per-vraag/${planId}`);
  const reviewPerLearnerRoute = useContextNavigation(`/toets-nakijken-per-leerling/${planId}`);
  const reviewPerStudentRoute = useContextNavigation(`/toets-nakijken-per-student/${planId}`);

  if (method) {
    return (
      <ILModalMolecule
        className="review-modal"
        isOpen={isOpen}
        handleClose={closeModal}
        title={t('reviewPage:review_test')}
      >
        <div className="review-modal-content">
          <div className="review-option-container">
            <img src={reviewPerQuestionIcon} alt={t('reviewPage:review_per_question')} />
            <h3>{t('reviewPage:review_per_question')}</h3>
            <Link
              className="tp-button tp-button-primary"
              to={{
                pathname: reviewPerQuestionRoute,
                state: {
                  returnUrl: history.location.pathname,
                },
              }}
            >
              {t('reviewPage:select')}
            </Link>
          </div>
          <div className="review-option-container">
            <img src={reviewPerStudentIcon} alt={t('reviewPage:review_per_student')} />
            <h3>{t('reviewPage:review_per_student')}</h3>
            <Link
              className="tp-button tp-button-primary"
              to={{
                pathname:
                  method.didactic_level === DidacticLevel.VocationalEducation ||
                  method.didactic_level === DidacticLevel.HigherEducation
                    ? reviewPerStudentRoute
                    : reviewPerLearnerRoute,
                state: {
                  returnUrl: history.location.pathname,
                },
              }}
            >
              {t('reviewPage:select')}
            </Link>
          </div>
        </div>
      </ILModalMolecule>
    );
  }

  return null;
}

export default TPTestReviewModalOrganism;
