import { gql } from '@apollo/client';

export const arrangeTestTypes = gql`
  """
  Definition for the questions type
  Questions contains information about the questions (how many etc)
  also questions a list with question objects
  """
  type SelectedQuestion {
    id: Int
    maxScore: Int
    level: [String]
    year: [String]
    chapter: [String]
    kti: [String]
    domain: [String]
    open: Boolean
  }

  """
  The definition for the arrangetest object.
  This object will keep track of the state during arranging a test.
  """
  type ArrangedTest {
    title: String
    mgrRecipeId: String
    currentStep: Int
    selectedQuestions: [SelectedQuestion]
  }

  """
  Definition for the questions filters
  Will be used to store which filters are available to filter a question
  """
  type QuestionFilters {
    domain: [String]
    kti: [String]
    type: [String]
  }

  """
  Definition for the questions input type
  Questions contains information about the questions (how many etc)
  also questions a list with question objects
  """
  input SelectedQuestionInput {
    id: Int
    maxScore: Int
    level: [String]
    year: [String]
    chapter: [String]
    kti: [String]
    domain: [String]
    open: Boolean
  }

  """
  Definition for the questioninput input type
  Contains the information of a paragin question
  """
  input QuestionInput {
    createdAt: Int
    id: Int
    binId: Int
    identifier: String
    snippet: String
    maxScore: Float
    type: String
    open: Boolean
    language: String
    properties: [QuestionInputProperty]
    blocks: [QuestionInputBlock]
    domains: [String]
    kti: [String]
  }

  """
  Definition for questionProperty input type
  Contains key value items
  """
  input QuestionInputProperty {
    key: String
    value: [String]
  }

  """
  Definition for questionblock input type
  Questionblock is part of question and contains the html blocks
  """
  input QuestionInputBlock {
    identifier: String
    type: String
    html: String
  }

  extend type Query {
    arrangedTest: ArrangedTest!
  }

  extend type Mutation {
    AddOrRemoveQuestion(question: QuestionInput): ArrangedTest
    SetTitle(title: String): ArrangedTest
    SetRecipeId(id: String): ArrangedTest
    ResetArrangedTest: ArrangedTest
    SetOrUpdateAvailableFilters(questions: QuestionInput): ArrangedTest
    ResetSelectedQuestions: ArrangedTest
    ChangeQuestionsOrder(newQuestionsList: [QuestionInput]): ArrangedTest
    SetSelectedFilters(
      ktiFilters: [String]
      domainFilters: [String]
      questionTypeFilters: [String]
    ): ArrangedTest
  }
`;
