import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { client } from '../../../graphql/ApolloClientService';
import {
  resetArrangedTest,
  resetSelectedQuestionFilters,
} from '../../../graphql/arrangeTest/arrangeTestOperations';
import { removeCachedQuestions } from '../../../graphql/questions/questionOperations';
import {
  GET_AVAILABLE_FILTERS,
  GET_FILTERS,
} from '../../../graphql/arrangeTest/filters/filterQueries';

import { FilterObject, FilterType } from '../../../models/state/arrangeTests/Filters';
import { ArrangedTest } from '../../../models/state/arrangeTests/ArrangedTest';

import { useWizardContext } from '../../../components/ILWizardOrganism/ILWizardOrganism';
import { ILLoadingIndicator } from '../../../components/ILLoadingIndicator';
import SelectFiltersStep from './SelectFiltersStep';
import { GET_ARRANGED_TEST } from '../../../graphql/arrangeTest/arrangeTestQueries';
import { availableFiltersVar } from '../../../graphql/arrangeTest/cache';

interface Props {
  categoryIds: number[];
  isMultiLingual: boolean;
  isVe: boolean;
  arrangedTest: ArrangedTest;
  initLoad: boolean;
  setInitLoaded: () => void;
}

function SelectFilterStepContainer({
  categoryIds,
  isMultiLingual,
  isVe,
  arrangedTest,
  initLoad,
  setInitLoaded,
}: Props): ReactElement {
  const { data, loading, refetch } = useQuery(GET_FILTERS, {
    variables: { categoryIds, isVe },
    partialRefetch: true,
    returnPartialData: true,
  });
  const { availableFilters } = useQuery(GET_AVAILABLE_FILTERS).data;
  const { setReady, setNotReady, goTo } = useWizardContext();

  // Step is valid if all levels has selected values
  const isValidStep = useCallback(
    (): boolean =>
      data?.filters
        ? data.filters.every((filter: FilterObject) => filter.selectedValues.length) > 0
        : false,
    [data]
  );

  const [isStepCompleted, setIsStepCompleted] = useState(isValidStep());

  const resetFilters = () => {
    resetSelectedQuestionFilters();
    removeCachedQuestions();
    resetArrangedTest();
  };

  // parse the selected filters into FilterObject, which can be passed as Query variables
  const getFilterQueryVariables = useCallback(
    (newList: FilterObject[]) => {
      let variables = {
        categoryIds,
        isVe,
        languages: [] as string[],
        learningLevels: [] as string[],
        learningYears: [] as string[],
      };

      newList.forEach((filter: FilterObject) => {
        if (filter.selectedValues.length > 0) {
          switch (filter.key) {
            case FilterType.Language:
              variables = { ...variables, ...{ languages: filter.selectedValues } };
              break;
            case FilterType.Level:
              variables = { ...variables, ...{ learningLevels: filter.selectedValues } };
              break;
            case FilterType.Year:
              variables = { ...variables, ...{ learningYears: filter.selectedValues } };
              break;
            case FilterType.Chapter:
              variables = { ...variables, ...{ chapter: filter.selectedValues } };
              break;
          }
        }
      });
      return variables;
    },
    [categoryIds, isVe]
  );

  // set the available filters according to the current method settings
  const setAvailableFilters = useCallback(() => {
    const filterList = [];
    if (isMultiLingual) {
      filterList.push(FilterType.Language);
    }
    if (isVe) {
      filterList.push(FilterType.Level, FilterType.Chapter);
    } else {
      filterList.push(FilterType.Level, FilterType.Year, FilterType.Chapter);
    }
    availableFiltersVar(filterList);
  }, [isMultiLingual, isVe]);

  // write new filters to cache and refetch filter query
  const writeFiltersToCacheAndRefetch = useCallback(
    (filtersList: FilterObject[], selectedFilterType: FilterType) => {
      const filterQueryVariables = getFilterQueryVariables(filtersList);
      client.writeQuery({
        variables: filterQueryVariables,
        query: GET_FILTERS,
        data: {
          filters: filtersList,
        },
        broadcast: true,
      });
      // prevent unneeded refetch on last level
      if (
        availableFilters.length > 0 &&
        selectedFilterType !== availableFilters[availableFilters.length - 1]
      ) {
        // read the cached data so we can use the updated filters for refetching the query
        const cachedData = client.readQuery({
          variables: filterQueryVariables,
          query: GET_FILTERS,
        });
        const refetchVariables = getFilterQueryVariables(cachedData.filters);
        refetch(refetchVariables);
      }
    },
    [availableFilters, getFilterQueryVariables, refetch]
  );

  // handle filter selection
  const updateSelection = (type: FilterType, values: string[]) => {
    const newList = [...data.filters];
    const filterIndex = newList.findIndex((filter: FilterObject) => filter.key === type);
    newList[filterIndex] = { ...newList[filterIndex], selectedValues: values };
    writeFiltersToCacheAndRefetch(newList, type);
  };

  useEffect(() => {
    setAvailableFilters();
  }, [setAvailableFilters]);

  // loading the correct step
  useEffect(() => {
    const cachedQuery = client.readQuery({ query: GET_ARRANGED_TEST });
    if (isStepCompleted && initLoad) {
      setInitLoaded();
      const initialStep = cachedQuery?.arrangedTest.currentStep;
      if (initialStep) {
        goTo(initialStep, true);
      }
    } else {
      client.writeQuery({
        query: GET_ARRANGED_TEST,
        data: {
          arrangedTest: {
            ...cachedQuery.arrangedTest,
            currentStep: 0,
          },
        },
      });
    }
  }, [goTo, initLoad, isStepCompleted, setInitLoaded]);

  // monitoring if the step is completed
  useEffect(() => {
    if (isValidStep()) {
      setReady();
      setIsStepCompleted(true);
    } else {
      setNotReady();
      setIsStepCompleted(false);
    }
  }, [isStepCompleted, isValidStep, setNotReady, setReady]);

  if (loading && !data?.filters) {
    return <ILLoadingIndicator />;
  }
  if (data && data.filters) {
    return (
      <SelectFiltersStep
        hasSelectedQuestions={arrangedTest.selectedQuestions.length > 0}
        availableFilters={availableFilters}
        filters={data.filters}
        onUpdate={updateSelection}
        resetStoredData={resetFilters}
        isLoading={loading}
      />
    );
  }
  return <></>;
}

export default SelectFilterStepContainer;
