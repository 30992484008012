import { gql } from '@apollo/client';

export const GET_AUTHENTICATED = gql`
  query GetAuthenticated {
    authenticated @client {
      isAuthenticated
      isTeacher
      selectedSchoolId
      udbId
    }
  }
`;
