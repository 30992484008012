import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { formatFloatValue } from '../../../utils/DataFormatter';
import ILScoreMeterAtom from '../../ILScoreMeterAtom';
import './style.scss';

interface Props {
  averageDuration: number;
  averageScore: number;
  maxScore: number;
}

function ILQuestionStats({ averageDuration, averageScore, maxScore }: Props): ReactElement {
  const { t } = useTranslation();
  const calculatePercentage = () => Math.round((averageScore / maxScore) * 100);

  return (
    <div className="question-stats">
      <div className="stat">
        {t('questionStats:average_duration')}
        <span className="stat-value">
          {`${formatFloatValue(averageDuration)} ${t('questionStats:seconds')}`}
        </span>
      </div>
      <div className="stat">
        {t('questionStats:average_score')}
        <span className="stat-value">
          {`${formatFloatValue(averageScore)} ${t('questionStats:of')} ${formatFloatValue(
            maxScore
          )} ${t('questionStats:points')}`}
        </span>
      </div>
      <div className="stat">
        {t('questionStats:average_score_percentage')}
        <ILScoreMeterAtom caesura={55} value={calculatePercentage()} isPercentage />
      </div>
    </div>
  );
}

export default ILQuestionStats;
