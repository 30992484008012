import { cache } from '../cache';
import { authenticatedVar, initialAuthenticatedState } from './cache';
import { isTeacher, getUdbId } from '../../services/JwtService';
import { Authenticated, AuthenticatedData } from '../../models/state/Authenticated';
import { GET_AUTHENTICATED } from './authenticatedQueries';
import { client } from '../ApolloClientService';

export const logout = (): void => {
  cache.evict({ fieldName: 'authenticated' });
  cache.gc();
};

export const login = (jwt: string, schoolId: string): void => {
  authenticatedVar({
    isAuthenticated: true,
    selectedSchoolId: schoolId,
    isTeacher: isTeacher(jwt),
    udbId: getUdbId(jwt),
  });
};

export function useGetAuthenticated(): Authenticated {
  const queryResult = client.readQuery<AuthenticatedData>({ query: GET_AUTHENTICATED });
  return queryResult?.authenticated || initialAuthenticatedState;
}
