import React, { ReactElement, useState } from 'react';
import { QuestionType, QuestionBlock } from '../../models/state/Question';
import './styles/ExtendedTextQuestion.scss';
import './styles/InlineChoiceQuestion.scss';
import './styles/OrderQuestion.scss';
import './styles/ChoiceQuestion.scss';
import './styles/style.scss';
import './styles/TextEntryQuestion.scss';
import './styles/MatchQuestion.scss';
import ILIconAtom, { IconType } from '../ILIconAtom';

interface Props {
  type: QuestionType;
  snippet?: string;
  enableExpand?: boolean;
  blocks: QuestionBlock[];
}

export default function ILQuestionItemMolecule({
  type,
  snippet,
  enableExpand,
  blocks,
}: Props): ReactElement {
  const getStylingModule = () => {
    switch (type) {
      case QuestionType.Choice:
        return 'choice-question';
      case QuestionType.TextEntry:
        return 'text-entry-question';
      case QuestionType.Order:
        return 'order-question';
      case QuestionType.ExtendedText:
        return 'extended-text-question';
      case QuestionType.InlineChoice:
        return 'inline-choice-question';
      case QuestionType.Combined:
        return 'combined';
      case QuestionType.Match:
        return 'match-question';
      default:
        return '';
    }
  };

  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapse = () => {
    if (collapsed) {
      setCollapsed(false);
    } else {
      setCollapsed(true);
    }
  };

  const mapBlocks = () =>
    blocks.map(question => (
      // eslint-disable-next-line @typescript-eslint/naming-convention
      <div key={question.identifier} dangerouslySetInnerHTML={{ __html: question.html }} />
    ));

  return (
    <div
      className={`question-wrapper ${getStylingModule()} ${
        collapsed && enableExpand ? 'collapsed' : ''
      } `}
    >
      {snippet && collapsed ? <p>{snippet}</p> : mapBlocks()}
      {enableExpand && (
        <button className="collapse-button" onClick={() => toggleCollapse()}>
          <ILIconAtom type={IconType.Collapse} size={17} />
        </button>
      )}
    </div>
  );
}
