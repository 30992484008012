import { gql } from '@apollo/client';

export const GET_REVIEW_TEST = gql`
  query reviewTest($planId: ID!, $methodId: ID, $selectedSchoolId: ID) {
    reviewTest(planId: $planId, methodId: $methodId, selectedSchoolId: $selectedSchoolId) {
      planId
      recipeId
      correctorId
      studentFeedback {
        udbId
        resultId
        feedback
        givenFeedback @client
      }
      percentageReviewed @client
      questionsWithAnswers {
        id
        number
        progress @client
        blocks {
          html
        }
        givenAnswers {
          itemCheckId
          udbId
          firstName
          lastName
          prefix
          correctAnswer
          sequence
          maxScore
          isReviewed @client
          candidateResponses {
            givenAnswer
            valueChanged @client
            criteria {
              correctorScore
              paraginKey
              minScore
              maxScore
              givenScore @client
              baseType
              interpretation
            }
          }
        }
      }
    }
  }
`;

export const GET_GIVEN_ANSWER_FRAGMENT = gql`
  fragment givenAnswer on GivenAnswer {
    itemCheckId
    isReviewed @client
    udbId
    firstName
    lastName
    prefix
    maxScore
    candidateResponses {
      correctAnswer
      givenAnswer
      valueChanged @client
      criteria {
        correctorScore
        paraginKey
        minScore
        maxScore
        givenScore @client
        baseType
        interpretation
      }
    }
  }
`;

export const GET_STUDENT_FEEDBACK_FRAGMENT = gql`
  fragment studentFeedback on StudentFeedback {
    udbId
    resultId
    feedback
    givenFeedback @client
  }
`;

export const UPDATE_SCORES = gql`
  mutation updateScores($correctorId: Float!, $recipeId: ID!, $reviewItems: [ReviewItemResult]) {
    updateScores(correctorId: $correctorId, recipeId: $recipeId, reviewItems: $reviewItems)
  }
`;

export const GET_REVIEW_TEST_PER_STUDENT = gql`
  query reviewTestPerStudent($planId: ID!, $methodId: ID, $selectedSchoolId: ID) {
    reviewTestPerStudent(
      planId: $planId
      methodId: $methodId
      selectedSchoolId: $selectedSchoolId
    ) {
      planId
      recipeId
      correctorId
      studentFeedback {
        udbId
        resultId
        feedback
        givenFeedback @client
      }
      percentageReviewed @client
      questions {
        id
        number
        blocks {
          html
        }
      }
      studentWithAnswers {
        udbId
        firstName
        lastName
        prefix
        progress @client
        studentGivenAnswers {
          questionId
          itemCheckId
          udbId
          correctAnswer
          sequence
          maxScore
          isReviewed @client
          candidateResponses {
            givenAnswer
            valueChanged @client
            criteria {
              correctorScore
              paraginKey
              minScore
              maxScore
              baseType
              interpretation
              givenScore @client
            }
          }
        }
      }
    }
  }
`;

export const GET_STUDENT_GIVEN_ANSWER_FRAGMENT = gql`
  fragment studentGivenAnswer on StudentGivenAnswer {
    itemCheckId
    questionId
    isReviewed @client
    udbId
    firstName
    lastName
    prefix
    maxScore
    candidateResponses {
      givenAnswer
      valueChanged @client
      criteria {
        correctorScore
        paraginKey
        minScore
        maxScore
        givenScore @client
        baseType
        interpretation
      }
    }
  }
`;
