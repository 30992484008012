import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { formatName } from '../../utils/NameFormatter';
import { Origin } from '../../models/enums/Origin';
import { BreadcrumbPart } from '../../components/Breadcrumb/Breadcrumb';
import { ResultDashboardStudent } from '../../models/dto/DashboardResult';
import ILViewAnswersOrganismContainer from '../../components/ILViewAnswersOrganism/ILViewAnswersOrganismContainer';
import Breadcrumb from '../../components/Breadcrumb';
import { useNlpRoutes, useNlpStudentDashboardRoutes } from '../../utils/nlpNavigation';
import { useContextNavigation } from '../../utils/useContextNavigation';

type Props = {
  result: ResultDashboardStudent;
};

function ViewAnswers({ result }: Props): ReactElement {
  const { t } = useTranslation();

  const nlpStudentsOverviewUrl = useNlpRoutes('studentsOverview');
  const nlpStudentResultsUrl = useNlpRoutes('studentResults', result.udbId);
  const nlpStudentResultDashboardUrl = useNlpStudentDashboardRoutes(result.planningId, result.udbId);
  const studentResultDashboardPath = useContextNavigation(`/resultaat/leerling/${result.udbId}/${result.planningId}`)

  const setBreadcrumbs = (): BreadcrumbPart[] => [
    {
      name: t('viewAnswersPage:results_students'),
      to: '/resultaten/leerlingen',
      nlpUrl: nlpStudentsOverviewUrl,
    },
    {
      name: formatName(result.firstName, result.lastName, result.prefix),
      to: `/resultaten/leerling/${result.udbId}`,
      nlpUrl: nlpStudentResultsUrl,
      state: {
        name: formatName(result.firstName, result.lastName, result.prefix),
      },
    },
    {
      name: `${t('viewAnswersPage:result')} ${result.title}`,
      to: studentResultDashboardPath,
      nlpUrl: nlpStudentResultDashboardUrl,
    },
    {
      name: `${t('viewAnswersPage:answers')} ${result.title}`,
    },
  ];

  return (
    <div className="main">
      <Breadcrumb crumbs={setBreadcrumbs()} />
      <h1>{`${t('viewAnswersPage:answers')} ${result.title}`}</h1>
      {result.origin === Origin.Ats ? (
        <ILViewAnswersOrganismContainer
          resultId={result.resultId}
          assignmentId={result.assignmentId}
        />
      ) : (
        <ILViewAnswersOrganismContainer resultId={result.resultId} />
      )}
    </div>
  );
}
export default ViewAnswers;
