import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useGetMethod } from '../../../../graphql/method/methodOperations';
import { getData } from '../../../../components/ILAjaxEther';
import { webApiUrl } from '../../../../api/settingsLoader';
import { GroupResult } from '../../../../models/dto/GroupResult';
import TPClassesOverviewTable from './TPClassesOverviewTable';
import { useGetAuthenticated } from '../../../../graphql/authenticated/authenticatedOperations';
import { usePageTracking } from '../../../../utils/usePageTracking';

function ClassesOverview() {
  const method = useGetMethod();
  const { selectedSchoolId } = useGetAuthenticated();
  const history = useHistory<{ name?: string }>();
  const { t } = useTranslation();
  const [groups, setGroups] = useState<GroupResult[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);

  usePageTracking('result', 'overview');

  useEffect(() => {
    if (method && selectedSchoolId) {
      getData(`${webApiUrl}result/groups/${method.structureId}/${selectedSchoolId}`).then(
        (response: GroupResult[]) => {
          setGroups(response);
          setIsLoadingData(false);
        }
      );
    }
  }, [method, selectedSchoolId]);

  const openLastResults = (classId: string, lastPlanId: string) => {
    history.push({
      pathname: `/resultaat/klas/${classId}/${lastPlanId}`,
    });
  };

  const goToAllResults = (classId: string, className: string) => {
    history.push({
      pathname: `/resultaten/klas/${classId}`,
      state: {
        name: className,
      },
    });
  };

  return (
    <div className="main">
      <h1>{t('resultsPage:test_results_classes')}</h1>
      <TPClassesOverviewTable
        tableData={groups}
        isLoading={isLoadingData}
        openLastResults={openLastResults}
        goToAllResults={goToAllResults}
      />
    </div>
  );
}

export default ClassesOverview;
