import React, { ReactElement } from 'react';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from 'pure-react-carousel';
import ILIconAtom, { IconType } from '../ILIconAtom/ILIconAtom';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './style.scss';

interface Props {
  items: JSX.Element[];
}

function ILCarouselMolecule({ items }: Props): ReactElement {
  const mapCarouselSlides = () =>
    items.map((item, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Slide key={index} index={index}>
        {item}
      </Slide>
    ));

  return (
    <CarouselProvider
      naturalSlideWidth={1280}
      naturalSlideHeight={350}
      orientation="horizontal"
      totalSlides={items.length}
      visibleSlides={1}
      infinite={true}
    >
      {items.length > 1 ? (
        <>
          <div className="carousel-inner">
            <ButtonBack>
              <ILIconAtom type={IconType.ThinArrowLeft} size={33} />
            </ButtonBack>
            <Slider>{mapCarouselSlides()}</Slider>
            <ButtonNext>
              <ILIconAtom type={IconType.ThinArrowRight} size={33} />
            </ButtonNext>
          </div>
          <DotGroup className="carousel-indicators" />
        </>
      ) : (
        <div className="carousel-inner single-slide">
          <Slider>{mapCarouselSlides()}</Slider>
        </div>
      )}
    </CarouselProvider>
  );
}
export default ILCarouselMolecule;
