import React, { useState } from 'react';
import { connect } from 'react-redux';
import { AxiosResponse, Method } from 'axios';
import { useParams, useHistory } from 'react-router-dom';
import { State } from '../../../store';
import { Plan } from '../../../models/state/Plan';
import { useIsNlpNavigationEnabled, useNlpRoutes } from '../../../utils/nlpNavigation';
import { useContextNavigation } from '../../../utils/useContextNavigation';
import TPPlanningFooterMolecule from './TPPlanningFooterMolecule';
import { PlanErrorTypes } from './PlanErrorTypes';
import { createRequest } from '../../../components/ILAjaxEther';
import { usePlanningContext } from '../PlanningContext';
import { MenuItem } from '../../../components/ILMenuMolecule/ILMenuMolecule';
import { MethodType } from '../../../components/ILAjaxEther/MethodType';
import { validatePlan } from '../utils/planValidation';
import { createPlanDto, createPlanningInfoDto } from '../utils/planMapper';
import {
  WEB_API_PLANNING_BASE_URL,
  WEB_API_PLAN_INFO_BASE_URL,
} from '../../../constants/PlanningResourcesContstants';
import { PlanPraResponse } from '../../../models/dto/PlanPraResponse';
import { useGetMethod } from '../../../graphql/method/methodOperations';

interface Props {
  plan: Plan;
  returnPage: MenuItem;
}

const mapStateToProps = (state: State) => ({
  plan: state.plan,
});

function TPPlanningFooterMoleculeContainer({ plan, returnPage }: Props) {
  const method = useGetMethod();
  const { planId } = useParams<{ planId: string }>();
  const history = useHistory();
  const isNlpNavigationEnabled = useIsNlpNavigationEnabled();
  const plannedTestsPath = useContextNavigation('/toetsafnames');
  const nlpPlannedTestsUrl = useNlpRoutes('plannedTests');
  const nlpBackUrl = useNlpRoutes(returnPage.nlpKey);

  const [errors, setErrors] = useState<PlanErrorTypes[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const { isEdit, isEditEnabled } = usePlanningContext();

  const saveOrUpdatePlan = (methodType: Method, planningId?: string) => {
    if (method?.structureId) {
      setIsSaving(true);
      const planningUrl = planningId
        ? `${WEB_API_PLANNING_BASE_URL}/${planningId}`
        : WEB_API_PLANNING_BASE_URL;
      const planningInfoUrl = planningId
        ? `${WEB_API_PLAN_INFO_BASE_URL}/${planningId}`
        : WEB_API_PLAN_INFO_BASE_URL;
      createRequest(methodType, planningUrl, createPlanDto(plan, method.structureId))
        .then((response: AxiosResponse<PlanPraResponse>) => {
          createRequest(
            methodType,
            planningInfoUrl,
            createPlanningInfoDto(plan, planningId || response.data.id)
          )
            .then(() => {
              if (isNlpNavigationEnabled && nlpPlannedTestsUrl) {
                window.location.href = nlpPlannedTestsUrl;
              }
              history.push(plannedTestsPath);
            })
            .catch(() => {
              setIsSaving(false);
            });
        })
        .catch(() => {
          setIsSaving(false);
        });
    }
  };

  const isValidPlan = (): boolean => {
    const validationMessages = validatePlan(plan, isEdit);
    setErrors(validationMessages);
    return validationMessages.length === 0;
  };

  const schedulePlan = () => {
    if (isValidPlan() && isEditEnabled) {
      if (isEdit) {
        saveOrUpdatePlan(MethodType.Update, planId);
      } else {
        saveOrUpdatePlan(MethodType.Save);
      }
    }
  };

  const cancelPlan = () => {
    if (isNlpNavigationEnabled) {
      window.location.href = nlpBackUrl;
    } else {
      history.push(returnPage.url);
    }
  };

  return (
    <TPPlanningFooterMolecule
      errors={errors}
      schedulePlan={schedulePlan}
      isSaveDisabled={!isEditEnabled || isSaving}
      cancelPlan={cancelPlan}
    />
  );
}

export default connect(mapStateToProps)(TPPlanningFooterMoleculeContainer);
