import { gql } from '@apollo/client';

export const GET_FILTERS = gql`
  query GetFilters(
    $categoryIds: [Int]!
    $languages: [String]
    $learningLevels: [String]
    $learningYears: [String]
    $isVe: Boolean
  ) {
    filters(
      categoryIds: $categoryIds
      languages: $languages
      learningLevels: $learningLevels
      learningYears: $learningYears
      isVe: $isVe
    ) {
      key
      values
      selectedValues @client
      isEnabled @client
    }
  }
`;

export const GET_AVAILABLE_FILTERS = gql`
  query AvailableFilters {
    availableFilters @client
  }
`;

export const GET_ARRANGE_TEST_FILTERS = gql`
  query ArrangeTestFilters {
    arrangeTestFilters @client
  }
`;
