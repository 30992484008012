import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { News } from '../../models/state/News';
import ILIconAtom, { IconType } from '../ILIconAtom/ILIconAtom';
import ILCarouselMolecule from '../ILCarouselMolecule';

import './style.scss';
import '../../assets/utilities/grid.scss';
import '../../assets/theme/tp-buttons.scss';

type Props = {
  news: News[];
  handleClose: (newsId: string) => void;
};

function ILNewsFeedMolecule({ news, handleClose }: Props): ReactElement | null {
  const { t } = useTranslation();

  const mapNewsItems = (newsArr: News[]): JSX.Element[] =>
    newsArr.map((newsItem, index) => (
      <div className="news-container" key={newsItem.id}>
        <div className="news-header">
          <div className="update-counter">
            {news.length > 1
              ? `${index + 1} ${t('newsFeed:of')} ${news.length} ${t('newsFeed:updates')}`
              : `${news.length} ${t('newsFeed:update')}`}
          </div>
          <span onClick={() => handleClose(newsItem.id)}>
            <ILIconAtom className="close-icon" type={IconType.ThinClose} size={24} />
          </span>
        </div>
        <div className="news-content col-layout">
          <div className={newsItem.image ? 'col-50' : ''}>
            <h2>{newsItem.title}</h2>
            <p>{newsItem.content}</p>
            <div className="news-footer">
              {newsItem.link && (
                <a
                  className="tp-button tp-button-primary news-link"
                  href={newsItem.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('newsFeed:more_info')}
                </a>
              )}
              {newsItem.file && (
                <a
                  className="tp-button tp-button-inverse news-link"
                  href={newsItem.file}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('newsFeed:download_file')}
                </a>
              )}
            </div>
          </div>
          {newsItem.image && (
            <div className="col-50 news-snippet">
              <img alt={newsItem.title} src={newsItem.image} />
            </div>
          )}
        </div>
      </div>
    ));

  if (news?.length) {
    return <ILCarouselMolecule items={mapNewsItems(news)} />;
  }
  return null;
}

export default ILNewsFeedMolecule;
