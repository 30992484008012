import React, { createContext, useContext, useState, useCallback, ReactElement } from 'react';
import ILWizardHeaderMolecule from './ILWizardHeaderMolecule';
import { WizardContextValue, wizardContextValueInitial } from './WizardContextValue';

interface WizardProps {
  children: JSX.Element[];
  finish: () => void;
}

function ILWizardOrganism({ children, finish }: WizardProps): ReactElement {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [canGoNext, setCanGoNext] = useState(false);

  const goBack = () => {
    if (activeStepIndex - 1 >= 0) {
      setActiveStepIndex(activeStepIndex - 1);
      setCanGoNext(false);
    }
  };

  const goNext = () => {
    if (activeStepIndex + 1 < children.length) {
      setActiveStepIndex(activeStepIndex + 1);
      setCanGoNext(false);
    }
  };

  const goTo = (index: number, canGoForward = false) => {
    if (activeStepIndex > index || canGoForward) {
      setActiveStepIndex(index);
      setCanGoNext(false);
    }
  };

  const setReadyMem = useCallback(() => {
    setCanGoNext(true);
  }, [setCanGoNext]);

  const setNotReadyMem = useCallback(() => {
    setCanGoNext(false);
  }, [setCanGoNext]);

  const showBackButton = useCallback((): boolean => activeStepIndex > 0, [activeStepIndex]);

  const showNextButton = useCallback((): boolean => activeStepIndex < children.length - 1, [
    activeStepIndex,
    children,
  ]);

  const isLastStep = useCallback((): boolean => activeStepIndex === children.length - 1, [
    activeStepIndex,
    children,
  ]);

  const showCustomButton = useCallback(() => !!children[activeStepIndex].props.customAction, [
    activeStepIndex,
    children,
  ]);

  const wizardContextValue: WizardContextValue = {
    canGoNext,
    goNext,
    goBack,
    goTo,
    showBackButton,
    showNextButton,
    showCustomButton,
    isLastStep,
    finish,
    setNotReady: setNotReadyMem,
    setReady: setReadyMem,
  };

  return (
    <WizardContext.Provider value={wizardContextValue}>
      <ILWizardHeaderMolecule
        activeStepIndex={activeStepIndex}
        goToStep={goTo}
        names={children.map(c => c.props.name)}
      />
      {children[activeStepIndex]}
    </WizardContext.Provider>
  );
}

export default ILWizardOrganism;

// eslint-disable-next-line @typescript-eslint/naming-convention
const WizardContext = createContext<WizardContextValue>(wizardContextValueInitial);
export const useWizardContext = (): WizardContextValue => useContext(WizardContext);
