import {
  AnswerValue,
  CriteriaValue,
  ReviewTestPerStudentResponse,
  ReviewTestResponse,
} from '../../../models/dto/ReviewTest';
import { ItemCriteriaToSave, ReviewItemsToSave } from '../../../models/dto/updateScoresRequest';

export const mapToCriteriaItemsToSave = (criteria: CriteriaValue[]): ItemCriteriaToSave[] =>
  criteria.map(criteriaValue => ({
    paraginKey: criteriaValue.paraginKey,
    interpretation: criteriaValue.interpretation,
    maxScore: criteriaValue.maxScore,
    givenScore:
      criteriaValue.givenScore !== undefined && criteriaValue.givenScore !== null
        ? criteriaValue.givenScore
        : criteriaValue.correctorScore,
  }));

export const updateCandidateResponseWithScore = (
  candidateResponses: AnswerValue[],
  key: string,
  score: number,
  responseIndex: number
): AnswerValue[] => {
  const newCandidateResponses = [...candidateResponses];
  const criteriaIndexToChange = newCandidateResponses[responseIndex].criteria.findIndex(
    (criteriaItem: CriteriaValue) => criteriaItem.paraginKey === key
  );

  const newCriteriaArr = [...newCandidateResponses[responseIndex].criteria];
  newCriteriaArr[criteriaIndexToChange] = {
    ...newCriteriaArr[criteriaIndexToChange],
    givenScore: score,
  };

  newCandidateResponses[responseIndex] = {
    ...newCandidateResponses[responseIndex],
    valueChanged: true,
    criteria: newCriteriaArr,
  };

  return newCandidateResponses;
};

export const isAnswerReviewed = (criteriaValues: CriteriaValue[]): boolean =>
  !criteriaValues.some(criteria => criteria.givenScore === null) ||
  !criteriaValues.some(criteria => criteria.correctorScore === null);

export const getMaxPoints = (candidateResponses: AnswerValue[]): number => {
  let maxPoints = 0;
  candidateResponses.forEach((response: AnswerValue) => {
    response.criteria.forEach((criteria: CriteriaValue) => {
      maxPoints += criteria.maxScore;
    });
  });
  return maxPoints;
};

export const getReviewQuestionItemsToSave = (
  data: ReviewTestResponse,
  saveAll: boolean
): ReviewItemsToSave[] => {
  const dataToSave: ReviewItemsToSave[] = [];
  data.reviewTest.questionsWithAnswers.forEach(questionWithAnswers => {
    questionWithAnswers.givenAnswers.forEach(givenAnswer => {
      givenAnswer.candidateResponses.forEach(candidateResponse => {
        if (saveAll || candidateResponse.valueChanged) {
          dataToSave.push({
            id: givenAnswer.itemCheckId,
            itemCriteria: mapToCriteriaItemsToSave(candidateResponse.criteria),
          });
        }
      });
    });
  });
  return dataToSave;
};

export const getStudentReviewItemsToSave = (
  data: ReviewTestPerStudentResponse,
  saveAll: boolean
): ReviewItemsToSave[] => {
  const dataToSave: ReviewItemsToSave[] = [];
  data.reviewTestPerStudent.studentWithAnswers.forEach(studentAnswer => {
    studentAnswer.studentGivenAnswers.forEach(studentGivenAnswer => {
      studentGivenAnswer.candidateResponses.forEach(candidateResponse => {
        if (saveAll || candidateResponse.valueChanged) {
          dataToSave.push({
            id: studentGivenAnswer.itemCheckId,
            itemCriteria: mapToCriteriaItemsToSave(candidateResponse.criteria),
          });
        }
      });
    });
  });
  return dataToSave;
};
