import React from 'react';
import { connect } from 'react-redux';
import { State } from '../../../store';
import { MenuItem } from '../../../components/ILMenuMolecule/ILMenuMolecule';
import TPBlueHeaderContainer from '../../../components/TPBlueHeaderOrganism';

interface Props {
  title: string;
  returnPage: MenuItem;
}

const mapStateToProps = (state: State) => ({
  title: state.plan.title,
});

function TPPlanningHeaderOrganismContainer({ title, returnPage }: Props) {
  return (
    <TPBlueHeaderContainer
      title={title}
      menuItem={returnPage}
    />
  );
}

export default connect(mapStateToProps)(TPPlanningHeaderOrganismContainer);
