import moment from 'moment';
import 'moment/locale/nl';
import { TFunction } from 'i18next';
import { Filter } from 'react-table';
import { getStartOfSchoolYear, getToday } from './DateTimeMapper';
import { Option } from '../components/ILTableOrganism/ILTableColumn/createColumns';

export enum DatePeriodOptions {
  LastMonth = 'last_month',
  Last3Month = 'last_3_month',
  SchoolYear = 'school_year',
}

export const getDatePeriodOptions = (t: TFunction): Option[] => [
  {
    key: DatePeriodOptions.LastMonth,
    text: `${t(`resultsPage:${DatePeriodOptions.LastMonth}`)}`,
  },
  {
    key: DatePeriodOptions.Last3Month,
    text: `${t(`resultsPage:${DatePeriodOptions.Last3Month}`)}`,
  },
  {
    key: DatePeriodOptions.SchoolYear,
    text: `${t(`resultsPage:${DatePeriodOptions.SchoolYear}`)}`,
  },
];

export const filterBySelectedPeriod = (filter: Filter, row: any): boolean => {
  switch (filter.value) {
    case DatePeriodOptions.LastMonth:
      // eslint-disable-next-line no-underscore-dangle
      return moment(row._original[filter.id]) > getToday().subtract(1, 'month');
    case DatePeriodOptions.Last3Month:
      // eslint-disable-next-line no-underscore-dangle
      return moment(row._original[filter.id]) > getToday().subtract(3, 'month');
    case DatePeriodOptions.SchoolYear:
      // eslint-disable-next-line no-underscore-dangle
      return moment(row._original[filter.id]) > getStartOfSchoolYear();
    default:
      return true;
  }
};
