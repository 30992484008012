import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useIsNlpNavigationEnabled, useNlpRoutes } from '../../../utils/nlpNavigation';
import { MenuItem } from '../../ILMenuMolecule/ILMenuMolecule';
import ILIconAtom, { IconType } from '../../ILIconAtom';

interface Props {
  menuItem: MenuItem;
}

function ILBreadCrumbsMolecule({ menuItem }: Props): ReactElement {
  const { t } = useTranslation();
  const nlpLink = useNlpRoutes(menuItem.nlpKey);
  const isNlpNavigationEnabled = useIsNlpNavigationEnabled();

  return (
    <span>
      {nlpLink && isNlpNavigationEnabled ? (
        <a id="link" href={nlpLink}>
          <ILIconAtom type={IconType.NavigationBack} size={13} />
          {` ${t(`menu:${menuItem.resourceKey}`)}`}
        </a>
      ) : (
        <NavLink exact={menuItem.url === '/'} to={menuItem.url} id="link">
          <ILIconAtom type={IconType.NavigationBack} size={13} />
          {` ${t(`menu:${menuItem.resourceKey}`)}`}
        </NavLink>
      )}
    </span>
  );
}

export default ILBreadCrumbsMolecule;
