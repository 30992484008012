import React, { ReactElement } from 'react';
import ILPaginationMenuAtom from './ILPaginationMenuAtom';
import './style.scss';

interface Props {
  items: JSX.Element[];
  pageNumbers: JSX.Element[];
  pageSizeDropdown: JSX.Element;
  totalPageCount: number;
  activePage: number;
  showTopNavigation: boolean;
  previousPageCallback: () => void;
  nextPageCallback: () => void;
}

export default function ILPaginationMolecule({
  items,
  pageNumbers,
  pageSizeDropdown,
  activePage,
  showTopNavigation,
  previousPageCallback,
  nextPageCallback,
  totalPageCount,
}: Props): ReactElement {
  return (
    <>
      {showTopNavigation && (
        <ILPaginationMenuAtom
          pageNumbers={pageNumbers}
          totalPageCount={totalPageCount}
          activePage={activePage}
          previousPageCallback={previousPageCallback}
          nextPageCallback={nextPageCallback}
        />
      )}
      {items}
      <ILPaginationMenuAtom
        pageNumbers={pageNumbers}
        totalPageCount={totalPageCount}
        pageSizeDropdown={pageSizeDropdown}
        activePage={activePage}
        previousPageCallback={previousPageCallback}
        nextPageCallback={nextPageCallback}
      />
    </>
  );
}
