import { CachePersistor } from 'apollo-cache-persist';
import { PersistentStorage, PersistedData } from 'apollo-cache-persist/types';
import { HttpLink, NormalizedCacheObject, ApolloClient } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import localForage from 'localforage';

import { GraphQLError } from 'graphql/error';
import { graphQLUrl, enableApolloDevTools } from '../api/settingsLoader';
import { cache } from './cache';

import { arrangeTestTypes } from './arrangeTest/arrangeTestTypes';
import { authenticatedTypes } from './authenticated/authenticatedTypes';

import { getToken } from '../services/JwtService';

const httpLink = new HttpLink({ uri: graphQLUrl });

const authLink = setContext(async (_, { headers }) => {
  const token = await getToken();
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  };
});

const retryLink = new RetryLink({
  delay: {
    initial: 800,
    max: 3000,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error, _operation) => !!error,
  },
});

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors?.some((error: GraphQLError) => error.extensions?.code === 'UNAUTHENTICATED')) {
    return forward(operation);
  }
});

export const cachePersistor = new CachePersistor({
  cache,
  storage: localForage as PersistentStorage<PersistedData<NormalizedCacheObject>>,
});
cachePersistor.restore();

export const client = new ApolloClient({
  cache,
  connectToDevTools: enableApolloDevTools,
  link: retryLink.concat(errorLink).concat(authLink).concat(httpLink),
  typeDefs: [arrangeTestTypes, authenticatedTypes],
});
