import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import Collapse from '@material-ui/core/Collapse';
import { sortTexts } from '../../utils/Sorting';
import { formatLastName } from '../../utils/NameFormatter';
import ILStudentSelectorAtom from './ILStudentSelectorAtom';
import ILIcon, { IconType } from '../ILIconAtom';
import { Groups, Profile } from '../../models/state/Groups';
import './ILStudentListMolecule.scss';

interface StudentListProps {
  studentGroup: Groups;
  collapsedHeight: number;
  checkedIds: string[];
  isOpen: boolean;
  handleSelectionChange: (studentId: string, groupId: string) => void;
  selectAllStudentToggle: (id: string) => void;
  isAllSelected: (studentGroupId: string) => boolean;
  countSelectedItems: (studentGroup: Groups) => number;
  toggleStudentGroup: (studentGroupId: string) => void;
  isIndeterminate: boolean;
}

function ILStudentListMolecule({
  studentGroup,
  collapsedHeight,
  checkedIds,
  handleSelectionChange,
  selectAllStudentToggle,
  isAllSelected,
  countSelectedItems,
  isOpen,
  toggleStudentGroup,
  isIndeterminate,
}: StudentListProps): ReactElement {
  const { t } = useTranslation();

  const renderStudents = () => {
    const sortedStudentList = studentGroup.profiles.sort((studentA, studentB) =>
      sortTexts(
        formatLastName(studentA.lastName, studentA.prefix),
        formatLastName(studentB.lastName, studentB.prefix)
      )
    );
    return (
      <ul className="student-list" key={studentGroup.group.id}>
        {sortedStudentList.map((student: Profile) => (
          <ILStudentSelectorAtom
            key={student.udbId}
            isChecked={checkedIds.indexOf(student.udbId) !== -1}
            handleSelectionChange={handleSelectionChange}
            student={student}
            groupId={studentGroup.group.id}
          />
        ))}
      </ul>
    );
  };

  const renderEmptyClassMessage = () => (
    <span className="empty-class">{t('studentList:empty_class')}</span>
  );

  return (
    <Collapse
      className="group"
      key={studentGroup.group.id}
      in={isOpen}
      collapsedHeight={`${collapsedHeight}px`}
    >
      <div
        role="button"
        className="group-header"
        tabIndex={-1}
        onClick={() => toggleStudentGroup(studentGroup.group.id)}
      >
        <div className="group-check">
          <label htmlFor={`group-checkbox-${studentGroup.group.id}`} className="select-input">
            <input
              id={`group-checkbox-${studentGroup.group.id}`}
              type="checkbox"
              name="group-checkbox"
              checked={isAllSelected(studentGroup.group.id) || isIndeterminate}
              aria-checked={true}
              onChange={() => selectAllStudentToggle(studentGroup.group.id)}
            />
            <span className={isIndeterminate ? 'indeterminate' : 'checkmark'} />
          </label>
          <span className="group-name">{studentGroup.group.name}</span>
        </div>
        <div>
          {isOpen && (
            <span className="selected-item-counter">
              {`${countSelectedItems(studentGroup)} ${t('studentList:from')} ${
                studentGroup.profiles.length
              } ${t('studentList:selected')}`}
            </span>
          )}
          <ILIcon type={isOpen ? IconType.Collapse : IconType.Expand} size={14} />
        </div>
      </div>
      {studentGroup.profiles.length > 0 ? renderStudents() : renderEmptyClassMessage()}
    </Collapse>
  );
}

export default ILStudentListMolecule;
