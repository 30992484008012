import { gql } from '@apollo/client';

export const GET_QUESTION_FILTER_OPTIONS = gql`
  query GetQuestionFiltersOptions {
    questionFilterOptions @client {
      domain
      kti
      type
    }
  }
`;

export const GET_SELECTED_QUESTION_FILTERS = gql`
  query GetSelectedQuestionFilters {
    selectedQuestionFilters @client {
      domain
      kti
      type
    }
  }
`;

export const GET_ARRANGED_TEST = gql`
  query GetArrangedTest {
    arrangedTest @client {
      title
      mgrRecipeId
      currentStep
      selectedQuestions {
        id
        maxScore
        level
        year
        chapter
        kti
        domain
        open
      }
    }
  }
`;
