import React, { ReactElement } from 'react';

type Props = {
  // Linting gives a false error here, as it is used in the parent component
  // eslint-disable-next-line react/no-unused-prop-types
  name: string;
  children: JSX.Element;
};

function ILTabMolecule({ children }: Props): ReactElement {
  return <>{children}</>;
}

export default ILTabMolecule;
