import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  domains: Map<string, number>;
}

function TPDomainPanelMolecule({ domains }: Props): ReactElement {
  const { t } = useTranslation();
  const renderDomainDataRows = (): JSX.Element[] => {
    const row: JSX.Element[] = [];
    domains.forEach((value: number, key: string) => {
      row.push(
        <tr key={value}>
          <td>{key}</td>
          <td className="detail-value">{value}</td>
        </tr>
      );
    });
    return row;
  };

  return (
    <div className="details-table-container">
      <h2 className="panel-title">{t('arrangedTestDetails:domain')}</h2>
      <table className="table-test-details">
        <tbody>{renderDomainDataRows()}</tbody>
      </table>
    </div>
  );
}

export default TPDomainPanelMolecule;
