import React, { ReactElement, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatName } from '../../../utils/NameFormatter';
import {
  ReviewQuestion,
  ReviewTestStudent,
  StudentWithAnswers,
} from '../../../models/dto/ReviewTest';
import ILIconAtom, { IconType } from '../../../components/ILIconAtom';
import TPSidebarMolecule from '../common/TPSidebarMolecule';
import TPReviewQuestionBlockOrganism from '../common/TPReviewExpandableBlockOrganism/TPReviewBlocks/TPReviewQuestionBlockOrganism';
import TPFinishReviewModalOrganism from '../common/TPFinishReviewModalOrganism';
import TPReviewExpandableBlockOrganism from '../common/TPReviewExpandableBlockOrganism';
import TPSidebarToggleAtom from '../common/TPSidebarMolecule/TPSidebarToggleAtom';
import '../style.scss';

type Props = {
  reviewStudents: ReviewTestStudent;
  isSidebarOpen: boolean;
  selectedStudentIndex: number;
  changeSelectedStudent: (selectedStudentIndex: number) => void;
  toggleSidebar: () => void;
  finishReview: () => void;
};

function ReviewPerStudents({
  reviewStudents,
  isSidebarOpen,
  selectedStudentIndex,
  changeSelectedStudent,
  toggleSidebar,
  finishReview,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [isFinishModalOpen, setIsFinishModalOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const scrollTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  };

  const findQuestion = (questionId: string): ReviewQuestion | undefined =>
    reviewStudents.questions.find(question => question.id === questionId);

  return (
    <div className={`review-container ${isSidebarOpen ? 'sidebar-open' : ''}`}>
      <TPSidebarMolecule
        percentageReviewed={reviewStudents.percentageReviewed}
        items={reviewStudents.studentWithAnswers.map((student: StudentWithAnswers) => ({
          title: formatName(student.firstName, student.lastName, student.prefix),
          progress: student.progress,
        }))}
        selectedItemIndex={selectedStudentIndex}
        isOpen={isSidebarOpen}
        handleItemSelection={changeSelectedStudent}
        handleFinishClick={() => setIsFinishModalOpen(!isFinishModalOpen)}
      />
      <div ref={contentRef} className="review-content">
        <TPSidebarToggleAtom isOpen={isSidebarOpen} toggle={toggleSidebar} />
        <div className={`review${isSidebarOpen ? '' : ' review__full'}`}>
          {reviewStudents.studentWithAnswers[selectedStudentIndex].studentGivenAnswers.map(
            studentGivenAnswer => (
              <TPReviewExpandableBlockOrganism
                key={`${selectedStudentIndex}_${studentGivenAnswer.questionId}`}
                title={`${t('reviewPage:question')} ${reviewStudents.questions.find(question => question.id === studentGivenAnswer.questionId)?.number}`}
                isReviewed={studentGivenAnswer.isReviewed}
                giveFeedbackEnabled={false}
              >
                <TPReviewQuestionBlockOrganism
                  studentGivenAnswer={studentGivenAnswer}
                  question={findQuestion(studentGivenAnswer.questionId)}
                />
              </TPReviewExpandableBlockOrganism>
            )
          )}
        </div>
        <div className="scroll-up" onClick={() => scrollTop()}>
          <ILIconAtom type={IconType.Up} size={14} />
        </div>
      </div>
      <TPFinishReviewModalOrganism
        isOpen={isFinishModalOpen}
        closeModal={() => setIsFinishModalOpen(false)}
        finishReview={finishReview}
      />
    </div>
  );
}

export default ReviewPerStudents;
