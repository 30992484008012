import React, { useState, useEffect, useCallback, ReactElement } from 'react';
import { PaginationPage } from './models/PaginationPage';
import ILPaginationMolecule from './ILPaginationMolecule';

interface Props {
  itemsPerPageStep: number;
  maxItemsPerStep: number;
  minItemPerStep: number;
  showTopNavigation?: boolean;
  itemCount?: number;
  items: JSX.Element[];
}

export default function ILPaginationMoleculeContainer({
  minItemPerStep,
  maxItemsPerStep,
  itemsPerPageStep,
  showTopNavigation,
  itemCount,
  items,
}: Props): ReactElement | null {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(minItemPerStep);
  const [itemList, setItemList] = useState<PaginationPage[]>();
  const handleFilterChange = (value: number) => {
    setItemsPerPage(value);
  };

  useEffect(() => {
    setItemsPerPage(minItemPerStep);
  }, [minItemPerStep]);

  useEffect(() => {
    try {
      window.scroll({
        top: 0,
        left: 0,
      });
    } catch (error) {
      window.scrollTo(0, 0);
    }
  }, [currentPage]);

  const shouldRenderSpread = useCallback(
    (pageNumber: number, index: number, array: number[], totalPages: number): boolean =>
      (totalPages > 6 && array[index - 1] + 3 < currentPage) ||
      (totalPages > 6 && pageNumber === totalPages && array[index - 1] !== totalPages - 1),
    [currentPage]
  );

  const mapPaginationPages = useCallback(
    (numberOfItems: number, elements: JSX.Element[]) => {
      const pageList: PaginationPage[] = [];
      const totalPages = Math.ceil(numberOfItems / itemsPerPage);
      for (let i = 1; i < totalPages + 1; i += 1) {
        const indexOfLast = i * itemsPerPage;
        const indexOfFirst = indexOfLast - itemsPerPage;
        pageList.push({
          pageNumber: i,
          items: elements.slice(indexOfFirst, indexOfLast),
        });
      }
      setItemList(pageList);
      if (totalPages > 0 && currentPage > totalPages) {
        setCurrentPage(totalPages);
      }
    },
    [currentPage, itemsPerPage]
  );

  const getAvailablePages = (activePage: number, totalPages: number): number[] => {
    if (totalPages < 7) {
      return [1, 2, 3, 4, 5, 6].filter((item: number) => item <= totalPages);
    }
    if (activePage > 4 && activePage + 2 < totalPages) {
      return [1, activePage - 1, activePage, activePage + 1, totalPages];
    }
    if (activePage > 4 && activePage + 2 >= totalPages) {
      return [1, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    }
    return [1, 2, 3, 4, 5, totalPages];
  };

  const renderPageNumbers = useCallback(
    (numberOfItems: number) => {
      const totalPages = Math.ceil(numberOfItems / itemsPerPage);
      const availablePages = getAvailablePages(currentPage, totalPages);
      return availablePages.map((pageNumber: number, index: number, array: number[]) => (
        <React.Fragment key={pageNumber.toString()}>
          {shouldRenderSpread(pageNumber, index, array, totalPages) && (
            <li
              className="spread-item"
              id={`${pageNumber.toString()}_prefix`}
              key={`${pageNumber.toString()}_prefix`}
            >
              ...
            </li>
          )}
          <li
            className={pageNumber === currentPage ? 'selected-page' : ''}
            id={pageNumber.toString()}
            key={pageNumber.toString()}
            onClick={() => setCurrentPage(pageNumber)}
            role="menuitem"
            tabIndex={-1}
          >
            {pageNumber.toString()}
          </li>
        </React.Fragment>
      ));
    },
    [currentPage, itemsPerPage, shouldRenderSpread]
  );

  useEffect(() => {
    if (itemCount) {
      mapPaginationPages(itemCount, items);
    } else {
      renderPageNumbers(items.length);
      mapPaginationPages(items.length, items);
    }
  }, [itemCount, items, mapPaginationPages, renderPageNumbers]);

  const goPreviousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goNextPage = () => {
    if (itemList && currentPage < itemList.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const renderFilterDropdown = () => {
    const dropdownItems = [];
    for (let i = minItemPerStep; i < maxItemsPerStep; i += itemsPerPageStep) {
      dropdownItems.push(
        <option value={i} key={i}>
          {i}
        </option>
      );
    }
    return (
      <select value={itemsPerPage} onChange={e => handleFilterChange(+e.target.value)}>
        {dropdownItems}
      </select>
    );
  };

  const getCurrentPage = () => {
    if (itemList) {
      const newPage = itemList.find(page => page.pageNumber === currentPage);
      if (newPage) {
        return newPage.items;
      }
    }
    return [];
  };

  if (itemCount || items.length > 0) {
    return (
      <ILPaginationMolecule
        items={getCurrentPage()}
        pageNumbers={itemCount ? renderPageNumbers(itemCount) : renderPageNumbers(items.length)}
        totalPageCount={itemList ? itemList.length : 0}
        pageSizeDropdown={renderFilterDropdown()}
        activePage={currentPage}
        showTopNavigation={showTopNavigation || false}
        previousPageCallback={goPreviousPage}
        nextPageCallback={goNextPage}
      />
    );
  }
  return null;
}

ILPaginationMoleculeContainer.defaultProps = { showTopNavigation: false };
