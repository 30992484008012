import { gql } from '@apollo/client';

export const ABORT_SUBSCRIPTION = gql`
  mutation abortSubscription($resultIds: [ID!]) {
    abortSubscription(resultIds: $resultIds) {
      success
      failedIds
    }
  }
`;
