import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { MenuItem } from '../ILMenuMolecule/ILMenuMolecule';
import { toggleDisplayMenuAction } from '../../store/context/actions';
import TPBlueHeaderOrganism from './TPBlueHeaderOrganism';

interface Props {
  title?: string;
  menuItem: MenuItem;
  dispatchToggleDisplayMenuAction: (displayMenu: boolean) => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchToggleDisplayMenuAction: (displayMenu: boolean) => {
    dispatch(toggleDisplayMenuAction(displayMenu));
  },
});

function TPBlueHeaderOrganismContainer({
  title,
  menuItem,
  dispatchToggleDisplayMenuAction,
}: Props): ReactElement {
  useEffect(() => {
    dispatchToggleDisplayMenuAction(false);
    return () => {
      dispatchToggleDisplayMenuAction(true);
    };
  }, [dispatchToggleDisplayMenuAction]);

  return <TPBlueHeaderOrganism title={title} menuItem={menuItem} />;
}

export default connect(null, mapDispatchToProps)(TPBlueHeaderOrganismContainer);
