import moment from 'moment';
import 'moment/locale/nl';

export const getToday = (): moment.Moment => moment().startOf('day');

export const getTomorrow = (): moment.Moment => moment().add(1, 'day').startOf('day');

export const getEndOfSchoolYear = (): moment.Moment => {
  const end = moment();
  if (moment().month() < 7) {
    end.set('year', moment().year());
    end.set('month', 6);
    end.set('date', 31);
  } else {
    end.set('year', moment().year() + 1);
    end.set('month', 6);
    end.set('date', 31);
  }
  return end.endOf('day');
};

export const getEndOfNextSchoolYear = (): moment.Moment => {
  const end = getEndOfSchoolYear().add(1, 'years');
  return end.endOf('day');
};

export const getStartOfSchoolYear = (): moment.Moment => {
  const start = moment();
  if (moment().month() < 7) {
    start.set('year', moment().year() - 1);
    start.set('month', 7);
    start.set('date', 1);
  } else {
    start.set('year', moment().year());
    start.set('month', 7);
    start.set('date', 1);
  }
  return start.startOf('day');
};

const getHour = (time: string): number => parseInt(time.split(':')[0], 10);

const getMinute = (time: string): number => parseInt(time.split(':')[1], 10);

export const createDateTime = (date: moment.Moment | null, time: string): moment.Moment | null => {
  if (date) {
    const dateTime = date.clone();
    dateTime.set('hour', getHour(time));
    dateTime.set('minute', getMinute(time));
    return dateTime;
  }
  return date;
};

export const convertMomentToString = (date: moment.Moment | null): string | null =>
  date ? date.format() : null;
