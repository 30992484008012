import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertType } from '../../ILAlertMessageMolecule/AlertType';
import { ILLoadingIndicator } from '../../ILLoadingIndicator';
import ILAlertMessageMolecule from '../../ILAlertMessageMolecule';
import ILSecretInputAtom from '../../ILSecretInputAtom';
import ILModalMolecule from '../../ILModalMolecule';
import ILModalButtonsMolecule from '../../ILModalMolecule/ILModalButtonsMolecule';
import './style.scss';

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  pin: string;
  handlePinChange: (newPin: string) => void;
  isWrongPin: boolean;
  validatePin: () => void;
  closeModal: () => void;
};

function ILRemindoCodeModalMolecule({
  isOpen,
  isLoading,
  pin,
  isWrongPin,
  handlePinChange,
  validatePin,
  closeModal,
}: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <ILModalMolecule
      className={`code-modal${isWrongPin ? ' code-modal-incorrect' : ''}`}
      handleClose={closeModal}
      isOpen={isOpen}
      title={t('remindo:enter_code')}
      subText={t('remindo:code_required')}
    >
      {isWrongPin ? (
        <ILAlertMessageMolecule
          type={AlertType.Error}
          message={t('remindo:incorrect_code')}
          showIcon
        />
      ) : (
        <></>
      )}
      {isLoading ? (
        <ILLoadingIndicator />
      ) : (
        <ILSecretInputAtom pin={pin} handleInputChange={handlePinChange} />
      )}
      <ILModalButtonsMolecule
        isDisabled={isLoading}
        acceptText={t('remindo:go_to_test')}
        onAccept={validatePin}
        onCancel={closeModal}
      />
    </ILModalMolecule>
  );
}

export default ILRemindoCodeModalMolecule;
