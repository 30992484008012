import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ILModalMolecule from '../../../../../components/ILModalMolecule';
import ILModalButtonsMolecule from '../../../../../components/ILModalMolecule/ILModalButtonsMolecule';
import './style.scss';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  releaseResult: () => void;
};

function TPReleaseModalOrganism({ isOpen, closeModal, releaseResult }: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <ILModalMolecule
      isOpen={isOpen}
      title={t('resultsPage:release_result')}
      handleClose={closeModal}
    >
      <div className="release-modal">
        <p>{t('resultsPage:student_see_results')}</p>
        <p>{t('resultsPage:can_not_adjust_later')}</p>
      </div>
      <ILModalButtonsMolecule
        onCancel={closeModal}
        onAccept={releaseResult}
        acceptText={t('resultsPage:release')}
      />
    </ILModalMolecule>
  );
}

export default TPReleaseModalOrganism;
