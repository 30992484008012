import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { State } from '../../../store';
import { Caesura } from '../../../models/state/Plan';
import { setGradeAction, setScoreAction } from '../../../store/planning/actions';
import TPPlanningCaesuraOrganism from './TPPlanningCaesuraOrganism';

interface Props {
  caesura: Caesura;
  dispatchSetScoreAction: (id: number, score: string) => void;
  dispatchSetGradeAction: (id: number, grade: string) => void;
}

const mapStateToProps = (state: State) => ({
  caesura: state.plan.caesura,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchSetScoreAction: (id: number, score: string) => {
    dispatch(setScoreAction(id, score));
  },
  dispatchSetGradeAction: (id: number, grade: string) => {
    dispatch(setGradeAction(id, grade));
  },
});

function TPPlanningCaesuraOrganismContainer({
  caesura,
  dispatchSetScoreAction,
  dispatchSetGradeAction,
}: Props) {
  return (
    <TPPlanningCaesuraOrganism
      caesura={caesura}
      handleScoreChange={dispatchSetScoreAction}
      handleGradeChange={dispatchSetGradeAction}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TPPlanningCaesuraOrganismContainer);
