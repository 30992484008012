import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useWizardContext } from '../ILWizardOrganism';
import ILIconAtom, { IconType } from '../../ILIconAtom/ILIconAtom';
import './ILWizardNavigationMolecule.scss';
import '../../../assets/theme/tp-buttons.scss';

interface WizardNavigationProps {
  nextButtonName?: string;
  backButtonName?: string;
  customButtonName?: string;
  finishButtonName?: string;
  nextButtonDescription?: string;
  backButtonDescription?: string;
  customButtonDescription?: string;
  finishButtonDescription?: string;
  customAction?: () => void;
}

function ILWizardNavigationMolecule({
  nextButtonName,
  backButtonName,
  customButtonName,
  finishButtonName,
  nextButtonDescription,
  backButtonDescription,
  customButtonDescription,
  finishButtonDescription,
  customAction,
}: WizardNavigationProps): ReactElement {
  const { t } = useTranslation();
  const {
    canGoNext,
    goNext,
    goBack,
    showBackButton,
    showNextButton,
    showCustomButton,
    isLastStep,
    finish,
  } = useWizardContext();

  return (
    <div className="navigation">
      <section className="navigation__section">
        {showBackButton() && (
          <>
            <button className="tp-button tp-button-link navigation__button--back" onClick={goBack}>
              {backButtonName || t('wizard:back')}
            </button>
            <ButtonDescription text={backButtonDescription} />
          </>
        )}
      </section>
      <section className="navigation__section">
        {showCustomButton() && (
          <>
            <button className="tp-button navigation__button--custom" onClick={customAction}>
              {customButtonName || t('wizard:custom')}
            </button>
            <ButtonDescription text={customButtonDescription} />
          </>
        )}
        {showNextButton() && !isLastStep() && (
          <>
            <button
              className="tp-button tp-button-secondary tp-button-with-icon navigation__button--next"
              onClick={goNext}
              disabled={!canGoNext}
            >
              {nextButtonName || t('wizard:next')}
              <ILIconAtom type={IconType.NavigationNext} size={14} />
            </button>
            <ButtonDescription text={nextButtonDescription} />
          </>
        )}
        {isLastStep() && (
          <>
            <button
              className="tp-button tp-button-primary navigation__button--finish"
              onClick={finish}
              disabled={!canGoNext}
            >
              {finishButtonName || t('wizard:finish')}
            </button>
            <ButtonDescription text={finishButtonDescription} />
          </>
        )}
      </section>
    </div>
  );
}

function ButtonDescription({ text }: { text?: string }) {
  return text ? <span className="navigation__button-description">{text}</span> : null;
}

export default ILWizardNavigationMolecule;
