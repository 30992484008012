export const appInsightsKey = process.env.REACT_APP_INSIGHTS_KEY;
export const applicationName = process.env.REACT_APP_APP_NAME || '';
export const arrangeTestQuestionsCap = parseInt(process.env.REACT_APP_ARRANGE_TEST_CAP || '', 10);
export const arrangeTestStudyId = parseInt(process.env.REACT_APP_ARRANGE_TEST_ID || '', 10);
export const configCatKey = process.env.REACT_APP_CONFIGCAT_KEY || '';
export const devKey = process.env.REACT_APP_DEV_KEY;
export const devSelectedSchool = process.env.REACT_APP_DEV_SCHOOL;
export const enableApolloDevTools = !!process.env.REACT_APP_ENABLE_DEVTOOLS || false;
export const gaTrackingCode = process.env.REACT_APP_GA_TRACKINGCODE || '';
export const graphQLUrl = process.env.REACT_APP_GRAPHQL_URL || '';
export const idpUrl = process.env.REACT_APP_IDP_URL || '';
export const paraginServiceUrl = process.env.REACT_APP_PARAGIN_URL || '';
export const maxPendingSeconds = process.env.PENDING_SECONDS || 120;
export const praApiUrl = process.env.REACT_APP_PRA_URL || '';
export const resultsPerQuestionPageSize = Number(process.env.REACT_APP_RESULTS_PER_QUESTION_PAGE_SIZE || 5);
export const segmentEnvironment = process.env.REACT_APP_SEGMENT_ENV || '';
export const segmentWriteKey = process.env.REACT_APP_SEGMENT_KEY || '';
export const settingsAppId = process.env.REACT_APP_SETTINGS_ID || '';
export const settingsWebApiUrl = process.env.REACT_APP_SETTINGS_URL || '';
export const version = process.env.REACT_APP_VERSION;
export const webApiUrl = process.env.REACT_APP_WEBAPI_URL || '';
export const nlpUrl = process.env.REACT_APP_NLP_URL || ''
