import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { webApiUrl } from '../../../../../../api/settingsLoader';
import { createRequest } from '../../../../../../components/ILAjaxEther';
import { MethodType } from '../../../../../../components/ILAjaxEther/MethodType';
import ILIconAtom, { IconType } from '../../../../../../components/ILIconAtom';
import ILScoreMeterAtom from '../../../../../../components/ILScoreMeterAtom';
import {
  TableColumn,
  TableDataType,
  TableFilterType,
  TableHeaderType,
} from '../../../../../../components/ILTableOrganism/ILTableColumn/createColumns';
import ILTableOrganism from '../../../../../../components/ILTableOrganism/ILTableOrganismContainer';
import { sortTexts } from '../../../../../../components/ILTableOrganism/utils/rowSorting';
import TPFeedbackMolecule from '../../../../../../components/TPFeedbackMolecule';
import { Status } from '../../../../../../components/TPStatusAtom/Status';
import { ResultDashboardStudent } from '../../../../../../models/dto/DashboardResult';
import { StudentsFeedback } from '../../../../../../models/dto/StudentsFeedback';
import { formatLastName } from '../../../../../../utils/NameFormatter';
import { useContextNavigation } from '../../../../../../utils/useContextNavigation';
import { renderStatusColumn } from '../../../../utils/renderCustomTableColumns'
import { findDomainScoreByKey, getDistinctDomains, getDistinctKti } from '../utils/domainMapper';
import './style.scss';

function StudentResultLink({
  name,
  studentId,
  planningId,
}: {
  name: string;
  studentId: string;
  planningId: string;
}): ReactElement {
  const studentResultRoute = useContextNavigation(`/resultaat/leerling/${studentId}/${planningId}`);
  return (
    <Link to={studentResultRoute} className="student-link">
      {name}
    </Link>
  );
}

type Props = {
  data: ResultDashboardStudent[];
  planningId: string;
  showAlphabeticGrade: boolean;
  hideGrade: boolean;
  isKti: boolean;
  status: Status;
  isFullScreen: boolean;
  isClassDashboard: boolean;
  openFullScreen: () => void;
  reloadData: () => void;
};

function TPStudentResultsTable({
  data,
  planningId,
  showAlphabeticGrade,
  hideGrade,
  isKti,
  status,
  isFullScreen,
  isClassDashboard,
  openFullScreen,
  reloadData,
}: Props): ReactElement {
  const { t } = useTranslation();

  const saveStudentFeedback = (feedback: string, resultId?: string) => {
    if (resultId) {
      const studentsFeedback: StudentsFeedback = {
        resultIds: [resultId],
        value: feedback,
      };
      createRequest(MethodType.Update, `${webApiUrl}result/feedback`, studentsFeedback).then(() =>
        reloadData()
      );
    }
  };

  const getCaesuraClass = (value: number): string => (value < 55 ? 'score-nok' : 'score-ok');

  const renderResultScoreCell = (rowData: ResultDashboardStudent, key: string): JSX.Element => {
    const score = findDomainScoreByKey(rowData, key, isKti);
    const className = score || score === 0 ? getCaesuraClass(score) : 'score-none';
    return (
      <div className="results-score-column">
        <span className={className}>{score || score === 0 ? `${score}%` : '-'}</span>
      </div>
    );
  };

  const mapResultToColumn = (key: string): TableColumn => ({
    key,
    headerTitle: isKti ? t(`resultsPage:${key}`) : key,
    columnData: TableDataType.Custom,
    header: TableHeaderType.SortableWithTooltip,
    filter: TableFilterType.None,
    customAccessor: rowData => findDomainScoreByKey(rowData, key, isKti) || -1,
    customCellRenderer: rowData => renderResultScoreCell(rowData, key),
    maxWidth: 110,
  });

  const renderGradeColumn = (rowData: ResultDashboardStudent): JSX.Element => (
    <ILScoreMeterAtom
      className="filled-grade"
      value={rowData.grade}
      caesura={5.5}
      isPercentage={false}
      hideValue={hideGrade || ![Status.NotReleased, Status.Released].includes(rowData.status)}
    />
  );

  const renderAlphanumericGradeColumn = (rowData: ResultDashboardStudent): JSX.Element => (
    <ILScoreMeterAtom
      value={rowData.alphabeticGrade}
      isPercentage={false}
      isAlphabetic={true}
      className="score-container-big"
    />
  );

  const addGradeColumn = (): TableColumn =>
    showAlphabeticGrade
      ? {
          key: 'alphabeticGrade',
          headerTitle: t('resultsPage:result'),
          columnData: TableDataType.Custom,
          header: TableHeaderType.SortableWithTooltip,
          filter: TableFilterType.None,
          customCellRenderer: renderAlphanumericGradeColumn,
          width: 85,
        }
      : {
          key: 'grade',
          headerTitle: t('resultsPage:grade'),
          columnData: TableDataType.Custom,
          header: TableHeaderType.SortableWithTooltip,
          filter: TableFilterType.None,
          customCellRenderer: renderGradeColumn,
          width: 50,
        };

  const createResultColumns = (): TableColumn[] => {
    const maxDomainNumber = isClassDashboard ? 6 : 5;
    const distinctValues: string[] = isKti ? getDistinctKti(data) : getDistinctDomains(data);
    const resultColumns = distinctValues.map((key: string) => mapResultToColumn(key));
    return isKti || isFullScreen || resultColumns.length <= maxDomainNumber
      ? resultColumns
      : resultColumns.slice(0, maxDomainNumber);
  };

  const renderFeedbackIcon = (isExpanded: boolean, feedback: string): JSX.Element => {
    if (feedback) {
      return (
        <span className={`given-feedback ${isExpanded ? ' opened-feedback' : ''}`}>
          <ILIconAtom type={IconType.FeedbackGiven} size={18} />
        </span>
      );
    }
    return (
      <span className={`${isExpanded ? 'opened-feedback' : ''}`}>
        <ILIconAtom type={IconType.Feedback} size={14} />
      </span>
    );
  };

  const renderFeedbackExpander = (
    isExpanded: boolean,
    rowData: ResultDashboardStudent
  ): JSX.Element => (
    <div className="expander-cell">{renderFeedbackIcon(isExpanded, rowData.feedback)}</div>
  );

  const renderScoreColumn = (rowData: ResultDashboardStudent): JSX.Element => (
    <ILScoreMeterAtom
      value={rowData.score}
      isPercentage={true}
      className="score-container-big"
      hideValue={![Status.NotReleased, Status.Released].includes(rowData.status)}
    />
  );

  const renderColumnsText = (): JSX.Element => {
    const maxDomainNumber = isClassDashboard ? 6 : 5;
    const fixColumnNumber = isClassDashboard ? 4 : 5;
    const domainNumber = getDistinctDomains(data).length;
    return domainNumber > maxDomainNumber ? (
      <span>
        {`${t('resultsPage:columns')}: 10 ${t('resultsPage:of')} ${domainNumber + fixColumnNumber}`}
      </span>
    ) : (
      <></>
    );
  };

  const getActionButtonText = () => {
    if (isKti) {
      return t('resultsPage:full_screen');
    }
    const maxDomainNumber = isClassDashboard ? 6 : 5;
    return getDistinctDomains(data).length > maxDomainNumber
      ? t('resultsPage:view_all_columns')
      : t('resultsPage:full_screen');
  };

  const renderTableActions = () => (
    <div className="table-action">
      {!isKti && renderColumnsText()}
      <button className="tp-button tp-button-inverse tp-button-thin" onClick={openFullScreen}>
        <ILIconAtom type={IconType.Eye} size={18} />
        <span>{getActionButtonText()}</span>
      </button>
    </div>
  );

  const renderNameCell = (displayName: string, studentId: string) =>
    status === Status.Released ? (
      <StudentResultLink name={displayName} studentId={studentId} planningId={planningId} />
    ) : (
      <span>{displayName}</span>
    );

  const domainColumns: TableColumn[] = [
    {
      key: 'firstName',
      headerTitle: t('resultsPage:first_name'),
      columnData: TableDataType.Custom,
      header: TableHeaderType.SortableWithTooltip,
      filter: TableFilterType.None,
      customSortMethod: (a: string, b: string) => sortTexts(a, b),
      customCellRenderer: (rowData: ResultDashboardStudent) =>
        renderNameCell(rowData.firstName, rowData.udbId),
      maxWidth: 120,
    },
    {
      key: 'lastName',
      headerTitle: t('resultsPage:last_name'),
      columnData: TableDataType.Custom,
      header: TableHeaderType.SortableWithTooltip,
      filter: TableFilterType.None,
      customAccessor: (row: ResultDashboardStudent) => formatLastName(row.lastName, row.prefix),
      customSortMethod: (a: string, b: string) => sortTexts(a, b),
      customCellRenderer: (rowData: ResultDashboardStudent) =>
        renderNameCell(formatLastName(rowData.lastName, rowData.prefix), rowData.udbId),
      maxWidth: 120,
    },
    ...(isClassDashboard
      ? []
      : [
          {
            key: 'groupName',
            headerTitle: t('resultsPage:class'),
            columnData: TableDataType.Text,
            header: TableHeaderType.SortableWithTooltip,
            filter: TableFilterType.None,
            maxWidth: 100,
          },
        ]),
    {
      key: 'status',
      headerTitle: t('resultsPage:status'),
      columnData: TableDataType.Custom,
      header: TableHeaderType.SortableWithTooltip,
      filter: TableFilterType.None,
      customCellRenderer: renderStatusColumn,
      width: 80,
    },
    ...(hideGrade ? [] : [addGradeColumn()]),
    {
      key: 'feedback',
      header: TableHeaderType.Empty,
      columnData: TableDataType.Custom,
      filter: TableFilterType.None,
      expander: true,
      customExpander: renderFeedbackExpander,
      width: 35,
      className: 'result-feedback-column',
    },
    {
      key: 'score',
      headerTitle: t('resultsPage:score'),
      columnData: TableDataType.Custom,
      header: TableHeaderType.SortableWithTooltip,
      filter: TableFilterType.None,
      customCellRenderer: renderScoreColumn,
      width: 60,
      className: 'result-score-column',
    },
    ...createResultColumns(),
  ];

  return (
    <div className="dashboard-student-table">
      {!isFullScreen && renderTableActions()}
      <ILTableOrganism
        data={data}
        columns={domainColumns}
        id="domain-results"
        sortable={true}
        filterable={false}
        shouldReload={false}
        noDataText=""
        className="results-table"
        defaultSorted={[
          {
            id: 'lastName',
            desc: false,
          },
        ]}
        subComponent={rowInfo => (
          <TPFeedbackMolecule
            feedback={rowInfo.original.feedback}
            resultId={rowInfo.original.resultId}
            sendFeedback={saveStudentFeedback}
          />
        )}
      />
    </div>
  );
}

export default TPStudentResultsTable;
