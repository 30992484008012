import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetMethod } from '../../../graphql/method/methodOperations';
import { DidacticLevel } from '../../../models/enums/DidacticLevel';

interface Props {
  level: string;
  year: string;
  chapters: string;
  numberOfQuestions: number;
  numberOfPoints: string;
  numberOfOpenQuestion: number;
}

function TPSummaryPanelMolecule({
  level,
  year,
  chapters,
  numberOfQuestions,
  numberOfPoints,
  numberOfOpenQuestion,
}: Props): ReactElement | null {
  const { t } = useTranslation();
  const method = useGetMethod();

  return method ? (
    <div className="details-table-container">
      <div className="arranged-test-details">
        <h2 className="panel-title">{t('arrangedTestDetails:summary')}</h2>
        <table className="table-test-details">
          <tbody>
            <tr>
              <td className="detail-title">{t('arrangedTestDetails:level')}</td>
              <td className="detail-value">{level}</td>
            </tr>
            {method.didactic_level !== DidacticLevel.VocationalEducation && (
              <tr>
                <td className="detail-title">{t('arrangedTestDetails:year')}</td>
                <td className="detail-value">{year}</td>
              </tr>
            )}
            <tr>
              <td className="detail-title">{t('arrangedTestDetails:chapter')}</td>
              <td className="detail-value">{chapters}</td>
            </tr>
            <tr>
              <td className="detail-title">{t('arrangedTestDetails:total_number_of_questions')}</td>
              <td className="detail-value">{numberOfQuestions}</td>
            </tr>
            <tr>
              <td>{t('arrangedTestDetails:total_number_of_open_questions')}</td>
              <td className="detail-value">{numberOfOpenQuestion}</td>
            </tr>
            <tr>
              <td>{t('arrangedTestDetails:total_number_of_closed_questions')}</td>
              <td className="detail-value">{numberOfQuestions - numberOfOpenQuestion}</td>
            </tr>
            <tr>
              <td>{t('arrangedTestDetails:total_number_of_points')}</td>
              <td className="detail-value">{numberOfPoints}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  ) : null;
}

export default TPSummaryPanelMolecule;
