import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DashboardResult } from '../../models/dto/DashboardResult';
import { useGetAuthenticated } from '../../graphql/authenticated/authenticatedOperations';
import { getData } from '../../components/ILAjaxEther';
import { webApiUrl } from '../../api/settingsLoader';
import { ILLoadingIndicator } from '../../components/ILLoadingIndicator';
import ResultsPerQuestion from './ResultsPerQuestion';
import { usePageTracking } from '../../utils/usePageTracking';

interface RouteParams {
  planId: string;
  classId?: string;
}

function ResultsPerQuestionContainer(): ReactElement | null {
  const { selectedSchoolId } = useGetAuthenticated();
  const { planId, classId } = useParams<RouteParams>();
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<DashboardResult>();

  usePageTracking('result', 'view-answers', planId);

  useEffect(() => {
    setIsLoading(true);
    getData(`${webApiUrl}dashboard/${selectedSchoolId}/${planId}${classId ? `/${classId}` : ''}`)
      .then((response: DashboardResult) => {
        setResult(response);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [selectedSchoolId, classId, planId]);

  if (!isLoading && result) {
    return <ResultsPerQuestion result={result} planId={planId} classId={classId} />;
  }
  if (isLoading) {
    return <ILLoadingIndicator isLoading fullSize />;
  }
  return null;
}

export default ResultsPerQuestionContainer;
