/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention */
import React, { ReactNode } from 'react';
import { CellInfo, Column, Filter } from 'react-table';
import ILTableSortingHeaderMolecule from '../ILTableSortingHeaderMolecule';
import {
  filterByIncludedText,
  filterBySelectedAlphaNumericValue,
  filterBySelectedNumberValue,
  filterBySelectedTextValue,
  filterStrictBySelectedValue,
  getDistinctValuesOfAlphanumericArray,
  getDistinctValuesOfNumbers,
  getDistinctValuesOfTextArray,
} from '../utils/rowFiltering';
import ILTableFilterTextAtom from '../ILTableFilterTextAtom';

import ILTableFilterSelectMolecule from '../ILTableFilterSelectMolecule';
import {
  sortAlphabeticallyAndParseToString,
  sortNumericallyAndParseToString,
  sortTexts,
} from '../utils/rowSorting';
import { getLocalisedDate, sortDates } from '../utils/tableDateHelpers';

export interface TableColumn {
  key: string;
  headerTitle?: string;
  width?: number;
  maxWidth?: number;
  defaultSortDesc?: boolean;
  className?: string;
  columnData: TableDataType;
  header: TableHeaderType;
  filter: TableFilterType;
  filterSortingArray?: string[];
  filterDefaultText?: string;
  customFilterOptions?: Option[];
  customFilterMethod?: (filter: Filter, row: any) => boolean;
  customCellRenderer?: (rowData: any) => JSX.Element;
  customAccessor?: (row: any) => any;
  customSortMethod?: (a: any, b: any) => number;
  expander?: boolean;
  customExpander?: (isExpanded: boolean, row: any) => ReactNode;
}

export interface Option {
  key: string;
  text: string;
}

export enum TableHeaderType {
  Default,
  Empty,
  Sortable,
  SortableWithTooltip,
  NotSortable,
}

export enum TableDataType {
  Text,
  TextArray,
  Numeric,
  Alphanumeric,
  AlphanumericArray,
  Custom,
  CustomText,
  Date,
  CustomDate,
}

export enum TableFilterType {
  None,
  SelectNumber,
  SelectAlphaNumeric,
  StrictSelectAlphaNumeric,
  SelectText,
  StrictSelectText,
  Text,
  CustomSelect,
}

const setTableHeader = (type: TableHeaderType, title?: string): Column => {
  switch (type) {
    case TableHeaderType.Default:
      return { Header: title || '' };
    case TableHeaderType.Sortable:
      return {
        Header: <ILTableSortingHeaderMolecule headerTitle={title || ''} />,
        sortable: true,
      };
    case TableHeaderType.SortableWithTooltip:
      return {
        Header: <ILTableSortingHeaderMolecule headerTitle={title || ''} showTooltip={true} />,
        sortable: true,
      };
    case TableHeaderType.NotSortable:
      return {
        Header: title || '',
        sortable: false,
      };
    case TableHeaderType.Empty:
      return {
        Header: '',
        sortable: false,
      };
    default:
      return {};
  }
};

function setColumnData(
  type: TableDataType,
  key: string,
  customAccessor?: (row: any) => any,
  customSortMethod?: (a: any, b: any) => number,
  customCellRenderer?: (rowData: any) => JSX.Element
): Column | undefined {
  switch (type) {
    case TableDataType.Text:
      return {
        accessor: key,
        sortMethod: (a: string, b: string) => sortTexts(a, b),
      };
    case TableDataType.TextArray:
      return {
        accessor: (row: any) => sortAlphabeticallyAndParseToString(row[key]),
      };
    case TableDataType.Alphanumeric:
      return {
        accessor: key,
        sortMethod: (a: string, b: string) => +a - +b,
      };
    case TableDataType.AlphanumericArray:
      return {
        accessor: (row: any): string => sortNumericallyAndParseToString(row[key]),
      };
    case TableDataType.Numeric:
      return {
        accessor: key,
      };
    case TableDataType.Date:
      return {
        accessor: (row: any) => getLocalisedDate(row[key]),
        sortMethod: (a: string, b: string) => sortDates(a, b),
      };
    case TableDataType.CustomDate:
      return {
        accessor: (row: any) => getLocalisedDate(row[key]),
        sortMethod: (a: string, b: string) => sortDates(a, b),
        Cell: (props: CellInfo) => {
          const { original } = props;
          return customCellRenderer ? customCellRenderer(original) : <span />;
        },
      };
    case TableDataType.Custom:
      return {
        accessor: customAccessor || key,
        Cell: (props: CellInfo) => {
          const { original } = props;
          return customCellRenderer ? customCellRenderer(original) : <span />;
        },
        sortMethod: customSortMethod || undefined,
      };
    case TableDataType.CustomText:
      return {
        accessor: customAccessor,
        sortMethod: (a: string, b: string) => sortTexts(a, b),
      };
    default:
  }
}

function setColumnFilter<Type>(
  tableData: Type[],
  key: string,
  type: TableFilterType,
  filterDefaultText?: string,
  filterSortingArray?: string[],
  customFilterMethod?: (filter: Filter, row: any) => boolean,
  customFilterOptions?: Option[]
): Column {
  switch (type) {
    case TableFilterType.None:
      return { filterable: false };
    case TableFilterType.Text:
      return {
        filterable: true,
        filterMethod: (filter: Filter, row: Type) => filterByIncludedText(filter, row),
        Filter: ({ filter, onChange }: any) => (
          <ILTableFilterTextAtom
            filter={filter}
            onChange={onChange}
            placeholder={filterDefaultText || ''}
          />
        ),
      };
    case TableFilterType.SelectAlphaNumeric:
      return {
        filterable: true,
        filterMethod: (filter: Filter, row: Type) => filterBySelectedAlphaNumericValue(filter, row),
        Filter: ({ filter, onChange }: any) => (
          <ILTableFilterSelectMolecule
            filter={filter}
            onChange={onChange}
            defaultOption={filterDefaultText || ''}
            options={getDistinctValuesOfAlphanumericArray(tableData, key)}
          />
        ),
      };
    case TableFilterType.StrictSelectAlphaNumeric:
      return {
        filterable: true,
        filterMethod: (filter: Filter, row: Type) => filterStrictBySelectedValue(filter, row),
        Filter: ({ filter, onChange }: any) => (
          <ILTableFilterSelectMolecule
            filter={filter}
            onChange={onChange}
            defaultOption={filterDefaultText || ''}
            options={getDistinctValuesOfAlphanumericArray(tableData, key)}
          />
        ),
      };
    case TableFilterType.SelectText:
      return {
        filterMethod: (filter: Filter, row: Type) => filterBySelectedTextValue(filter, row),
        Filter: ({ filter, onChange }: any) => (
          <ILTableFilterSelectMolecule
            filter={filter}
            onChange={onChange}
            defaultOption={filterDefaultText || ''}
            options={getDistinctValuesOfTextArray(tableData, key, filterSortingArray)}
          />
        ),
      };
    case TableFilterType.StrictSelectText:
      return {
        filterMethod: (filter: Filter, row: Type) => filterStrictBySelectedValue(filter, row),
        Filter: ({ filter, onChange }: any) => (
          <ILTableFilterSelectMolecule
            filter={filter}
            onChange={onChange}
            defaultOption={filterDefaultText || ''}
            options={getDistinctValuesOfTextArray(tableData, key, filterSortingArray)}
          />
        ),
      };
    case TableFilterType.SelectNumber:
      return {
        filterMethod: (filter: Filter, row: Type) => filterBySelectedNumberValue(filter, row),
        Filter: ({ filter, onChange }: any) => (
          <ILTableFilterSelectMolecule
            filter={filter}
            onChange={onChange}
            defaultOption={filterDefaultText || ''}
            options={getDistinctValuesOfNumbers(tableData, key)}
          />
        ),
      };
    case TableFilterType.CustomSelect:
      if (customFilterOptions && customFilterMethod) {
        const options = customFilterOptions.map((option: Option) => option.text);
        const keys = customFilterOptions.map((option: Option) => option.key);
        return {
          filterMethod: (filter: Filter, row: Type) => customFilterMethod(filter, row),
          Filter: ({ filter, onChange }: any) => (
            <ILTableFilterSelectMolecule
              filter={filter}
              onChange={onChange}
              defaultOption={filterDefaultText || ''}
              options={options}
              keys={keys}
            />
          ),
        };
      }
      return { filterable: false };
    default:
      return { filterable: false };
  }
}

const setExpander = (
  isExpandable?: boolean,
  customExpander?: (isExpanded: boolean, row: any) => ReactNode
): Column => {
  if (isExpandable && customExpander) {
    return {
      expander: isExpandable,
      Expander: (props: CellInfo) => customExpander(props.isExpanded, props.original),
    };
  }
  return {};
};

export function createTableColumns<Type>(tableData: Type[], columns: TableColumn[]): Column[] {
  return columns.map((column: TableColumn) => ({
    id: column.key,
    width: column.width,
    maxWidth: column.maxWidth,
    expander: column.expander,
    className: column.className || '',
    headerClassName: column.className || '',
    ...setTableHeader(column.header, column.headerTitle),
    ...setColumnData(
      column.columnData,
      column.key,
      column.customAccessor,
      column.customSortMethod,
      column.customCellRenderer
    ),
    ...setColumnFilter(
      tableData,
      column.key,
      column.filter,
      column.filterDefaultText,
      column.filterSortingArray,
      column.customFilterMethod,
      column.customFilterOptions
    ),
    ...setExpander(column.expander, column.customExpander),
  }));
}
