import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { LoadingState } from '../../../../components/ILLoadingStatusIndicator/ILLoadingStatusIndicator';
import { toggleDisplayMenuAction } from '../../../../store/context/actions';
import TPReviewHeaderOrganism from './TPReviewHeaderOrganism';

interface Props {
  isFirst: boolean;
  isLast: boolean;
  loadingState: LoadingState;
  isStudentReview: boolean;
  goBack: () => void;
  goNext: () => void;
  exitReview: () => void;
  dispatchToggleDisplayMenuAction: (displayMenu: boolean) => void;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchToggleDisplayMenuAction: (displayMenu: boolean) => {
    dispatch(toggleDisplayMenuAction(displayMenu));
  },
});

function TPReviewHeaderOrganismContainer({
  isFirst,
  isLast,
  loadingState,
  isStudentReview,
  goBack,
  goNext,
  exitReview,
  dispatchToggleDisplayMenuAction,
}: Props) {
  useEffect(() => {
    dispatchToggleDisplayMenuAction(false);
    return () => {
      dispatchToggleDisplayMenuAction(true);
    };
  }, [dispatchToggleDisplayMenuAction]);

  return (
    <TPReviewHeaderOrganism
      loadingState={loadingState}
      isFirst={isFirst}
      isLast={isLast}
      isStudentReview={isStudentReview}
      goBack={goBack}
      goNext={goNext}
      exitReview={exitReview}
    />
  );
}

export default connect(null, mapDispatchToProps)(TPReviewHeaderOrganismContainer);
