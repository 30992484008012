import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrangeTestsItemResponse } from '../../../models/dto/ArrangeTestsResponse';
import ILTableOrganismContainer from '../../../components/ILTableOrganism';
import TPMyTestOptionsMolecule from '../TPMyTestOptionsMolecule';
import {
  TableColumn,
  TableDataType,
  TableFilterType,
  TableHeaderType,
} from '../../../components/ILTableOrganism/ILTableColumn/createColumns';
import { CONCEPT_TEST, ATS_LEVEL_ORDER } from '../../../constants/ArrangeTestContstants';

interface Props {
  isLoading: boolean;
  data: ArrangeTestsItemResponse[];
  openDetails: (id: string) => void;
  openPlanning: (id: string) => void;
  openDeleteConfirmationModal: (id: string) => void;
  downloadFile: (recipeId: string, title: string, type: string) => void;
}

function TPMyOverviewTableOrganism({
  data,
  openDetails,
  openPlanning,
  isLoading,
  openDeleteConfirmationModal,
  downloadFile,
}: Props): ReactElement {
  const { t } = useTranslation();

  const renderMyTestActionColumn = (rowData: ArrangeTestsItemResponse) => (
    <TPMyTestOptionsMolecule
      mgrRecipeId={rowData.mgrRecipeId}
      recipeId={rowData.recipeId}
      title={rowData.name}
      isConceptTest={rowData.status === CONCEPT_TEST}
      openDetails={openDetails}
      planTest={openPlanning}
      deleteTest={openDeleteConfirmationModal}
      downloadFile={downloadFile}
    />
  );

  const myTestColumnInfo: TableColumn[] = [
    {
      key: 'name',
      headerTitle: t('myTestPage:title'),
      columnData: TableDataType.Text,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.Text,
      filterDefaultText: t('myTestPage:add_title_filter'),
      width: 300,
    },
    {
      key: 'years',
      headerTitle: t('myTestPage:year'),
      columnData: TableDataType.AlphanumericArray,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.SelectAlphaNumeric,
      filterDefaultText: t('myTestPage:all_year'),
    },
    {
      key: 'levels',
      headerTitle: t('myTestPage:level'),
      columnData: TableDataType.TextArray,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.SelectText,
      filterSortingArray: ATS_LEVEL_ORDER,
      filterDefaultText: t('myTestPage:all_level'),
    },
    {
      key: 'chapters',
      headerTitle: t('myTestPage:chapter'),
      columnData: TableDataType.AlphanumericArray,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.SelectAlphaNumeric,
      filterDefaultText: t('myTestPage:all_chapter'),
    },
    {
      key: 'questionCount',
      headerTitle: t('myTestPage:questions'),
      columnData: TableDataType.Numeric,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.None,
      width: 110,
    },
    {
      key: 'lastModified',
      headerTitle: t('myTestPage:created_on'),
      columnData: TableDataType.Date,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.None,
      width: 140,
    },
    {
      key: 'options',
      header: TableHeaderType.Empty,
      columnData: TableDataType.Custom,
      filter: TableFilterType.None,
      customCellRenderer: renderMyTestActionColumn,
      width: 200,
    },
  ];

  return (
    <ILTableOrganismContainer
      id="my-test"
      isLoading={isLoading}
      columns={myTestColumnInfo}
      data={data}
      noDataText={data.length === 0 ? t('myTestPage:no_arranged_test') : t('myTestPage:no_result')}
      sortable={true}
      filterable={true}
      shouldReload={true}
      defaultSorted={[
        {
          id: 'lastModified',
          desc: true,
        },
      ]}
    />
  );
}

export default TPMyOverviewTableOrganism;
