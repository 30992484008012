import { Plan } from '../../models/state/Plan';
import { GradeType } from '../../models/enums/GradeType';

import {
  ClearPinAction,
  InitPlanAction,
  LoadPlanAction,
  PlanActionType,
  ResetPlanAction,
  SetCheckAnswerAction,
  SetDateAction,
  SetDateRangeAction,
  SetEndTimeAction,
  SetGradeAction,
  SetGradeTypeAction,
  SetHasPinAction,
  SetPinAction,
  SetScheduleTypeAction,
  SetScoreAction,
  SetStartTimeAction,
  SetStudentGroupsAction,
} from './actions';

const initialState: Plan = {
  id: '',
  title: '',
  classification: '',
  studentGroups: {
    groupIds: [],
    adHocStudentGroups: [],
  },
  scheduleType: '',
  gradeType: GradeType.Numeric,
  endDate: null,
  startDate: null,
  date: null,
  startTime: '',
  endTime: '',
  checkAnswer: false,
  hasPin: false,
  pin: '',
  caesura: {},
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default (
  state = initialState,
  action:
    | ResetPlanAction
    | SetStudentGroupsAction
    | SetScheduleTypeAction
    | SetDateRangeAction
    | SetStartTimeAction
    | SetEndTimeAction
    | SetDateAction
    | SetGradeTypeAction
    | SetCheckAnswerAction
    | SetHasPinAction
    | SetPinAction
    | ClearPinAction
    | SetScoreAction
    | SetGradeAction
    | InitPlanAction
    | LoadPlanAction
) => {
  switch (action.type) {
    case PlanActionType.RESET_PLAN: {
      return { ...state, ...initialState };
    }
    case PlanActionType.SET_STUDENT_GROUPS: {
      return { ...state, studentGroups: action.studentGroups };
    }
    case PlanActionType.SET_SCHEDULE_TYPE: {
      return {
        ...state,
        scheduleType: action.scheduleType,
        startDate: null,
        endDate: null,
        date: null,
        startTime: '',
        endTime: '',
      };
    }
    case PlanActionType.SET_DATE_RANGE: {
      return {
        ...state,
        startDate: action.dateRange.startDate,
        endDate: action.dateRange.endDate,
      };
    }
    case PlanActionType.SET_DATE: {
      return {
        ...state,
        date: action.date,
      };
    }
    case PlanActionType.SET_START_TIME: {
      return {
        ...state,
        startTime: action.startTime,
      };
    }
    case PlanActionType.SET_END_TIME: {
      return {
        ...state,
        endTime: action.endTime,
      };
    }
    case PlanActionType.SET_GRADE_TYPE: {
      return {
        ...state,
        gradeType: action.gradeType,
      };
    }
    case PlanActionType.SET_CHECK_ANSWER: {
      return {
        ...state,
        checkAnswer: action.checkAnswer,
      };
    }
    case PlanActionType.SET_HAS_PIN: {
      return {
        ...state,
        hasPin: true,
      };
    }
    case PlanActionType.SET_PIN: {
      return {
        ...state,
        pin: action.pin,
      };
    }
    case PlanActionType.CLEAR_PIN: {
      return {
        ...state,
        hasPin: false,
        pin: '',
      };
    }
    case PlanActionType.SET_SCORE: {
      return {
        ...state,
        caesura: {
          ...state.caesura,
          [action.id]: { ...state.caesura[action.id], score: action.score },
        },
      };
    }
    case PlanActionType.SET_GRADE: {
      return {
        ...state,
        caesura: {
          ...state.caesura,
          [action.id]: { ...state.caesura[action.id], grade: action.grade },
        },
      };
    }
    case PlanActionType.INIT_PLAN: {
      return {
        ...state,
        title: action.title,
        id: action.id,
        classification: action.classification,
        caesura: action.caesura,
      };
    }
    case PlanActionType.LOAD_PLAN: {
      return {
        ...state,
        ...action.plan,
      };
    }
    default:
      return state;
  }
};
