import { createContext, useContext } from 'react';

export interface ReviewPlanContextValue {
  setPlanIdToReview: (planId: string) => void;
}

const reviewPlanValueInitial: ReviewPlanContextValue = {
  setPlanIdToReview: () => null,
};

// eslint-disable-next-line @typescript-eslint/naming-convention
const ReviewPlanContext = createContext<ReviewPlanContextValue>(reviewPlanValueInitial);

export const useReviewPlanContext = (): ReviewPlanContextValue => useContext(ReviewPlanContext);

export default ReviewPlanContext;
