import React from 'react';
import { connect } from 'react-redux';
import ILFooterMolecule from './ILFooterMolecule';
import { State } from '../../store';

interface Props {
  version?: string;
  displayFooter: boolean;
}

const mapStateToProps = (state: State) => ({
  displayFooter: state.context.displayFooter,
});

function ILFooterMoleculeContainer({ version, displayFooter }: Props) {
  return displayFooter ? <ILFooterMolecule version={version} /> : null;
}

export default connect(mapStateToProps)(ILFooterMoleculeContainer);
