import { gql } from '@apollo/client';

export const GET_QUESTIONS_WITH_RESULTS = gql`
  query questionsWithResults($resultId: ID!, $assignmentId: ID) {
    questionsWithResults(resultId: $resultId, assignmentId: $assignmentId) {
      id
      type
      maxScore
      result {
        questionId
        score
        maxScore
        duration
        questionResultResponses {
          key
          candidateResponse
          correctResponse
          feedback
        }
      }
      scoring {
        key
        value
        caseSensitive
        keys
      }
      blocks {
        identifier
        type
        html
      }
    }
  }
`;

export const GET_QUESTIONS_WITH_RESULTS_COLLECTIONS = gql`
  query questionsWithResultsCollections(
    $resultIds: [ID!]
    $assignmentId: ID
    $after: String
    $pageSize: Int
    $planId: ID
  ) {
    questionsWithResultsList(
      resultIds: $resultIds
      assignmentId: $assignmentId
      planId: $planId
      after: $after
      pageSize: $pageSize
    ) {
      cursor
      hasMore
      questionWithResultsCollections {
        resultId
        questionsWithResults {
          id
          type
          maxScore
          result {
            questionId
            score
            maxScore
            duration
            questionResultResponses {
              key
              candidateResponse
              correctResponse
              feedback
            }
          }
          scoring {
            key
            value
            caseSensitive
            keys
          }
          blocks {
            identifier
            type
            html
          }
        }
      }
    }
  }
`;

export const GET_QUESTIONS_WITH_RESULTS_COLLECTION_FRAGMENT = gql`
  fragment selectedQuestionWithResultsCollection on QuestionWithResultsCollection {
    resultId
    questionsWithResults {
      id
      type
      maxScore
      result {
        questionId
        score
        maxScore
        duration
        questionResultResponses {
          key
          candidateResponse
          correctResponse
          feedback
        }
      }
      scoring {
        key
        value
        caseSensitive
        keys
      }
      blocks {
        identifier
        type
        html
      }
    }
  }
`;
