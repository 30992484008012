import React, { ReactElement } from 'react';

interface PlanStepProps {
  title: string;
  children: JSX.Element;
  help?: string;
  last?: boolean;
}

function PlanStep({ title, children, help, last }: PlanStepProps): ReactElement {
  return (
    <section className={`step-container${last ? '' : ' with-divider'}`}>
      <p className="step-title">{title}</p>
      {help && <p className="step-subtitle">{help}</p>}
      {children}
    </section>
  );
}

export default PlanStep;
