export interface WizardContextValue {
  canGoNext: boolean;
  goNext: () => void;
  goBack: () => void;
  goTo: (index: number, loaded: boolean) => void;
  showNextButton: () => boolean;
  showBackButton: () => boolean;
  showCustomButton: () => boolean;
  isLastStep: () => boolean;
  finish: () => void;
  setReady: () => void;
  setNotReady: () => void;
}

export const wizardContextValueInitial: WizardContextValue = {
  canGoNext: false,
  goNext: () => null,
  goBack: () => null,
  goTo: () => null,
  showNextButton: () => false,
  showBackButton: () => false,
  showCustomButton: () => false,
  isLastStep: () => false,
  finish: () => null,
  setReady: () => null,
  setNotReady: () => null,
};
