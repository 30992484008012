import { DomainResult, ResultDashboardStudent } from '../../../../../../models/dto/DashboardResult';
import { sortByArray, sortTexts } from '../../../../../../utils/Sorting';

export const getDistinctDomains = (data: ResultDashboardStudent[]): string[] =>
  Array.from(
    new Set(
      data.reduce<string[]>(
        (accumulator, actualPlanning) => [
          ...accumulator,
          ...actualPlanning.domainResults.map((group: DomainResult) => group.domain),
        ],
        []
      )
    )
  ).sort((a, b) => sortTexts(a, b));

export const getDistinctKti = (data: ResultDashboardStudent[]): string[] =>
  Array.from(
    new Set(
      data.reduce<string[]>(
        (accumulator, actualPlanning) => [
          ...accumulator,
          ...actualPlanning.cognitiveResults.map((group: DomainResult) => group.domain),
        ],
        []
      )
    )
  ).sort((a, b) => sortByArray(a, b, ['KtiK', 'BloomK', 'KtiT', 'BloomT', 'KtiI', 'BloomI']));

export const findDomainScoreByKey = (
  data: ResultDashboardStudent,
  key: string,
  isKti: boolean
): number | undefined => {
  if (isKti) {
    return data.cognitiveResults.find((domain: DomainResult) => domain.domain === key)?.score;
  }
  return data.domainResults.find((domain: DomainResult) => domain.domain === key)?.score;
};

export const findAndFormatDomainScoreByKey = (
  data: ResultDashboardStudent,
  key: string,
  isKti: boolean
): string => {
  const score = findDomainScoreByKey(data, key, isKti);
  return score !== undefined ? `${score}%` : '-';
};
