import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from '../../../../../components/TPStatusAtom/Status';
import { GradeType } from '../../../../../models/enums/GradeType';
import { DashboardScoreType } from '../../../../../models/enums/DashboardScoreType';
import { AlphabeticGradeType } from '../../../../../models/enums/AlphabeticGradeType';
import ILDashboardScoreMolecule from '../../../../../components/ILDashboardScoreMolecule/ILDashboardScoreMolecule';
import './style.scss';

type Props = {
  isStudentDashboard: boolean;
  status: Status;
  score: number;
  gradeType: GradeType;
  grade: number;
  alphabeticGrade?: AlphabeticGradeType;
  hideGrade?: boolean;
};

function TPDashboardScoresOrganism({
  isStudentDashboard,
  status,
  score,
  gradeType,
  grade,
  alphabeticGrade,
  hideGrade,
}: Props): ReactElement {
  const { t } = useTranslation();

  const getGradeLabel = (): string => {
    if (isStudentDashboard) {
      return gradeType === GradeType.Alphabetic
        ? t('resultsPage:result').toLowerCase()
        : t('resultsPage:grade').toLowerCase();
    }
    return t('resultsPage:average_grade');
  };

  return (
    <div className="dashboard-scores">
      <ILDashboardScoreMolecule
        value={score}
        scoreType={DashboardScoreType.Score}
        label={isStudentDashboard ? t('resultPage:score') : t('resultsPage:average_score')}
        isHidden={status === Status.Started}
      />
      {(isStudentDashboard || gradeType === GradeType.Numeric) && !hideGrade && (
        <ILDashboardScoreMolecule
          value={gradeType === GradeType.Alphabetic && alphabeticGrade ? alphabeticGrade : grade}
          scoreType={
            gradeType === GradeType.Numeric
              ? DashboardScoreType.Grade
              : DashboardScoreType.Alphabetic
          }
          label={getGradeLabel()}
          cutValue={5.5}
          isHidden={
            hideGrade ||
            (isStudentDashboard
              ? status !== Status.Released
              : status !== Status.NotReleased && status !== Status.Released)
          }
        />
      )}
    </div>
  );
}

export default TPDashboardScoresOrganism;
