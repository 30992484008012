export const OPEN_MODAL_PAGE = 'open_modal_page';
export const CLOSE_MODAL_PAGE = 'close_modal_page';
export const OPEN_DEMO_REMINDO_PLAYER = 'open_demo_remindo_player';
export const NAVIGATE_TO_PAGE = 'navigate_to_page';
export const AUTHENTICATED_PAGE_LOADED = 'authenticated_page_loaded';
export const URL_FOR_REDIRECT = 'url_for_redirect';
export const BEFORE_AUTHENTICATED_PAGE_UNLOADED = 'before_authenticated_page_unloaded';
export const OPEN_RESULTS_CLASSES = 'open_results_classes';
export const OPEN_RESULTS_CLASS = 'open_results_class';
export const OPEN_RESULTS_STUDENTS = 'open_results_students';
export const OPEN_RESULTS_STUDENT = 'open_results_student';
export const OPEN_RESULT_CLASS = 'open_result_class';
export const OPEN_RESULT_ALL = 'open_result_all';
export const CLOSE_REMINDO = 'close_remindo_player';
