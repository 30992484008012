import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { State } from '../../../store';
import { setGradeTypeAction } from '../../../store/planning/actions';
import { usePlanningContext } from '../PlanningContext';
import TPPlanningResultOrganism from './TPPlanningResultOrganism';

interface Props {
  gradeType: string;
  dispatchSetGradeTypeAction: (gradeType: string) => void;
}

const mapStateToProps = (state: State) => ({
  gradeType: state.plan.gradeType,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchSetGradeTypeAction: (gradeType: string) => {
    dispatch(setGradeTypeAction(gradeType));
  },
});

function TPPlanningResultOrganismContainer({ gradeType, dispatchSetGradeTypeAction }: Props) {
  const { isEditEnabled } = usePlanningContext();

  const handleGradeTypeSelection = (type: string) => {
    if (isEditEnabled) {
      dispatchSetGradeTypeAction(type);
    }
  };

  return (
    <TPPlanningResultOrganism
      selectedGradeType={gradeType}
      handleSelection={handleGradeTypeSelection}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TPPlanningResultOrganismContainer);
