import React, { useState } from 'react';
import { useGetMethod } from '../../graphql/method/methodOperations';
import ReviewPlanContext, { ReviewPlanContextValue } from './ReviewPlanContext';
import ILNewsFeedMoleculeContainer from '../../components/ILNewsFeedMolecule';
import TPNotificationsOrganismContainer from '../../components/TPNotificationsOrganism';
import TPTestReviewModalOrganism from '../TestReview/TPTestReviewModalOrganism';
import { useGetAuthenticated } from '../../graphql/authenticated/authenticatedOperations';
import { usePageTracking } from '../../utils/usePageTracking';

function Home() {
  const method = useGetMethod();
  const { isTeacher } = useGetAuthenticated();
  const [planIdToReview, setPlanIdToReview] = useState<string>('');

  const reviewPlan: ReviewPlanContextValue = {
    setPlanIdToReview,
  };

  usePageTracking('home', 'notifications');

  return (
    <div className="main main-no-title">
      {method && isTeacher && <ILNewsFeedMoleculeContainer pageId="StartPage" />}
      <ReviewPlanContext.Provider value={reviewPlan}>
        <TPNotificationsOrganismContainer />
      </ReviewPlanContext.Provider>
      <TPTestReviewModalOrganism
        isOpen={!!planIdToReview}
        planId={planIdToReview}
        closeModal={() => setPlanIdToReview('')}
      />
    </div>
  );
}

export default Home;
