import React, { useState, ReactElement } from 'react';
import { useQuery } from '@apollo/client';
import { getQuestionsWithResultsFromCollection } from '../../graphql/questionsWithResults/questionWithResultsOperations';
import {
  QuestionsWithResultCollection,
  QuestionWithResult,
  QuestionWithResultsCollectionsResponse,
} from '../../models/dto/QuestionWithResult';
import { GET_QUESTIONS_WITH_RESULTS_COLLECTIONS } from '../../graphql/questionsWithResults/getQuestionsWithResultsQuery';
import { ILLoadingIndicator } from '../ILLoadingIndicator';
import ILViewAnswersOrganism from './ILViewAnswersOrganism';

interface Props {
  resultId: string;
  planId: string;
}

function ILViewAnswersFromCollectionContainer({ resultId, planId }: Props): ReactElement {
  const { data } = useQuery<QuestionWithResultsCollectionsResponse>(
    GET_QUESTIONS_WITH_RESULTS_COLLECTIONS,
    {
      variables: { planId },
      fetchPolicy: 'cache-only',
    }
  );
  const [averageScore, setAverageScore] = useState<number>();
  const [averageDuration, setAverageDuration] = useState<number>();
  const questionsWithResults =
    getQuestionsWithResultsFromCollection(resultId)?.questionsWithResults || [];

  const handleQuestionChange = (questionId: string) => {
    let totalScore = 0;
    let totalDuration = 0;
    let totalCount = 0;
    if (data?.questionsWithResultsList) {
      data.questionsWithResultsList.questionWithResultsCollections.forEach(
        (questionsWithResultCollection: QuestionsWithResultCollection) => {
          const question = questionsWithResultCollection.questionsWithResults.find(
            (questionWithResults: QuestionWithResult) => questionWithResults.id === questionId
          );
          totalScore += question?.result?.score || 0;
          totalDuration += question?.result?.duration || 0;
          totalCount += 1;
        }
      );
      setAverageScore(totalScore / totalCount);
      setAverageDuration(totalDuration / totalCount);
    }
  };

  if (questionsWithResults?.length > 0) {
    return (
      <ILViewAnswersOrganism
        questionsWithResults={questionsWithResults}
        handleQuestionChange={handleQuestionChange}
        showTotalStats={true}
        averageScore={averageScore}
        averageDuration={averageDuration}
      />
    );
  }
  return <ILLoadingIndicator fullSize />;
}
export default ILViewAnswersFromCollectionContainer;
