import React from 'react';
import { useTranslation } from 'react-i18next';
import ILIconAtom, { IconType } from '../ILIconAtom';
import ILInlineLoader from '../ILInlineLoader';
import './style.scss';

export enum LoadingState {
  Saving,
  Saved,
  None,
}

interface Props {
  loadingState: LoadingState;
}

function ILLoadingStatusIndicator({ loadingState }: Props) {
  const { t } = useTranslation();
  switch (loadingState) {
    case LoadingState.Saving:
      return (
        <div className="saving-status">
          <ILInlineLoader dotSize={6} />
          <span>{` ${t('loadingStatusIndicator:saving')}`}</span>
        </div>
      );
    case LoadingState.Saved:
      return (
        <div className="saving-status saving-status__saved">
          <ILIconAtom className="saved-icon" type={IconType.Saved} size={18} />
          <span>{` ${t('loadingStatusIndicator:saved')}`}</span>
        </div>
      );
    default:
      return <></>;
  }
}
export default ILLoadingStatusIndicator;
