import moment from 'moment';
import 'moment/locale/nl';
import { applicationName } from '../../../api/settingsLoader';
import { Origin } from '../../../models/enums/Origin';
import { TestType } from '../../../models/enums/TestType';
import { PlanPra } from '../../../models/state/PlanPra';
import { PlanningInfo } from '../../../models/state/PlanningInfo';
import { AdHocStudentGroup, Caesura, CaesuraScores, Plan } from '../../../models/state/Plan';
import { ScheduleType } from '../../../models/enums/ScheduleType';
import { convertMomentToString, createDateTime } from '../../../utils/DateTimeMapper';
import { User } from '../../../models/state/User';
import { TestDetails } from '../../../models/dto/TestDetails';
import { PlanningInfoResponse } from '../../../models/dto/PlanningInfoResponse';
import { PlanPraResponse } from '../../../models/dto/PlanPraResponse';

export const convertArrayToCaesura = (caesuraArray: CaesuraScores[]): Caesura => {
  const caesura: Caesura = {};
  caesuraArray.forEach((row, index) => {
    caesura[index + 1] = {
      grade: row.grade.replace(',', '.'),
      score: row.score.replace(',', '.'),
    };
  });
  return caesura;
};

export const convertCaesuraToArray = (caesura: Caesura): CaesuraScores[] => {
  const caesuraArray: CaesuraScores[] = [];
  Object.keys(caesura).forEach((key: string) => {
    caesuraArray.push(caesura[+key]);
  });
  return caesuraArray;
};

const getStartDate = (plan: Plan): string | null => {
  switch (plan.scheduleType) {
    case ScheduleType.Direct: {
      return convertMomentToString(moment());
    }
    case ScheduleType.Period: {
      return convertMomentToString(plan.startDate!.startOf('day'));
    }
    case ScheduleType.Hour: {
      return convertMomentToString(createDateTime(plan.date, plan.startTime));
    }
    default:
      return null;
  }
};

const getEndDate = (plan: Plan): string | null => {
  switch (plan.scheduleType) {
    case ScheduleType.Direct: {
      return convertMomentToString(moment());
    }
    case ScheduleType.Period: {
      return convertMomentToString(plan.endDate!.endOf('day'));
    }
    case ScheduleType.Hour: {
      return convertMomentToString(createDateTime(plan.date, plan.endTime));
    }
    default:
      return null;
  }
};

const getAdHocStudentGroup = (users: User[]): AdHocStudentGroup[] => {
  const adHocStudentGroups: AdHocStudentGroup[] = [];
  users.forEach((user: User) => {
    const groupIndex = adHocStudentGroups.findIndex(group => group.groupId === user.groupId);
    if (groupIndex > -1) {
      adHocStudentGroups[groupIndex].studentId.push(user.userId);
    } else {
      adHocStudentGroups.push({
        groupId: user.groupId,
        studentId: [user.userId],
      });
    }
  });
  return adHocStudentGroups;
};

export const createPlanDto = (plan: Plan, domainId: string): PlanPra => ({
  domainId,
  title: plan.title,
  application: applicationName,
  plannedContent: [
    {
      contentId: plan.id,
      startDate: getStartDate(plan),
      endDate: getEndDate(plan),
      note: '',
      classification: plan.classification,
      origin: plan.classification === TestType.Arranged ? Origin.Ats : Origin.Cdp,
    },
  ],
  assignedStudentGroups: plan.studentGroups.groupIds,
  assignedAdHocStudentGroups: plan.studentGroups.adHocStudentGroups,
});

export const createPlanningInfoDto = (plan: Plan, praId: string): PlanningInfo => ({
  planId: praId,
  planInfoType: plan.scheduleType,
  caesura: convertCaesuraToArray(plan.caesura),
  gradeType: plan.gradeType,
  showAnswers: plan.checkAnswer,
  pinCode: plan.pin,
  classification: plan.classification,
});

export const createPlanObject = (
  contentResponse: TestDetails,
  praResponse: PlanPraResponse,
  infoResponse: PlanningInfoResponse
): Plan => ({
  id: contentResponse.id,
  title: praResponse.title,
  classification: contentResponse.classification,
  studentGroups: {
    groupIds: praResponse.groups.map((group: { groupId: string }) => group.groupId),
    adHocStudentGroups: getAdHocStudentGroup(praResponse.users),
  },
  scheduleType: infoResponse.planInfoType,
  gradeType: infoResponse.gradeType,
  startDate: moment(praResponse.startDate),
  endDate: moment(praResponse.endDate),
  date: moment(praResponse.startDate),
  startTime: moment(praResponse.startDate).format('HH:mm'),
  endTime: moment(praResponse.endDate).format('HH:mm'),
  checkAnswer: infoResponse.showAnswers,
  hasPin: !!infoResponse.pinCode,
  pin: infoResponse.pinCode,
  caesura: convertArrayToCaesura(infoResponse.caesura),
});
