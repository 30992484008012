import React, { useEffect, useRef, useState, useCallback, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetMethod } from '../../graphql/method/methodOperations';
import {
  AUTHENTICATED_PAGE_LOADED,
  BEFORE_AUTHENTICATED_PAGE_UNLOADED,
} from '../../constants/IFrameMessageConstants';
import { ILLoadingIndicator } from '../ILLoadingIndicator';
import ILIframeAtom from '../ILIframeAtom';
import './style.scss';

interface Props {
  url: string;
  key?: string;
  handleFrameMessage?: (data: any) => void;
  disableLoading?: boolean | false;
}

function ILIframeViewerMolecule({
  url,
  key,
  handleFrameMessage,
  disableLoading,
}: Props): ReactElement {
  const method = useGetMethod();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [showLoadingText, setShowLoadingText] = useState(false);
  const timer = useRef(setTimeout(() => null, 0));

  const setLoading = useCallback(
    (loadingEnabled: boolean) => {
      if (!disableLoading) {
        setIsLoading(loadingEnabled);
      }
    },
    [disableLoading]
  );

  const onIFrameMessage = (event: MessageEvent) => {
    try {
      const data = JSON.parse(event.data);
      if (data.type === BEFORE_AUTHENTICATED_PAGE_UNLOADED) {
        setShowLoadingText(false);
        setLoading(true);
      }
      if (data.type === AUTHENTICATED_PAGE_LOADED) {
        setLoading(false);
        setShowLoadingText(false);
      }
      if (handleFrameMessage) {
        handleFrameMessage(data);
      }
    } catch (error) {
      // continue regardless of error
    }
  };

  useEffect(() => {
    setLoading(true);
    window.addEventListener('message', onIFrameMessage, false);
    return () => {
      window.removeEventListener('message', onIFrameMessage, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setLoading]);

  useEffect(() => {
    if (isLoading) {
      timer.current = setTimeout(() => {
        setShowLoadingText(true);
      }, 5000);
    } else {
      clearTimeout(timer.current);
    }
  }, [isLoading]);

  return (
    <div className="viewer-wrapper">
      <div className={isLoading ? 'loading-wrapper' : 'loading-hidden'}>
        <h2 className={showLoadingText ? 'loading-text' : 'loading-hidden'}>
          {t('loading:loading_text')}
        </h2>
        <ILLoadingIndicator isLoading={isLoading} />
      </div>
      {method?.structureId ? <ILIframeAtom url={url} key={key} /> : ''}
    </div>
  );
}

export default ILIframeViewerMolecule;
