import moment from 'moment';
import 'moment/locale/nl';

export const getLocalisedDate = (utcDate: string): string => moment.utc(utcDate).local().format('DD-MM-YYYY');

export const sortDates = (a: string, b: string): number => {
  if (moment(a, 'DD-MM-YYYY') > moment(b, 'DD-MM-YYYY')) {
    return 1;
  }
  if (moment(a, 'DD-MM-YYYY') < moment(b, 'DD-MM-YYYY')) {
    return -1;
  }
  return 0;
};
