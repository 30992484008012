import React, { ReactElement, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import { useTranslation } from 'react-i18next';
import TPKtiPanelMolecule from './TPKtiPanelMolecule';
import TPQuestionDetailPanelMolecule from './TPQuestionDetailPanelMolecule';
import ILIconAtom, { IconType } from '../ILIconAtom/ILIconAtom';
import './style.scss';
import '../../assets/theme/tp-buttons.scss';

interface Props {
  level: string;
  year: string;
  chapters: string;
  numberOfQuestions: number;
  numberOfPoints: string;
  title: string;
  getKTISum: (key: string) => number;
  handleTitleChange: (title: string) => void;
}

function TPArrangedTestDetailsSideOrganism({
  level,
  year,
  chapters,
  numberOfQuestions,
  numberOfPoints,
  title,
  getKTISum,
  handleTitleChange,
}: Props): ReactElement {
  const { t } = useTranslation();
  const titleTextArea = useRef<HTMLTextAreaElement>(null);
  const focusOnTitle = () => {
    const titleElement = titleTextArea.current;
    if (titleElement) {
      titleElement.focus();
    }
  };

  return (
    <div>
      <div className="title-container">
        <TextareaAutosize
          inputRef={titleTextArea}
          className="title-box"
          placeholder={t('arrangedTestDetails:title_placeholder')}
          value={title}
          onChange={event => handleTitleChange(event.target.value)}
          minRows={1}
          maxRows={4}
          maxLength={100}
        />
        <button
          className="tp-button tp-button-primary-light tp-button-icon"
          onClick={() => focusOnTitle()}
        >
          <ILIconAtom type={IconType.Pencil} size={16} />
        </button>
      </div>
      <div className="arranged-test-details">
        <TPQuestionDetailPanelMolecule
          level={level}
          year={year}
          chapters={chapters}
          numberOfQuestions={numberOfQuestions}
          numberOfPoints={numberOfPoints}
        />
        <TPKtiPanelMolecule
          knowledgeSum={getKTISum('Kennis')}
          applicationSum={getKTISum('Toepassing')}
          insightSum={getKTISum('Inzicht')}
        />
      </div>
    </div>
  );
}

export default TPArrangedTestDetailsSideOrganism;
