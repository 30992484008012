import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useGetMethod } from '../../../graphql/method/methodOperations';
import { getData } from '../../../components/ILAjaxEther';
import { webApiUrl } from '../../../api/settingsLoader';
import { PlanningResult } from '../../../models/dto/PlanningResult';
import TPAllResultsTable from './TPAllResultsTable';
import { useGetAuthenticated } from '../../../graphql/authenticated/authenticatedOperations';
import { usePageTracking } from '../../../utils/usePageTracking';

function AllResults(): ReactElement {
  const method = useGetMethod();
  const { selectedSchoolId } = useGetAuthenticated();
  const history = useHistory();
  const { t } = useTranslation();
  const [results, setResults] = useState<PlanningResult[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);

  usePageTracking('result', 'result-list');

  useEffect(() => {
    if (method?.structureId && selectedSchoolId) {
      getData(`${webApiUrl}result/plans/${method.structureId}/${selectedSchoolId}`).then(
        (response: PlanningResult[]) => {
          setResults(response);
          setIsLoadingData(false);
        }
      );
    }
  }, [method, selectedSchoolId]);

  const openResult = (planId: string) => {
    history.push(`/resultaat/alle/${planId}`);
  };

  return (
    <div className="main">
      <h1>{t('resultsPage:all_results_title')}</h1>
      <TPAllResultsTable tableData={results} openResult={openResult} isLoading={isLoadingData} />
    </div>
  );
}

export default AllResults;
