import React, { ReactElement } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatName } from '../../../../utils/NameFormatter';
import { ResultDashboardStudent } from '../../../../models/dto/DashboardResult';
import { AssignmentType } from '../../../../models/enums/AssignmentType';
import { BreadcrumbPart } from '../../../../components/Breadcrumb/Breadcrumb';
import ILIconAtom, { IconType } from '../../../../components/ILIconAtom';
import { useNlpRoutes } from '../../../../utils/nlpNavigation';
import { useContextNavigation } from '../../../../utils/useContextNavigation';
import TPDashboardScoresMolecule from '../common/TPDashboardScoresOrganism/TPDashboardScoresOrganism';
import TPDomainAverageOrganism from '../common/TPDomainAverageOrganism';
import TPFeedbackMolecule from '../../../../components/TPFeedbackMolecule';
import TPQuestionResultsTableOrganismContainer from './TPQuestionResultsTableOrganism';
import Breadcrumb from '../../../../components/Breadcrumb';
import '../style.scss';
import '../../../../assets/theme/tp-buttons.scss';
import '../../../../assets/theme/tp-table.scss';

type Props = {
  result: ResultDashboardStudent;
  isTeacher: boolean;
  planId: string;
  studentId: string;
  isFeedbackEditorOpen: boolean;
  toggleFeedbackEditor: () => void;
  saveFeedback: (feedback: string, resultId?: string) => void;
};

function StudentResultDashboard({
  result,
  isTeacher,
  planId,
  studentId,
  isFeedbackEditorOpen,
  toggleFeedbackEditor,
  saveFeedback,
}: Props): ReactElement {
  const { t } = useTranslation();
  const nlpStudentsOverviewUrl = useNlpRoutes('studentsOverview');
  const nlpStudentResultsUrl = useNlpRoutes('studentResults', result.udbId);
  const viewAnswersRoute = useContextNavigation(`/antwoorden-bekijken/${planId}/${studentId}`)

  const setBreadcrumbs = (): BreadcrumbPart[] => [
    {
      name: t('resultsPage:test_results_students'),
      to: '/resultaten/leerlingen',
      nlpUrl: nlpStudentsOverviewUrl,
    },
    {
      name: formatName(result.firstName, result.lastName, result.prefix),
      to: `/resultaten/leerling/${result.udbId}`,
      nlpUrl: nlpStudentResultsUrl,
      state: {
        name: formatName(result.firstName, result.lastName, result.prefix),
      },
    },
    {
      name: `${t('resultsPage:result')} ${result.title}`,
    },
  ];

  const setTitle = (): string =>
    isTeacher
      ? `${t('resultsPage:result')} ${result.firstName} ${result.lastName} - ${result.title}`
      : `${t('resultsPage:result')} ${result?.title || ''}`;

  const renderFeedbackRow = () => {
    if (!isFeedbackEditorOpen && (!result.feedback || result.feedback?.trim().length === 0)) {
      return (
        <tr className="feedback-button-row">
          <td />
          <td className="feedback-cell">
            <button className="tp-button tp-button-primary" onClick={toggleFeedbackEditor}>
              <ILIconAtom type={IconType.FeedbackGiven} size={23} />
              <span>{t('resultsPage:give_feedback')}</span>
            </button>
          </td>
        </tr>
      );
    }
    return (
      <tr className="feedback-row">
        <td>{t('resultsPage:feedback')}</td>
        {isFeedbackEditorOpen ? (
          <td>
            <TPFeedbackMolecule
              feedback={result.feedback}
              resultId={result.resultId}
              sendFeedback={saveFeedback}
              isVertical={true}
              onCancel={toggleFeedbackEditor}
            />
          </td>
        ) : (
          <td className="feedback-view">
            <span>{result.feedback}</span>
            <button
              className="tp-button tp-button-primary-light tp-button-icon"
              onClick={toggleFeedbackEditor}
            >
              <ILIconAtom type={IconType.Pencil} size={16} />
            </button>
          </td>
        )}
      </tr>
    );
  };

  return (
    <>
      {isTeacher && <Breadcrumb crumbs={setBreadcrumbs()} />}
      <h1>{setTitle()}</h1>
      <div className="top-section">
        <div className="top-panel">
          <div className="top-panel__header">
            <h2>{t('resultsPage:summary')}</h2>
          </div>
          <TPDashboardScoresMolecule
            isStudentDashboard={true}
            status={result.status}
            score={result.score}
            gradeType={result.gradeType}
            grade={result.grade}
            alphabeticGrade={result.alphabeticGrade}
            hideGrade={result.assignmentType === AssignmentType.ReferenceTest}
          />
          <div className="result-details-table">
            <table className="tp-table tp-table-info">
              <tbody>
                {result.questionCount && (
                  <tr>
                    <td>{t('resultsPage:number_of_questions')}</td>
                    <td>{result.questionCount}</td>
                  </tr>
                )}
                <tr>
                  <td>{t('resultsPage:time')}</td>
                  <td>
                    {`${moment
                      .duration(result.duration)
                      .asMinutes()
                      .toLocaleString('nl-NL', { maximumFractionDigits: 0 })} ${t(
                      'resultsPage:minutes'
                    )}`}
                  </td>
                </tr>
                <tr>
                  <td>{t('resultsPage:rounded_to')}</td>
                  <td>{moment.utc(result.date).local().format('DD-MM-YYYY')}</td>
                </tr>
                {isTeacher
                  ? renderFeedbackRow()
                  : result.feedback?.trim() && (
                      <tr className="feedback-row">
                        <td>{t('resultsPage:your_teacher_said')}</td>
                        <td>{result.feedback}</td>
                      </tr>
                    )}
              </tbody>
            </table>
          </div>
        </div>

        <div className="top-panel">
          <div className="top-panel__header">
            <h2>{t('resultsPage:partial_scores')}</h2>
          </div>
          <TPDomainAverageOrganism
            domainResults={result.domainResults}
            cognitiveResults={result.cognitiveResults}
            assignmentType={result.assignmentType}
          />
        </div>
      </div>

      <div className="bottom-section">
        <div className="bottom-panel">
          <div className="bottom-panel__header">
            <h2>{t('resultsPage:result_per_question')}</h2>
            {isTeacher && (
              <Link
                className="tp-button tp-button-inverse"
                to={viewAnswersRoute}
              >
                <ILIconAtom type={IconType.Eye} />
                <span>{t('resultsPage:review_answers')}</span>
              </Link>
            )}
          </div>
          <div className="bottom-panel__content">
            <TPQuestionResultsTableOrganismContainer
              testId={result.testId}
              resultId={result.resultId}
              assignmentType={result.assignmentType}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default StudentResultDashboard;
