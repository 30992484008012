/* eslint-disable camelcase,@typescript-eslint/naming-convention */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// resources imports
import menu_nl from './components/ILMenuMolecule/resources/nl_nl.json';
import menu_ve from './components/ILMenuMolecule/resources/nl_ve.json';
import menu_he from './components/ILMenuMolecule/resources/nl_he.json';
import notFoundPage from './pages/PageNotFound/resources.json';
import resultsPage_nl from './pages/Results/resources/nl_nl.json';
import resultsPage_ve from './pages/Results/resources/nl_ve.json';
import resultsPage_he from './pages/Results/resources/nl_he.json';
import testsPage from './pages/TestOverview/resources.json';
import notifications from './components/TPNotificationsOrganism/resources.json';
import testPage from './pages/TakeTest/resources.json';
import planning_nl from './pages/Planning/resources/nl_nl.json';
import planning_ve from './pages/Planning/resources/nl_ve.json';
import planning_he from './pages/Planning/resources/nl_he.json';
import studentList_nl from './components/ILStudentListMolecule/resources/nl_nl.json';
import studentList_ve from './components/ILStudentListMolecule/resources/nl_ve.json';
import studentList_he from './components/ILStudentListMolecule/resources/nl_he.json';
import datePicker from './components/DatePicker/resources.json';
import caesura from './components/TPCaesuraTableOrganism/resources.json';
import alert from './components/ILAlertMessageMolecule/resources.json';
import footer from './components/ILFooterMolecule/resources.json';
import loading from './components/ILIframeViewerMolecule/resources.json';
import pagination from './components/ILPaginationMolecule/resources.json';
import wizard from './components/ILWizardOrganism/resources.json';
import testArrange from './pages/ArrangeTest/resources.json';
import arrangedTestDetails from './components/TPArrangedTestDetailsOrganism/resources.json';
import filterStep from './pages/ArrangeTest/SelectFiltersStep/resources.json';
import selectQuestionsStep from './pages/ArrangeTest/SelectQuestionsStep/resources.json';
import myTestPage from './pages/MyTests/resources.json';
import confirm from './components/ILConfirmationModalMolecule/resources.json';
import testDetails_nl from './pages/TestDetails/resources/nl-nl.json';
import testDetails_ve from './pages/TestDetails/resources/nl-ve.json';
import testDetails_he from './pages/TestDetails/resources/nl-he.json';
import viewAnswersPage_nl from './pages/ViewAnswers/resources/nl_nl.json';
import viewAnswersPage_ve from './pages/ViewAnswers/resources/nl_ve.json';
import viewAnswersPage_he from './pages/ViewAnswers/resources/nl_he.json';
import administrationPage_nl from './pages/TestAdministration/resources/nl-nl.json';
import administrationPage_ve from './pages/TestAdministration/resources/nl-ve.json';
import administrationPage_he from './pages/TestAdministration/resources/nl-he.json';
import studentResultSelector_nl from './pages/ResultsPerQuestion/TPStudentResultSelector/resources/nl-nl.json';
import studentResultSelector_ve from './pages/ResultsPerQuestion/TPStudentResultSelector/resources/nl-ve.json';
import studentResultSelector_he from './pages/ResultsPerQuestion/TPStudentResultSelector/resources/nl-he.json';
import reviewPage_nl from './pages/TestReview/resources/nl-nl.json';
import reviewPage_ve from './pages/TestReview/resources/nl-ve.json';
import reviewPage_he from './pages/TestReview/resources/nl-he.json';
import support from './pages/Support/resources.json';
import card from './components/Card/resources.json';
import newsFeed from './components/ILNewsFeedMolecule/resources.json';
import newsArchive from './pages/NewsArchive/resources.json';
import feedback from './components/TPFeedbackMolecule/resources.json';
import modal from './components/ILModalMolecule/resources.json';
import score from './components/ILScoreMeterAtom/resources.json';
import dashboardScore from './components/ILDashboardScoreMolecule/resources.json';
import viewAnswers from './components/ILViewAnswersOrganism/resources.json';
import questionSelector from './components/ILViewAnswersOrganism/ILQuestionSelector/resources.json';
import breadcrumb from './components/Breadcrumb/resources.json';
import status from './components/TPStatusAtom/resources.json';
import questionStats from './components/ILViewAnswersOrganism/ILQuestionStats/resources.json';
import resultsPerQuestion from './pages/ResultsPerQuestion/resources.json';
import remindo from './components/ILRemindoPlayerOrganism/resources.json';
import progressBar from './components/ILProgressBarMolecule/resources.json';
import loadingStatusIndicator from './components/ILLoadingStatusIndicator/resources.json';
import ressultSumamry_nl from './components/TPResultSummaryOrganism/resources/nl_nl.json';
import ressultSumamry_ve from './components/TPResultSummaryOrganism/resources/nl_ve.json';
import ressultSumamry_he from './components/TPResultSummaryOrganism/resources/nl_he.json';

const resources = {
  nl_nl: {
    testsPage,
    notFoundPage,
    testPage,
    notifications,
    datePicker,
    caesura,
    alert,
    footer,
    loading,
    breadcrumb,
    pagination,
    viewAnswers,
    wizard,
    testArrange,
    arrangedTestDetails,
    filterStep,
    selectQuestionsStep,
    myTestPage,
    confirm,
    support,
    card,
    newsFeed,
    newsArchive,
    feedback,
    modal,
    score,
    dashboardScore,
    questionSelector,
    status,
    questionStats,
    resultsPerQuestion,
    remindo,
    progressBar,
    menu: menu_nl,
    loadingStatusIndicator,
    viewAnswersPage: viewAnswersPage_nl,
    resultsPage: resultsPage_nl,
    planning: planning_nl,
    studentList: studentList_nl,
    testDetails: testDetails_nl,
    administrationPage: administrationPage_nl,
    studentResultSelector: studentResultSelector_nl,
    reviewPage: reviewPage_nl,
    resultSummary: ressultSumamry_nl,
  },
  nl_ve: {
    menu: menu_ve,
    resultsPage: resultsPage_ve,
    planning: planning_ve,
    studentList: studentList_ve,
    testDetails: testDetails_ve,
    viewAnswersPage: viewAnswersPage_ve,
    administrationPage: administrationPage_ve,
    studentResultSelector: studentResultSelector_ve,
    reviewPage: reviewPage_ve,
    resultSummary: ressultSumamry_ve,
  },
  nl_he: {
    menu: menu_he,
    resultsPage: resultsPage_he,
    planning: planning_he,
    studentList: studentList_he,
    testDetails: testDetails_he,
    viewAnswersPage: viewAnswersPage_he,
    administrationPage: administrationPage_he,
    studentResultSelector: studentResultSelector_he,
    reviewPage: reviewPage_he,
    resultSummary: ressultSumamry_he,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'nl_nl',
  fallbackLng: 'nl_nl',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});
