import React, { ReactElement, useEffect } from 'react';
import { useQuery } from '@apollo/client';

import OrderQuestionsStep from './OrderQuestionsStep';
import { Question } from '../../../models/state/Question';

import { useWizardContext } from '../../../components/ILWizardOrganism/ILWizardOrganism';
import { client } from '../../../graphql/ApolloClientService';
import { GET_QUESTION_FRAGMENT } from '../../../graphql/questions/questionQueries';
import { GET_ARRANGED_TEST } from '../../../graphql/arrangeTest/arrangeTestQueries';
import {
  ArrangedTestResult,
  SelectedQuestion,
} from '../../../models/state/arrangeTests/ArrangedTest';
import { getNewQuestionsList } from '../../../graphql/arrangeTest/arrangeTestOperations';

type Props = {
  isSaving: boolean;
  isVe: boolean;
};

function OrderQuestionsStepContainer({ isSaving, isVe }: Props): ReactElement | null {
  const { setReady, setNotReady, canGoNext } = useWizardContext();
  const { arrangedTest }: ArrangedTestResult = useQuery(GET_ARRANGED_TEST).data;

  useEffect(() => {
    if (arrangedTest && arrangedTest.currentStep !== 2) {
      client.writeQuery({
        query: GET_ARRANGED_TEST,
        data: {
          arrangedTest: {
            ...arrangedTest,
            currentStep: 2,
          },
        },
      });
    }
  }, [arrangedTest]);

  const updateQuestionsList = (newList: SelectedQuestion[]) => {
    client.writeQuery({
      query: GET_ARRANGED_TEST,
      data: {
        arrangedTest: {
          ...arrangedTest,
          selectedQuestions: newList,
        },
      },
    });
  };

  const moveQuestionToPosition = (oldPosition: number, newPosition: number) => {
    const tempQuestionsArray = [...arrangedTest.selectedQuestions];
    tempQuestionsArray.splice(newPosition, 0, tempQuestionsArray.splice(oldPosition, 1)[0]);
    updateQuestionsList(tempQuestionsArray);
  };

  useEffect(() => {
    if (!isSaving && arrangedTest?.selectedQuestions?.length > 0 && !canGoNext) {
      setReady();
    }
    if (isSaving || (arrangedTest?.selectedQuestions?.length === 0 && canGoNext)) {
      setNotReady();
    }
  }, [arrangedTest, canGoNext, isSaving, setNotReady, setReady]);

  if (arrangedTest) {
    const moveUp = (position: number) => {
      moveQuestionToPosition(position, position - 1);
    };

    const moveDown = (position: number) => {
      moveQuestionToPosition(position, position + 1);
    };

    const getQuestionById = (id: number) =>
      client.readFragment({
        id: `Question:${id}`,
        fragment: GET_QUESTION_FRAGMENT,
      });

    const handleAddOrRemove = (question: Question) => {
      updateQuestionsList(getNewQuestionsList(arrangedTest.selectedQuestions, question, isVe));
    };

    return (
      <OrderQuestionsStep
        moveDown={moveDown}
        moveUp={moveUp}
        handleAddOrRemove={handleAddOrRemove}
        getQuestionById={getQuestionById}
        arrangedTest={arrangedTest}
      />
    );
  }
  return null;
}

export default OrderQuestionsStepContainer;
