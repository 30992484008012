import React, { ReactElement } from 'react';

interface WizardStepProps {
  // Disabled as the parent is using this prop
  // eslint-disable-next-line react/no-unused-prop-types
  name: string;
  children: JSX.Element;
}

function ILWizardStep({ children }: WizardStepProps): ReactElement {
  return <>{children}</>;
}

export default ILWizardStep;
