import React, { ReactElement } from 'react';
import ILDropDownMenuAtom from './ILDropDownMenuAtom/ILDropDownMenuAtom';
import { NLPLocationKey, useIsNlpNavigationEnabled } from '../../utils/nlpNavigation';
import { ILNlpMenuLink } from './ILNlpMenulLink/ILNlpMenuLink';
import { ILTpMenuLink } from './ILTpMenuLink/ILTpMenuLink';
import './style.scss';

export interface MenuItem {
  url: string;
  resourceKey: string;
  matchFullUrl?: boolean;
  featureToggleKey?: string;
  items?: MenuItem[];
  nlpKey?: NLPLocationKey;
}

export interface Props {
  menuItems: MenuItem[];
  showLicensedTests?: boolean;
  hideChapterTests?: boolean;
  showArrangedTests?: boolean;
  display: boolean;
}

function ILMenuMolecule({
  menuItems,
  showLicensedTests,
  hideChapterTests,
  showArrangedTests,
  display,
}: Props): ReactElement | null {
  const isNlpNavigationEnabled = useIsNlpNavigationEnabled();

  const handleTouchEnd = () => {
    // NOTE: fix for touch devices that doesn't implement hover effects
  };

  const getFilteredDropdown = (parentKey: string, dropdownItems: MenuItem[]) => {
    if (parentKey === 'tests') {
      let filteredDropdown = dropdownItems;
      if (!showLicensedTests) {
        filteredDropdown = filteredDropdown.filter(title => title.resourceKey !== 'level_tests');
      }
      if (hideChapterTests) {
        filteredDropdown = filteredDropdown.filter(title => title.resourceKey !== 'chapter_tests');
      }
      if (!showArrangedTests) {
        filteredDropdown = filteredDropdown.filter(title => title.resourceKey !== 'my_tests');
      }
      return filteredDropdown;
    }
    return dropdownItems;
  };

  const renderMenuItems = () =>
    menuItems.map((menuItem: MenuItem) => (
      <li key={menuItem.resourceKey} className="menu-item" onTouchEnd={handleTouchEnd}>
        {menuItem.nlpKey && isNlpNavigationEnabled ? (
          <ILNlpMenuLink menuItem={menuItem} />
        ) : (
          <ILTpMenuLink menuItem={menuItem} />
        )}
        {menuItem.items ? (
          <ILDropDownMenuAtom items={getFilteredDropdown(menuItem.resourceKey, menuItem.items)} />
        ) : (
          ''
        )}
      </li>
    ));

  return display ? <ul className="menu">{renderMenuItems()}</ul> : null;
}
ILMenuMolecule.defaultProps = {
  showLicensedTests: false,
  hideChapterTests: false,
};

export default ILMenuMolecule;
