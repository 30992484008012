const studentMenu = [
  {
    url: '/',
    resourceKey: 'start',
    matchFullUrl: true,
  },
  {
    url: '/mijn-resultaten',
    resourceKey: 'test_results',
  },
];

export default studentMenu;
