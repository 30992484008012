import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ResultGroupUser } from '../../../models/dto/PlanningResult';
import { formatName } from '../../../utils/NameFormatter';
import ILIconAtom, { IconType } from '../../ILIconAtom';
import ILModalMolecule from '../../ILModalMolecule';
import './style.scss';

type Props = {
  isOpen: boolean;
  title: string;
  plannedCount: number;
  notStartedStudents: ResultGroupUser[];
  startedStudents: ResultGroupUser[];
  closeModal: () => void;
};

function TPStudentListModalOrganism({
  isOpen,
  title,
  plannedCount,
  notStartedStudents,
  startedStudents,
  closeModal,
}: Props): ReactElement {
  const { t } = useTranslation();

  const getFullName = (student: ResultGroupUser): string =>
    formatName(student.firstName, student.lastName, student.prefix);

  const renderNotStartedStudentList = () => (
    <ul className="students-list students-list-nok">
      {notStartedStudents.map((student: ResultGroupUser) => (
        <li key={student.udbId} className="students-list-item">
          <ILIconAtom type={IconType.CrossCircle} size={18} />
          {getFullName(student)}
        </li>
      ))}
    </ul>
  );

  const renderStartedStudentList = () => (
    <ul className="students-list students-list-ok">
      {startedStudents.map((student: ResultGroupUser) => (
        <li key={student.udbId} className="students-list-item">
          <ILIconAtom type={IconType.CheckCircle} size={18} />
          {getFullName(student)}
        </li>
      ))}
    </ul>
  );

  return (
    <ILModalMolecule
      className="student-list-modal"
      isOpen={isOpen}
      title={title}
      subText={`${t('resultSummary:planned_for')} ${plannedCount} ${
        plannedCount === 1 ? t('resultSummary:student') : t('resultSummary:students')
      }.`}
      handleClose={closeModal}
    >
      <div className="student-list-modal__content">
        {notStartedStudents.length > 0 && (
          <div className="student-list-modal__content-section">
            <h4>
              {`${t('resultSummary:test_not_made_by')} ${notStartedStudents.length} ${
                notStartedStudents.length === 1
                  ? t('resultSummary:student')
                  : t('resultSummary:students')
              }:`}
            </h4>
            {renderNotStartedStudentList()}
          </div>
        )}
        {startedStudents.length > 0 && (
          <div className="student-list-modal__content-section">
            <h4>
              {`${t('resultSummary:test_made_by')} ${startedStudents.length} ${
                startedStudents.length === 1
                  ? t('resultSummary:student')
                  : t('resultSummary:students')
              }:`}
            </h4>
            {renderStartedStudentList()}
          </div>
        )}
      </div>
    </ILModalMolecule>
  );
}

export default TPStudentListModalOrganism;
