import React, { ReactElement } from 'react';
import './ILWizardHeaderMolecule.scss';

export interface WizardHeaderProps {
  activeStepIndex: number;
  names: string[];
  goToStep: (index: number) => void;
}

function ILWizardHeaderMolecule({
  activeStepIndex,
  names,
  goToStep,
}: WizardHeaderProps): ReactElement {
  const getClass = (index: number, className: string) => {
    let classOfBullet = '';
    let classOfName = '';
    let classOfLine = '';
    if (activeStepIndex === index) {
      classOfBullet = 'stepper__step-bullet--active';
      classOfName = 'stepper__step-name--active';
      classOfLine = 'stepper__step-line--active';
    } else if (activeStepIndex > index) {
      classOfBullet = 'stepper__step-bullet--finished';
      classOfName = 'stepper__step-name--finished';
      classOfLine = 'stepper__step-line--finished';
    } else {
      classOfBullet = '';
    }

    if (className === 'bullet') {
      return classOfBullet;
    }
    if (className === 'line') {
      return classOfLine;
    }
    return classOfName;
  };

  const renderSteps = () => {
    const stepElements: JSX.Element[] = [];

    names.forEach((step, index) => {
      stepElements.push(
        <React.Fragment key={step}>
          <div className={`stepper__step-line ${getClass(index, 'line')}`} />
          <div
            role="menuitem"
            className="stepper__step"
            tabIndex={-1}
            onClick={() => goToStep(index)}
          >
            <div className={`stepper__step-bullet ${getClass(index, 'bullet')}`} />
            <span className={`stepper__step-name ${getClass(index, 'name')}`}>{step}</span>
          </div>
          {index === names.length - 1 && <div className="stepper__step-line" />}
        </React.Fragment>
      );
    });

    return stepElements;
  };

  return <section className="stepper">{renderSteps()}</section>;
}

export default ILWizardHeaderMolecule;
