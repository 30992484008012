import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '../../components/ILMenuMolecule/ILMenuMolecule';
import { QuestionGroupCount, TestDetails } from '../../models/dto/TestDetails';
import { TPIframeMessage } from '../../models/dto/TPIframeMessage';
import { COGNITIVE_LEVEL_ORDER } from '../../constants/TestConstants';
import { CLOSE_REMINDO } from '../../constants/IFrameMessageConstants';
import { AlertType } from '../../components/ILAlertMessageMolecule/AlertType';
import TPBlueHeaderContainer from '../../components/TPBlueHeaderOrganism/TPBlueHeaderOrganismContainer';
import ILRemindoPlayerOrganism from '../../components/ILRemindoPlayerOrganism/ILRemindoPlayerOrganism';
import ILAlertMessageMolecule from '../../components/ILAlertMessageMolecule';
import ILAlertMessageButtonAtom from '../../components/ILAlertMessageMolecule/ILAlertMessageButtonAtom';
import './style.scss';
import '../../assets/utilities/grid.scss';
import '../../assets/theme/tp-buttons.scss';

type Props = {
  testDetails: TestDetails | undefined;
  worksheetsDownloadUrl: string | null;
  returnPage: MenuItem;
  remindoPlayerUrl: string;
  remindoPlayerTitle: string;
  openDemoRemindoPlayer: (recipeId: string, testTitle: string) => void;
  closeDemoRemindoPlayer: () => void;
};

function TestDetailsView({
  testDetails,
  worksheetsDownloadUrl,
  returnPage,
  remindoPlayerUrl,
  remindoPlayerTitle,
  openDemoRemindoPlayer,
  closeDemoRemindoPlayer,
}: Props): ReactElement {
  const { t } = useTranslation();

  const handleFrameMessage = (data: TPIframeMessage) => {
    if (data.type === CLOSE_REMINDO) {
      closeDemoRemindoPlayer();
    }
  };

  const renderQuestionGroupCountRows = (
    questionGroups: QuestionGroupCount[],
    isKti: boolean
  ): JSX.Element[] => {
    const sortedArray: QuestionGroupCount[] = isKti
      ? questionGroups.sort(
          (a, b) =>
            COGNITIVE_LEVEL_ORDER.indexOf(a.groupingName) -
            COGNITIVE_LEVEL_ORDER.indexOf(b.groupingName)
        )
      : questionGroups.sort((a, b) =>
          a.groupingName?.toLowerCase().localeCompare(b.groupingName?.toLowerCase(), 'nl-NL')
        );
    return sortedArray.map((group: QuestionGroupCount) => (
      <tr key={`group-${group.groupingName}`}>
        <td>{group.groupingName}</td>
        <td>{group.questionCount}</td>
      </tr>
    ));
  };

  return (
    <>
      <TPBlueHeaderContainer title={testDetails?.title || ''} menuItem={returnPage} />
      <div className="main">
        {testDetails && (
          <div className="detail-container">
            {worksheetsDownloadUrl && (
              <ILAlertMessageMolecule
                type={AlertType.PayAttention}
                message={t('testDetails:worksheet_needed')}
              >
                <ILAlertMessageButtonAtom
                  text={t('testDetails:download_worksheet')}
                  handleClick={() => window.open(worksheetsDownloadUrl, '_blank')}
                />
              </ILAlertMessageMolecule>
            )}
            <div className="col-layout">
              <div className="col-50">
                <div className="detail-panel">
                  <div className="detail-panel-header">
                    <h2>{t('testDetails:details')}</h2>
                    <button
                      className="tp-button tp-button-secondary tp-button-thin"
                      onClick={() => openDemoRemindoPlayer(testDetails.recipeId, testDetails.title)}
                    >
                      {t('testDetails:take_test')}
                    </button>
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>{t('testDetails:title')}</td>
                        <td>{testDetails.title}</td>
                      </tr>
                      <tr>
                        <td>{t('testDetails:level')}</td>
                        <td>{testDetails.level}</td>
                      </tr>
                      <tr>
                        <td>{t('testDetails:chapter')}</td>
                        <td>{testDetails.chapter}</td>
                      </tr>
                      <tr>
                        <td>{t('testDetails:closed_questions')}</td>
                        <td>{testDetails.questionsClosedCount}</td>
                      </tr>
                      <tr>
                        <td>{t('testDetails:open_questions')}</td>
                        <td>{testDetails.questionsOpenCount}</td>
                      </tr>
                      <tr>
                        <td>{t('testDetails:question_count')}</td>
                        <td>{testDetails.questionsTotalCount}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-50">
                {testDetails.domainQuestionsData?.length > 0 && (
                  <div className="detail-panel">
                    <h2>{t('testDetails:domain_questions')}</h2>
                    <table>
                      <tbody>
                        {renderQuestionGroupCountRows(testDetails.domainQuestionsData, false)}
                      </tbody>
                    </table>
                  </div>
                )}
                {testDetails.cognitiveQuestionsData?.length > 0 && (
                  <div className="detail-panel">
                    <h2>{t('testDetails:cognitive_level_questions')}</h2>
                    <table>
                      <tbody>
                        {renderQuestionGroupCountRows(testDetails.cognitiveQuestionsData, true)}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <ILRemindoPlayerOrganism
          src={remindoPlayerUrl}
          title={remindoPlayerTitle}
          onClose={closeDemoRemindoPlayer}
          handleFrameMessage={handleFrameMessage}
        />
      </div>
    </>
  );
}

export default TestDetailsView;
