import React, { ReactElement } from 'react';
import { RouteComponentProps } from 'react-router';
import { Route, RouteProps } from 'react-router-dom';
import { TPLayout } from '../TPLayout/TPLayout';

interface Props extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TPRoute = ({ component: Component, ...rest }: Props): ReactElement => (
  <Route
    {...rest}
    render={props => (
      <TPLayout>
        <Component {...props} />
      </TPLayout>
    )}
  />
);
