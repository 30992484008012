import { gql } from '@apollo/client';

export const GET_ALL_NEWS_FLASHES = gql`
  query allNewsFlashes {
    allNewsFlashes {
      pageId
      id
      title
      content
      startDate
      endDate
      image
      file
      link
      relatedFeature
    }
  }
`;
