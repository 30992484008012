import React, { ElementType, useCallback, useEffect, useState } from 'react';
import { Filter, SortingRule, SubComponentFunction } from 'react-table';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createTableColumns, TableColumn } from './ILTableColumn/createColumns';
import { Tables } from '../../models/state/Tables';
import { State } from '../../store';
import {
  setTableFiltersAction,
  setTablePageAction,
  setTablePageSizeAction,
} from '../../store/tables/actions';
import ILTablePaginationMolecule from '../ILPaginationMolecule/ILTablePaginationMolecule';
import ILTableOrganism from './ILTableOrganism';

interface Props<Type> {
  /** Unique identifier of the table */
  id: string;
  /** Data that needs to be displayed in the table */
  data: Type[];
  /** Info about the layout of the columns */
  columns: TableColumn[];
  /** This text will be displayed in case there is no data to be displayed in the table */
  noDataText: string;
  /** Set to true if the table should be filterable */
  filterable: boolean;
  /** Set to true if the table should be sortable */
  sortable: boolean;
  /** Set the initial sorted column and direction */
  defaultSorted?: SortingRule[];
  /** Display loading indicator */
  isLoading?: boolean;
  /** Set to true, if the table should reload it's previous session (selected page, page site, filters) */
  shouldReload: boolean;
  /** How many items should be displayed in a page initially */
  defaultPageSize?: number;
  /** Selectable page sizes */
  pageSizeOptions?: number[];
  /** Custom pagination component */
  paginationComponent?: ElementType;
  /** Css classname */
  className?: string;
  subComponent?: SubComponentFunction;
  tables: Tables;
  dispatchSetTablePageAction: (id: string, page: number) => void;
  dispatchSetTablePageSizeAction: (id: string, pageSize: number) => void;
  dispatchSetTableFiltersAction: (id: string, filtered: Filter[]) => void;
}

const mapStateToProps = (state: State) => ({
  tables: state.tables,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchSetTablePageAction: (id: string, page: number) => {
    dispatch(setTablePageAction(id, page));
  },
  dispatchSetTablePageSizeAction: (id: string, pageSize: number) => {
    dispatch(setTablePageSizeAction(id, pageSize));
  },
  dispatchSetTableFiltersAction: (id: string, filtered: Filter[]) => {
    dispatch(setTableFiltersAction(id, filtered));
  },
});

function ILTableOrganismContainer<Type>({
  id,
  data,
  columns,
  noDataText,
  sortable,
  isLoading,
  filterable,
  shouldReload,
  defaultPageSize = 10,
  paginationComponent = ILTablePaginationMolecule,
  pageSizeOptions = [10, 20, 50],
  className,
  subComponent,
  tables,
  defaultSorted,
  dispatchSetTablePageAction,
  dispatchSetTablePageSizeAction,
  dispatchSetTableFiltersAction,
}: Props<Type>) {
  const [activePage, setActivePage] = useState(tables[id]?.page || undefined);
  const [activePageSize, setActivePageSize] = useState(tables[id]?.pageSize || undefined);
  const [activeFilters, setActiveFilters] = useState(tables[id]?.filtered || []);

  const handlePageChange = useCallback(
    (pageIndex: number) => {
      setActivePage(pageIndex);
      if (shouldReload) {
        dispatchSetTablePageAction(id, pageIndex);
      }
    },
    [dispatchSetTablePageAction, id, shouldReload]
  );

  useEffect(() => {
    if (
      !isLoading &&
      activePage &&
      activePage > Math.floor(data.length / (activePageSize || defaultPageSize))
    ) {
      handlePageChange(Math.floor(data.length / (activePageSize || defaultPageSize)));
    }
  }, [isLoading, data, activePage, activePageSize, defaultPageSize, handlePageChange]);

  const handlePageSizeChange = (pageSize: number) => {
    setActivePageSize(pageSize);
    if (activePage && activeFilters.length > 0 && activePageSize && activePageSize < pageSize) {
      handlePageChange(0);
    } else if (activePage && activePage > Math.floor(data.length / pageSize)) {
      handlePageChange(Math.floor(data.length / pageSize));
    }
    if (shouldReload) {
      dispatchSetTablePageSizeAction(id, pageSize);
    }
  };

  const handleFiltersChange = (filtered: Filter[]) => {
    setActiveFilters(filtered);
    if (activePage) {
      handlePageChange(0);
    }
    if (shouldReload) {
      dispatchSetTableFiltersAction(id, filtered);
    }
  };
  return (
    <ILTableOrganism
      data={data}
      page={activePage}
      pageSize={activePageSize}
      filtered={activeFilters}
      isLoading={isLoading}
      columns={createTableColumns(data, columns)}
      sortable={sortable}
      filterable={filterable}
      defaultPageSize={defaultPageSize}
      pageSizeOptions={pageSizeOptions}
      paginationComponent={paginationComponent}
      noDataText={noDataText}
      className={className}
      defaultSorted={defaultSorted}
      subComponent={subComponent}
      handlePageChange={handlePageChange}
      handlePageSizeChange={handlePageSizeChange}
      handleFiltersChange={handleFiltersChange}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ILTableOrganismContainer);
