import React from 'react';
import { formatStringGradeValue } from '../../../utils/DataFormatter';

export const noNotificationsText = (): JSX.Element => (
  <td>Er zijn op dit moment geen meldingen.</td>
);

export const noLinkedGroupsText = (): JSX.Element => (
  <td>
    Uw klassen zijn nog niet bekend. Ga naar de online leeromgeving om te
    registreren aan welke klassen u lesgeeft.
  </td>
);

export const noAttachedClassText = (): JSX.Element => (
  <td>
    Je bent nog niet in een klas of groep geplaatst. Ga naar de online
    leeromgeving om je klas te kiezen. Vraag je docent om je daarbij te helpen.
  </td>
);

export const canBeMadeText = (title: string, timing: string | JSX.Element): JSX.Element => (
  <td>
    Toets 
    {' '}
    <strong>{title}</strong>
    {' '}
kan 
    {' '}
    <strong>{timing}</strong>
    {' '}
worden gemaakt.
  </td>
);

export const canBeMadeTextError = (title: string): JSX.Element => (
  <td>
    Toets 
    {' '}
    <strong>{title}</strong>
    {' '}
kan 
    {' '}
    <strong>niet</strong>
    {' '}
worden gemaakt.
    <span className="error">Geen licentie (geactiveerd)</span>
  </td>
);

export const pendingTextTeacher = (groupName: string, title: string): JSX.Element => (
  <td>
    De nagekeken resultaten van
    {' '}
    <strong>{groupName}</strong>
    {' '}
    voor toets
    {' '}
    <strong>{title}</strong>
    {' '}
    worden verwerkt.
  </td>
);

export const needsReviewTeacher = (groupName: string, title: string): JSX.Element => (
  <td>
    Er zijn resultaten binnen van 
    {' '}
    <strong>{groupName}</strong>
    {' '}
voor toets
    {' '}
    <strong>{title}</strong>
  </td>
);

export const beingGradedTextStudent = (title: string): JSX.Element => (
  <td>
    Toets 
    {' '}
    <strong>{title}</strong>
    {' '}
wordt nagekeken door je docent
  </td>
);

export const beingGradedTextTeacher = (
  groupName: string,
  title: string,
  timing: string
): JSX.Element => (
  <td>
    <strong>{groupName}</strong>
    {' '}
heeft toets
    {' '}
    <strong>{title}</strong>
    {' '}
met
    {' '}
    einddatum 
    {' '}
    {timing}
    {' '}
afgerond. Het resultaat van de toets is nog niet
    {' '}
    vrijgegeven.
  </td>
);

export const finishedWithTextStudent = (title: string, grade: string): JSX.Element => (
  <td>
    Toets 
    {' '}
    <strong>{title}</strong>
    {' '}
is afgerond met een 
    {' '}
    <strong>{formatStringGradeValue(grade)}</strong>
  </td>
);

export const finishedWithTextTeacher = (
  groupName: string,
  title: string,
  timing: string
): JSX.Element => (
  <td>
    <strong>{groupName}</strong>
    {' '}
heeft toets
    {' '}
    <strong>{title}</strong>
    {' '}
met
    {' '}
    einddatum 
    {' '}
    {timing}
    {' '}
afgerond.
  </td>
);

export const finishedWithText4RTPStudent = (title: string, grade: string): JSX.Element => (
  <td>
    Toets 
    {' '}
    <strong>{title}</strong>
    {' '}
is afgerond met een score van
    {' '}
    <strong>{grade}</strong>
  </td>
);

export const canBeMadeDateText = (
  date: string,
  startTime: string,
  endTime: string
): JSX.Element => (
  <span>
    op 
    {' '}
    <strong>{date}</strong>
    {' '}
tussen 
    {' '}
    <strong>{startTime}</strong>
    {' '}
en
    {' '}
    <strong>{endTime}</strong>
  </span>
);

export const canBeMadePeriodText = (startDate: string, endDate: string): JSX.Element => (
  <span>
    van 
    {' '}
    <strong>{startDate}</strong>
    {' '}
tot 
    {' '}
    <strong>{endDate}</strong>
  </span>
);

export const unknownStatusText = (title: string): JSX.Element => (
  <td>
    ??? 
    {' '}
    <strong>{title}</strong>
  </td>
);
