import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { formatGradeValue } from '../../utils/DataFormatter';
import { DashboardScoreType } from '../../models/enums/DashboardScoreType';
import { AlphabeticGradeType } from '../../models/enums/AlphabeticGradeType';
import './style.scss';

type Props = {
  value: number | string;
  scoreType: DashboardScoreType;
  label?: string;
  cutValue?: number;
  isHidden?: boolean;
};

function ILDashboardScoreMolecule({ value, scoreType, label, cutValue, isHidden }: Props): ReactElement {
  const { t } = useTranslation();
  const setValueType = (): string => {
    if (isHidden) {
      return 'dash-score-none';
    }

    if (scoreType !== DashboardScoreType.Alphabetic && cutValue) {
      return value < cutValue ? 'dash-score-nok' : 'dash-score-ok';
    }

    if (scoreType === DashboardScoreType.Alphabetic) {
      return value === AlphabeticGradeType.Passed ? 'dash-score-ok' : 'dash-score-nok';
    }

    return 'dash-score-default';
  };

  const getValue = (): string | JSX.Element => {
    if (isHidden) {
      return '-';
    }
    switch (scoreType) {
      case DashboardScoreType.Grade:
        return formatGradeValue(value);
      case DashboardScoreType.Score:
        return `${value}%`;
      case DashboardScoreType.Alphabetic:
        return (
          <div className="alphabetic">
            <span>{value}</span>
            <span className="alphabetic-label">{t(`dashboardScore:${value}`)}</span>
          </div>
        );
      default:
        return `${value}`;
    }
  };

  return (
    <div className="dashboard-score-container">
      <div className="dashboard-score">
        <div className={`dashboard-score-value ${setValueType()}`}>{getValue()}</div>
        {label && <span className="dashboard-score-label">{label}</span>}
      </div>
    </div>
  );
}


export default ILDashboardScoreMolecule;
