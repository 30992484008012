export enum LogoType {
  Buitenland = 'buitenland-logo',
  Chemie = 'chemie-logo',
  ChemieOveral = 'chemie-overal-logo',
  GeschiedenisWerkplaats = 'geschiedeniswerkplaats-logo',
  GetalEnRuimte = 'getal_ruimte-logo',
  GrandesLignes = 'grandes-lignes-logo',
  GrandesLignesOnderbouw = 'grandes-lignes-onderbouw-logo',
  ModerneWiskunde = 'moderne-wiskunde-logo',
  Nectar = 'nectar-logo',
  NeueKontakte6e = 'neue-kontakte-logo-6e-editie-logo',
  NeueKontakte = 'neue-kontakte-logo-versie-X-logo',
  NieuwNederlands = 'nieuw-nederlands-logo',
  NuBurgerschap = 'NU-Burgerschap',
  NuCommercieel = 'NU-Commercieel',
  NuEngels = 'NU-Engels',
  NuFinancieel = 'NU-Financieel',
  NuJuridisch = 'NU-Juridisch',
  NuNederlands = 'NU-Nederlands',
  NuPedagigischWerk = 'NU-Pedagogischwerk',
  NuRekenen = 'NU-Rekenen',
  NuRetail = 'NU-Retail',
  Overal = 'overal-logo',
  Pincode = 'pincode-logo',
  PleinM = 'Plein-M',
  Pulsar = 'pulsar-logo',
  SteppingStones = 'stepping-stones-onderbouw',
  Trabitour = 'trabitour-logo',
  TechniekOpMaat = 'Techniek-op-maat-2-0',
  Prepzone = 'Prepzone',
  Technologisch = 'technologisch-logo',
}
