import React, { useEffect, useState, useCallback, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, useHistory } from 'react-router-dom';
import ILIconAtom, { IconType } from '../../components/ILIconAtom/ILIconAtom';
import { ArrangeTestsItemResponse } from '../../models/dto/ArrangeTestsResponse';
import { getData } from '../../components/ILAjaxEther';
import { useGetMethod } from '../../graphql/method/methodOperations';
import { useGetAuthenticated } from '../../graphql/authenticated/authenticatedOperations';
import {
  CONCEPT_TEST,
  WEB_API_ARRRANGE_TEST_BASE_URL,
} from '../../constants/ArrangeTestContstants';
import { paraginServiceUrl } from '../../api/settingsLoader';
import ILConfirmationModalMolecule, {
  ConfirmModalInfo,
} from '../../components/ILConfirmationModalMolecule/ILConfirmationModalMolecule';
import { myTestMenuItem } from '../../components/ILMenuMolecule/teacherMenu';
import { autoDownloadZipFile } from '../../utils/AutoDownload';
import { Origin } from '../../models/enums/Origin';
import TPMyOverviewTableOrganism from './TPMyTestOverviewTableOrganism';
import PageNotFound from '../PageNotFound';
import ILLoadingModalMolecule from '../../components/ILModalMolecule/ILLoadingModalMolecule';
import './style.scss';
import '../../assets/theme/tp-buttons.scss';
import { usePageTracking } from '../../utils/usePageTracking';

function MyTests(): ReactElement {
  const method = useGetMethod();
  const { selectedSchoolId } = useGetAuthenticated();
  const history = useHistory();
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<ArrangeTestsItemResponse[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [modalSettings, setModalSettings] = useState<ConfirmModalInfo>({
    itemId: '',
    display: false,
    showLoading: false,
  });
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState<boolean>(false);

  usePageTracking('content', 'overview');

  const loadArrangedTest = useCallback(() => {
    setIsLoadingData(true);
    getData(
      `${WEB_API_ARRRANGE_TEST_BASE_URL}/getRecipes/${method?.structureId}/${selectedSchoolId}`
    ).then((response: ArrangeTestsItemResponse[]) => {
      // NOTE: filter concept tests until edit of arranged test isn't available
      const filteredTableData: ArrangeTestsItemResponse[] = response.filter(
        (arrangedTest: ArrangeTestsItemResponse) => arrangedTest.status !== CONCEPT_TEST
      );
      setTableData(filteredTableData);
      setIsLoadingData(false);
    });
  }, [method, selectedSchoolId]);

  useEffect(() => {
    if (method?.structureId) {
      loadArrangedTest();
    }
  }, [loadArrangedTest, method]);

  const openTestArrange = () => {
    history.push({
      pathname: '/toets-samenstellen',
      state: {
        key: myTestMenuItem.resourceKey,
      },
    });
  };

  const openDetailsPage = (id: string) => {
    history.push(`${process.env.PUBLIC_URL}/toets-details/${id}/${Origin.Ats}`);
  };

  const openPlanning = (mgrRecipeId: string) => {
    // Planning is created based on mgrRecipeId. This because the mgrRecipeId is the RowKey in Table Storage
    history.push(`${process.env.PUBLIC_URL}/planning/new/${mgrRecipeId}/${Origin.Ats}`);
  };

  const openDeleteConfirmationModal = (recipeId: string) => {
    setModalSettings({
      itemId: recipeId,
      display: true,
      showLoading: false,
    });
  };

  const closeDeleteConfirmationModal = () => {
    setModalSettings({
      itemId: '',
      display: false,
      showLoading: false,
    });
  };

  const deleteArrangedTest = (recipeId: string) => {
    if (method?.structureId && selectedSchoolId) {
      getData(
        `${WEB_API_ARRRANGE_TEST_BASE_URL}/deleteRecipe/${method.structureId}/${selectedSchoolId}/${recipeId}`
      ).then(() => {
        closeDeleteConfirmationModal();
        loadArrangedTest();
      });
    }
  };

  const downloadZipFile = (recipeId: string, title: string, type: string) => {
    setIsDownloadModalOpen(true);
    getData(`${paraginServiceUrl}/api/v1/recipes/download/${recipeId}/${type}`)
      .then(response => {
        setIsDownloadModalOpen(false);
        autoDownloadZipFile(response, title);
      })
      .catch(() => setIsDownloadModalOpen(false));
  };

  if (method) {
    if (method.structureId && !method.arrangeTest) {
      return <Route component={PageNotFound} />;
    }
    if (method.structureId && method.arrangeTest) {
      return (
        <div className="main">
          <div className="main__title">
            <h1>{t('myTestPage:my_tests')}</h1>
            {method.arrangeTest && (
              <button
                className="tp-button tp-button-primary tp-button-with-icon tp-button-thin"
                onClick={openTestArrange}
              >
                <ILIconAtom type={IconType.PlusCircle} size={17} />
                {t('testsPage:arrange_test')}
              </button>
            )}
          </div>
          <TPMyOverviewTableOrganism
            isLoading={isLoadingData}
            data={tableData}
            openDetails={openDetailsPage}
            openPlanning={openPlanning}
            openDeleteConfirmationModal={openDeleteConfirmationModal}
            downloadFile={downloadZipFile}
          />
          <ILConfirmationModalMolecule
            modalInfo={modalSettings}
            title={t('myTestPage:delete_modal_title')}
            description={t('myTestPage:delete_modal_description')}
            customAcceptText={t('myTestPage:delete')}
            onCancel={closeDeleteConfirmationModal}
            onAccept={deleteArrangedTest}
          />
          <ILLoadingModalMolecule
            isOpen={isDownloadModalOpen}
            title={t('testsPage:download_files')}
            onClose={() => setIsDownloadModalOpen(false)}
          >
            <div className="download-description">
              <p>{t('testsPage:create_files')}</p>
              <p>{t('testsPage:download_will_start')}</p>
            </div>
          </ILLoadingModalMolecule>
        </div>
      );
    }
  }
  return <></>;
}

export default MyTests;
