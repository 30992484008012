import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import { useIsNlpNavigationEnabled } from '../../../utils/nlpNavigation';
import ILFeatureToggleAtom from '../../ILFeatureToggleAtom';
import { MenuItem } from '../ILMenuMolecule';
import { ILNlpSubLink } from './ILNlpSubLink/ILNlpSubLink';
import './style.scss';
import { ILTpSubLink } from './ILTpSubLink/ILTpSubLink';

interface Props {
  items: MenuItem[];
}

const createMenuItem = (
  item: MenuItem,
  isNlpNavigationEnabled: boolean,
  t: TFunction
): JSX.Element => (
  <li key={item.resourceKey}>
    {item.nlpKey && isNlpNavigationEnabled ? (
      <ILNlpSubLink nlpKey={item.nlpKey} resourceKey={item.resourceKey} />
    ) : (
      <ILTpSubLink resourceKey={item.resourceKey} url={item.url} />
    )}
  </li>
);

const getMenuItems = (
  items: MenuItem[],
  isNlpNavigationEnabled: boolean,
  t: TFunction
): JSX.Element[] => {
  if (items !== undefined) {
    return items.map((item: MenuItem) =>
      item.featureToggleKey ? (
        <ILFeatureToggleAtom name={item.featureToggleKey} key={item.resourceKey}>
          {createMenuItem(item, isNlpNavigationEnabled, t)}
        </ILFeatureToggleAtom>
      ) : (
        createMenuItem(item, isNlpNavigationEnabled, t)
      )
    );
  }
  return [];
};

function ILDropDownMenuAtom({ items }: Props): ReactElement {
  const { t } = useTranslation();
  const isNlpNavigationEnabled = useIsNlpNavigationEnabled();

  return <ul className="drop-down-menu">{getMenuItems(items, isNlpNavigationEnabled, t)}</ul>;
}

export default ILDropDownMenuAtom;
