import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useContextNavigation } from '../../../../utils/useContextNavigation';
import '../style.scss';

interface Props {
  resourceKey: string;
  url: string;
}

export const ILTpSubLink: React.FC<Props> = ({ resourceKey, url }) => {
  const { t } = useTranslation();
  const menuContextRoute = useContextNavigation(url);

  return (
    <NavLink
      to={menuContextRoute}
      id="link"
      className="drop-down-menu-item"
      activeClassName="drop-down-menu-item-active"
    >
      {t(`menu:${resourceKey}`)}
    </NavLink>
  );
};
