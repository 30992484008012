import React, { ReactElement } from 'react';
import '../../../i18n';
import ILStudentListMolecule from '../../../components/ILStudentListMolecule';
import '../Planning.scss';
import { Groups } from '../../../models/state/Groups';

interface Props {
  studentGroups: Groups[];
  openGroupId: string;
  getCheckedStudents: (studentGroup: Groups) => string[];
  handleSelectionChange: (studentId: string, groupId: string) => void;
  selectAllStudentToggle: (studentGroupId: string) => void;
  isAllSelected: (studentGroupId: string) => boolean;
  countSelectedItems: (studentGroup: Groups) => number;
  toggleStudentGroup: (studentGroupId: string) => void;
}

function TPPlanningParticipantsOrganism({
  studentGroups,
  openGroupId,
  getCheckedStudents,
  handleSelectionChange,
  selectAllStudentToggle,
  isAllSelected,
  countSelectedItems,
  toggleStudentGroup,
}: Props): ReactElement {
  const renderStudentGroups = () =>
    studentGroups.map(studentGroup => (
      <ILStudentListMolecule
        key={studentGroup.group.id}
        studentGroup={studentGroup}
        collapsedHeight={48}
        checkedIds={getCheckedStudents(studentGroup)}
        handleSelectionChange={handleSelectionChange}
        selectAllStudentToggle={selectAllStudentToggle}
        isAllSelected={isAllSelected}
        countSelectedItems={countSelectedItems}
        isOpen={studentGroup.group.id === openGroupId}
        toggleStudentGroup={toggleStudentGroup}
        isIndeterminate={
          !isAllSelected(studentGroup.group.id) && countSelectedItems(studentGroup) > 0
        }
      />
    ));

  return <section className="group-container">{renderStudentGroups()}</section>;
}

export default TPPlanningParticipantsOrganism;
