import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { PlanErrorTypes } from '../../../../Planning/TPPlanningFooterMolecule/PlanErrorTypes';
import { AlertType } from '../../../../../components/ILAlertMessageMolecule/AlertType';
import { Caesura } from '../../../../../models/state/Plan';
import TPCaesuraTableOrganism from '../../../../../components/TPCaesuraTableOrganism';
import ILAlertMessageMolecule from '../../../../../components/ILAlertMessageMolecule';
import ILModalButtonsMolecule from '../../../../../components/ILModalMolecule/ILModalButtonsMolecule';
import ILModalMolecule from '../../../../../components/ILModalMolecule';
import './style.scss';

type Props = {
  isOpen: boolean;
  isLoading: boolean;
  caesura: Caesura;
  errors: PlanErrorTypes[];
  handleScoreChange: (id: number, score: string) => void;
  handleGradeChange: (id: number, score: string) => void;
  handleCancelClick: () => void;
  handleUpdateClick: () => void;
};

function TPCaesuraModalOrganism({
  isOpen,
  isLoading,
  caesura,
  errors,
  handleScoreChange,
  handleGradeChange,
  handleCancelClick,
  handleUpdateClick,
}: Props): ReactElement {
  const { t } = useTranslation();

  const renderValidationErrors = (): JSX.Element[] =>
    errors.map((error: PlanErrorTypes) => (
      <ILAlertMessageMolecule key={error} type={AlertType.Error} message={t(`planning:${error}`)} />
    ));

  return (
    <ILModalMolecule
      isOpen={isOpen}
      title={t('resultsPage:adjust_caesura')}
      subText={t('resultsPage:caesura_description')}
      handleClose={handleCancelClick}
    >
      <TPCaesuraTableOrganism
        caesura={caesura}
        isEditEnabled={true}
        handleScoreChange={handleScoreChange}
        handleGradeChange={handleGradeChange}
      />
      {errors.length > 0 ? <div className="caesura-errors">{renderValidationErrors()}</div> : <></>}
      <ILModalButtonsMolecule
        isDisabled={isLoading}
        acceptText={t('resultsPage:save')}
        onAccept={handleUpdateClick}
        onCancel={handleCancelClick}
      />
    </ILModalMolecule>
  );
}

export default TPCaesuraModalOrganism;
