import { FieldFunctionOptions, Reference, TypePolicies } from '@apollo/client';
import { AnswerValue, CriteriaValue } from '../../models/dto/ReviewTest';

export const reviewTestCache: TypePolicies = {
  ReviewTest: {
    keyFields: false,
    fields: {
      percentageReviewed: {
        read(_, { readField }: FieldFunctionOptions): number {
          let totalCount = 0;
          let reviewedCount = 0;
          const questions = readField<Reference[]>('questionsWithAnswers');
          if (questions) {
            questions.forEach(questionWithAnswer => {
              const givenAnswers = readField<Reference[]>('givenAnswers', questionWithAnswer);
              if (givenAnswers) {
                totalCount += givenAnswers.length || 0;
                givenAnswers.forEach(value => {
                  if (readField<boolean>('isReviewed', value)) {
                    reviewedCount += 1;
                  }
                });
              }
            });
          }
          return (reviewedCount / totalCount) * 100;
        },
      },
    },
  },
  StudentFeedback: {
    keyFields: ['resultId'],
    fields: {
      givenFeedback: {
        read(givenFeedback = null): string {
          return givenFeedback;
        },
      },
    },
  },
  QuestionsWithAnswer: {
    keyFields: ['id'],
    fields: {
      progress: {
        read(_, { readField }: FieldFunctionOptions): number {
          let totalCount = 0;
          let reviewedCount = 0;
          const givenAnswers = readField<Reference[]>('givenAnswers');
          if (givenAnswers) {
            totalCount += givenAnswers.length || 0;
            givenAnswers.forEach(answer => {
              if (readField<boolean>('isReviewed', answer)) {
                reviewedCount += 1;
              }
            });
          }
          return totalCount ? reviewedCount / totalCount : 0;
        },
      },
    },
  },
  CriteriaValue: {
    fields: {
      givenScore: {
        read(givenScore = null): number {
          return givenScore;
        },
      },
    },
  },
  GivenAnswer: {
    keyFields: ['itemCheckId'],
    fields: {
      isReviewed: {
        read(isReviewed = null, { readField }: FieldFunctionOptions): boolean | null {
          if (isReviewed === null) {
            const answerValues = readField<AnswerValue[]>('candidateResponses');
            if (answerValues) {
              return answerValues.every(
                (answerValue: AnswerValue) =>
                  !answerValue.criteria.some(
                    (criteriaItem: CriteriaValue) =>
                      (criteriaItem.correctorScore === null ||
                        criteriaItem.correctorScore === undefined) &&
                      (criteriaItem.givenScore === null || criteriaItem.givenScore === undefined)
                  )
              );
            }
            return false;
          }
          return isReviewed;
        },
      },
    },
  },
  AnswerValue: {
    fields: {
      valueChanged: {
        read(valueChanged = false): boolean {
          return valueChanged;
        },
      },
    },
  },
};

export const reviewTestStudentCache: TypePolicies = {
  ReviewTestStudent: {
    keyFields: false,
    fields: {
      percentageReviewed: {
        read(_, { readField }: FieldFunctionOptions): number {
          let totalCount = 0;
          let reviewedCount = 0;
          const students = readField<Reference[]>('studentWithAnswers');
          if (students) {
            students.forEach(studentWithAnswers => {
              const givenAnswers = readField<Reference[]>(
                'studentGivenAnswers',
                studentWithAnswers
              );
              if (givenAnswers) {
                totalCount += givenAnswers.length || 0;
                givenAnswers.forEach(value => {
                  if (readField<boolean>('isReviewed', value)) {
                    reviewedCount += 1;
                  }
                });
              }
            });
          }
          return (reviewedCount / totalCount) * 100;
        },
      },
    },
  },
  StudentWithAnswers: {
    keyFields: false,
    fields: {
      progress: {
        read(_, { readField }: FieldFunctionOptions): number {
          let totalCount = 0;
          let reviewedCount = 0;
          const studentGivenAnswers = readField<Reference[]>('studentGivenAnswers');
          if (studentGivenAnswers) {
            totalCount += studentGivenAnswers.length || 0;
            studentGivenAnswers.forEach(answer => {
              if (readField<boolean>('isReviewed', answer)) {
                reviewedCount += 1;
              }
            });
          }
          return totalCount ? reviewedCount / totalCount : 0;
        },
      },
    },
  },
  StudentGivenAnswer: {
    keyFields: ['itemCheckId'],
    fields: {
      isReviewed: {
        read(isReviewed = null, { readField }: FieldFunctionOptions): boolean | null {
          if (isReviewed === null) {
            const answerValues = readField<AnswerValue[]>('candidateResponses');
            if (answerValues) {
              return answerValues.every(
                (answerValue: AnswerValue) =>
                  !answerValue.criteria.some(
                    (criteriaItem: CriteriaValue) =>
                      (criteriaItem.correctorScore === null ||
                        criteriaItem.correctorScore === undefined) &&
                      (criteriaItem.givenScore === null || criteriaItem.givenScore === undefined)
                  )
              );
            }
            return false;
          }
          return isReviewed;
        },
      },
    },
  },
  CriteriaValue: {
    fields: {
      givenScore: {
        read(givenScore = null): number {
          return givenScore;
        },
      },
    },
  },
  AnswerValue: {
    fields: {
      valueChanged: {
        read(valueChanged = false): boolean {
          return valueChanged;
        },
      },
    },
  },
};
