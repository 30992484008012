import React, { ReactElement, useState } from 'react';
import ILTabHeaderMolecule from './ILTabHeaderMolecule';

type Props = {
  children: JSX.Element[];
};

function ILTabsOrganism({ children }: Props): ReactElement {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);

  return (
    <>
      <ILTabHeaderMolecule
        activeTabIndex={activeTabIndex}
        changeTab={setActiveTabIndex}
        tabTitles={children.map(c => c.props.name)}
      />
      {children[activeTabIndex]}
    </>
  );
}

export default ILTabsOrganism;
