import { gql } from '@apollo/client';

export const authenticatedTypes = gql`
  """
  Definition for the authenticated object
  """
  type SelectedQuestion {
    isAuthenticated: Boolean
    isTeacher: Boolean
    selectedSchoolId: ID
    udbId: ID
  }
`;
