import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { MethodType } from '../../../../../components/ILAjaxEther/MethodType';
import { webApiUrl } from '../../../../../api/settingsLoader';
import { createRequest } from '../../../../../components/ILAjaxEther';
import { autoDownloadCsvFile } from '../../../../../utils/AutoDownload';
import { formatToLocalDate } from '../../../../../utils/dateTimeFormatter';
import { formatGradeValue } from '../../../../../utils/DataFormatter';
import { formatLastName } from '../../../../../utils/NameFormatter';
import {
  findAndFormatDomainScoreByKey,
  getDistinctDomains,
  getDistinctKti,
} from './utils/domainMapper';
import { useContextNavigation } from '../../../../../utils/useContextNavigation';
import { DashboardResult, ResultDashboardStudent } from '../../../../../models/dto/DashboardResult';
import { AssignmentType } from '../../../../../models/enums/AssignmentType';
import { StudentsFeedback } from '../../../../../models/dto/StudentsFeedback';
import { Status } from '../../../../../components/TPStatusAtom/Status';
import { AlertType } from '../../../../../components/ILAlertMessageMolecule/AlertType';
import ILIconAtom, { IconType } from '../../../../../components/ILIconAtom';
import ILTabsOrganism from '../../../../../components/ILTabsOrganism';
import ILTabMolecule from '../../../../../components/ILTabsOrganism/ILTabMolecule';
import TPFeedbackMolecule from '../../../../../components/TPFeedbackMolecule';
import ILAlertMessageMolecule from '../../../../../components/ILAlertMessageMolecule';
import TPStudentResultsTable from './TPStudentResultsTable';
import './style.scss';
import '../../../../../assets/theme/tp-buttons.scss';

type Props = {
  result: DashboardResult;
  showAlphabeticGrade: boolean;
  hideGrade: boolean;
  isClassDashboard: boolean;
  isFullSizeEnabled: boolean;
  handleFullSizeClick: () => void;
  reloadData: () => void;
  classId?: string;
};

function TPStudentResultsPanelOrganism({
  result,
  showAlphabeticGrade,
  hideGrade,
  isClassDashboard,
  isFullSizeEnabled,
  handleFullSizeClick,
  reloadData,
  classId,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [isGroupFeedbackOpen, setIsGroupFeedbackOpen] = useState(false);

  const resultsPerQuestionPath = useContextNavigation(
    `/resultaten-per-vraag/${result.planningId}${classId ? `/${classId}` : ''}`
  );

  const sendGroupFeedback = (feedback: string) => {
    const studentsFeedback: StudentsFeedback = {
      resultIds: result.studentResults.map((student: ResultDashboardStudent) => student.resultId),
      value: feedback,
    };
    createRequest(MethodType.Update, `${webApiUrl}result/feedback`, studentsFeedback).then(() => {
      setIsGroupFeedbackOpen(false);
      reloadData();
    });
  };

  const getGradeOfStudent = (student: ResultDashboardStudent): string => {
    if (hideGrade) {
      return '-';
    }
    return showAlphabeticGrade ? student.alphabeticGrade : formatGradeValue(student.grade);
  };

  const getStudentResultAsAnExportableArray = (): string => {
    const separator = ';';
    const newLine = '\n';

    const domains = getDistinctDomains(result.studentResults);
    const ktiValues = getDistinctKti(result.studentResults);

    // Static info: title and date
    const csvIntro = `${result.title}${newLine}${formatToLocalDate(result.date)}${newLine}`;

    // Static part of header texts
    const csvHeader = `${t('resultsPage:first_name')}${separator}${t(
      'resultsPage:last_name'
    )}${separator}${t('resultsPage:class')}${separator}${
      showAlphabeticGrade ? t('resultsPage:result') : t('resultsPage:grade')
    }${separator}${t('resultsPage:score')}`;

    // Dynamic part of header texts (domain and cognitive levels)
    let csvDomainHeader = '';
    let csvKtiHeader = '';
    domains.forEach(domain => {
      csvDomainHeader = csvDomainHeader.concat(`${separator}${domain}`);
    });
    ktiValues.forEach(kti => {
      csvKtiHeader = csvKtiHeader.concat(`${separator}${t(`resultsPage:${kti}`)}`);
    });

    let csv = `${csvIntro}${csvHeader}${csvDomainHeader}${csvKtiHeader}${newLine}`;

    // Add student data rows
    result.studentResults.forEach(student => {
      csv = csv.concat(
        `${student.firstName}${separator}${formatLastName(
          student.lastName,
          student.prefix
        )}${separator}${student.groupName}${separator}${getGradeOfStudent(student)}${separator}${
          student.score
        }%`
      );
      // add domain and cognitive scores of a student to the row
      domains.forEach(domainKey => {
        csv = csv.concat(`${separator}${findAndFormatDomainScoreByKey(student, domainKey, false)}`);
      });
      ktiValues.forEach(ktiKey => {
        csv = csv.concat(`${separator}${findAndFormatDomainScoreByKey(student, ktiKey, true)}`);
      });
      csv = csv.concat(`${newLine}`);
    });

    return csv;
  };

  const downloadResultsAsCsv = () => {
    const csv = getStudentResultAsAnExportableArray();
    const fileName = `${result.title}, ${
      isClassDashboard ? `${result.studentResults[0].groupName}, ` : ''
    }${formatToLocalDate(result.date)}`;
    autoDownloadCsvFile(csv, fileName);
  };

  return (
    <div className="result-panel">
      <div className="result-panel__header">
        <div className="header-title">
          <h2>{t('resultsPage:results')}</h2>
          <span className="table-details">
            {`${t('resultsPage:results_from')} ${result.studentResults.length} ${
              result.studentResults.length > 1
                ? t('resultsPage:students')
                : t('resultsPage:student')
            }.`}
          </span>
        </div>
        <div className="header-actions">
          <Link
            className={`tp-button tp-button-inverse navigation-button ${
              result.status !== Status.Released && result.status !== Status.NotReleased
                ? 'dashboard-link-disabled tp-button:disabled'
                : ''
            }`}
            to={resultsPerQuestionPath}
          >
            <ILIconAtom type={IconType.Eye} />
            {t('resultsPage:view_answers')}
          </Link>
          <button
            className="tp-button tp-button-primary tp-button-with-icon feedback-button"
            onClick={() => setIsGroupFeedbackOpen(!isGroupFeedbackOpen)}
          >
            <ILIconAtom type={IconType.Feedback} size={18} />
            <span>{t('resultsPage:group_feedback')}</span>
          </button>
          <button
            className="tp-button tp-button-primary tp-button-with-icon download-button"
            onClick={() => downloadResultsAsCsv()}
          >
            <ILIconAtom type={IconType.CsvFile} size={20} />
            <span>{t('resultsPage:download_results')}</span>
          </button>
          {isFullSizeEnabled && (
            <button
              className="tp-button tp-button-inverse tp-button-icon-big close-button"
              onClick={handleFullSizeClick}
            >
              <ILIconAtom type={IconType.Close} size={14} />
            </button>
          )}
        </div>
      </div>
      {isGroupFeedbackOpen && (
        <TPFeedbackMolecule
          sendFeedback={sendGroupFeedback}
          instruction={t('resultsPage:feedback_instruction')}
        />
      )}
      {result.status !== Status.NotReleased && result.status !== Status.Released && (
        <div className="result-panel__alert-container">
          <ILAlertMessageMolecule
            type={AlertType.PayAttention}
            message={t('resultsPage:provisional_scores_alert')}
          />
        </div>
      )}
      <div className="tab-table-container">
        <ILTabsOrganism>
          <ILTabMolecule key="domains" name={t('resultsPage:domains')}>
            <div className="result-panel__table-container">
              <TPStudentResultsTable
                data={result.studentResults}
                planningId={result.planningId}
                showAlphabeticGrade={showAlphabeticGrade}
                hideGrade={hideGrade}
                isKti={false}
                status={result.status}
                isFullScreen={isFullSizeEnabled}
                isClassDashboard={isClassDashboard}
                openFullScreen={handleFullSizeClick}
                reloadData={reloadData}
              />
            </div>
          </ILTabMolecule>
          {result.assignmentType !== AssignmentType.ReferenceTest ? (
            <ILTabMolecule key="kti" name={t('resultsPage:cognitive_level')}>
              <div className="result-panel__table-container">
                <TPStudentResultsTable
                  data={result.studentResults}
                  planningId={result.planningId}
                  showAlphabeticGrade={showAlphabeticGrade}
                  hideGrade={hideGrade}
                  isKti={true}
                  status={result.status}
                  isFullScreen={isFullSizeEnabled}
                  isClassDashboard={isClassDashboard}
                  openFullScreen={handleFullSizeClick}
                  reloadData={reloadData}
                />
              </div>
            </ILTabMolecule>
          ) : (
            <></>
          )}
        </ILTabsOrganism>
      </div>
    </div>
  );
}

export default TPStudentResultsPanelOrganism;
