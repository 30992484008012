import { Dispatch } from 'redux';
import { getData } from '../../components/ILAjaxEther';
import { webApiUrl } from '../../api/settingsLoader';
import { Notification } from '../../models/state/Notification';

export enum Actions {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS',
}

export interface ReceiveNotificationsAction {
  notifications: Notification[];
  type: Actions.RECEIVE_NOTIFICATIONS;
}

export const receiveNotifications = (
  notifications: Notification[]
): ReceiveNotificationsAction => ({
  notifications,
  type: Actions.RECEIVE_NOTIFICATIONS,
});

export const fetchNotifications = (methodId: string, selectedSchoolId: string) => (
  dispatch: Dispatch
): void => {
  if (methodId && selectedSchoolId) {
    getData(`${webApiUrl}notification/${methodId}/${selectedSchoolId}`).then(data => {
      dispatch(receiveNotifications(data));
    });
  }
};
