import React, { ReactElement, useEffect, useState } from 'react';
import { AssignmentType } from '../../../../../models/enums/AssignmentType';
import { QuestionResult, QuestionResults } from '../../../../../models/dto/QuestionResults';
import { getData } from '../../../../../components/ILAjaxEther';
import { webApiUrl } from '../../../../../api/settingsLoader';
import TPQuestionResultsTableOrganism from './TPQuestionResultsTableOrganism';

type Props = {
  testId: string;
  resultId: string;
  assignmentType: AssignmentType;
};

function TPQuestionResultsTableOrganismContainer({
  testId,
  resultId,
  assignmentType,
}: Props): ReactElement {
  const [questionResults, setQuestionResults] = useState<QuestionResult[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (resultId && testId) {
      setIsLoading(true);
      getData(`${webApiUrl}dashboard/questionResultDetails/${testId}/${resultId}`).then(
        (response: QuestionResults) => {
          setQuestionResults(response.results);
          setIsLoading(false);
        }
      );
    }
  }, [resultId, testId]);

  return (
    <TPQuestionResultsTableOrganism
      questionResults={questionResults}
      isRefTest={assignmentType === AssignmentType.ReferenceTest}
      isLoading={isLoading}
    />
  );
}

export default TPQuestionResultsTableOrganismContainer;
