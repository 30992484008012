import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TableColumn,
  TableDataType,
  TableFilterType,
  TableHeaderType,
} from '../../../../../components/ILTableOrganism/ILTableColumn/createColumns';
import TPResultsOverviewOptionsMolecule from '../../../common/TPResultsOverviewOptionsMolecule';
import { testMomentsColumn } from '../../../utils/resultColumns';
import { GroupResult } from '../../../../../models/dto/GroupResult';
import ILTableOrganismContainer from '../../../../../components/ILTableOrganism';

type Props = {
  tableData: GroupResult[];
  isLoading: boolean;
  openLastResults: (id: string, lastResult: string) => void;
  goToAllResults: (id: string, name: string) => void;
};

function TPClassesOverviewTable({
  tableData,
  isLoading,
  openLastResults,
  goToAllResults,
}: Props): ReactElement {
  const { t } = useTranslation();

  const renderClassesResultsActions = (rowData: GroupResult): JSX.Element => (
    <TPResultsOverviewOptionsMolecule
      id={rowData.groupId}
      name={rowData.groupName}
      lastPlanId={rowData.mostRecentPlanningId}
      openLastResult={openLastResults}
      goToAllResults={goToAllResults}
    />
  );

  const classesResultsColumns: TableColumn[] = [
    {
      key: 'groupName',
      headerTitle: t('resultsPage:class'),
      columnData: TableDataType.Text,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.Text,
      filterDefaultText: t('resultsPage:class_name'),
    },
    testMomentsColumn(t),
    {
      key: 'options',
      columnData: TableDataType.Custom,
      header: TableHeaderType.Empty,
      filter: TableFilterType.None,
      customCellRenderer: renderClassesResultsActions,
      width: 500,
    },
  ];

  return (
    <ILTableOrganismContainer
      data={tableData}
      id="classes-overview"
      columns={classesResultsColumns}
      isLoading={isLoading}
      noDataText={t('resultsPage:no_results')}
      sortable={true}
      filterable={true}
      shouldReload={true}
    />
  );
}

export default TPClassesOverviewTable;
