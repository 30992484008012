import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { formatFloatValueWithDigits } from '../../utils/DataFormatter';
import './style.scss';

type Props = {
  completed: number;
};

function ILProgressBarMolecule({ completed }: Props): ReactElement {
  const { t } = useTranslation();
  const validatedValue = Math.min(Math.max(completed, 0), 100);
  const fillStyle = {
    width: `${validatedValue}%`,
  };

  return (
    <div className="progress-bar-wrapper">
      <div className="progress-bar">
        <div className="progress-slide" style={fillStyle} />
      </div>
      <p className="progress-bar-label">
        <b>{`${formatFloatValueWithDigits(validatedValue, 0)}%`}</b>
        {` ${t('progressBar:completed')}`}
      </p>
    </div>
  );
}

export default ILProgressBarMolecule;
