import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ILTableOrganism from '../../../../components/ILTableOrganism';
import {
  TableColumn,
  TableDataType,
  TableFilterType,
  TableHeaderType,
} from '../../../../components/ILTableOrganism/ILTableColumn/createColumns';
import {
  mapDistinctClassToTableOption,
  mapGroupsToString,
} from '../../../../utils/DataObjectMapper';
import { IconType } from '../../../../components/ILIconAtom';
import { PlanningResult } from '../../../../models/dto/PlanningResult';
import { Status } from '../../../../components/TPStatusAtom/Status';
import {
  dateColumn,
  gradeColumn,
  scoreColumn,
  statusColumn,
  testTitleColumn,
} from '../../utils/resultColumns';
import { filterBySelectedTextValue } from '../../../../components/ILTableOrganism/utils/rowFiltering';
import ILTableButtonAtom from '../../../../components/ILTableOrganism/ILTableButtonAtom';
import TPGroupHoverListMolecule from '../../../../components/TPGroupHoverListMolecule';

type Props = {
  tableData: PlanningResult[];
  openResult: (planId: string) => void;
  isLoading: boolean;
};

function TPAllResultsTable({ tableData, openResult, isLoading }: Props): ReactElement {
  const { t } = useTranslation();

  const renderGroups = (rowData: PlanningResult): JSX.Element => (
    <TPGroupHoverListMolecule groups={rowData.groups} />
  );

  const renderResultsActions = (rowData: PlanningResult): JSX.Element =>
    rowData.status !== Status.Started || !rowData.isAvailable ? (
      <ILTableButtonAtom
        type={IconType.Stats}
        title={t('resultsPage:view_results')}
        handleClick={() => openResult(rowData.planningId)}
      />
    ) : (
      <></>
    );

  const resultsColumns: TableColumn[] = [
    testTitleColumn(t),
    dateColumn(t),
    {
      key: 'groups',
      headerTitle: t('resultsPage:class'),
      columnData: TableDataType.Custom,
      header: TableHeaderType.NotSortable,
      filter: TableFilterType.CustomSelect,
      filterDefaultText: t('resultsPage:all_classes'),
      customFilterOptions: mapDistinctClassToTableOption(tableData),
      customFilterMethod: filterBySelectedTextValue,
      customAccessor: (rowData: PlanningResult) => mapGroupsToString(rowData.groups),
      customCellRenderer: renderGroups,
    },
    statusColumn(t, false),
    gradeColumn(t),
    scoreColumn(t, true),
    {
      key: 'options',
      columnData: TableDataType.Custom,
      header: TableHeaderType.Empty,
      filter: TableFilterType.None,
      width: 80,
      customCellRenderer: renderResultsActions,
    },
  ];

  return (
    <ILTableOrganism
      data={tableData}
      isLoading={isLoading}
      columns={resultsColumns}
      id="all-results"
      noDataText={t('resultsPage:no_results')}
      sortable={true}
      filterable={true}
      shouldReload={true}
      defaultSorted={[
        {
          id: 'date',

          desc: true,
        },
      ]}
    />
  );
}

export default TPAllResultsTable;
