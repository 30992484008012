import React, { ReactElement } from 'react';
import ILIconAtom, { IconType } from '../../ILIconAtom/ILIconAtom';
import '../../../assets/theme/tp-buttons.scss';

interface Props {
  type: IconType;
  title?: string;
  isDisabled?: boolean;
  className?: string;
  handleClick: () => void;
}

function ILTableButtonAtom({
  type,
  title,
  isDisabled,
  className,
  handleClick,
}: Props): ReactElement {
  return (
    <button
      className={`tp-button tp-button-primary-light tp-button-icon icon-button${
        className ? ` ${className}` : ''
      }`}
      disabled={!!isDisabled}
      title={title}
      onClick={() => handleClick()}
    >
      <ILIconAtom type={type} size={16} />
    </button>
  );
}

export default ILTableButtonAtom;
