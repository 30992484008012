import React, { ReactElement } from 'react';
import '../../../assets/theme/tp-buttons.scss';

type Props = {
  text: string;
  isOptional?: boolean;
  handleClick: () => void;
};

function ILAlertMessageButtonAtom({ text, isOptional, handleClick }: Props): ReactElement {
  return (
    <button
      className={`tp-button tp-button-thin${isOptional ? ' optional-button' : ''}`}
      onClick={handleClick}
    >
      {text}
    </button>
  );
}

export default ILAlertMessageButtonAtom;
