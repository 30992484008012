import React from 'react';
import './style.scss';

interface Props {
  dotSize: number;
}

function ILInlineLoader({ dotSize }: Props) {
  return (
    <div className="inline-loader">
      <div className="loader-dot" style={{ width: `${dotSize}px`, height: `${dotSize}px` }} />
      <div className="loader-dot" style={{ width: `${dotSize}px`, height: `${dotSize}px` }} />
      <div className="loader-dot" style={{ width: `${dotSize}px`, height: `${dotSize}px` }} />
    </div>
  );
}

export default ILInlineLoader;
