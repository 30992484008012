import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useReviewPlanContext } from '../../../pages/Home/ReviewPlanContext';

type Props = {
  planId: string;
};

function TPReviewNotificationAtom({ planId }: Props): ReactElement {
  const { t } = useTranslation();
  const { setPlanIdToReview } = useReviewPlanContext();

  return <button onClick={() => setPlanIdToReview(planId)}>{t('notifications:to_Grade')}</button>;
}

export default TPReviewNotificationAtom;
