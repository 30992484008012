import React, { ReactElement } from 'react';

import './CardGrid.scss';

interface Props {
  children: JSX.Element[];
}

export function CardGrid({ children }: Props): ReactElement {
  return <div className="card-grid">{children}</div>;
}
