import { gql } from '@apollo/client';

export const GET_METHOD = gql`
  query method($settingsAppId: ID!, $hostName: String!) {
    method(settingsAppId: $settingsAppId, hostName: $hostName) {
      url
      dnnUrl
      structureId
      title
      subtitle
      logoImage
      loginImage
      remindo_studyids
      didactic_level
      showLicensedTests
      hideChapterTests
      categoryIds
      categoryId
      showReadspeaker
      isMultiLingual
      arrangeTest
      showChapterDownload
      useIdp
    }
  }
`;
