import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useGetMethod } from '../../graphql/method/methodOperations';
import { identify, segmentInit, setGA, trackPage } from '../../services/AnalyticsService';
import { getToken, getSchoolId } from '../../services/JwtService';

function ILAnalyticsAtom(): ReactElement | null {
  const location = useLocation();
  const method = useGetMethod();
  const [initialized, setInitialized] = useState(false);

  const initAnalytics = useCallback(async () => {
    await segmentInit()
    setInitialized(true)
  }, [])

  useEffect(() => {
    initAnalytics()
  }, [initAnalytics]);

  useEffect(() => {
    if (initialized) {
      getToken().then(res => {
        if (res) {
          identify(res, getSchoolId(res));
        }
      });
    }
  }, [initialized]);

  useEffect(() => {
    if (method) {
      trackPage(location.pathname, method.structureId, method.title);
      setGA(location.pathname);
    }
  }, [location, method]);

  return null;
}

export default ILAnalyticsAtom;
