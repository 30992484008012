import { DomainResult } from '../models/dto/DashboardResult';

export const sortNumericallyAndParseToString = (arr: string[], separator = ', '): string =>
  arr.sort((a: string, b: string) => +a - +b).join(separator);

export const sortAlphabeticallyAndParseToString = (arr: string[], separator = ', '): string =>
  arr.sort().join(separator);

export const sortTexts = (a: string, b: string): number =>
  a.toLowerCase().localeCompare(b.toLowerCase(), 'nl-NL');

export const sortTextsWithNullCheck = (a?: string, b?: string): number => {
  if (a && b) {
    return a.toLowerCase().localeCompare(b.toLowerCase(), 'nl-NL');
  }
  if (a) {
    return -1;
  }
  if (b) {
    return 1;
  }
  return 0;
};

export const sortKti = (a: string, b: string): number => {
  const sortingArray = ['kennis', 'toepassing', 'inzicht'];
  return sortingArray.indexOf(a.toLowerCase()) - sortingArray.indexOf(b.toLowerCase());
};

export const sortByArray = (a: string, b: string, sortingArray: string[]): number =>
  sortingArray.indexOf(a) - sortingArray.indexOf(b);

export const sortDomainResult = (results: DomainResult[], isKti: boolean): DomainResult[] => {
  const sortingArray = ['KtiK', 'BloomK', 'KtiT', 'BloomT', 'KtiI', 'BloomI'];
  return isKti
    ? results.sort((a, b) => sortingArray.indexOf(a.domain) - sortingArray.indexOf(b.domain))
    : results.sort((a, b) =>
        a.domain?.toLowerCase().localeCompare(b.domain?.toLowerCase(), 'nl-NL')
      );
};
