import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

type Props = {
  feedback?: string;
  resultId?: string;
  sendFeedback: (feedback: string, resultId?: string) => void;
  instruction?: string;
  isVertical?: boolean;
  onCancel?: () => void;
};

function TPFeedbackMolecule({
  feedback,
  resultId,
  sendFeedback,
  instruction,
  isVertical,
  onCancel,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [feedbackText, setFeedbackText] = useState(feedback || '');

  const handleFeedbackChange = (text: string) => {
    if (text.length <= 255) {
      setFeedbackText(text);
    }
  };

  return (
    <div className={`feedback${isVertical ? ' feedback-vertical' : ''}`}>
      {instruction && <p className="feedback-intro">{instruction}</p>}
      <div className="feedback-container">
        <textarea
          placeholder={t('feedback:type_comments')}
          onChange={event => handleFeedbackChange(event.target.value)}
          rows={isVertical ? 5 : 4}
          value={feedbackText}
        />
        <div className="feedback-buttons">
          {onCancel && (
            <button className="tp-button tp-button-link cancel-button" onClick={onCancel}>
              {t('feedback:cancel')}
            </button>
          )}
          <button
            className="tp-button tp-button-primary"
            onClick={() => sendFeedback(feedbackText, resultId)}
          >
            {t('feedback:send_feedback')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default TPFeedbackMolecule;
