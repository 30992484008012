import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { formatToLocalDate } from '../../utils/dateTimeFormatter';
import { DashboardResult } from '../../models/dto/DashboardResult';
import { Status } from '../TPStatusAtom/Status';
import TPDashboardScoresMolecule from '../../pages/Results/ResultDashboards/common/TPDashboardScoresOrganism';
import TPStatusAtom from '../TPStatusAtom';
import './style.scss';
import '../../assets/theme/tp-table.scss';

type Props = {
  result: DashboardResult;
  className?: string;
  minimized?: boolean;
  toggleStudentModal: () => void;
};

function TPResultSummaryOrganism({
  result,
  className,
  minimized,
  toggleStudentModal,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className={className || ''}>
      <TPDashboardScoresMolecule
        isStudentDashboard={false}
        status={result.status}
        score={result.score}
        gradeType={result.gradeType}
        grade={result.grade}
      />
      <div className="result-summary-table">
        <table className="tp-table tp-table-info">
          <tbody>
            <tr>
              <td>{t('resultSummary:rounded_to')}</td>
              <td>{formatToLocalDate(result.date)}</td>
            </tr>
            <tr>
              <td>{t('resultSummary:made_by')}</td>
              <td>
                <button
                  onClick={toggleStudentModal}
                  className="tp-button tp-button-link tp-button-thin list-button"
                >
                  <b>{result.plannedParticipants - result.notStartedStudents?.length}</b>
                  {minimized
                    ? ` ${t('resultSummary:of_total_min')} `
                    : ` ${t('resultSummary:students')} ${t('resultSummary:of_total')} `}
                  <b>{result.plannedParticipants}</b>
                  {` ${t('resultSummary:students')}`}
                </button>
              </td>
            </tr>
            <tr>
              <td>{t('resultSummary:class')}</td>
              <td>{result.groupNames?.sort().join(', ')}</td>
            </tr>
            <tr>
              <td>{t('resultSummary:status')}</td>
              <td>
                <TPStatusAtom status={result.status as Status} />
              </td>
            </tr>
            {(result.status === Status.NotReleased || result.status === Status.Released) && (
              <tr>
                <td>{t('resultSummary:caesura')}</td>
                <td>
                  <span className="caesura-sum">
                    <b>{result.passedTestAmount}</b>
                    {` ${t('resultSummary:passed')}`}
                  </span>
                  <span className="caesura-sum">
                    <b>{result.failedTestAmount}</b>
                    {` ${t('resultSummary:failed')}`}
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TPResultSummaryOrganism;
