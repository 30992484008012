import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

/* reducers */
import notifications from './notifications/reducers';
import plan from './planning/reducers';
import studentGroups from './studentGroups/reducers';
import context from './context/reducer';
import tables from './tables/reducers';
import { Plan } from '../models/state/Plan';
import { Groups } from '../models/state/Groups';
import { Notification } from '../models/state/Notification';
import { Context } from '../models/state/Context';
import { Tables } from '../models/state/Tables';

export interface State {
  notifications: Notification[];
  plan: Plan;
  studentGroups: Groups[];
  context: Context;
  tables: Tables;
}

const rootReducer = combineReducers({
  notifications,
  plan,
  studentGroups,
  context,
  tables,
});

export type Store = ReturnType<typeof rootReducer>;
const composeEnhancers = composeWithDevTools({});
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
