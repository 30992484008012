import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { resetQuestionWithResultsCollections } from '../../../../graphql/questionsWithResults/questionWithResultsOperations';
import { GradeType } from '../../../../models/enums/GradeType';
import { AssignmentType } from '../../../../models/enums/AssignmentType';
import { DashboardResult } from '../../../../models/dto/DashboardResult';
import TPStudentResultsPanelOrganism from '../common/TPStudentResultsPanelOrganism';
import TPResultNotificationOrganism from '../common/TPResultNotificationOrganism';
import TPCaesuraModalOrganismContainer from '../modals/TPCaesuraModalOrganism';
import ILModalMolecule from '../../../../components/ILModalMolecule';
import TPCloseTestModalOrganismContainer from '../modals/TPCloseTestModalOrganism';
import TPStudentListModalOrganism from '../../../../components/TPResultSummaryOrganism/TPStudentListModalOrganism';
import TPResultSummaryOrganism from '../../../../components/TPResultSummaryOrganism';
import TPCloseInfoModalOrganism from '../modals/TPCloseInfoModalOrganism';
import TPDomainAverageOrganism from '../common/TPDomainAverageOrganism';
import TPReleaseModalOrganism from '../modals/TPReleaseModalOrganism';
import TPCloseErrorModalOrganism from '../modals/TPCloseErrorModalOrganism';
import TPTestReviewModalOrganism from '../../../TestReview/TPTestReviewModalOrganism';
import '../style.scss';
import '../../../../assets/theme/tp-buttons.scss';

type Props = {
  result: DashboardResult;
  isClassDashboard: boolean;
  isFullSizeEnabled: boolean;
  isCaesuraModalOpen: boolean;
  isCloseTestOpen: boolean;
  isStudentListOpen: boolean;
  isCloseInfoModalOpen: boolean;
  isReleaseModalOpen: boolean;
  isErrorModalOpen: boolean;
  isReviewModalOpen: boolean;
  classId?: string;
  udbId: string;
  reloadData: () => void;
  toggleFullSizeModal: () => void;
  toggleCaesuraModal: () => void;
  toggleCloseTestModal: () => void;
  toggleStudentListModal: () => void;
  toggleLoaderModal: () => void;
  toggleReleaseModal: () => void;
  toggleErrorModal: () => void;
  toggleReviewModal: () => void;
  releaseResults: () => void;
};

function GroupResultDashboard({
  result,
  isClassDashboard,
  isFullSizeEnabled,
  isCaesuraModalOpen,
  isCloseTestOpen,
  isStudentListOpen,
  isCloseInfoModalOpen,
  isReleaseModalOpen,
  isErrorModalOpen,
  isReviewModalOpen,
  classId,
  udbId,
  reloadData,
  toggleFullSizeModal,
  toggleCaesuraModal,
  toggleCloseTestModal,
  toggleStudentListModal,
  toggleLoaderModal,
  toggleReleaseModal,
  toggleErrorModal,
  toggleReviewModal,
  releaseResults,
}: Props): ReactElement {
  const { t } = useTranslation();
  // Remove the cached collections for results per question
  useEffect(() => {
    resetQuestionWithResultsCollections();
  }, []);

  return (
    <>
      {result.planOwnerId === udbId && (
        <TPResultNotificationOrganism
          result={result}
          closeTests={toggleCloseTestModal}
          openReviewModal={toggleReviewModal}
          adjustCaesura={toggleCaesuraModal}
          releaseResults={toggleReleaseModal}
          reload={reloadData}
        />
      )}
      <div className="top-section">
        <div className="top-panel">
          <div className="top-panel__header">
            <h2>{t('resultsPage:summary')}</h2>
          </div>
          <TPResultSummaryOrganism result={result} toggleStudentModal={toggleStudentListModal} />
        </div>

        <div className="top-panel">
          <div className="top-panel__header">
            <h2>{t('resultsPage:averages')}</h2>
          </div>
          <TPDomainAverageOrganism
            domainResults={result.domainResults}
            cognitiveResults={result.cognitiveResults}
            assignmentType={result.assignmentType}
          />
        </div>
      </div>

      {result.studentResults && (
        <div className="bottom-section">
          <TPStudentResultsPanelOrganism
            classId={classId}
            result={result}
            hideGrade={result.assignmentType === AssignmentType.ReferenceTest}
            showAlphabeticGrade={result.gradeType === GradeType.Alphabetic}
            isFullSizeEnabled={isFullSizeEnabled}
            isClassDashboard={isClassDashboard}
            handleFullSizeClick={toggleFullSizeModal}
            reloadData={reloadData}
          />
        </div>
      )}

      {result.caesura && (
        <TPCaesuraModalOrganismContainer
          isOpen={isCaesuraModalOpen}
          caesuraScores={result.caesura}
          planningId={result.planningId}
          closeModal={toggleCaesuraModal}
          reloadDashboardData={reloadData}
        />
      )}
      {result.notFinishedStudents && result.studentResults && (
        <TPCloseTestModalOrganismContainer
          isOpen={isCloseTestOpen}
          notFinishedStudents={result.notFinishedStudents}
          studentResults={result.studentResults}
          closeModal={toggleCloseTestModal}
          handleCloseTestSuccess={toggleLoaderModal}
          handleCloseTestFail={toggleErrorModal}
        />
      )}
      {result.notStartedStudents && result.studentResults && (
        <TPStudentListModalOrganism
          isOpen={isStudentListOpen}
          title={result.title}
          plannedCount={result.plannedParticipants}
          notStartedStudents={result.notStartedStudents}
          startedStudents={result.studentResults}
          closeModal={toggleStudentListModal}
        />
      )}
      <TPCloseInfoModalOrganism
        isOpen={isCloseInfoModalOpen}
        handleClose={() => {
          toggleLoaderModal();
          reloadData();
        }}
      />
      <TPCloseErrorModalOrganism
        isOpen={isErrorModalOpen}
        closeModal={() => {
          toggleErrorModal();
          reloadData();
        }}
      />
      <TPReleaseModalOrganism
        isOpen={isReleaseModalOpen}
        closeModal={toggleReleaseModal}
        releaseResult={releaseResults}
      />
      <TPTestReviewModalOrganism
        isOpen={isReviewModalOpen}
        planId={result.planningId}
        closeModal={toggleReviewModal}
      />
      {result.studentResults && (
        <ILModalMolecule
          isOpen={isFullSizeEnabled}
          isFullScreen={true}
          handleClose={toggleFullSizeModal}
        >
          <TPStudentResultsPanelOrganism
            classId={classId}
            showAlphabeticGrade={result.gradeType === GradeType.Alphabetic}
            hideGrade={result.assignmentType === AssignmentType.ReferenceTest}
            result={result}
            isFullSizeEnabled={true}
            isClassDashboard={isClassDashboard}
            handleFullSizeClick={toggleFullSizeModal}
            reloadData={reloadData}
          />
        </ILModalMolecule>
      )}
    </>
  );
}

export default GroupResultDashboard;
