import React, { ReactElement } from 'react';
import { useQuery } from '@apollo/client';
import {
  GET_QUESTION_FILTER_OPTIONS,
  GET_SELECTED_QUESTION_FILTERS,
} from '../../../../graphql/arrangeTest/arrangeTestQueries';
import { client } from '../../../../graphql/ApolloClientService';
import TPQuestionFilterOrganism from './TPQuestionFilterOrganism';

function TPQuestionFilterOrganismContainer(): ReactElement | null {
  const { questionFilterOptions } = useQuery(GET_QUESTION_FILTER_OPTIONS).data;
  const { selectedQuestionFilters } = useQuery(GET_SELECTED_QUESTION_FILTERS).data;

  const updateQuestionFilters = (
    ktiFilters: string[],
    domainFilters: string[],
    questionTypeFilters: string[]
  ) => {
    client.writeQuery({
      query: GET_SELECTED_QUESTION_FILTERS,
      data: {
        selectedQuestionFilters: {
          kti: ktiFilters,
          domain: domainFilters,
          type: questionTypeFilters,
        },
      },
    });
  };

  if (questionFilterOptions) {
    return (
      <TPQuestionFilterOrganism
        updateQuestionFilters={updateQuestionFilters}
        filterOptions={questionFilterOptions}
        selectedFilters={selectedQuestionFilters}
      />
    );
  }
  return null;
}

export default TPQuestionFilterOrganismContainer;
