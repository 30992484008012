import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { formatGradeValue } from '../../utils/DataFormatter';
import { AlphabeticGradeType } from '../../models/enums/AlphabeticGradeType';
import './style.scss';

type Props = {
  value: number | string;
  isPercentage: boolean;
  hideValue?: boolean;
  className?: string;
  caesura?: number;
  notAvailable?: boolean;
  isAlphabetic?: boolean;
};

function ILScoreMeterAtom({
  value,
  isPercentage,
  hideValue,
  className,
  caesura,
  notAvailable,
  isAlphabetic,
}: Props): ReactElement {
  const { t } = useTranslation();

  const getMeterClass = (): string => {
    if (hideValue) {
      return 'meter-none';
    }
    if (notAvailable) {
      return 'meter-default';
    }
    if (isAlphabetic) {
      return value === AlphabeticGradeType.Passed ? 'meter-ok' : 'meter-nok';
    }
    if (caesura) {
      return value < caesura ? 'meter-nok' : 'meter-ok';
    }
    return 'meter-default';
  };

  const displayedValue = (): string => {
    if (hideValue) {
      return '-';
    }
    if (notAvailable) {
      return t('score:not_available');
    }
    if (isAlphabetic) {
      return `${value}`;
    }
    return isPercentage ? `${value}%` : formatGradeValue(value);
  };

  return (
    <div className={`score-container ${getMeterClass()} ${className}`}>
      <span>{displayedValue()}</span>
    </div>
  );
}

export default ILScoreMeterAtom;
