import React, { ReactElement } from 'react';
import { useQuery } from '@apollo/client';
import { useGetMethod } from '../../graphql/method/methodOperations';
import { GET_ALL_NEWS_FLASHES } from './NewsArchiveQuery';
import { AllNewsFlashesResponse } from '../../models/state/News';
import { filterNews } from '../../utils/NewsItemsFilter';
import { ILLoadingIndicator } from '../../components/ILLoadingIndicator';
import NewsArchive from './NewsArchive';
import { usePageTracking } from '../../utils/usePageTracking';

function NewsArchiveContainer(): ReactElement {
  const method = useGetMethod();
  const { data, loading } = useQuery<AllNewsFlashesResponse>(GET_ALL_NEWS_FLASHES);

  usePageTracking('teacher-material', 'news-article');

  if (loading && !method) {
    return <ILLoadingIndicator fullSize />;
  }
  if (method && data && data.allNewsFlashes?.length > 0) {
    return <NewsArchive newsItems={filterNews(data.allNewsFlashes, method)} />;
  }
  return <></>;
}
export default NewsArchiveContainer;
