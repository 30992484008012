import React, { ReactElement } from 'react';
import { PropagateLoader } from 'react-spinners';
import './style.scss';

interface Props {
  isLoading?: boolean;
  fullSize?: boolean;
}

export function ILLoadingIndicator({ isLoading, fullSize }: Props): ReactElement {
  if (fullSize) {
    return (
      <div className="loading-container">
        <div className="loading-indicator">
          <PropagateLoader loading={isLoading} sizeUnit="px" size={15} color="#007DD2" />
        </div>
      </div>
    );
  }
  return (
    <div className="loading-indicator">
      <PropagateLoader loading={isLoading} sizeUnit="px" size={15} color="#007DD2" />
    </div>
  );
}
