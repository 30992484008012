import React, { ReactElement, useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ILLoadingIndicator } from '../ILLoadingIndicator';
import './style.scss';
import '../../assets/theme/tp-buttons.scss';

export interface ConfirmModalInfo {
  itemId: string;
  display: boolean;
  showLoading: boolean;
  confirmData?: any;
}

interface Props {
  modalInfo: ConfirmModalInfo;
  title: string;
  description: string | JSX.Element;
  customAcceptText?: string;
  customCancelText?: string;
  onCancel: () => void;
  onAccept?: (data: string | any) => void;
}

function ILConfirmationModalMolecule({
  modalInfo,
  title,
  description,
  customAcceptText,
  customCancelText,
  onCancel,
  onAccept,
}: Props): ReactElement | null {
  const { t } = useTranslation();
  const modalRef = useRef(null);

  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      if (modalRef?.current === event.target) {
        onCancel();
        document.removeEventListener('click', handleOutsideClick);
      }
    },
    [onCancel]
  );

  useEffect(() => {
    if (modalInfo.display) {
      document.addEventListener('click', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [handleOutsideClick, modalInfo.display]);

  if (modalInfo.showLoading) {
    return (
      <div className="loading-fullscreen">
        <ILLoadingIndicator isLoading={modalInfo.showLoading} />
      </div>
    );
  }

  return modalInfo.display ? (
    <div className="modal display-block" ref={modalRef}>
      <section className="modal-container">
        <p className="delete-title">{title}</p>
        <p className="delete-sub">{description}</p>
        {onAccept && (
          <button
            className="tp-button tp-button-primary delete-btn"
            onClick={() =>
              modalInfo.confirmData ? onAccept(modalInfo.confirmData) : onAccept(modalInfo.itemId)
            }
          >
            {customAcceptText || t('confirm:accept')}
          </button>
        )}
        <button className="tp-button tp-button-link cancel-btn" onClick={onCancel}>
          {customCancelText || t('confirm:cancel')}
        </button>
      </section>
    </div>
  ) : null;
}

export default ILConfirmationModalMolecule;
