import React, { useState, useEffect, ReactElement } from 'react';
import { Collapse } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SelectType } from '../../models/enums/SelectType';
import ILIconAtom, { IconType } from '../ILIconAtom/ILIconAtom';
import ILSelectOptionMolecule from '../ILSelectOptionMolecule';
import './style.scss';

type Props = {
  name: string;
  options: string[];
  type: SelectType;
  translationKey?: string;
  selectedOptions: string[];
  isActive: boolean;
  addOrRemove: (value: string) => void;
};

function ILDropDownSelectOrganism({
  name,
  options,
  selectedOptions,
  addOrRemove,
  type,
  translationKey,
  isActive,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [isOpened, setIsOpened] = useState(false);

  const isSelected = (optionValue: string) =>
    selectedOptions.some((value: string) => value === optionValue);

  useEffect(() => {
    if (selectedOptions?.length > 0) {
      setIsOpened(true);
    }
    if (selectedOptions?.length === 0 && !isActive) {
      setIsOpened(false);
    }
  }, [isActive, selectedOptions]);

  if (options?.length > 0) {
    return (
      <Collapse
        className={`filter-collapse ${isOpened ? 'expanded-list' : ''}`}
        in={isOpened}
        collapsedHeight="49px"
      >
        <div className="filter-collapse__header" onClick={() => setIsOpened(!isOpened)}>
          <span className="filter-collapse__header__title">{name}</span>
          <ILIconAtom type={IconType.Expand} size={10} />
        </div>
        <div className="filter-collapse__options">
          {options.map(option => (
            <div key={option} className="option-item">
              <ILSelectOptionMolecule
                id={option}
                type={type}
                label={translationKey ? t(`${translationKey}:${option}`) : option}
                isSelected={isSelected(option)}
                handleCheckboxChange={addOrRemove}
              />
            </div>
          ))}
        </div>
      </Collapse>
    );
  }
  return <></>;
}

export default ILDropDownSelectOrganism;
