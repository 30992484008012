/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
import React, { useEffect } from 'react';
import { LoginContainer } from '@infinitaslearning/il.authentication.avatar';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useGetMethod } from '../../graphql/method/methodOperations';
import { idpConfig } from '../../services/AuthenticationService';
import { toggleDisplayMenuAction } from '../../store/context/actions';
import { getLogo } from '../../services/logoService';
import { LogoType } from '../../models/enums/LogoType';

import './style.scss';
import { usePageTracking } from '../../utils/usePageTracking';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchToggleDisplayMenuAction: (displayMenu: boolean) => {
    dispatch(toggleDisplayMenuAction(displayMenu));
  },
});

interface Props {
  dispatchToggleDisplayMenuAction: (displayMenu: boolean) => void;
}

function Login({ dispatchToggleDisplayMenuAction }: Props) {
  const method = useGetMethod();

  usePageTracking('home', 'login');

  useEffect(() => {
    dispatchToggleDisplayMenuAction(false);
    return () => {
      dispatchToggleDisplayMenuAction(true);
    };
  }, [dispatchToggleDisplayMenuAction]);
  const loginConfig = {
    ...idpConfig,
    image: method?.loginImage ? require(`../../images/${method?.loginImage}`) : '',
    logo: method?.logoImage ? getLogo(method?.logoImage as LogoType) : '',
  };

  return (
    <div className="main main-no-title login-page">
      <LoginContainer {...loginConfig} />
    </div>
  );
}
export default connect(null, mapDispatchToProps)(Login);
