import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GivenAnswer,
  QuestionWithAnswer,
  QuestionWithAnswerBlock,
  ReviewTest,
} from '../../../models/dto/ReviewTest';
import { QuestionType } from '../../../models/state/Question';
import { getMaxPoints } from '../utils/reviewDataMapper';
import { formatName } from '../../../utils/NameFormatter';
import ILIconAtom, { IconType } from '../../../components/ILIconAtom';
import TPSidebarMolecule from '../common/TPSidebarMolecule';
import TPReviewExpandableBlockOrganism from '../common/TPReviewExpandableBlockOrganism';
import TPReviewStudentBlockOrganism from '../common/TPReviewExpandableBlockOrganism/TPReviewBlocks/TPReviewStudentBlockOrganism';
import TPFinishReviewModalOrganism from '../common/TPFinishReviewModalOrganism';
import TPReviewQuestionInfoOrganism from '../common/TPReviewQuestionInfoOrganism';
import TPSidebarToggleAtom from '../common/TPSidebarMolecule/TPSidebarToggleAtom';
import ILQuestionItemMolecule from '../../../components/ILQuestionItemMolecule/ILQuestionItemMolecule';
import '../style.scss';

type Props = {
  reviewTest: ReviewTest;
  selectedQuestionIndex: number;
  isSidebarOpen: boolean;
  changeSelectedQuestion: (newSelected: number) => void;
  toggleSidebar: () => void;
  finishReview: () => void;
};

function ReviewPerQuestions({
  reviewTest,
  selectedQuestionIndex,
  isSidebarOpen,
  changeSelectedQuestion,
  toggleSidebar,
  finishReview,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [isFinishModalOpen, setIsFinishModalOpen] = useState(false);
  const [useStickyBlock, setUseStickyBlock] = useState(false);
  const [displayQuestion, setDisplayQuestion] = useState(true);
  const [displayAnswer, setDisplayAnswer] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const answerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setUseStickyBlock(false);
    setDisplayQuestion(true);
    setDisplayAnswer(false);
  }, [selectedQuestionIndex]);

  const handleStickyScroll = () => {
    if (contentRef.current?.scrollTop && answerRef.current?.offsetTop) {
      if (!useStickyBlock && contentRef.current?.scrollTop >= answerRef.current?.offsetTop) {
        setUseStickyBlock(true);
      } else if (useStickyBlock && contentRef.current?.scrollTop < answerRef.current?.offsetTop) {
        setUseStickyBlock(false);
      }
    }
  };

  const scrollTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  };

  const renderCorrectAnswerBlock = (): JSX.Element => (
    <TPReviewQuestionInfoOrganism
      isOpen={displayAnswer}
      title={`${t('reviewPage:model_answer')}`}
      closeText={t('reviewPage:close_answer')}
      openText={t('reviewPage:display_answer')}
      toggleOpen={() => setDisplayAnswer(!displayAnswer)}
      shouldStick
    >
      <div className="correct-answer">
        <h4>{t('reviewPage:correct_answer')}</h4>
        <div
          dangerouslySetInnerHTML={{
            __html:
              reviewTest.questionsWithAnswers[selectedQuestionIndex].givenAnswers[0].correctAnswer,
          }}
        />
      </div>
    </TPReviewQuestionInfoOrganism>
  );

  const renderMaxPoints = (): JSX.Element => {
    const maxPoints = getMaxPoints(
      reviewTest.questionsWithAnswers[selectedQuestionIndex].givenAnswers[0].candidateResponses
    );

    return (
      <p className="max-score">
        <b>{`${t('reviewPage:max')}: `}</b>
        {`${maxPoints} ${maxPoints === 1 ? t('reviewPage:point') : t('reviewPage:points')}`}
      </p>
    );
  };

  return (
    <div className={`review-container ${isSidebarOpen ? 'sidebar-open' : ''}`}>
      <TPSidebarMolecule
        percentageReviewed={reviewTest.percentageReviewed}
        items={reviewTest.questionsWithAnswers.map(
          (question: QuestionWithAnswer) => ({
            title: `${t('reviewPage:question')} ${question.number}`,
            progress: question.progress,
          })
        )}
        selectedItemIndex={selectedQuestionIndex}
        isOpen={isSidebarOpen}
        handleItemSelection={changeSelectedQuestion}
        handleFinishClick={() => setIsFinishModalOpen(!isFinishModalOpen)}
      />
      <div ref={contentRef} className="review-content" onScroll={() => handleStickyScroll()}>
        <TPSidebarToggleAtom isOpen={isSidebarOpen} toggle={toggleSidebar} />
        <div className={`model-answer${useStickyBlock ? ' model-answer-display' : ''}`}>
          {renderCorrectAnswerBlock()}
        </div>
        <div className={`review${isSidebarOpen ? '' : ' review__full'}`}>
          <TPReviewQuestionInfoOrganism
            isOpen={displayQuestion}
            title={`${t('reviewPage:question')} ${reviewTest.questionsWithAnswers[selectedQuestionIndex].number}`}
            closeText={t('reviewPage:close_question')}
            openText={t('reviewPage:display_question')}
            toggleOpen={() => setDisplayQuestion(!displayQuestion)}
          >
            <div className="question-info">
              {renderMaxPoints()}
              <ILQuestionItemMolecule
                type={QuestionType.ExtendedText}
                blocks={reviewTest.questionsWithAnswers[selectedQuestionIndex].blocks.map(
                  (block: QuestionWithAnswerBlock, index: number) => ({
                    identifier: `${reviewTest.questionsWithAnswers[selectedQuestionIndex].id}_block_${index}`,
                    type: block.type,
                    html: block.html,
                  })
                )}
              />
            </div>
          </TPReviewQuestionInfoOrganism>
          <div ref={answerRef}>{renderCorrectAnswerBlock()}</div>
          {reviewTest.questionsWithAnswers[selectedQuestionIndex].givenAnswers.map(
            (givenAnswer: GivenAnswer) => (
              <TPReviewExpandableBlockOrganism
                key={givenAnswer.itemCheckId}
                title={formatName(givenAnswer.firstName, givenAnswer.lastName, givenAnswer.prefix)}
                isReviewed={givenAnswer.isReviewed}
                giveFeedbackEnabled={false}
              >
                <TPReviewStudentBlockOrganism
                  candidateResponses={givenAnswer.candidateResponses}
                  itemCheckId={givenAnswer.itemCheckId}
                />
              </TPReviewExpandableBlockOrganism>
            )
          )}
        </div>
        <div className="scroll-up" onClick={() => scrollTop()}>
          <ILIconAtom type={IconType.Up} size={14} />
        </div>
      </div>
      <TPFinishReviewModalOrganism
        isOpen={isFinishModalOpen}
        closeModal={() => setIsFinishModalOpen(false)}
        finishReview={finishReview}
      />
    </div>
  );
}

export default ReviewPerQuestions;
