import React, { ReactElement, useState } from 'react';
import { useMutation } from '@apollo/client';
import { ExecutionResult } from 'graphql/execution';
import { ABORT_SUBSCRIPTION } from '../../../resultsMutations';
import {
  AbortSubscriptionRequest,
  AbortSubscriptionResponse,
} from '../../../../../models/dto/AbortSubscription';
import { ResultGroupUser } from '../../../../../models/dto/PlanningResult';
import { ResultDashboardStudent } from '../../../../../models/dto/DashboardResult';
import TPCloseTestModalOrganism from './TPCloseTestModalOrganism';

type Props = {
  isOpen: boolean;
  notFinishedStudents: ResultGroupUser[];
  studentResults: ResultDashboardStudent[];
  handleCloseTestSuccess: () => void;
  handleCloseTestFail: () => void;
  closeModal: () => void;
};

function TPCloseTestModalOrganismContainer({
  isOpen,
  notFinishedStudents,
  studentResults,
  handleCloseTestSuccess,
  handleCloseTestFail,
  closeModal,
}: Props): ReactElement {
  const [abortSubscription, { loading }] = useMutation<
    AbortSubscriptionResponse,
    AbortSubscriptionRequest
  >(ABORT_SUBSCRIPTION);
  const [selectedStudents, setSelectedStudents] = useState<string[]>([]);

  const toggleSelectedStudents = (id: string) => {
    if (selectedStudents.some((selected: string) => selected === id)) {
      setSelectedStudents(selectedStudents.filter((selected: string) => selected !== id));
    } else {
      setSelectedStudents([...selectedStudents, id]);
    }
  };

  const selectAll = () => {
    if (selectedStudents.length !== notFinishedStudents.length) {
      setSelectedStudents(notFinishedStudents.map((student: ResultGroupUser) => student.udbId));
    } else {
      setSelectedStudents([]);
    }
  };

  const closeTests = () => {
    if (selectedStudents.length > 0) {
      const resultIds = studentResults
        .filter(studentResult => selectedStudents.some(id => id === studentResult.udbId))
        .map(results => results.resultId);
      if (resultIds) {
        abortSubscription({ variables: { resultIds } })
          .then((response: ExecutionResult<AbortSubscriptionResponse>) => {
            closeModal();
            if (response?.data?.abortSubscription.success) {
              handleCloseTestSuccess();
            } else {
              handleCloseTestFail();
            }
          })
          .catch(() => {
            closeModal();
            handleCloseTestFail();
          });
      }
    }
  };

  return (
    <TPCloseTestModalOrganism
      isOpen={isOpen}
      isLoading={loading}
      notFinishedStudents={notFinishedStudents}
      selectedStudents={selectedStudents}
      handleSelection={toggleSelectedStudents}
      handleSelectAll={selectAll}
      closeModal={closeModal}
      closeTest={closeTests}
    />
  );
}

export default TPCloseTestModalOrganismContainer;
