import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { webApiUrl } from '../../../../api/settingsLoader';
import { getData } from '../../../../components/ILAjaxEther';
import { DashboardResult } from '../../../../models/dto/DashboardResult';
import { Status } from '../../../../components/TPStatusAtom/Status';
import { ILLoadingIndicator } from '../../../../components/ILLoadingIndicator';
import Breadcrumb, { BreadcrumbPart } from '../../../../components/Breadcrumb/Breadcrumb';
import { useNlpRoutes } from '../../../../utils/nlpNavigation';
import GroupResultDashboard from './GroupResultDashboard';
import { useGetAuthenticated } from '../../../../graphql/authenticated/authenticatedOperations';
import { usePageTracking } from '../../../../utils/usePageTracking';
import { getPendingPlans } from '../../../../graphql/reviewedPlans/reviewedPlansOperations';

interface Props {
  isReviewOpen?: boolean;
}

function GroupResultDashboardContainer({ isReviewOpen }: Props): ReactElement {
  const { t } = useTranslation();
  const { selectedSchoolId, udbId } = useGetAuthenticated();
  const location = useLocation();
  const { classId, planId } = useParams();

  const [result, setResult] = useState<DashboardResult>();
  const [isLoading, setIsLoading] = useState(false);
  const [isFullSizeEnabled, setIsFullSizeEnabled] = useState(false);
  const [isCaesuraOpen, setIsCaesuraOpen] = useState(false);
  const [isCloseTestOpen, setIsCloseTestOpen] = useState(false);
  const [isStudentListOpen, setIsStudentListOpen] = useState(false);
  const [isCloseInfoModalOpen, setIsCloseInfoModalOpen] = useState(false);
  const [isReleaseModalOpen, setIsReleaseModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);

  const nlpClassedOverviewUrl = useNlpRoutes('classesOverview');
  const nlpClassResultsUrl = useNlpRoutes(
    'classResults',
    classId || result?.studentResults[0].groupId
  );
  const nlpAllResultsUrl = useNlpRoutes('allResults');

  usePageTracking('result', 'dashboard', planId);

  const isClassResult = useCallback(() => location.pathname.includes('/resultaat/klas/'), [
    location,
  ]);

  const handleReviewModalInit = useCallback(
    (status: Status) => {
      if (!!isReviewOpen && status === Status.NotReviewed) {
        const pendingPlans = getPendingPlans();
        const isPending = pendingPlans.includes(planId);
        setIsReviewModalOpen(!isPending);
      }
    },
    [planId, isReviewOpen]
  );

  const fetchDashboardData = useCallback(() => {
    setIsLoading(true);
    getData(
      `${webApiUrl}dashboard/${selectedSchoolId}/${planId}${isClassResult() ? `/${classId}` : ''}`
    )
      .then((response: DashboardResult) => {
        setResult(response);
        setIsLoading(false);
        handleReviewModalInit(response.status);
      })
      .catch(() => setIsLoading(false));
  }, [selectedSchoolId, planId, isClassResult, classId, handleReviewModalInit]);

  useEffect(() => {
    if (selectedSchoolId && planId) {
      fetchDashboardData();
    }
  }, [fetchDashboardData, planId, selectedSchoolId]);

  const getDashboardTitle = (): string => {
    if (isClassResult()) {
      return `${t('resultsPage:result')} ${result?.groupNames[0] || t('resultsPage:class')}${
        result?.title ? ` - ${result?.title}` : ''
      }`;
    }
    return `${t('resultsPage:result')} ${result?.title || ''}`;
  };

  const releaseResults = () => {
    if (result) {
      getData(
        `${webApiUrl}result/releaseResults${
          isClassResult() ? `/${classId || result.studentResults[0].groupId}` : ''
        }/${result.planningId}`
      ).then(() => {
        fetchDashboardData();
        setIsReleaseModalOpen(false);
      });
    }
  };

  const setBreadcrumbs = (): BreadcrumbPart[] => {
    if (isLoading) {
      return [];
    }
    return isClassResult()
      ? [
          {
            name: t('resultsPage:test_results_classes'),
            to: '/resultaten/klassen',
            nlpUrl: nlpClassedOverviewUrl,
          },
          {
            name: result?.groupNames[0] || '',
            to: `/resultaten/klas/${classId || result?.studentResults[0].groupId}`,
            state: {
              name: result?.groupNames[0],
            },
            nlpUrl: nlpClassResultsUrl,
          },
          {
            name: getDashboardTitle() || '',
          },
        ]
      : [
          {
            name: t('resultsPage:all_results_title'),
            to: '/resultaten/alle',
            nlpUrl: nlpAllResultsUrl,
          },
          {
            name: getDashboardTitle() || '',
          },
        ];
  };

  return (
    <div className="main">
      <Breadcrumb crumbs={setBreadcrumbs()} />
      <h1>{getDashboardTitle()}</h1>
      {!isLoading && result ? (
        <GroupResultDashboard
          udbId={udbId}
          result={result}
          isClassDashboard={isClassResult()}
          isFullSizeEnabled={isFullSizeEnabled}
          isCaesuraModalOpen={isCaesuraOpen}
          isCloseTestOpen={isCloseTestOpen}
          isStudentListOpen={isStudentListOpen}
          isCloseInfoModalOpen={isCloseInfoModalOpen}
          isReleaseModalOpen={isReleaseModalOpen}
          isErrorModalOpen={isErrorModalOpen}
          isReviewModalOpen={isReviewModalOpen}
          classId={isClassResult() ? classId || result?.studentResults[0].groupId : undefined}
          reloadData={fetchDashboardData}
          toggleFullSizeModal={() => setIsFullSizeEnabled(!isFullSizeEnabled)}
          toggleCaesuraModal={() => setIsCaesuraOpen(!isCaesuraOpen)}
          toggleCloseTestModal={() => setIsCloseTestOpen(!isCloseTestOpen)}
          toggleStudentListModal={() => setIsStudentListOpen(!isStudentListOpen)}
          toggleLoaderModal={() => setIsCloseInfoModalOpen(!isCloseInfoModalOpen)}
          toggleReleaseModal={() => setIsReleaseModalOpen(!isReleaseModalOpen)}
          toggleErrorModal={() => setIsErrorModalOpen(!isErrorModalOpen)}
          toggleReviewModal={() => setIsReviewModalOpen(!isReviewModalOpen)}
          releaseResults={releaseResults}
        />
      ) : (
        <ILLoadingIndicator fullSize />
      )}
    </div>
  );
}

export default GroupResultDashboardContainer;
