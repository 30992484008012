import { GET_QUESTIONS_WITH_RESULTS_COLLECTION_FRAGMENT } from './getQuestionsWithResultsQuery';
import { cache } from '../cache';
import { QuestionsWithResultCollection } from '../../models/dto/QuestionWithResult';

export const getQuestionsWithResultsFromCollection = (
  resultId: string
): QuestionsWithResultCollection | null =>
  cache.readFragment<QuestionsWithResultCollection>({
    id: `QuestionWithResultsCollection:{"resultId":"${resultId}"}`,
    fragment: GET_QUESTIONS_WITH_RESULTS_COLLECTION_FRAGMENT,
  });

export const resetQuestionWithResultsCollections = (): void => {
  cache.evict({ fieldName: 'questionsWithResultsList' });
  cache.gc();
};
