import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CriteriaValue } from '../../../../../models/dto/ReviewTest';
import './style.scss';

type Props = {
  criteria: CriteriaValue;
  responseIndex: number;
  updateCriteriaScore: (key: string, newScore: number, responseIndex: number) => void;
};

function TPReviewScoreMolecule({
  criteria,
  updateCriteriaScore,
  responseIndex,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [score, setScore] = useState<number>();

  useEffect(() => {
    if (criteria.givenScore !== null) {
      setScore(criteria.givenScore);
    } else if (criteria.correctorScore !== null) {
      setScore(criteria.correctorScore);
    }
  }, [criteria]);

  const updateScore = (newScore: number) => {
    setScore(newScore);
    updateCriteriaScore(criteria.paraginKey, newScore, responseIndex);
  };

  const decreasePoint = () => {
    if (score !== undefined && score !== criteria.minScore) {
      updateScore(score - 1);
    }
  };

  const increasePoint = () => {
    if (score === undefined) {
      updateScore(criteria.minScore);
    } else if (score < criteria.maxScore) {
      updateScore(score + 1);
    }
  };

  return (
    <div className="scoring-row">
      <span className="scoring-title">{criteria.interpretation}</span>
      <div className="scoring">
        <span className="max-score-label">
          {`${t('reviewPage:max_score')}: ${criteria.maxScore}`}
        </span>
        <div className="point-selector">
          <div className="point-button point-button-minus" onClick={() => decreasePoint()}>
            -
          </div>
          <input
            className="given-points"
            type="text"
            value={score}
            placeholder={t('reviewPage:rate_answer')}
            readOnly
          />
          <div className="point-button point-button-plus" onClick={() => increasePoint()}>
            +
          </div>
        </div>
      </div>
    </div>
  );
}

export default TPReviewScoreMolecule;
