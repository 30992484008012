import React, { useCallback, useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { webApiUrl } from '../../api/settingsLoader';
import { toggleDisplayFooterAction } from '../../store/context/actions';
import { useGetMethod } from '../../graphql/method/methodOperations';
import { createRequest } from '../ILAjaxEther';
import { formatToLocalDate } from '../../utils/dateTimeFormatter';
import { MethodType } from '../ILAjaxEther/MethodType';
import { TPIframeMessage } from '../../models/dto/TPIframeMessage';
import { CLOSE_REMINDO } from '../../constants/IFrameMessageConstants';
import { RemindoCode } from './RemindoCode';
import { ILLoadingIndicator } from '../ILLoadingIndicator';
import ILRemindoPlayerOrganism from './ILRemindoPlayerOrganism';
import ILErrorModalMolecule from '../ILModalMolecule/ILErrorModalMolecule';
import ILRemindoCodeModalMolecule from './ILRemindoCodeModalMolecule';
import { useGetAuthenticated } from '../../graphql/authenticated/authenticatedOperations';

interface Props {
  dispatchToggleDisplayFooterAction: (displayFooter: boolean) => void;
  planId: string;
  title: string;
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchToggleDisplayFooterAction: (displayFooter: boolean) => {
    dispatch(toggleDisplayFooterAction(displayFooter));
  },
});

function ILRemindoPlayerOrganismContainer({
  dispatchToggleDisplayFooterAction,
  planId,
  title,
}: Props) {
  const method = useGetMethod();
  const { selectedSchoolId } = useGetAuthenticated();
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [url, setUrl] = useState<string>();
  const [pin, setPin] = useState<string>('');
  const [pinRequired, setPinRequired] = useState<boolean>(false);
  const [showWrongPin, setShowWrongPin] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const getUrl = useCallback(() => {
    setLoading(true);
    setShowWrongPin(false);
    if (method) {
      createRequest(MethodType.Save, `${webApiUrl}playtest/validateTestMoment`, {
        planId,
        schoolId: selectedSchoolId,
        methodId: method.structureId,
        showReadSpeaker: method.showReadspeaker,
        returnUrl: `${window.origin}/#/close-remindo`,
        pincode: pin || '',
      })
        .then(res => {
          switch (res.data.code) {
            case RemindoCode.LoginToken:
              setUrl(res.data.message);
              break;
            case RemindoCode.PinRequired:
              setPinRequired(true);
              break;
            case RemindoCode.IncorrectPin:
              setShowWrongPin(true);
              break;
            case RemindoCode.PlanExpired:
              setErrorMessage(t('remindo:plan_expired'));
              break;
            case RemindoCode.UnfinishedTest:
              setErrorMessage(
                res.data.date
                  ? t('remindo:unfinished_test_with_date', {
                      date: formatToLocalDate(res.data.date),
                    })
                  : t('remindo:unfinished_test')
              );
              break;
            case RemindoCode.NoToken:
            default:
              setErrorMessage(t('remindo:error'));
              setPinRequired(false);
              break;
          }
          setLoading(false);
        })
        .catch(() => {
          setErrorMessage(t('remindo:error'));
        });
    }
  }, [method, pin, planId, selectedSchoolId, t]);

  useEffect(() => {
    if (!url && !pinRequired && selectedSchoolId) {
      getUrl();
    }
  }, [getUrl, pinRequired, selectedSchoolId, url]);

  const closePlayer = () => {
    history.push('/');
  };

  const closeErrorModal = () => {
    setErrorMessage('');
    closePlayer();
  };

  const handlePinChange = (input: string) => {
    if (input.length <= 5) {
      setPin(input);
    }
  };

  const handleFrameMessage = (data: TPIframeMessage) => {
    if (data.type === CLOSE_REMINDO) {
      closePlayer();
    }
  };

  useEffect(() => {
    if (url) {
      dispatchToggleDisplayFooterAction(false);
    }
    return () => {
      dispatchToggleDisplayFooterAction(true);
    };
  }, [url, dispatchToggleDisplayFooterAction]);

  return (
    <>
      <ILErrorModalMolecule
        onClose={closeErrorModal}
        isOpen={!!errorMessage}
        subText={errorMessage}
        title={t('remindo:cannot_start_test')}
      />
      <ILRemindoCodeModalMolecule
        isOpen={pinRequired && !url}
        isLoading={isLoading}
        pin={pin}
        isWrongPin={showWrongPin}
        handlePinChange={handlePinChange}
        validatePin={getUrl}
        closeModal={() => history.push('/')}
      />
      {isLoading ? (
        <ILLoadingIndicator isLoading fullSize />
      ) : (
        <ILRemindoPlayerOrganism
          src={url || ''}
          title={title}
          onClose={closePlayer}
          handleFrameMessage={handleFrameMessage}
        />
      )}
    </>
  );
}
export default connect(null, mapDispatchToProps)(ILRemindoPlayerOrganismContainer);
