export const TEST_LEVEL_ORDER = [
  'vmbo b',
  'vmbo bk',
  'vmbo k',
  'vmbo kgt',
  'vmbo g',
  'vmbo gt',
  'vmbo t',
  'vmbo gt havo',
  'vmbo th',
  'vmbo t havo',
  'havo',
  'havo vwo',
  'vwo',
  '1F',
  '1F/2F',
  '2F',
  '3F',
];

export const COGNITIVE_LEVEL_ORDER = ['Kennis', 'Toepassing', 'Inzicht'];
