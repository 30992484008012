import React, { ReactElement } from 'react';

import { CardType } from './Card';
import ILIconAtom, { IconType } from '../ILIconAtom';

import './Card.scss';

interface Props {
  title: string;
  subtitle?: string;
  type: CardType;
}

const renderIcon = (type: CardType) => {
  let iconType = IconType.Assignment;
  switch (type) {
    case CardType.Answer:
      iconType = IconType.EpubFile;
      break;
    case CardType.Video:
      iconType = IconType.VideoFile;
      break;
    case CardType.Audio:
      iconType = IconType.AudioFile;
      break;
    case CardType.DownloadText:
      iconType = IconType.WordFile;
      break;
    case CardType.DownloadImage:
      iconType = IconType.ImageFile;
      break;
    case CardType.DownloadSpreadsheet:
      iconType = IconType.ExcelFile;
      break;
    case CardType.DownloadPresentation:
      iconType = IconType.PptFile;
      break;
    case CardType.DownloadPdf:
      iconType = IconType.PdfFile;
      break;
    case CardType.Download:
    default:
      iconType = IconType.GenericFile;
  }
  return type === CardType.None ? '' : <ILIconAtom className="card--icon" type={iconType} />;
};

function CardTitle({ title, subtitle, type = CardType.None }: Props): ReactElement {
  return (
    <>
      <span className="card--title">
        {renderIcon(type)}
        <strong>{title}</strong>
      </span>
      <div className="card--subtitle">{subtitle && <span>{subtitle}</span>}</div>
    </>
  );
}

export default CardTitle;
