import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/nl';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { State } from '../../../store';
import {
  setDateAction,
  setEndTimeAction,
  setStartTimeAction,
} from '../../../store/planning/actions';
import ILClassHourSelectMolecule from './ILClassHourSelectMolecule';
import { usePlanningContext } from '../../../pages/Planning/PlanningContext';

interface Props {
  date: moment.Moment | null;
  startTime: string;
  endTime: string;
  dispatchSetDateAction: (date: moment.Moment | null) => void;
  dispatchSetStartTimeAction: (startTime: string) => void;
  dispatchSetEndTimeAction: (endTime: string) => void;
}

const mapStateToProps = (state: State) => ({
  date: state.plan.date,
  startTime: state.plan.startTime,
  endTime: state.plan.endTime,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchSetDateAction: (date: moment.Moment | null) => {
    dispatch(setDateAction(date));
  },
  dispatchSetStartTimeAction: (startTime: string) => {
    dispatch(setStartTimeAction(startTime));
  },
  dispatchSetEndTimeAction: (endTime: string) => {
    dispatch(setEndTimeAction(endTime));
  },
});

function ILClassHourSelectMoleculeContainer ({
  date,
  startTime,
  endTime,
  dispatchSetDateAction,
  dispatchSetStartTimeAction,
  dispatchSetEndTimeAction,
}: Props) {
  const [pickedDate, setPickedDate] = useState<moment.Moment | null>(date || null);
  const [rangeStartTime, setRangeStartTime] = useState<string>(startTime);
  const [rangeEndTime, setRangeEndTime] = useState<string>(endTime);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const { isEditEnabled } = usePlanningContext();

  const handleDateChange = (newDate: moment.Moment | null) => {
    if (isEditEnabled) {
      setPickedDate(newDate);
      dispatchSetDateAction(newDate);
    }
  };

  const getFormattedTime = (time: string): string => {
    let newTime: string = time;
    if (newTime.length === 2 && newTime.search(':') === -1) {
      newTime = `${newTime}:`;
    }
    return newTime;
  };

  const handleStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isEditEnabled) {
      const formattedTime = getFormattedTime(event.target.value);
      setRangeStartTime(formattedTime);
      dispatchSetStartTimeAction(formattedTime);
    }
  };

  const handleEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isEditEnabled) {
      const formattedTime = getFormattedTime(event.target.value);
      setRangeEndTime(formattedTime);
      dispatchSetEndTimeAction(formattedTime);
    }
  };

  const handleFocusChange = (focused: boolean | null) => {
    setIsFocused(!!focused);
  };

  return (
    <ILClassHourSelectMolecule
      date={pickedDate}
      startTime={rangeStartTime}
      endTime={rangeEndTime}
      isFocused={isFocused}
      isDisabled={!isEditEnabled}
      onFocusChange={handleFocusChange}
      onDateChange={handleDateChange}
      onStartTimeChange={handleStartTimeChange}
      onEndTimeChange={handleEndTimeChange}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ILClassHourSelectMoleculeContainer);
