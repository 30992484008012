import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TestDetails } from '../../models/dto/TestDetails';
import { TestType } from '../../models/enums/TestType';
import ILIconAtom, { IconType } from '../../components/ILIconAtom/ILIconAtom';
import TPTestOverviewTableMolecule from './TPTestOverviewTableMolecule';
import ILLoadingModalMolecule from '../../components/ILModalMolecule/ILLoadingModalMolecule';

import './style..scss';
import '../../assets/theme/tp-buttons.scss';

type Props = {
  data: TestDetails[];
  isLevelTest: boolean;
  isLoading: boolean;
  isArrangeTestEnabled: boolean;
  showChapterDownload: boolean;
  isDownloadModalOpen: boolean;
  openDetailsPage: (id: string, downloadUrl?: string) => void;
  openTestArrange: () => void;
  planTest: (id: string) => void;
  downloadFile: (recipeId: string, title: string, type: string) => void;
  closeDownloadModal: () => void;
};

function TestOverview({
  data,
  isLevelTest,
  isLoading,
  showChapterDownload,
  isDownloadModalOpen,
  isArrangeTestEnabled,
  openDetailsPage,
  openTestArrange,
  planTest,
  downloadFile,
  closeDownloadModal,
}: Props): ReactElement {
  const { t } = useTranslation();

  const getChapterTests = (): TestDetails[] =>
    data.filter(
      (test: TestDetails) =>
        test.classification === TestType.Rtti || test.classification === TestType.Bloom
    );

  const getLevelTests = (): TestDetails[] =>
    data.filter((test: TestDetails) => test.classification === TestType.Ref);

  return (
    <div className="main">
      <div className="main__title">
        <h1>{isLevelTest ? t('testsPage:level_tests') : t('testsPage:chapter_tests')}</h1>
        {!isLevelTest && isArrangeTestEnabled && (
          <button
            className="tp-button tp-button-primary tp-button-with-icon tp-button-thin"
            onClick={openTestArrange}
          >
            <ILIconAtom type={IconType.PlusCircle} size={17} />
            {t('testsPage:arrange_test')}
          </button>
        )}
      </div>
      <TPTestOverviewTableMolecule
        tableData={isLevelTest ? getLevelTests() : getChapterTests()}
        isLevelTest={isLevelTest}
        openDetailsPage={openDetailsPage}
        planTest={planTest}
        isLoading={isLoading}
        downloadFile={downloadFile}
        showChapterDownload={showChapterDownload}
      />
      <ILLoadingModalMolecule
        isOpen={isDownloadModalOpen}
        title={t('testsPage:download_files')}
        onClose={closeDownloadModal}
      >
        <div className="download-description">
          <p>{t('testsPage:create_files')}</p>
          <p>{t('testsPage:download_will_start')}</p>
        </div>
      </ILLoadingModalMolecule>
    </div>
  );
}

export default TestOverview;
