import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectType } from '../../../models/enums/SelectType';
import { ScheduleType } from '../../../models/enums/ScheduleType';
import DateRangeSelectContainer from '../../../components/DatePicker/ILDateRangeSelectMolecule';
import ClassHourSelectContainer from '../../../components/DatePicker/ILClassHourSelectMolecule';
import ILSelectOptionMolecule from '../../../components/ILSelectOptionMolecule';

interface Props {
  selectedScheduleType: string;
  handleSelection: (id: string) => void;
}

function TPPlanningScheduleOrganism({
  selectedScheduleType,
  handleSelection,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <>
      <ILSelectOptionMolecule
        key={0}
        id={ScheduleType.Direct}
        type={SelectType.Single}
        label={t(`planning:${ScheduleType.Direct}`)}
        subLabel={t(`planning:${ScheduleType.Direct}_sub`)}
        isSelected={selectedScheduleType === ScheduleType.Direct}
        handleCheckboxChange={() => handleSelection(ScheduleType.Direct)}
      />
      <ILSelectOptionMolecule
        key={1}
        id={ScheduleType.Period}
        type={SelectType.Single}
        label={t(`planning:${ScheduleType.Period}`)}
        subLabel={t(`planning:${ScheduleType.Period}_sub`)}
        isSelected={selectedScheduleType === ScheduleType.Period}
        handleCheckboxChange={() => handleSelection(ScheduleType.Period)}
      >
        <DateRangeSelectContainer />
      </ILSelectOptionMolecule>
      <ILSelectOptionMolecule
        key={2}
        id={ScheduleType.Hour}
        type={SelectType.Single}
        label={t(`planning:${ScheduleType.Hour}`)}
        subLabel={t(`planning:${ScheduleType.Hour}_sub`)}
        isSelected={selectedScheduleType === ScheduleType.Hour}
        handleCheckboxChange={() => handleSelection(ScheduleType.Hour)}
      >
        <ClassHourSelectContainer />
      </ILSelectOptionMolecule>
    </>
  );
}

export default TPPlanningScheduleOrganism;
