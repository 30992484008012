import React, { ReactElement } from 'react';
import { useQuery } from '@apollo/client';
import { GET_NEWS_FLASHES } from './newsFeedQueries';
import { useGetMethod } from '../../graphql/method/methodOperations';
import { createRequest } from '../ILAjaxEther';
import { webApiUrl } from '../../api/settingsLoader';
import { filterNews } from '../../utils/NewsItemsFilter';
import { NewsResponse } from '../../models/state/News';
import ILNewsFeedMolecule from './ILNewsFeedMolecule';

type Props = {
  pageId: string;
};

function ILNewsFeedMoleculeContainer({ pageId }: Props): ReactElement {
  const method = useGetMethod();
  const { data, refetch } = useQuery<NewsResponse, { pageId: string }>(GET_NEWS_FLASHES, {
    variables: { pageId },
    fetchPolicy: 'no-cache',
  });

  const closeFeed = (newsId: string) => {
    createRequest('post', `${webApiUrl}news/viewed/${newsId}`).then(() => {
      refetch();
    });
  };

  return (
    <>
      {method && data && data.newsflashes?.length > 0 && (
        <ILNewsFeedMolecule news={filterNews(data.newsflashes, method)} handleClose={closeFeed} />
      )}
    </>
  );
}

export default ILNewsFeedMoleculeContainer;
