import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CardGrid } from '../../components/CardGrid/CardGrid';
import { ContentItem } from '../../models/dto/ContentItem';
import Card from '../../components/Card/Card';

import './style.scss';
import { ILLoadingIndicator } from '../../components/ILLoadingIndicator';

type Props = {
  contentItems: ContentItem[];
  isLoading: boolean;
};

function Support({ contentItems, isLoading }: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <div className="main">
      <h1>{t('support:support')}</h1>
      {isLoading && <ILLoadingIndicator fullSize />}
      {contentItems && contentItems.length > 0 ? (
        <CardGrid>
          {contentItems.map((item: ContentItem) => (
            <Card
              key={item.id}
              title={item.title}
              subtitle={item.subTitle}
              url={item.url}
              isShared={true}
              openNewTab={true}
            />
          ))}
        </CardGrid>
      ) : (
        !isLoading && (
          <div className="white-message-container">
            <span className="no-result">{t('support:no_documents')}</span>
          </div>
        )
      )}
    </div>
  );
}

export default Support;
