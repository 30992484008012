import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { StudentResult } from '../../../../models/dto/StudentResult';
import { getData } from '../../../../components/ILAjaxEther';
import { webApiUrl } from '../../../../api/settingsLoader';
import { useGetMethod } from '../../../../graphql/method/methodOperations';
import TPStudentsOverviewTable from './TPStudentsOverviewTable';
import { useGetAuthenticated } from '../../../../graphql/authenticated/authenticatedOperations';
import { usePageTracking } from '../../../../utils/usePageTracking';

function StudentsOverview(): ReactElement {
  const method = useGetMethod();
  const { selectedSchoolId } = useGetAuthenticated();
  const history = useHistory<{ name?: string }>();

  const { t } = useTranslation();
  const [students, setStudents] = useState<StudentResult[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);

  usePageTracking('result', 'overview');

  useEffect(() => {
    if (method?.structureId && selectedSchoolId) {
      getData(`${webApiUrl}result/students/${method.structureId}/${selectedSchoolId}`).then(
        (response: StudentResult[]) => {
          setStudents(response);
          setIsLoadingData(false);
        }
      );
    }
  }, [method, selectedSchoolId]);

  const openLastResult = (studentId: string, lastPlanId: string) => {
    history.push(`/resultaat/leerling/${studentId}/${lastPlanId}`);
  };

  const goToAllResults = (studentId: string, studentName: string) => {
    history.push({
      pathname: `/resultaten/leerling/${studentId}`,
      state: {
        name: studentName,
      },
    });
  };

  return (
    <div className="main">
      <h1>{t('resultsPage:test_results_students')}</h1>
      <TPStudentsOverviewTable
        tableData={students}
        isLoading={isLoadingData}
        openLastResult={openLastResult}
        goToAllResults={goToAllResults}
      />
    </div>
  );
}

export default StudentsOverview;
