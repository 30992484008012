import React, { ReactElement } from 'react';
import { usePlanningContext } from '../PlanningContext';
import { Caesura } from '../../../models/state/Plan';
import TPCaesuraTableOrganism from '../../../components/TPCaesuraTableOrganism';

type Props = {
  caesura: Caesura;
  handleScoreChange: (id: number, score: string) => void;
  handleGradeChange: (id: number, grade: string) => void;
};

function TPPlanningCaesuraOrganism({
  caesura,
  handleScoreChange,
  handleGradeChange,
}: Props): ReactElement {
  const { isEditEnabled } = usePlanningContext();
  return (
    <TPCaesuraTableOrganism
      caesura={caesura}
      isEditEnabled={isEditEnabled}
      handleScoreChange={handleScoreChange}
      handleGradeChange={handleGradeChange}
    />
  );
}

export default TPPlanningCaesuraOrganism;
