import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { CardType } from './Card';
import './Card.scss';

interface Props {
  url: string;
  type?: CardType;
  openNewTab?: boolean;
}

export function CardButton({ url, type = CardType.None, openNewTab = false }: Props): ReactElement {
  const { t } = useTranslation();
  const labelTag = (cardType: CardType) => {
    switch (cardType) {
      case CardType.Audio:
        return 'card:listen';

      case CardType.Answer:
      case CardType.Video:
        return 'card:view';

      case CardType.Continue:
        return 'card:continue';

      case CardType.None:
      case CardType.Make:
        return 'card:make';

      default:
        return 'card:download';
    }
  };

  return (
    <a
      className="card__button -full"
      href={url}
      target={openNewTab ? '_blank' : '_self'}
      rel="noopener noreferrer"
    >
      {t(labelTag(type))}
    </a>
  );
}
