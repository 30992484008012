import React, { ReactNode } from 'react';
import { Route } from 'react-router';
import PageNotFound from '../../pages/PageNotFound';
import { useGetAuthenticated } from '../../graphql/authenticated/authenticatedOperations';

type Props = {
  children: ReactNode;
};

function TPTeacherAccessForPage({ children }: Props) {
  const { isAuthenticated, isTeacher } = useGetAuthenticated();
  if (isAuthenticated) {
    return isTeacher ? <>{children}</> : <Route component={PageNotFound} />;
  }
  return null;
}

export default TPTeacherAccessForPage;
