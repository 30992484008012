import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';
import '../../assets/theme/tp-buttons.scss';

type Props = {
  isDisabled?: boolean;
  isAcceptDisabled?: boolean;
  acceptText?: string;
  cancelText?: string;
  onAccept?: () => void;
  onCancel: () => void;
};

function ILModalButtonsMolecule({
  isDisabled,
  isAcceptDisabled,
  acceptText,
  cancelText,
  onAccept,
  onCancel,
}: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <div className="modal-footer">
      {onAccept && (
        <button
          className="tp-button tp-button-primary ok-btn"
          disabled={isDisabled || isAcceptDisabled}
          onClick={onAccept}
        >
          {acceptText || t('modal:accept')}
        </button>
      )}
      <button
        className="tp-button tp-button-link cancel-btn"
        disabled={isDisabled}
        onClick={onCancel}
      >
        {cancelText || t('modal:cancel')}
      </button>
    </div>
  );
}

export default ILModalButtonsMolecule;
