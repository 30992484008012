import React, { ReactElement } from 'react';
import { ControlledStateOverrideProps, TableProps } from 'react-table';
import ILPaginationMenuAtom from '../ILPaginationMenuAtom';

function ILTablePaginationMolecule({
  pageSizeOptions,
  pageSize,
  page,
  pages,
  onPageChange,
  onPageSizeChange,
}: TableProps & ControlledStateOverrideProps): ReactElement {
  const changePage = (selectedPage: number) => {
    onPageChange(selectedPage);
  };
  const shouldRenderSpread = (pageNumber: number, index: number, array: number[]): boolean =>
    (pages! > 6 && array[index - 1] + 2 < page!) ||
    (pages! > 6 && pageNumber === pages && array[index - 1] !== pages - 1);

  const getAvailablePages = (activePage: number, totalPages: number): number[] => {
    if (totalPages < 7) {
      return [1, 2, 3, 4, 5, 6].filter((item: number) => item <= totalPages);
    }
    if (activePage > 4 && activePage + 2 < totalPages) {
      return [1, activePage - 1, activePage, activePage + 1, totalPages];
    }
    if (activePage > 4 && activePage + 2 >= totalPages) {
      return [1, totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    }
    return [1, 2, 3, 4, 5, totalPages];
  };

  const handlePageSizeChange = (newPageSize: number) => {
    onPageSizeChange(newPageSize, 0);
  };

  const renderPageNumbers = () => {
    const activePage = page! + 1;
    const availablePages = getAvailablePages(activePage, pages!);
    return availablePages.map((pageNumber: number, index: number, array: number[]) => (
      <React.Fragment key={pageNumber.toString()}>
        {shouldRenderSpread(pageNumber, index, array) && (
          <li
            className="spread-item"
            id={`${pageNumber.toString()}_prefix`}
            key={`${pageNumber.toString()}_prefix`}
          >
            ...
          </li>
        )}
        <li
          className={pageNumber === activePage ? 'selected-page' : ''}
          id={pageNumber.toString()}
          key={pageNumber.toString()}
          onClick={() => changePage(pageNumber - 1)}
          role="menuitem"
          tabIndex={-1}
        >
          {pageNumber}
        </li>
      </React.Fragment>
    ));
  };

  const renderPagesDropdown = (): JSX.Element => (
    <select value={pageSize} onChange={e => handlePageSizeChange(+e.target.value)}>
      {pageSizeOptions.map(option => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  );

  const handleBack = () => {
    if (page! !== 0) {
      changePage(page! - 1);
    }
  };

  const handleNext = () => {
    if (page! + 1 !== pages) {
      changePage(page! + 1);
    }
  };

  return (
    <ILPaginationMenuAtom
      pageNumbers={renderPageNumbers()}
      previousPageCallback={handleBack}
      totalPageCount={pages || 0}
      activePage={page! + 1}
      nextPageCallback={handleNext}
      pageSizeDropdown={renderPagesDropdown()}
    />
  );
}

export default ILTablePaginationMolecule;
