import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ILTableTextButtonAtom from '../../../../components/ILTableOrganism/ILTableTextButtonAtom';
import '../../style.scss';

type Props = {
  id: string;
  name: string;
  lastPlanId?: string;
  openLastResult: (id: string, lastPlanId: string) => void;
  goToAllResults: (id: string, className: string) => void;
};

function TPResultsOverviewOptionsMolecule({
  id,
  name,
  lastPlanId,
  openLastResult,
  goToAllResults,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="right-aligned-column">
      {lastPlanId && (
        <ILTableTextButtonAtom
          text={t('resultsPage:last_result')}
          handleClick={() => openLastResult(id, lastPlanId)}
        />
      )}
      <ILTableTextButtonAtom
        text={t('resultsPage:all_results')}
        handleClick={() => goToAllResults(id, name)}
      />
    </div>
  );
}

export default TPResultsOverviewOptionsMolecule;
