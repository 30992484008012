import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TableColumn,
  TableDataType,
  TableFilterType,
  TableHeaderType,
} from '../../../components/ILTableOrganism/ILTableColumn/createColumns';
import { FileType } from '../../../models/enums/FileType';
import { IconType } from '../../../components/ILIconAtom';
import { TEST_LEVEL_ORDER } from '../../../constants/TestConstants';
import { TestDetails } from '../../../models/dto/TestDetails';
import ILTableOrganismContainer from '../../../components/ILTableOrganism';
import ILTableButtonAtom from '../../../components/ILTableOrganism/ILTableButtonAtom';
import './style.scss';

type Props = {
  tableData: TestDetails[];
  isLevelTest: boolean;
  openDetailsPage: (id: string, downloadUrl?: string) => void;
  planTest: (id: string) => void;
  isLoading: boolean;
  downloadFile: (recipeId: string, title: string, type: string) => void;
  showChapterDownload: boolean;
};

function TPTestOverviewTableMolecule({
  tableData,
  isLevelTest,
  openDetailsPage,
  planTest,
  isLoading,
  downloadFile,
  showChapterDownload,
}: Props): ReactElement {
  const { t } = useTranslation();

  const renderActionButtons = (rowData: TestDetails) => (
    <div className="action-buttons-container">
      <ILTableButtonAtom
        type={IconType.Eye}
        title={t('testsPage:view_details')}
        handleClick={() => openDetailsPage(rowData.id, rowData.worksheetsDownloadUrl)}
      />
      {!isLevelTest && showChapterDownload && (
        <>
          <ILTableButtonAtom
            type={IconType.PdfFile}
            title={t('testsPage:download_pdf')}
            handleClick={() => downloadFile(rowData.recipeId, rowData.title, FileType.Pdf)}
          />
          {false && (
            <ILTableButtonAtom
              type={IconType.WordFile}
              title={t('testsPage:download_doc')}
              handleClick={() => downloadFile(rowData.recipeId, rowData.title, FileType.Docx)}
            />
          )}
        </>
      )}
      <ILTableButtonAtom
        type={IconType.Calendar}
        title={t('testsPage:plan_test')}
        handleClick={() => planTest(rowData.id)}
      />
    </div>
  );

  const chapterTestTableColumns: TableColumn[] = [
    {
      key: 'title',
      headerTitle: t('testsPage:title'),
      columnData: TableDataType.Text,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.Text,
      filterDefaultText: t('testsPage:type_title'),
      width: 300,
    },
    {
      key: 'edition',
      headerTitle: t('testsPage:edition'),
      columnData: TableDataType.Alphanumeric,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.StrictSelectAlphaNumeric,
      filterDefaultText: t('testsPage:all_edition'),
    },
    {
      key: 'learningYear',
      headerTitle: t('testsPage:year'),
      columnData: TableDataType.Alphanumeric,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.StrictSelectAlphaNumeric,
      filterDefaultText: t('testsPage:all_year'),
    },
    {
      key: 'level',
      headerTitle: t('testsPage:level'),
      columnData: TableDataType.Text,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.StrictSelectText,
      filterSortingArray: TEST_LEVEL_ORDER,
      filterDefaultText: t('testsPage:all_level'),
    },
    {
      key: 'chapter',
      headerTitle: t('testsPage:chapter'),
      columnData: TableDataType.Alphanumeric,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.StrictSelectAlphaNumeric,
      filterDefaultText: t('testsPage:all_chapter'),
    },
    {
      key: 'questionsTotalCount',
      headerTitle: t('testsPage:questions'),
      columnData: TableDataType.Numeric,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.None,
      width: 110,
    },
    {
      key: 'options',
      columnData: TableDataType.Custom,
      header: TableHeaderType.Empty,
      filter: TableFilterType.None,
      customCellRenderer: renderActionButtons,
      width: showChapterDownload ? 160 : 100,
    },
  ];

  const levelTestColumns: TableColumn[] = [
    {
      key: 'title',
      headerTitle: t('testsPage:title'),
      columnData: TableDataType.Text,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.Text,
      filterDefaultText: t('testsPage:type_title'),
      width: 500,
    },
    {
      key: 'referenceLevel',
      headerTitle: t('testsPage:level'),
      columnData: TableDataType.Text,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.StrictSelectText,
      filterSortingArray: TEST_LEVEL_ORDER,
      filterDefaultText: t('testsPage:all_level'),
    },
    {
      key: 'questionsTotalCount',
      headerTitle: t('testsPage:questions'),
      columnData: TableDataType.Numeric,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.None,
    },
    {
      key: 'options',
      columnData: TableDataType.Custom,
      header: TableHeaderType.Empty,
      filter: TableFilterType.None,
      customCellRenderer: renderActionButtons,
      width: 100,
    },
  ];

  return (
    <ILTableOrganismContainer
      id={`test-overview-${isLevelTest ? 'level' : 'chapter'}`}
      data={tableData}
      columns={isLevelTest ? levelTestColumns : chapterTestTableColumns}
      noDataText={t('testsPage:no_tests')}
      sortable={true}
      filterable={true}
      shouldReload={true}
      isLoading={isLoading}
      defaultSorted={[
        {
          id: 'title',
          desc: false,
        },
      ]}
    />
  );
}

export default TPTestOverviewTableMolecule;
