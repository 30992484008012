import { Context } from '../../models/state/Context';
import {
  ContextActionTypes,
  ToggleDisplayFooterAction,
  ToggleDisplayMenuAction,
  ToggleDisplayNewsFeedAction,
} from './actions';

const initialState: Context = {
  displayMenu: true,
  displayFooter: true,
  displayNewsFeed: true,
};

export default (
  state = initialState,
  action: ToggleDisplayMenuAction | ToggleDisplayFooterAction | ToggleDisplayNewsFeedAction
): Context => {
  switch (action.type) {
    case ContextActionTypes.TOGGLE_DISPLAY_MENU: {
      return { ...state, displayMenu: action.displayMenu };
    }
    case ContextActionTypes.TOGGLE_DISPLAY_FOOTER: {
      return { ...state, displayFooter: action.displayFooter };
    }
    case ContextActionTypes.TOGGLE_DISPLAY_NEWS_FEED: {
      return { ...state, displayNewsFeed: action.displayNewsFeed };
    }
    default: {
      return { ...state };
    }
  }
};
