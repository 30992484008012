import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ILLoadingIndicator } from '../ILLoadingIndicator';
import { Notification } from '../../models/state/Notification';
import TPNotificationsTableMolecule from './TPNotificationsTableMolecule/index';
import './style.scss';

interface Props {
  notifications: Notification[];
  isTeacher?: boolean;
  reloadNotifications?: () => void;
}

function TPNotificationsOrganism({
  notifications,
  isTeacher,
  reloadNotifications,
}: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <div className="notifications">
      <div className="notification-header">
        <h2>{t('notifications:notifications')}</h2>
        <button
          className="refresh-button"
          title={t('notifications:refresh')}
          onClick={reloadNotifications}
        >
          {t('notifications:refresh')}
        </button>
      </div>
      {notifications && notifications.length === 0 ? (
        <ILLoadingIndicator isLoading={true} />
      ) : (
        <TPNotificationsTableMolecule notifications={notifications} isTeacher={isTeacher} />
      )}
    </div>
  );
}

export default TPNotificationsOrganism;
