import React, { ReactElement } from 'react';
import Grid from '@material-ui/core/Grid';
import { Profile } from '../../../models/state/Groups';
import '../ILStudentListMolecule.scss';

interface Props {
  student: Profile;
  handleSelectionChange: (studentId: string, groupId: string) => void;
  isChecked: boolean;
  groupId: string;
}

function ILStudentSelectorAtom({
  student,
  handleSelectionChange,
  isChecked,
  groupId,
}: Props): ReactElement {
  return (
    <Grid item={true} xs={3} component="div">
      <li
        key={student.udbId}
        role="option"
        aria-selected={isChecked}
        onClick={() => handleSelectionChange(student.udbId, groupId)}
        className={`student-list-item${isChecked ? '_checked' : ''}`}
      >
        <span className="student">
          {`${student.lastName}, ${student.firstName} ${student.prefix ? student.prefix : ''}`}
        </span>
      </li>
    </Grid>
  );
}

export default ILStudentSelectorAtom;
