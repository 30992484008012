import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNlpRoutes } from '../../../utils/nlpNavigation';
import '../style.scss';
import { MenuItem } from '../ILMenuMolecule';

interface Props {
  menuItem: MenuItem;
}

export const ILNlpMenuLink: React.FC<Props> = ({ menuItem }) => {
  const { t } = useTranslation();
  const menuRoute = useNlpRoutes(menuItem.nlpKey);

  return (
    <a className={`menu-link${menuItem.items?.length ? ' disabled-link' : ''}`} href={menuRoute}>
      {t(`menu:${menuItem.resourceKey}`)}
    </a>
  );
};
