import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useGetMethod } from '../../graphql/method/methodOperations';
import { useGetAuthenticated } from '../../graphql/authenticated/authenticatedOperations';
import { createRequest, deleteData, getData } from '../../components/ILAjaxEther';
import { ConfirmModalInfo } from '../../components/ILConfirmationModalMolecule/ILConfirmationModalMolecule';
import { PlanningOverview } from '../../models/dto/PlanningResult';
import { MethodType } from '../../components/ILAjaxEther/MethodType';
import { Origin } from '../../models/enums/Origin';
import { Status } from '../../components/TPStatusAtom/Status';
import {
  PRA_PLAN_BASE_URL,
  WEB_API_PLAN_INFO_BASE_URL,
} from '../../constants/PlanningResourcesContstants';
import { webApiUrl } from '../../api/settingsLoader';
import TestAdministration from './TestAdministration';
import { usePageTracking } from '../../utils/usePageTracking';

function TestAdministrationContainer(): ReactElement {
  const method = useGetMethod();
  const { selectedSchoolId } = useGetAuthenticated();
  const history = useHistory();
  const [plannings, setPlannings] = useState<PlanningOverview[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalSettings, setModalSettings] = useState<ConfirmModalInfo>({
    itemId: '',
    display: false,
    showLoading: false,
  });

  usePageTracking('planning', 'overview');

  const fetchPlanningOverview = useCallback(() => {
    if (method && selectedSchoolId) {
      getData(`${webApiUrl}planning/planoverview/${method.structureId}/${selectedSchoolId}`).then(
        (response: PlanningOverview[]) => {
          setPlannings(response);
          setIsLoading(false);
        }
      );
    }
  }, [method, selectedSchoolId]);

  const closeDeleteModal = () => {
    setModalSettings({
      itemId: '',
      display: false,
      showLoading: false,
    });
  };

  const openDeleteModal = (planId: string) => {
    setModalSettings({
      itemId: planId,
      display: true,
      showLoading: false,
    });
  };

  const toggleDirectPlan = (planId: string) => {
    setIsLoading(true);
    createRequest(MethodType.Save, `${webApiUrl}planninginfo/openClosePlan/${planId}`).then(() =>
      fetchPlanningOverview()
    );
  };

  const openResult = (planId: string) => {
    history.push(`/resultaat/alle/${planId}`);
  };

  const openEditPlanning = (planId: string, origin: Origin, status: Status) => {
    history.push(`/planning/edit/${planId}/${origin}/${status}`);
  };

  const handleDeletePlan = () => {
    setModalSettings({
      ...modalSettings,
      showLoading: true,
    });
    setIsLoading(true);
    deleteData(`${PRA_PLAN_BASE_URL}/${modalSettings.itemId}`)
      .then(() => {
        deleteData(`${WEB_API_PLAN_INFO_BASE_URL}/${modalSettings.itemId}`)
          .then(() => {
            closeDeleteModal();
            fetchPlanningOverview();
          })
          .catch(() => {
            setModalSettings({
              ...modalSettings,
              showLoading: false,
            });
          });
      })
      .catch(() => {
        setModalSettings({
          ...modalSettings,
          showLoading: false,
        });
      });
  };

  useEffect(() => {
    fetchPlanningOverview();
  }, [fetchPlanningOverview, method, selectedSchoolId]);

  return (
    <TestAdministration
      data={plannings}
      isLoading={isLoading}
      toggleDirectPlan={toggleDirectPlan}
      openResult={openResult}
      openEditPlanning={openEditPlanning}
      deletePlanning={handleDeletePlan}
      openDeleteModal={openDeleteModal}
      closeDeleteModal={closeDeleteModal}
      deleteModalSettings={modalSettings}
    />
  );
}

export default TestAdministrationContainer;
