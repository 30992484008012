import moment from 'moment';
import 'moment/locale/nl';

export const formatToLocalDate = (date: string): string =>
  moment.utc(date).local().format('DD-MM-YYYY');

export const formatToLocalTime = (date: string): string => moment.utc(date).local().format('HH:mm');

export const secondsToTime = (seconds: number): string =>
  new Date(seconds * 1000).toISOString().substr(14, 5);
