import { gql } from '@apollo/client';

export const GET_QUESTIONS = gql`
  query questions(
    $categoryIds: [Int]!
    $language: String!
    $learningLevels: [String]!
    $learningYears: [String]!
    $chapters: [String]!
    $page: Int
    $isVe: Boolean
  ) {
    questions(
      categoryIds: $categoryIds
      language: $language
      learningLevels: $learningLevels
      learningYears: $learningYears
      chapters: $chapters
      page: $page
      isVe: $isVe
    ) {
      count
      limit
      questionList {
        id
        binId
        identifier
        snippet
        maxScore
        type
        open
        language
        kti
        domains
        properties {
          key
          value
        }
        blocks {
          html
          identifier
          type
        }
      }
    }
  }
`;

export const GET_QUESTION_FRAGMENT = gql`
  fragment question on Question {
    id
    binId
    identifier
    snippet
    maxScore
    type
    open
    language
    properties {
      key
      value
    }
    blocks {
      html
      identifier
      type
    }
  }
`;
