import React, { useEffect, useRef, useCallback, ReactElement } from 'react';
import { ModalType } from './ModalType';
import ILIconAtom, { IconType } from '../ILIconAtom';
import './style.scss';

type Props = {
  isOpen: boolean;
  type?: ModalType;
  children: JSX.Element | JSX.Element[];
  title?: string;
  subText?: string;
  isFullScreen?: boolean;
  className?: string;
  handleClose: () => void;
};

function ILModalMolecule({
  isOpen,
  type,
  children,
  title,
  subText,
  isFullScreen,
  className,
  handleClose,
}: Props): ReactElement | null {
  const modalRef = useRef(null);

  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      if (modalRef?.current === event.target) {
        handleClose();
        document.removeEventListener('click', handleOutsideClick);
      }
    },
    [handleClose]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [handleOutsideClick, isOpen]);

  const getModalClasses = (): string => {
    let classes = 'modal';
    if (type === ModalType.Error) {
      classes = classes.concat(' error-modal');
    }
    if (isFullScreen) {
      classes = classes.concat(' full-screen-modal');
    }
    if (className) {
      classes = classes.concat(` ${className}`);
    }
    return classes;
  };

  return isOpen ? (
    <div className="modal-overlay" ref={modalRef}>
      <div className={getModalClasses()}>
        {title && (
          <div className="modal-top">
            <h3 className="modal-title">
              {type === ModalType.Error && (
                <span className="modal-title-icon">
                  <ILIconAtom type={IconType.Alert} size={20} />
                </span>
              )}
              {title}
            </h3>
            {handleClose && (
              <span className="modal-close" onClick={handleClose}>
                <ILIconAtom type={IconType.Close} size={10} />
              </span>
            )}
          </div>
        )}
        <div className="modal-body">
          {subText && <p className="modal-sub">{subText}</p>}
          {children}
        </div>
      </div>
    </div>
  ) : null;
}

export default ILModalMolecule;
