import React, { ReactChild, ReactElement } from 'react';
import { CardButton } from './CardButton';
import ILIconAtom, { IconType } from '../ILIconAtom';
import CardTitle from './CardTitle';
import './Card.scss';

interface Props {
  title: string;
  subtitle?: string;
  url?: string;
  children?: ReactChild;
  isShared?: boolean;
  type?: CardType;
  openNewTab?: boolean;
}

export enum CardType {
  None,
  Answer,
  Continue,
  Video,
  Audio,
  Download,
  DownloadText,
  DownloadImage,
  DownloadSpreadsheet,
  DownloadPresentation,
  DownloadPdf,
  Make,
}

const determineCardType = (url: string) => {
  const file = url.substr(url.lastIndexOf('/') + 1);
  const extension =
    file.lastIndexOf('.') < 0 || file.includes('ibookviewer')
      ? ''
      : file
          .substr(file.lastIndexOf('.') + 1)
          .trim()
          .toLowerCase();

  switch (extension) {
    case '':
      return CardType.Answer;
    case 'mp3':
    case 'mid':
    case 'wav':
      return CardType.Audio;
    case 'f4v':
    case 'mp4':
      return CardType.Video;
    case 'txt':
    case 'doc':
    case 'docx':
    case 'rtf':
      return CardType.DownloadText;
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'png':
      return CardType.DownloadImage;
    case 'xls':
    case 'xlsx':
    case 'csv':
      return CardType.DownloadSpreadsheet;
    case 'ppt':
    case 'pptx':
    case 'pps':
      return CardType.DownloadPresentation;
    case 'pdf':
      return CardType.DownloadPdf;
    case 'zip':
    default:
      return CardType.Download;
  }
};

function Card({
  title,
  url,
  isShared = false,
  children,
  type,
  subtitle,
  openNewTab,
}: Props): ReactElement {
  const determinedType = type || (url && determineCardType(url)) || CardType.None;

  return (
    <div
      className={`card ${!isShared ? 'no-hover' : ''}`}
      onClick={() => window.open(url, openNewTab ? '_blank' : '_self')}
    >
      {isShared || (
        <div className="card-locked-icon">
          <ILIconAtom type={IconType.Locked} size={70} />
        </div>
      )}
      <CardTitle title={title} type={determinedType} subtitle={subtitle} />
      {children}
      {isShared && url && (
        <div className="card__bottom-field">
          <CardButton url={url} type={determinedType} openNewTab={openNewTab} />
        </div>
      )}
    </div>
  );
}

export default Card;
