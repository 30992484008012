import { TFunction } from 'i18next';
import { Option } from '../ILTableOrganism/ILTableColumn/createColumns';
import { Status } from './Status';

const resultStatusList: Status[] = [
  Status.Started,
  Status.Completed,
  Status.NotReviewed,
  Status.NotReleased,
  Status.Released,
];
const studentResultStatusList: Status[] = [
  Status.Started,
  Status.NotReviewed,
  Status.NotReleased,
  Status.Released,
];
const planningStatusList: Status[] = [
  Status.Planned,
  Status.Started,
  Status.Completed,
  Status.NotReviewed,
  Status.NotReleased,
  Status.Released,
];

const mapStatusListToOptions = (statusList: Status[], t: TFunction): Option[] => {
  const options: Option[] = [];
  statusList.forEach(status =>
    options.push({
      key: status,
      text: `${t(`status:${status}`)}`,
    })
  );
  return options;
};

export const getStatusOptions = (t: TFunction): Option[] =>
  mapStatusListToOptions(resultStatusList, t);

export const getStudentStatusOptions = (t: TFunction): Option[] =>
  mapStatusListToOptions(studentResultStatusList, t);

export const getPlanningStatusOptions = (t: TFunction): Option[] =>
  mapStatusListToOptions(planningStatusList, t);
