import moment from 'moment';
import 'moment/locale/nl';
import { client } from '../ApolloClientService';
import { GET_REVIEWED_PLANS } from './reviewedPlansQueries';
import { ReviewedPlansData } from '../../models/state/ReviewedPlansData';
import { maxPendingSeconds } from '../../api/settingsLoader';
import { cache } from '../cache';

const clearReviewedPlans = (): void => {
  cache.evict({ fieldName: 'reviewedPlans' });
  cache.evict({ fieldName: 'reviewMoment' });
  cache.gc();
};

export const getPendingPlans = (): string[] => {
  const queryResult = client.readQuery<ReviewedPlansData>({ query: GET_REVIEWED_PLANS });
  if (queryResult) {
    const now = moment();
    if (queryResult.reviewMoment) {
      const timeDifference = moment.duration(now.diff(moment(queryResult.reviewMoment)));
      if (timeDifference.asSeconds() <= maxPendingSeconds) {
        return queryResult.reviewedPlans || [];
      }
    }
    if (queryResult.reviewMoment || queryResult.reviewedPlans.length !== 0) {
      clearReviewedPlans();
    }
  }
  return [];
};

export const setReviewedPlans = (planningId: string): void => {
  const queryResult = client.readQuery<ReviewedPlansData>({ query: GET_REVIEWED_PLANS });
  if (queryResult) {
    const newState = [...queryResult.reviewedPlans, planningId];
    client.writeQuery({
      query: GET_REVIEWED_PLANS,
      data: {
        reviewedPlans: newState,
        reviewMoment: moment().format(),
      },
    });
  }
};
