import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { StudentGroups } from '../../../models/state/Plan';
import { setStudentGroupAction } from '../../../store/planning/actions';
import { studentGroupsRequest } from '../../../store/studentGroups/reducers';
import { State } from '../../../store';
import TPPlanningParticipantsOrganism from './TPPlanningParticipantsOrganism';
import { Groups } from '../../../models/state/Groups';
import { usePlanningContext } from '../PlanningContext';
import { useGetAuthenticated } from '../../../graphql/authenticated/authenticatedOperations';

interface Props {
  selectedGroups: StudentGroups;
  dispatchSetStudentGroupsAction: (studentGroups: StudentGroups) => void;
  studentGroups: Groups[];
  dispatchGetStudentGroupsRequest: (schoolId: string) => void;
}

const mapStateToProps = (state: State) => ({
  selectedGroups: state.plan.studentGroups,
  studentGroups: state.studentGroups,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<State, void, Action>) => ({
  dispatchSetStudentGroupsAction: (studentGroups: StudentGroups) => {
    dispatch(setStudentGroupAction(studentGroups));
  },
  dispatchGetStudentGroupsRequest: (schoolId: string) => {
    dispatch(studentGroupsRequest(schoolId));
  },
});

function TPPlanningParticipantsOrganismContainer({
  selectedGroups,
  dispatchSetStudentGroupsAction,
  studentGroups,
  dispatchGetStudentGroupsRequest,
}: Props) {
  const [openGroupId, setOpenGroupId] = useState<string>('');
  const { isEditEnabled } = usePlanningContext();
  const { selectedSchoolId } = useGetAuthenticated();

  useEffect(() => {
    if (selectedSchoolId) {
      dispatchGetStudentGroupsRequest(selectedSchoolId);
    }
  }, [dispatchGetStudentGroupsRequest, selectedSchoolId]);

  const isAllSelected = useCallback(
    (studentGroupId: string): boolean => selectedGroups.groupIds.includes(studentGroupId),
    [selectedGroups]
  );

  const toggleStudentGroup = (studentGroupId: string): void => {
    setOpenGroupId(studentGroupId === openGroupId ? '' : studentGroupId);
  };

  const selectAllStudentToggle = (studentGroupId: string) => {
    if (isEditEnabled) {
      const newClassIds = [...selectedGroups.groupIds];

      if (newClassIds.includes(studentGroupId)) {
        newClassIds.splice(newClassIds.indexOf(studentGroupId), 1);
      } else {
        newClassIds.push(studentGroupId);
      }

      dispatchSetStudentGroupsAction({
        groupIds: newClassIds,
        adHocStudentGroups: selectedGroups.adHocStudentGroups.filter(
          studentGroup => studentGroup.groupId !== studentGroupId
        ),
      });
    }
  };

  const countSelectedItems = useCallback(
    (studentGroup: Groups) => {
      if (isAllSelected(studentGroup.group.id)) {
        return studentGroup.profiles.length;
      }
      const group = selectedGroups.adHocStudentGroups.find(
        adHocStudentGroup => adHocStudentGroup.groupId === studentGroup.group.id
      );

      return group ? group.studentId.length : 0;
    },
    [selectedGroups, isAllSelected]
  );

  const getStudentGroupIndex = (groupId: string): number =>
    selectedGroups.adHocStudentGroups.findIndex(studentGroup => studentGroup.groupId === groupId);

  const getCheckedStudents = (studentGroup: Groups): string[] => {
    if (isAllSelected(studentGroup.group.id)) {
      return studentGroup.profiles.map(student => student.udbId);
    }

    const studentGroupIndex = getStudentGroupIndex(studentGroup.group.id);

    return studentGroupIndex > -1
      ? selectedGroups.adHocStudentGroups[studentGroupIndex].studentId
      : [];
  };

  const getStudentGroupLength = (groupId: string): number => {
    const group = studentGroups.find(groupObject => groupObject.group.id === groupId);
    return group!.profiles.length;
  };

  const handleSelectionChange = (studentId: string, groupId: string) => {
    if (isEditEnabled) {
      const newStudentGroups = selectedGroups.groupIds.filter(id => id !== groupId);
      const newAdHocStudentGroups = [...selectedGroups.adHocStudentGroups];
      if (getStudentGroupLength(groupId) === 1) {
        if (!isAllSelected(groupId)) {
          newStudentGroups.push(groupId);
        }
      } else if (isAllSelected(groupId)) {
        const studentGroup = studentGroups.find(groupObject => groupObject.group.id === groupId);
        newAdHocStudentGroups.push({
          groupId,
          studentId: studentGroup!.profiles
            .filter(student => student.udbId !== studentId)
            .map(student => student.udbId),
        });
      } else {
        const studentGroupIndex = getStudentGroupIndex(groupId);

        if (studentGroupIndex > -1) {
          if (newAdHocStudentGroups[studentGroupIndex].studentId.includes(studentId)) {
            newAdHocStudentGroups[studentGroupIndex].studentId = newAdHocStudentGroups[
              studentGroupIndex
            ].studentId.filter(id => id !== studentId);
          } else if (
            newAdHocStudentGroups[studentGroupIndex].studentId.length + 1 ===
            getStudentGroupLength(groupId)
          ) {
            newStudentGroups.push(groupId);
            newAdHocStudentGroups.splice(studentGroupIndex, 1);
          } else {
            newAdHocStudentGroups[studentGroupIndex].studentId.push(studentId);
          }
        } else {
          newAdHocStudentGroups.push({
            groupId,
            studentId: [studentId],
          });
        }
      }

      dispatchSetStudentGroupsAction({
        groupIds: newStudentGroups,
        adHocStudentGroups: newAdHocStudentGroups,
      });
    }
  };

  return (
    <TPPlanningParticipantsOrganism
      studentGroups={studentGroups}
      openGroupId={openGroupId}
      getCheckedStudents={getCheckedStudents}
      handleSelectionChange={handleSelectionChange}
      selectAllStudentToggle={selectAllStudentToggle}
      isAllSelected={isAllSelected}
      countSelectedItems={countSelectedItems}
      toggleStudentGroup={toggleStudentGroup}
    />
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TPPlanningParticipantsOrganismContainer);
