import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TableColumn,
  TableDataType,
  TableFilterType,
  TableHeaderType,
} from '../../../../../components/ILTableOrganism/ILTableColumn/createColumns';
import { IconType } from '../../../../../components/ILIconAtom';
import { Status } from '../../../../../components/TPStatusAtom/Status';
import { PlanningResult } from '../../../../../models/dto/PlanningResult';
import {
  dateColumn,
  gradeColumn,
  scoreColumn,
  statusColumn,
  testTitleColumn,
} from '../../../utils/resultColumns';
import ILTableOrganism from '../../../../../components/ILTableOrganism';
import ILTableButtonAtom from '../../../../../components/ILTableOrganism/ILTableButtonAtom';

type Props = {
  tableData: PlanningResult[];
  openResult: (planId: string, title: string) => void;
  isLoading: boolean;
};

function TPClassAllResultsTable({ tableData, openResult, isLoading }: Props): ReactElement {
  const { t } = useTranslation();

  const renderResultsActions = (rowData: PlanningResult): JSX.Element => (
    <>
      {(rowData.status !== Status.Started || !rowData.isAvailable) && (
        <ILTableButtonAtom
          type={IconType.Stats}
          title={t('resultsPage:view_results')}
          handleClick={() => openResult(rowData.planningId, rowData.title)}
        />
      )}
    </>
  );

  const setParticipantColumn = (rowData: PlanningResult): JSX.Element => (
    <span>{`${rowData.participants} ${t('resultsPage:students')}`}</span>
  );

  const resultsColumns: TableColumn[] = [
    testTitleColumn(t),
    dateColumn(t),
    {
      key: 'participants',
      headerTitle: t('resultsPage:participants'),
      columnData: TableDataType.Custom,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.None,
      customCellRenderer: setParticipantColumn,
      width: 130,
    },
    statusColumn(t, false),
    gradeColumn(t),
    scoreColumn(t, true),
    {
      key: 'options',
      columnData: TableDataType.Custom,
      header: TableHeaderType.Empty,
      filter: TableFilterType.None,
      width: 80,
      customCellRenderer: renderResultsActions,
    },
  ];

  return (
    <ILTableOrganism
      data={tableData}
      columns={resultsColumns}
      id="class-all-results"
      isLoading={isLoading}
      noDataText={t('resultsPage:no_results')}
      sortable={true}
      filterable={true}
      shouldReload={true}
      defaultSorted={[
        {
          id: 'date',
          desc: true,
        },
      ]}
    />
  );
}

export default TPClassAllResultsTable;
