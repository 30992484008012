import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useGetMethod } from '../../../../graphql/method/methodOperations';
import { useGetAuthenticated } from '../../../../graphql/authenticated/authenticatedOperations';
import { getData } from '../../../../components/ILAjaxEther';
import { webApiUrl } from '../../../../api/settingsLoader';
import { PlanningResult } from '../../../../models/dto/PlanningResult';
import { BreadcrumbPart } from '../../../../components/Breadcrumb/Breadcrumb';
import { useNlpRoutes } from '../../../../utils/nlpNavigation';
import TPClassAllResultsTable from './TPClassAllResultsTable';
import Breadcrumb from '../../../../components/Breadcrumb';
import { usePageTracking } from '../../../../utils/usePageTracking';

function ClassAllResults(): ReactElement {
  const method = useGetMethod();
  const { selectedSchoolId } = useGetAuthenticated();
  const { t } = useTranslation();
  const [results, setResults] = useState<PlanningResult[]>([]);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);

  const nlpClassedOverviewUrl = useNlpRoutes('classesOverview');
  const location = useLocation<{ name: string }>();
  const history = useHistory();
  const { classId } = useParams();

  usePageTracking('result', 'result-list', classId);

  useEffect(() => {
    if (method && selectedSchoolId && classId) {
      getData(
        `${webApiUrl}result/groups/${method.structureId}/${selectedSchoolId}/${classId}`
      ).then((response: PlanningResult[]) => {
        setResults(response);
        setIsLoadingData(false);
      });
    }
  }, [classId, method, selectedSchoolId]);

  const setPageTitle = (): string => {
    if (location.state?.name) {
      return `${t('resultsPage:results')} ${location.state.name}`;
    }
    if (results[0]?.groups[0]?.groupName) {
      return `${t('resultsPage:results')} ${results[0]?.groups[0]?.groupName}`;
    }
    return t('resultsPage:test_results_classes');
  };

  const openResult = (planId: string) => {
    history.push(`/resultaat/klas/${classId}/${planId}`);
  };

  const setBreadcrumbs = (): BreadcrumbPart[] => [
    {
      name: t('resultsPage:test_results_classes'),
      to: '/resultaten/klassen',
      nlpUrl: nlpClassedOverviewUrl,
    },
    {
      name: location.state?.name || results[0]?.groups[0]?.groupName || '',
    },
  ];

  return (
    <div className="main">
      <Breadcrumb crumbs={setBreadcrumbs()} />
      <h1>{setPageTitle()}</h1>
      <TPClassAllResultsTable
        tableData={results}
        openResult={openResult}
        isLoading={isLoadingData}
      />
    </div>
  );
}

export default ClassAllResults;
