import React, { ReactElement } from 'react';
import ILIconAtom, { IconType } from '../../../../../components/ILIconAtom';
import './style.scss';

type Props = {
  isOpen: boolean;
  toggle: () => void;
}

function TPSidebarToggleAtom({isOpen, toggle}: Props): ReactElement {
  return (
    <div className="sidebar-toggle" onClick={toggle}>
      <ILIconAtom
        type={isOpen ? IconType.NavigationBack : IconType.NavigationNext}
        size={18}
      />
    </div>
  )
}

export default TPSidebarToggleAtom;
