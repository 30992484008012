import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ILIconAtom, { IconType } from '../../ILIconAtom';

interface Props {
  pageNumbers: JSX.Element[];
  pageSizeDropdown?: JSX.Element;
  activePage: number;
  totalPageCount: number;
  previousPageCallback: () => void;
  nextPageCallback: () => void;
}

export default function ILPaginationMenuAtom({
  pageNumbers,
  pageSizeDropdown,
  activePage,
  previousPageCallback,
  nextPageCallback,
  totalPageCount,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="pagination-menu">
      {activePage !== 1 && (
        <div
          className="nav-back"
          onClick={() => previousPageCallback()}
          role="menuitem"
          tabIndex={-1}
        >
          <ILIconAtom type={IconType.NavigationBack} size={12} />
        </div>
      )}
      <ul className="pagination-navigation">{pageNumbers}</ul>
      {activePage < totalPageCount && (
        <div className="nav-next" onClick={() => nextPageCallback()} role="menuitem" tabIndex={-1}>
          <ILIconAtom type={IconType.NavigationNext} size={12} />
        </div>
      )}
      {pageSizeDropdown && (
        <div className="display-filter">
          <span>{t('pagination:display')}</span>
          {pageSizeDropdown}
        </div>
      )}
    </div>
  );
}
