import React from 'react';
import { version } from '../../api/settingsLoader';
import ILHeaderOrganism from '../ILHeaderOrganism/ILHeaderOrganismContainer';
import ILFooterMolecule from '../ILFooterMolecule/ILFooterMoleculeContainer';

export const TPLayout: React.FC = ({ children }) => (
  <>
    <ILHeaderOrganism />
    {children}
    <ILFooterMolecule version={version} />
  </>
);
