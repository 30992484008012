import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetMethod } from '../../../graphql/method/methodOperations';
import { DidacticLevel } from '../../../models/enums/DidacticLevel';

interface Props {
  level: string;
  year: string;
  chapters: string;
  numberOfQuestions: number;
  numberOfPoints: string;
}

function TPQuestionDetailPanelMolecule({
  level,
  year,
  chapters,
  numberOfPoints,
  numberOfQuestions,
}: Props): ReactElement | null {
  const { t } = useTranslation();
  const method = useGetMethod();

  return method ? (
    <table className="table-test-details">
      <tbody>
        <tr>
          <td>{t('arrangedTestDetails:level')}</td>
          <td className="detail-value">{level}</td>
        </tr>
        {method.didactic_level !== DidacticLevel.VocationalEducation && (
          <tr>
            <td>{t('arrangedTestDetails:year')}</td>
            <td className="detail-value">{year}</td>
          </tr>
        )}
        <tr>
          <td>{t('arrangedTestDetails:chapter')}</td>
          <td className="detail-value">{chapters}</td>
        </tr>
        <tr>
          <td>{t('arrangedTestDetails:number_of_questions')}</td>
          <td className="detail-value">{numberOfQuestions}</td>
        </tr>
        <tr>
          <td>{t('arrangedTestDetails:number_of_points')}</td>
          <td className="detail-value">{numberOfPoints}</td>
        </tr>
      </tbody>
    </table>
  ) : null;
}


export default TPQuestionDetailPanelMolecule;
