import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ILTableOrganismContainer from '../../../../../components/ILTableOrganism/ILTableOrganismContainer';
import {
  TableColumn,
  TableDataType,
  TableFilterType,
  TableHeaderType,
} from '../../../../../components/ILTableOrganism/ILTableColumn/createColumns';
import { testMomentsColumn } from '../../../utils/resultColumns';
import { StudentResult } from '../../../../../models/dto/StudentResult';
import TPResultsOverviewOptionsMolecule from '../../../common/TPResultsOverviewOptionsMolecule';

type Props = {
  tableData: StudentResult[];
  isLoading: boolean;
  openLastResult: (id: string, lastPlanId: string) => void;
  goToAllResults: (id: string, name: string) => void;
};

function TPStudentsOverviewTable({
  tableData,
  isLoading,
  openLastResult,
  goToAllResults,
}: Props): ReactElement {
  const { t } = useTranslation();

  const renderResultsActions = (rowData: StudentResult): JSX.Element => (
    <TPResultsOverviewOptionsMolecule
      id={rowData.udbId}
      name={`${rowData.firstName} ${rowData.lastName}`}
      lastPlanId={rowData.mostRecentPlanningId}
      openLastResult={openLastResult}
      goToAllResults={goToAllResults}
    />
  );

  const setSurname = (rowData: StudentResult): string =>
    `${rowData.lastName}${rowData.prefix ? `, ${rowData.prefix}` : ''}`;

  const studentOverviewColumns: TableColumn[] = [
    {
      key: 'firstName',
      headerTitle: t('resultsPage:first_name'),
      columnData: TableDataType.Text,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.Text,
      filterDefaultText: t('resultsPage:first_name'),
    },
    {
      key: 'lastName',
      headerTitle: t('resultsPage:last_name'),
      columnData: TableDataType.CustomText,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.Text,
      customAccessor: setSurname,
      filterDefaultText: t('resultsPage:last_name'),
    },
    {
      key: 'groupName',
      headerTitle: t('resultsPage:class'),
      columnData: TableDataType.Text,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.SelectText,
      filterDefaultText: t('resultsPage:all_classes'),
    },
    testMomentsColumn(t),
    {
      key: 'options',
      columnData: TableDataType.Custom,
      header: TableHeaderType.Empty,
      filter: TableFilterType.None,
      customCellRenderer: renderResultsActions,
      width: 400,
    },
  ];

  return (
    <ILTableOrganismContainer
      data={tableData}
      id="student-overview"
      columns={studentOverviewColumns}
      isLoading={isLoading}
      noDataText={t('resultsPage:select_filter')}
      sortable={true}
      filterable={true}
      shouldReload={true}
      defaultSorted={[
        {
          id: 'lastName',
          desc: false,
        },
      ]}
    />
  );
}

export default TPStudentsOverviewTable;
