import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CutOff, cutOffs } from './CutOff';
import { Caesura } from '../../models/state/Plan';
import TPCaesuraRowMolecule from './TPCaesuraRowMolecule/TPCaesuraRowMolecule';
import './style.scss';

type Props = {
  caesura: Caesura;
  isEditEnabled: boolean;
  handleScoreChange: (id: number, score: string) => void;
  handleGradeChange: (id: number, grade: string) => void;
};

function TPCaesuraTableOrganism({
  caesura,
  isEditEnabled,
  handleScoreChange,
  handleGradeChange,
}: Props): ReactElement {
  const { t } = useTranslation();

  const renderCaesuraHeader = (): JSX.Element => (
    <tr className="header-row">
      <th>&nbsp;</th>
      <th>{t('caesura:grade')}</th>
      <th>&nbsp;</th>
      <th>{t('caesura:percentage')}</th>
    </tr>
  );

  const renderCaesuraRows = (): JSX.Element[] =>
    cutOffs.map((cut: CutOff) => (
      <TPCaesuraRowMolecule
        key={cut.name}
        cut={cut}
        caesuraScores={caesura[cut.index]}
        isEditEnabled={isEditEnabled}
        handleScoreChange={handleScoreChange}
        handleGradeChange={handleGradeChange}
      />
    ));

  return (
    <div className="caesura-container">
      <table className="caesura-table">
        <tbody>
          {renderCaesuraHeader()}
          {renderCaesuraRows()}
        </tbody>
      </table>
    </div>
  );
}

export default TPCaesuraTableOrganism;
