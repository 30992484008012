import { useQuery } from '@apollo/client';
import { Method, MethodData } from '../../models/state/Method';
import { GET_METHOD } from './methodQuery';
import { settingsAppId } from '../../api/settingsLoader';

export function useGetMethod(): Method | undefined {
  const queryResult = useQuery<MethodData>(GET_METHOD, {
    variables: { settingsAppId, hostName: window.location.hostname },
  });
  return queryResult?.data?.method || undefined;
}
