export interface FilterObject {
  key: FilterType;
  values: string[];
  isEnabled: boolean;
  selectedValues: string[];
}

export enum FilterType {
  Language = 'languages',
  Level = 'learningLevels',
  Year = 'learningYears',
  Chapter = 'chapter',
}
