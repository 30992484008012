import React, { ReactElement } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './style.scss';
import ILPaginationMolecule from '../../components/ILPaginationMolecule';
import { News } from '../../models/state/News';

interface Props {
  newsItems: News[];
}

function NewsArchive({ newsItems }: Props): ReactElement {
  const { t } = useTranslation();

  const mapNewsItems = () =>
    newsItems.map((item: News) => (
      <div key={item.id} className="newsarchive-item">
        <div className="newsarchive-content">
          <h3>{item.title}</h3>
          <p>{moment(item.startDate).format('LL')}</p>
          <p>{item.content}</p>
          <div className="news-footer">
            {item.link && (
              <a
                className="tp-button tp-button-primary news-link"
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('newsArchive:more_info')}
              </a>
            )}
            {item.file && (
              <a
                className="tp-button tp-button-inverse news-link"
                href={item.file}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('newsArchive:download_file')}
              </a>
            )}
          </div>
        </div>
        {item.image && (
          <div className="newsarchive-image">
            <img src={item.image} alt={item.title} />
          </div>
        )}
      </div>
    ));
  return (
    <div className="main">
      <h1>{t('newsArchive:news_archive')}</h1>
      <ILPaginationMolecule
        items={mapNewsItems()}
        minItemPerStep={5}
        maxItemsPerStep={20}
        itemsPerPageStep={5}
        showTopNavigation={false}
      />
    </div>
  );
}
export default NewsArchive;
