import React, { ReactElement } from 'react';
import { Collapse } from '@material-ui/core';
import './style.scss';

type Props = {
  isOpen: boolean;
  title: string;
  closeText: string;
  openText: string;
  shouldStick?: boolean;
  children: JSX.Element;
  toggleOpen: () => void;
};

function TPReviewQuestionInfoOrganism({
  isOpen,
  title,
  closeText,
  openText,
  shouldStick,
  children,
  toggleOpen,
}: Props): ReactElement {
  return (
    <Collapse
      className={`question-info-wrapper${shouldStick ? ' question-info-wrapper-sticky' : ''}`}
      in={isOpen}
      collapsedHeight="60px"
    >
      <div className="question-info-head">
        <h2>{title}</h2>
        <button className="tp-button tp-button-link toggle-button" onClick={() => toggleOpen()}>
          {`${isOpen ? `- ${closeText}` : `+ ${openText}`}`}
        </button>
      </div>
      <div className="question-info-content">{children}</div>
    </Collapse>
  );
}

export default TPReviewQuestionInfoOrganism;
