import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useGetMethod } from '../../../graphql/method/methodOperations';
import { setReviewedPlans } from '../../../graphql/reviewedPlans/reviewedPlansOperations';
import { GET_REVIEW_TEST } from '../../../graphql/reviewTest/reviewTestQuery';
import { resetReviewTestPerQuestionData } from '../../../graphql/reviewTest/reviewTestOperations';
import { ReviewTestResponse } from '../../../models/dto/ReviewTest';
import { LoadingState } from '../../../components/ILLoadingStatusIndicator/ILLoadingStatusIndicator';
import { ILLoadingIndicator } from '../../../components/ILLoadingIndicator';
import ReviewPerQuestions from './ReviewPerQuestions';
import TPReviewHeaderOrganismContainer from '../common/TPReviewHeader';

type Props = {
  selectedSchoolId: string;
  loadingState: LoadingState;
  sendScoresToServer: (data: ReviewTestResponse) => void;
  navigateBack: (isReviewFinished: boolean) => void;
};

function ReviewPerQuestionsContainer({
  selectedSchoolId,
  loadingState,
  sendScoresToServer,
  navigateBack
}: Props): ReactElement | null {
  const method = useGetMethod();
  const { planId } = useParams<{ planId: string }>();
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState<number>(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { data, loading } = useQuery<ReviewTestResponse>(GET_REVIEW_TEST, {
    skip: !method,
    variables: {
      planId,
      selectedSchoolId,
      methodId: method?.structureId,
    },
  });

  const updateScores = () => {
    if (data) {
      sendScoresToServer(data);
    }
  };

  const changeSelectedQuestion = (newSelected: number) => {
    updateScores();
    setSelectedQuestionIndex(newSelected);
  };

  const goBack = () => {
    if (selectedQuestionIndex > 0) {
      changeSelectedQuestion(selectedQuestionIndex - 1);
    }
  };

  const goNext = () => {
    if (
      data?.reviewTest?.questionsWithAnswers &&
      selectedQuestionIndex < data?.reviewTest?.questionsWithAnswers?.length - 1
    ) {
      changeSelectedQuestion(selectedQuestionIndex + 1);
    }
  };

  const handleNavigation = (event: KeyboardEvent) => {
    if (!loading && data) {
      if (event.key === 'ArrowLeft') {
        goBack();
      }
      if (event.key === 'ArrowRight') {
        goNext();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleNavigation);

    return () => {
      window.removeEventListener('keydown', handleNavigation);
    };
  });

  const exitReview = () => {
    updateScores();
    resetReviewTestPerQuestionData();
  };

  const handleBackClick = () => {
    exitReview();
    navigateBack(false);
  };

  const finishReview = () => {
    exitReview();
    if (data?.reviewTest.planId) {
      setReviewedPlans(data.reviewTest.planId)
    }
    // Timeout is to make sure the update callback is finalized.
    setTimeout(() => {
      navigateBack(true);
    }, 2000);
  };

  if (loading || !data) {
    return (
      <div className="review-page">
        <ILLoadingIndicator fullSize isLoading />
      </div>
    );
  }

  if (!loading && data) {
    return (
      <div className="review-page">
        <TPReviewHeaderOrganismContainer
          isFirst={selectedQuestionIndex === 0}
          isLast={selectedQuestionIndex === data.reviewTest.questionsWithAnswers?.length - 1}
          loadingState={loadingState}
          isStudentReview={false}
          goBack={goBack}
          goNext={goNext}
          exitReview={handleBackClick}
        />
        <ReviewPerQuestions
          reviewTest={data.reviewTest}
          selectedQuestionIndex={selectedQuestionIndex}
          isSidebarOpen={isSidebarOpen}
          changeSelectedQuestion={changeSelectedQuestion}
          toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
          finishReview={finishReview}
        />
      </div>
    );
  }

  return null;
}

export default ReviewPerQuestionsContainer;
