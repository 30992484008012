import { useParams } from 'react-router';
import { NlpContentMatchParams } from './nlpNavigation';

export const useContextNavigation = (path?: string): string => {
  const { themeId, bookId } = useParams<NlpContentMatchParams>();

  if (!path) {
    return '/';
  }
  if (themeId || bookId) {
    return themeId ? `/theme/${themeId}${path}` : `/book/${bookId}${path}`;
  }
  return path;
};
