import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { Question, QuestionType } from '../../../models/state/Question';
import { SelectedQuestion, ArrangedTest } from '../../../models/state/arrangeTests/ArrangedTest';
import ILQuestionItemMolecule from '../../../components/ILQuestionItemMolecule';

import ILIconAtom, { IconType } from '../../../components/ILIconAtom';

import ILWizardNavigationMolecule from '../../../components/ILWizardOrganism/ILWizardNavigationMolecule';
import TPQuestionMetaMolecule from '../../../components/TPQuestionMetaMolecule';
import TPArrangedTestDetailsOrganism from '../../../components/TPArrangedTestDetailsOrganism';

import './style.scss';
import '../../../assets/theme/tp-buttons.scss';

interface Props {
  moveUp: (index: number) => void;
  moveDown: (index: number) => void;
  handleAddOrRemove: (question: Question) => void;
  getQuestionById: (id: number) => Question;
  arrangedTest: ArrangedTest;
}

function OrderQuestionsStep({
  moveUp,
  moveDown,
  handleAddOrRemove,
  getQuestionById,
  arrangedTest,
}: Props): ReactElement {
  const { t } = useTranslation();
  const mapQuestionsList = () =>
    arrangedTest.selectedQuestions.map((selectedQuestionItem: SelectedQuestion) => {
      const questionItem = getQuestionById(selectedQuestionItem.id);
      const currentIndex = arrangedTest.selectedQuestions.indexOf(selectedQuestionItem);
      if (questionItem) {
        return (
          <React.Fragment key={questionItem.id}>
            <div className="question-list-item">
              <p className="score">
                {`${t('selectQuestionsStep:score')} `}
                <span>
                  {`${questionItem.maxScore.toString().replace('.', ',')} ${t(
                    `selectQuestionsStep:${questionItem.maxScore > 1 ? 'points' : 'point'}`
                  )}`}
                </span>
              </p>
              <ILQuestionItemMolecule
                blocks={questionItem.blocks}
                snippet={questionItem.snippet}
                type={questionItem.type as QuestionType}
                enableExpand={true}
              />
            </div>
            <div className="question-list-item-footer">
              <div className="question-list-item-footer__meta">
                <TPQuestionMetaMolecule properties={questionItem.properties} />
              </div>
              <div className="question-list-item-footer__actions">
                {currentIndex !== 0 && (
                  <button
                    className="tp-button tp-button-primary-light tp-button-icon-big order-button"
                    onClick={() => moveUp(currentIndex)}
                  >
                    <ILIconAtom type={IconType.Up} size={17} />
                  </button>
                )}
                {currentIndex !== arrangedTest.selectedQuestions.length - 1 && (
                  <button
                    className="tp-button tp-button-primary-light tp-button-icon-big order-button"
                    onClick={() => moveDown(currentIndex)}
                  >
                    <ILIconAtom type={IconType.Down} size={17} />
                  </button>
                )}
                <button
                  className="tp-button tp-button-red tp-button-icon-big"
                  onClick={() => handleAddOrRemove(questionItem)}
                >
                  <ILIconAtom type={IconType.Delete} size={17} />
                </button>
              </div>
            </div>
          </React.Fragment>
        );
      }
      return <></>;
    });

  return (
    <>
      <div className="arranged-test-summary">
        <TPArrangedTestDetailsOrganism topBar={true} />
        <div className="summary-navigation">
          <ILWizardNavigationMolecule />
        </div>
      </div>
      <div className="question-list">{mapQuestionsList()}</div>
    </>
  );
}

export default OrderQuestionsStep;
