import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertType } from '../../../components/ILAlertMessageMolecule/AlertType';
import { usePlanningContext } from '../PlanningContext';
import ILAlertMessageMolecule from '../../../components/ILAlertMessageMolecule';

import './TPPlanningFooterMolecule.scss';
import '../../../assets/theme/tp-buttons.scss';

interface Props {
  errors: string[];
  isSaveDisabled: boolean;
  schedulePlan: () => void;
  cancelPlan: () => void;
}

function TPPlanningFooterMolecule({
  errors,
  schedulePlan,
  isSaveDisabled,
  cancelPlan,
}: Props): ReactElement {
  const { t } = useTranslation();
  const { isEdit } = usePlanningContext();

  const renderValidationErrors = () =>
    errors.map(error => (
      <ILAlertMessageMolecule key={error} type={AlertType.Error} message={t(`planning:${error}`)} />
    ));

  return (
    <div className="planning-footer">
      {renderValidationErrors()}
      <button
        className="tp-button tp-button-primary planning-button"
        onClick={schedulePlan}
        disabled={isSaveDisabled}
      >
        {isEdit ? t('planning:save_changes') : t('planning:schedule')}
      </button>
      <button className="tp-button tp-button-link planning-button" onClick={cancelPlan}>
        {t('planning:cancel')}
      </button>
    </div>
  );
}

export default TPPlanningFooterMolecule;
