import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { Method } from '../../models/state/Method';
import ILMenuMolecule from '../ILMenuMolecule';
import teacherMenu from '../ILMenuMolecule/teacherMenu';
import studentMenu from '../ILMenuMolecule/studentMenu';
import TPLogoAtom from '../TPLogoAtom';
import './style.scss';
import { LogoType } from '../../models/enums/LogoType';
import { useIsNlpNavigationEnabled, useNlpRoutes } from '../../utils/nlpNavigation';

interface Props {
  method?: Method;
  isTeacher: boolean;
  isAuthenticated: boolean;
  displayMenu: boolean;
}

function ILHeaderOrganism({
  method,
  isTeacher,
  isAuthenticated,
  displayMenu,
}: Props): ReactElement {
  const headerClassName = displayMenu ? 'header header-idp' : 'header-hidden';
  const isNlpNavigationEnabled = useIsNlpNavigationEnabled();
  const nlpNotificationUrl = useNlpRoutes('notifications');

  return (
    <div className={headerClassName}>
      <div className="header-content">
        {method && displayMenu && (
          <div className="header-nav">
            {nlpNotificationUrl && isNlpNavigationEnabled ? (
              <a className="logoLink" href={nlpNotificationUrl}>
                {method.logoImage && <TPLogoAtom type={method.logoImage as LogoType} />}
                {method.subtitle && <span>{method.subtitle}</span>}
              </a>
            ) : (
              <Link className="logoLink" to="/">
                {method.logoImage && <TPLogoAtom type={method.logoImage as LogoType} />}
                {method.subtitle && <span>{method.subtitle}</span>}
              </Link>
            )}
            <ILMenuMolecule
              showLicensedTests={!!method.showLicensedTests}
              hideChapterTests={!!method.hideChapterTests}
              showArrangedTests={!!method.arrangeTest}
              menuItems={isTeacher ? teacherMenu : studentMenu}
              display={isAuthenticated}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ILHeaderOrganism;
