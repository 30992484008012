import { webApiUrl } from '../api/settingsLoader';

export const WEB_API_ARRRANGE_TEST_BASE_URL = `${webApiUrl}arrangetest`;
export const DEFAULT_LANGUAGE = 'nl-NL';
export const CONCEPT_TEST = 'concept';
export const ATS_LEVEL_ORDER = [
  'vmbo-b',
  'vmbo-bk',
  'vmbo-k',
  'vmbo-kgt',
  'vmbo-g',
  'vmbo-gt',
  'vmbo-t',
  'vmbo-gt/havo',
  'vmbo-t/havo',
  'havo',
  'havo/vwo',
  'vwo',
];
