import React, { ReactElement } from 'react';
import { ReactComponent as ExpandIcon } from './Icons/expand.svg';
import { ReactComponent as CollapseIcon } from './Icons/collapse.svg';
import { ReactComponent as NavigationBackIcon } from './Icons/navigation-back.svg';
import { ReactComponent as NavigationNextIcon } from './Icons/navigation-next.svg';
import { ReactComponent as PlusCircleIcon } from './Icons/plus-circle.svg';
import { ReactComponent as DeleteIcon } from './Icons/delete.svg';
import { ReactComponent as DragIcon } from './Icons/drag.svg';
import { ReactComponent as FilterIcon } from './Icons/filter.svg';
import { ReactComponent as PencilIcon } from './Icons/pencil.svg';
import { ReactComponent as CalendarIcon } from './Icons/calendar.svg';
import { ReactComponent as EyeIcon } from './Icons/eye.svg';
import { ReactComponent as UpIcon } from './Icons/arrow-up.svg';
import { ReactComponent as DownIcon } from './Icons/arrow-down.svg';
import { ReactComponent as StatsIcon } from './Icons/stats.svg';
import { ReactComponent as LockedIcon } from './Icons/icon-locked.svg';
import { ReactComponent as PdfFileIcon } from './Icons/pdf-file.svg';
import { ReactComponent as AssignmentIcon } from './Icons/assignment.svg';
import { ReactComponent as EpubFileIcon } from './Icons/epub-file.svg';
import { ReactComponent as GenericFileIcon } from './Icons/generic-file.svg';
import { ReactComponent as AudioFileIcon } from './Icons/sound-file.svg';
import { ReactComponent as WordFileIcon } from './Icons/word-file.svg';
import { ReactComponent as VideoFileIcon } from './Icons/video-file.svg';
import { ReactComponent as ImageFileIcon } from './Icons/jpg-file.svg';
import { ReactComponent as ExcelFileIcon } from './Icons/xls-file.svg';
import { ReactComponent as PptFileIcon } from './Icons/ppt-file.svg';
import { ReactComponent as CloseIcon } from './Icons/icon-close.svg';
import { ReactComponent as ThinCloseIcon } from './Icons/close-thin.svg';
import { ReactComponent as ThinArrowLeftIcon } from './Icons/arrow-thin-left.svg';
import { ReactComponent as ThinArrowRightIcon } from './Icons/arrow-thin-right.svg';
import { ReactComponent as CsvFileIcon } from './Icons/csv-file.svg';
import { ReactComponent as SettingsIcon } from './Icons/settings.svg';
import { ReactComponent as ZoomIcon } from './Icons/zoom.svg';
import { ReactComponent as FeedbackIcon } from './Icons/feedback.svg';
import { ReactComponent as FeedbackGivenIcon } from './Icons/feedback-given.svg';
import { ReactComponent as CrossCircleIcon } from './Icons/cross-circle.svg';
import { ReactComponent as CheckCircleIcon } from './Icons/check-circle.svg';
import { ReactComponent as AlertIcon } from './Icons/alert.svg';
import { ReactComponent as EditIcon } from './Icons/edit.svg';
import { ReactComponent as ClosedLockIcon } from './Icons/lock-closed.svg';
import { ReactComponent as OpenedLockIcon } from './Icons/lock-open.svg';
import { ReactComponent as HideIcon } from './Icons/hide.svg';
import { ReactComponent as ShowIcon } from './Icons/show.svg';
import { ReactComponent as TestIcon } from './Icons/test.svg';
import { ReactComponent as SavedIcon } from './Icons/saved.svg';
import { ReactComponent as PresentingIcon } from './Icons/presenting.svg';

interface Props {
  type: IconType;
  className?: string;
  size?: number;
}

export enum IconType {
  Expand = 'expand',
  Collapse = 'collapse',
  NavigationBack = 'navigation-back',
  NavigationNext = 'navigation-next',
  PlusCircle = 'plus-circle',
  Delete = 'delete',
  Drag = 'drag',
  Filter = 'filter',
  Pencil = 'pencil',
  Calendar = 'calendar',
  Eye = 'eye',
  Up = 'up',
  Down = 'down',
  Stats = 'stats',
  Locked = 'locked',
  PdfFile = 'pdf-file',
  Assignment = 'assignment',
  EpubFile = 'epub-file',
  VideoFile = 'video-file',
  AudioFile = 'audio-file',
  WordFile = 'word-file',
  ImageFile = 'image-file',
  ExcelFile = 'excel-file',
  PptFile = 'ppt-file',
  GenericFile = 'generic-file',
  Close = 'close',
  ThinArrowLeft = 'arrow-thin-left',
  ThinArrowRight = 'arrow-thin-right',
  ThinClose = 'close-thin',
  CsvFile = 'csv-file',
  Settings = 'settings',
  Zoom = 'zoom',
  Feedback = 'feedback',
  FeedbackGiven = 'feedback-given',
  CrossCircle = 'cross-circle',
  CheckCircle = 'check-circle',
  Alert = 'alert',
  Edit = 'edit',
  ClosedLock = 'lock-closed',
  OpenedLock = 'lock-open',
  Hide = 'hide',
  Show = 'show',
  Test = 'test',
  Saved = 'saved',
  Presenting = 'presenting',
}

const renderIcon = (iconType: IconType, size?: number) => {
  const style = size !== undefined ? { width: `${size}px`, height: `${size}px` } : {};

  switch (iconType) {
    case IconType.Expand:
      return <ExpandIcon style={style} />;
    case IconType.Collapse:
      return <CollapseIcon style={style} />;
    case IconType.NavigationBack:
      return <NavigationBackIcon style={style} />;
    case IconType.NavigationNext:
      return <NavigationNextIcon style={style} />;
    case IconType.PlusCircle:
      return <PlusCircleIcon style={style} />;
    case IconType.Delete:
      return <DeleteIcon style={style} />;
    case IconType.Drag:
      return <DragIcon style={style} />;
    case IconType.Filter:
      return <FilterIcon style={style} />;
    case IconType.Pencil:
      return <PencilIcon style={style} />;
    case IconType.Calendar:
      return <CalendarIcon style={style} />;
    case IconType.Eye:
      return <EyeIcon style={style} />;
    case IconType.Up:
      return <UpIcon style={style} />;
    case IconType.Down:
      return <DownIcon style={style} />;
    case IconType.Stats:
      return <StatsIcon style={style} />;
    case IconType.Locked:
      return <LockedIcon style={style} />;
    case IconType.PdfFile:
      return <PdfFileIcon style={style} />;
    case IconType.Assignment:
      return <AssignmentIcon style={style} />;
    case IconType.EpubFile:
      return <EpubFileIcon style={style} />;
    case IconType.VideoFile:
      return <VideoFileIcon style={style} />;
    case IconType.AudioFile:
      return <AudioFileIcon style={style} />;
    case IconType.WordFile:
      return <WordFileIcon style={style} />;
    case IconType.ImageFile:
      return <ImageFileIcon style={style} />;
    case IconType.ExcelFile:
      return <ExcelFileIcon style={style} />;
    case IconType.PptFile:
      return <PptFileIcon style={style} />;
    case IconType.GenericFile:
      return <GenericFileIcon style={style} />;
    case IconType.Close:
      return <CloseIcon style={style} />;
    case IconType.ThinArrowLeft:
      return <ThinArrowLeftIcon style={style} />;
    case IconType.ThinArrowRight:
      return <ThinArrowRightIcon style={style} />;
    case IconType.ThinClose:
      return <ThinCloseIcon style={style} />;
    case IconType.CsvFile:
      return <CsvFileIcon style={style} />;
    case IconType.Settings:
      return <SettingsIcon style={style} />;
    case IconType.Zoom:
      return <ZoomIcon style={style} />;
    case IconType.Feedback:
      return <FeedbackIcon style={style} />;
    case IconType.FeedbackGiven:
      return <FeedbackGivenIcon style={style} />;
    case IconType.CrossCircle:
      return <CrossCircleIcon style={style} />;
    case IconType.CheckCircle:
      return <CheckCircleIcon style={style} />;
    case IconType.Alert:
      return <AlertIcon style={style} />;
    case IconType.Edit:
      return <EditIcon style={style} />;
    case IconType.ClosedLock:
      return <ClosedLockIcon style={style} />;
    case IconType.OpenedLock:
      return <OpenedLockIcon style={style} />;
    case IconType.Hide:
      return <HideIcon style={style} />;
    case IconType.Show:
      return <ShowIcon style={style} />;
    case IconType.Test:
      return <TestIcon style={style} />;
    case IconType.Saved:
      return <SavedIcon style={style} />;
    case IconType.Presenting:
      return <PresentingIcon style={style} />;
    default:
      return <ExpandIcon style={style} />;
  }
};

function ILIconAtom({ type, className, size }: Props): ReactElement {
  const classes = className !== undefined ? `${className} icon` : 'icon';

  return <span className={classes}>{renderIcon(type, size)}</span>;
}

export default ILIconAtom;
