import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useGetMethod } from '../../graphql/method/methodOperations';
import { useGetAuthenticated } from '../../graphql/authenticated/authenticatedOperations';
import { getData } from '../../components/ILAjaxEther';
import { paraginServiceUrl } from '../../api/settingsLoader';
import { WEB_API_CONTENT_BASE_URL } from '../../constants/PlanningResourcesContstants';
import { TestDetails } from '../../models/dto/TestDetails';
import {
  chapterTestMenuItem,
  levelTestMenuItem,
  myTestMenuItem,
} from '../../components/ILMenuMolecule/teacherMenu';
import { Origin } from '../../models/enums/Origin';
import { MenuItem } from '../../components/ILMenuMolecule/ILMenuMolecule';
import { TestType } from '../../models/enums/TestType';
import TestDetailsView from './TestDetailsView';
import { usePageTracking } from '../../utils/usePageTracking';
import { useContextNavigation } from '../../utils/useContextNavigation';

type TestParams = {
  testId: string;
  origin: string;
};

function TestDetailsContainer(): ReactElement {
  const { testId, origin } = useParams<TestParams>();
  const history = useHistory();
  const { selectedSchoolId } = useGetAuthenticated();
  const location = useLocation();
  const method = useGetMethod();
  const [testDetails, setTestDetails] = useState<TestDetails>();
  const [remindoPlayerSettings, setRemindoPlayerSettings] = useState({
    url: '',
    title: '',
  });
  const notFoundRoute = useContextNavigation('/404');

  usePageTracking('content', 'details', testId);

  useEffect(() => {
    if (testId && method?.structureId && selectedSchoolId) {
      let url;
      if (origin && (origin as Origin) === Origin.Ats) {
        url = `${WEB_API_CONTENT_BASE_URL}/${testId}/${method.structureId}/${selectedSchoolId}`;
      } else {
        url = `${WEB_API_CONTENT_BASE_URL}/${testId}`;
      }
      getData(url)
        .then((response: TestDetails) => setTestDetails(response))
        .catch(() => {
          history.push(notFoundRoute);
        });
    }
  }, [selectedSchoolId, history, method, origin, testId, notFoundRoute]);

  const openDemoRemindoPlayer = (recipeId: string, testTitle: string) => {
    if (method) {
      const returnUrl = window.location.href.includes('?')
        ? window.location.href.split('?')[0]
        : window.location;
      getData(
        `${paraginServiceUrl}/api/v1/demo-login-token?id=${recipeId}&returnUrl=${encodeURIComponent(
          `${returnUrl}`
        )}/closeRemindoPlayer&showReadspeaker=${!!method.showReadspeaker}&studies=${
          method.remindo_studyids
        }`
      ).then(response =>
        setRemindoPlayerSettings({
          url: response.url,
          title: testTitle,
        })
      );
    }
  };

  const closeRemindoPlayer = () => {
    setRemindoPlayerSettings({
      url: '',
      title: '',
    });
  };

  const getReturnPage = useCallback((): MenuItem => {
    if (origin === Origin.Ats) {
      return myTestMenuItem;
    }
    return testDetails?.classification === TestType.Ref ? levelTestMenuItem : chapterTestMenuItem;
  }, [origin, testDetails]);

  const getWorkSheetDownloadUrl = (): string | null =>
    new URLSearchParams(location.search).get('worksheetsDownloadUrl');

  return (
    <TestDetailsView
      testDetails={testDetails}
      worksheetsDownloadUrl={getWorkSheetDownloadUrl()}
      returnPage={getReturnPage()}
      remindoPlayerUrl={remindoPlayerSettings.url}
      remindoPlayerTitle={remindoPlayerSettings.title}
      openDemoRemindoPlayer={openDemoRemindoPlayer}
      closeDemoRemindoPlayer={closeRemindoPlayer}
    />
  );
}

export default TestDetailsContainer;
