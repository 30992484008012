export interface CutOff {
  index: number;
  name: CutOffName;
  fixedPercentage: boolean;
}

export enum CutOffName {
  Min = 'minimum',
  Cut1 = 'cut1',
  Cut2 = 'cut2',
  Max = 'maximum',
}

export const cutOffs: CutOff[] = [
  {
    index: 1,
    name: CutOffName.Min,
    fixedPercentage: true,
  },
  {
    index: 2,
    name: CutOffName.Cut1,
    fixedPercentage: false,
  },
  {
    index: 3,
    name: CutOffName.Cut2,
    fixedPercentage: false,
  },
  {
    index: 4,
    name: CutOffName.Max,
    fixedPercentage: true,
  },
];
