import { TypePolicies } from '@apollo/client';

export const questionsWithResultsCache: TypePolicies = {
  QuestionWithResults: {
    keyFields: false,
  },
  QuestionWithResultsCollection: {
    keyFields: ['resultId'],
  },
  Query: {
    fields: {
      questionsWithResultsList: {
        keyArgs: ['planId'],
        merge(existing, incoming) {
          if (existing?.questionWithResultsCollections) {
            const newList = [
              ...existing.questionWithResultsCollections,
              ...incoming.questionWithResultsCollections,
            ];
            return {
              ...incoming,
              questionWithResultsCollections: newList,
            };
          }
          return incoming;
        },
      },
    },
  },
};
