/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types */
import { Filter } from 'react-table';
import { Option } from '../ILTableColumn/createColumns';

export const filterBySelectedTextValue = (filter: Filter, row: any): boolean => {
  if (filter.value === 'default_option') {
    return true;
  }
  return row[filter.id]?.includes(filter.value);
};

export const filterStrictBySelectedValue = (filter: Filter, row: any): boolean => {
  if (filter.value === 'default_option') {
    return true;
  }
  return row[filter.id] === filter.value;
};

export const filterBySelectedAlphaNumericValue = (filter: Filter, row: any): boolean => {
  if (filter.value === 'default_option') {
    return true;
  }
  return row[filter.id]?.includes(filter.value);
};

export const filterBySelectedNumberValue = (filter: Filter, row: any): boolean => {
  if (filter.value === 'default_option') {
    return true;
  }
  return row[filter.id] === +filter.value;
};

export const filterByIncludedText = (filter: Filter, row: any): boolean =>
  row[filter.id].toLowerCase().includes(filter.value.toLowerCase());

export const getDistinctValuesOfNumbers = (data: any[], key: string): number[] =>
  [...new Set(data.map(test => test[key]))].sort((a: number, b: number) => a - b);

export const getDistinctValuesOfTextArray = (
  data: any[],
  key: string,
  sortingArray?: string[]
): string[] => {
  const distinct = [...new Set([].concat(...data.map(item => item[key])))];
  return sortingArray
    ? distinct.sort((a: string, b: string) => sortingArray.indexOf(a) - sortingArray.indexOf(b))
    : distinct.sort((a: string, b: string) => a?.toLowerCase().localeCompare(b?.toLowerCase()));
};

export const getDistinctValuesOfAlphanumericArray = (data: any[], key: string): string[] =>
  [...new Set([].concat(...data.map(item => item[key])))].sort((a: string, b: string) => +a - +b);

export const mapTextToCustomFilterOption = (option: string): Option => ({
  key: option,
  text: option,
});
