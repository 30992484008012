import React, { ReactElement } from 'react';
import { Filter, ReactTableFunction } from 'react-table';

interface Props {
  filter: Filter;
  onChange: ReactTableFunction;
  defaultOption: string;
  options: string[] | number[];
  keys?: string[];
}

function ILTableFilterSelectMolecule({
  filter,
  onChange,
  defaultOption,
  options,
  keys,
}: Props): ReactElement {
  const renderOptions = (values: Array<string | number>) =>
    values.map((value, index) => {
      if (value || value === 0) {
        return (
          <option key={value} aria-selected={false} value={keys ? keys[index] : value}>
            {value}
          </option>
        );
      }
      return null;
    });

  return (
    <select
      className={
        !filter || (filter && filter.value) === 'default_option' ? 'placeholder-option' : ''
      }
      onChange={event => onChange(event.target.value)}
      value={filter ? filter.value : 'default_option'}
    >
      <option key="default" aria-selected={true} value="default_option">
        {defaultOption}
      </option>
      {renderOptions(options)}
    </select>
  );
}

export default ILTableFilterSelectMolecule;
