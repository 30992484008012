import React, { ReactElement } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  version?: string;
}

function ILFooterMolecule({ version }: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <span>{`${version} ${t('footer:brand')}`}</span>
    </div>
  );
}

export default ILFooterMolecule;
