/* eslint-disable @typescript-eslint/naming-convention */
import { Filter } from 'react-table';

export enum Actions {
  SET_TABLE_PAGE = 'SET_TABLE_PAGE',
  SET_TABLE_PAGE_SIZE = 'SET_TABLE_PAGE_SIZE',
  SET_TABLE_FILTERS = 'SET_TABLE_FILTERS',
}

export interface SetTablePageAction {
  id: string;
  page: number;
  type: Actions.SET_TABLE_PAGE;
}

export interface SetTablePageSizeAction {
  id: string;
  pageSize: number;
  type: Actions.SET_TABLE_PAGE_SIZE;
}

export interface SetTableFiltersAction {
  id: string;
  filtered: Filter[];
  type: Actions.SET_TABLE_FILTERS;
}

export const setTablePageAction = (
  id: string,
  page: number
): SetTablePageAction => ({
  id,
  page,
  type: Actions.SET_TABLE_PAGE,
});

export const setTablePageSizeAction = (
  id: string,
  pageSize: number
): SetTablePageSizeAction => ({
  id,
  pageSize,
  type: Actions.SET_TABLE_PAGE_SIZE,
});

export const setTableFiltersAction = (
  id: string,
  filtered: Filter[]
): SetTableFiltersAction => ({
  id,
  filtered,
  type: Actions.SET_TABLE_FILTERS,
});
