import React, { ReactElement } from 'react';
import { useQuery } from '@apollo/client';
import { GET_QUESTIONS_WITH_RESULTS } from '../../graphql/questionsWithResults/getQuestionsWithResultsQuery';
import { ILLoadingIndicator } from '../ILLoadingIndicator';
import ILViewAnswersOrganism from '.';

interface Props {
  resultId: string;
  assignmentId?: string;
}

function ILViewAnswersOrganismContainer({ resultId, assignmentId }: Props): ReactElement {
  const { loading, data } = useQuery(GET_QUESTIONS_WITH_RESULTS, {
    variables: { resultId, assignmentId },
  });
  if (loading) {
    return <ILLoadingIndicator fullSize />;
  }

  if (data?.questionsWithResults?.length > 0) {
    return <ILViewAnswersOrganism questionsWithResults={data.questionsWithResults} />;
  }
  return <></>;
}
export default ILViewAnswersOrganismContainer;
