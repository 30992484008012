import React, { ReactElement } from 'react';
import { ILLoadingIndicator } from '../ILLoadingIndicator';
import ILModalMolecule from './ILModalMolecule';
import './style.scss';

type Props = {
  isOpen: boolean;
  title?: string;
  children?: JSX.Element;
  onClose: () => void;
};

function ILLoadingModalMolecule({ isOpen, title, children, onClose }: Props): ReactElement {
  return (
    <ILModalMolecule isOpen={isOpen} title={title} handleClose={onClose}>
      <>
        <div className="modal-loader">
          <ILLoadingIndicator isLoading={true} />
        </div>
        {children}
      </>
    </ILModalMolecule>
  );
}

export default ILLoadingModalMolecule;
