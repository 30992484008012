import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ILRemindoPlayerOrganismContainer from '../../components/ILRemindoPlayerOrganism';
import { usePageTracking } from '../../utils/usePageTracking';

interface MatchParams {
  planId?: string;
  title?: string;
}

function TakeTest(): ReactElement {
  const { planId, title } = useParams<MatchParams>();
  const { t } = useTranslation();

  usePageTracking('content', 'take', planId);

  return (
    <div className="main">
      <h1>{t('testPage:make_test')}</h1>
      {planId && <ILRemindoPlayerOrganismContainer planId={planId} title={title || ''} />}
    </div>
  );
}

export default TakeTest;
