import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ILProgressBarMolecule from '../../../../components/ILProgressBarMolecule';
import './style.scss';
import '../../../../assets/theme/tp-buttons.scss';

interface SidebarItem {
  title: string;
  progress: number;
}

type Props = {
  items: SidebarItem[];
  selectedItemIndex: number;
  isOpen: boolean;
  handleItemSelection: (selectedIndex: number) => void;
  handleFinishClick: () => void;
  percentageReviewed: number;
};

function TPSidebarMolecule({
  items,
  selectedItemIndex,
  isOpen,
  percentageReviewed,
  handleItemSelection,
  handleFinishClick,
}: Props): ReactElement {
  const { t } = useTranslation();

  const getSidebarItemStatus = (progress: number): string => {
    if (progress === 0) {
      return 'sidebar-item-status__todo';
    }
    if (progress === 1) {
      return 'sidebar-item-status__done';
    }
    return 'sidebar-item-status__active';
  };

  return (
    <div className={`sidebar ${isOpen ? 'sidebar__opened' : 'sidebar__closed'}`}>
      <h2>{t('reviewPage:review_test')}</h2>
      <div
        className={`sidebar-progress-wrapper ${
          percentageReviewed < 100 ? 'sidebar-sticky-wrapper' : ''
        } `}
      >
        <ILProgressBarMolecule completed={percentageReviewed} />
      </div>
      <div
        className={`finish-button-wrapper ${
          percentageReviewed === 100 ? 'sidebar-sticky-wrapper' : ''
        }`}
      >
        <button
          className="tp-button tp-button-secondary tp-button-thin"
          onClick={() => handleFinishClick()}
          disabled={percentageReviewed < 100}
        >
          {t('reviewPage:finish_review')}
        </button>
      </div>
      <div className="sticky-divider" />
      {items.map((item, index) => (
        <div
          key={item.title}
          className={`sidebar-item${selectedItemIndex === index ? ' sidebar-item__selected' : ''}`}
          onClick={() => handleItemSelection(index)}
        >
          <div className={`sidebar-item-status ${getSidebarItemStatus(item.progress)}`} />
          <span>{item.title}</span>
        </div>
      ))}
    </div>
  );
}

export default TPSidebarMolecule;
