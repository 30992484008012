import React, { ReactElement } from 'react';
import { LogoType } from '../../models/enums/LogoType';
import { getLogo } from '../../services/logoService';

interface Props {
  type: LogoType;
}

function TPLogoAtom({ type }: Props): ReactElement {
  return <img src={getLogo(type)} alt="logo" />;
}

export default TPLogoAtom;
