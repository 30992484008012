import { News } from '../models/state/News';
import { Method } from '../models/state/Method';

const isRelatedFeatureEnabled = (relatedFeature: string, method: Method): boolean =>
  !!method[relatedFeature as keyof Method];

export const filterNews = (newsArr: News[], method: Method): News[] =>
  newsArr.filter(
    (newsItem: News) =>
      !newsItem.relatedFeature ||
      (newsItem.relatedFeature && isRelatedFeatureEnabled(newsItem.relatedFeature, method))
  );
