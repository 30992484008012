import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ILLoadingIndicator } from '../../../../components/ILLoadingIndicator';
import ILIconAtom, { IconType } from '../../../../components/ILIconAtom';
import ILModalMolecule from '../../../../components/ILModalMolecule';
import ILModalButtonsMolecule from '../../../../components/ILModalMolecule/ILModalButtonsMolecule';
import './style.scss';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  finishReview: () => void;
};

function TPFinishReviewModalOrganism({ isOpen, closeModal, finishReview }: Props): ReactElement {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const handleFinish = () => {
    setIsLoading(true);
    finishReview();
  };

  return (
    <ILModalMolecule
      className="finish-review-modal"
      isOpen={isOpen}
      title={`${t('reviewPage:finish_review')}?`}
      handleClose={closeModal}
    >
      {isLoading ? (
        <div className="loading-container">
          <ILLoadingIndicator isLoading />
        </div>
      ) : (
        <>
          <ILIconAtom type={IconType.Test} size={230} />
          <ILModalButtonsMolecule
            onCancel={closeModal}
            onAccept={handleFinish}
            cancelText={t('reviewPage:continue_review')}
            acceptText={t('reviewPage:submit_and_close')}
          />
        </>
      )}
    </ILModalMolecule>
  );
}

export default TPFinishReviewModalOrganism;
