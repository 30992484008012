import { InMemoryCache } from '@apollo/client';
import merge from 'lodash.merge';
import { arrangeTestCache } from './arrangeTest/cache';
import { authenticatedCache } from './authenticated/cache';
import { questionsWithResultsCache } from './questionsWithResults/cache';
import { reviewTestCache, reviewTestStudentCache } from './reviewTest/cache';
import { reviewedPlansCache } from './reviewedPlans/cache';

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: merge(
    arrangeTestCache,
    reviewTestCache,
    reviewTestStudentCache,
    questionsWithResultsCache,
    authenticatedCache,
    reviewedPlansCache,
  ),
});
