import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { State } from '../../../store';
import { setScheduleTypeAction } from '../../../store/planning/actions';
import { usePlanningContext } from '../PlanningContext';
import TPPlanningScheduleOrganism from './TPPlanningScheduleOrganism';

interface Props {
  scheduleType: string;
  dispatchSetScheduleTypeAction: (scheduleType: string) => void;
}

const mapStateToProps = (state: State) => ({
  scheduleType: state.plan.scheduleType,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchSetScheduleTypeAction: (scheduleType: string) => {
    dispatch(setScheduleTypeAction(scheduleType));
  },
});

function TPPlanningScheduleOrganismContainer({
  scheduleType,
  dispatchSetScheduleTypeAction,
}: Props) {
  const { isEditEnabled } = usePlanningContext();

  const handleScheduleSelection = (type: string) => {
    if (isEditEnabled) {
      dispatchSetScheduleTypeAction(type);
    }
  };

  return (
    <TPPlanningScheduleOrganism
      selectedScheduleType={scheduleType}
      handleSelection={handleScheduleSelection}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TPPlanningScheduleOrganismContainer);
