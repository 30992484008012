import React, { ReactElement, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { ResultDashboardStudent } from '../../../models/dto/DashboardResult';
import { GET_QUESTIONS_WITH_RESULTS_COLLECTIONS } from '../../../graphql/questionsWithResults/getQuestionsWithResultsQuery';
import {
  QuestionWithResultsCollectionsResponse,
  QuestionsWithResultCollection,
} from '../../../models/dto/QuestionWithResult';
import TPStudentResultSelector from './TPStudentResultSelector';
import { resultsPerQuestionPageSize } from '../../../api/settingsLoader';

export interface StudentSelectorItem {
  firstName: string;
  prefix: string | undefined;
  score: number;
  isLoaded: boolean;
  isSelected: boolean;
  resultId: string;
  lastName: string;
}

interface Props {
  studentResults: ResultDashboardStudent[];
  handleChange: (resultId: string) => void;
  assignmentId?: string | null;
  selectedStudent?: string | null;
  planId: string;
}

function TPStudentResultSelectorContainer({
  studentResults,
  handleChange,
  assignmentId,
  selectedStudent,
  planId,
}: Props): ReactElement {
  const getResultIdList = () =>
    studentResults.map((result: ResultDashboardStudent) => result.resultId);

  const { data, fetchMore } = useQuery<QuestionWithResultsCollectionsResponse>(
    GET_QUESTIONS_WITH_RESULTS_COLLECTIONS,
    {
      variables: {
        resultIds: getResultIdList(),
        assignmentId,
        planId,
        pageSize: resultsPerQuestionPageSize,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const checkIsLoaded = (resultId: string): boolean =>
    data?.questionsWithResultsList?.questionWithResultsCollections.some(
      (collection: QuestionsWithResultCollection) => collection.resultId === resultId
    ) || false;

  const mapToStudentSelectorItem = (): StudentSelectorItem[] =>
    studentResults.map((result: ResultDashboardStudent) => ({
      firstName: result.firstName,
      prefix: result.prefix,
      score: result.score,
      isLoaded: checkIsLoaded(result.resultId),
      resultId: result.resultId,
      assignmentId: result.assignmentId,
      isSelected: result.resultId === selectedStudent,
      lastName: result.lastName,
    }));

  useEffect(() => {
    if (!selectedStudent && data?.questionsWithResultsList?.questionWithResultsCollections) {
      handleChange(data.questionsWithResultsList.questionWithResultsCollections[0].resultId);
    }
    if (data?.questionsWithResultsList?.hasMore) {
      fetchMore({
        variables: {
          after: data.questionsWithResultsList.cursor,
        },
      });
    }
  }, [data, fetchMore, handleChange, selectedStudent, studentResults]);

  return (
    <TPStudentResultSelector
      studentItems={mapToStudentSelectorItem()}
      handleChange={handleChange}
    />
  );
}

export default TPStudentResultSelectorContainer;
