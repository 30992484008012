import { ReactiveVar, makeVar, TypePolicies } from '@apollo/client';
import { Authenticated } from '../../models/state/Authenticated';

export const initialAuthenticatedState = {
  isAuthenticated: false,
  isTeacher: false,
  selectedSchoolId: '',
  udbId: '',
};

export const authenticatedVar: ReactiveVar<Authenticated> = makeVar<Authenticated>(
  initialAuthenticatedState
);

export const authenticatedCache: TypePolicies = {
  Query: {
    fields: {
      authenticated: {
        read() {
          return authenticatedVar();
        },
      },
    },
  },
};
