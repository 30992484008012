import React, { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useGetMethod } from '../../../graphql/method/methodOperations';
import { resetReviewTestPerStudentData } from '../../../graphql/reviewTest/reviewTestOperations';
import { GET_REVIEW_TEST_PER_STUDENT } from '../../../graphql/reviewTest/reviewTestQuery';
import { ReviewTestPerStudentResponse } from '../../../models/dto/ReviewTest';
import { LoadingState } from '../../../components/ILLoadingStatusIndicator/ILLoadingStatusIndicator';
import { ILLoadingIndicator } from '../../../components/ILLoadingIndicator';
import TPReviewHeaderOrganismContainer from '../common/TPReviewHeader/TPReviewHeaderOrganismContainer';
import ReviewPerStudents from './ReviewPerStudents';
import { setReviewedPlans } from '../../../graphql/reviewedPlans/reviewedPlansOperations';

type Props = {
  selectedSchoolId: string;
  loadingState: LoadingState;
  sendScoresToServer: (data: ReviewTestPerStudentResponse) => void;
  navigateBack: (isReviewFinished: boolean) => void;
};

function ReviewPerStudentsContainer({
  selectedSchoolId,
  loadingState,
  sendScoresToServer,
  navigateBack,
}: Props): ReactElement | null {
  const method = useGetMethod();
  const { planId } = useParams<{ planId: string }>();
  const [selectedStudentIndex, setSelectedStudentIndex] = useState<number>(0);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { data, loading } = useQuery<ReviewTestPerStudentResponse>(GET_REVIEW_TEST_PER_STUDENT, {
    skip: !method,
    variables: {
      planId,
      selectedSchoolId,
      methodId: method?.structureId,
    },
  });

  const updateScores = () => {
    if (data) {
      sendScoresToServer(data);
    }
  };

  const changeSelectedStudent = (newSelected: number) => {
    updateScores();
    setSelectedStudentIndex(newSelected);
  };

  const goBack = () => {
    if (selectedStudentIndex > 0) {
      changeSelectedStudent(selectedStudentIndex - 1);
    }
  };

  const goNext = () => {
    if (
      data?.reviewTestPerStudent?.studentWithAnswers &&
      selectedStudentIndex < data.reviewTestPerStudent.studentWithAnswers.length - 1
    ) {
      changeSelectedStudent(selectedStudentIndex + 1);
    }
  };

  const handleNavigation = (event: KeyboardEvent) => {
    if (!loading && data) {
      if (event.key === 'ArrowLeft') {
        goBack();
      }
      if (event.key === 'ArrowRight') {
        goNext();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleNavigation);

    return () => {
      window.removeEventListener('keydown', handleNavigation);
    };
  });

  const exitReview = () => {
    updateScores();
    resetReviewTestPerStudentData();
  };

  const handleBackClick = () => {
    exitReview();
    navigateBack(false);
  };

  const finishReview = () => {
    exitReview();
    if (data?.reviewTestPerStudent.planId) {
      setReviewedPlans(data.reviewTestPerStudent.planId)
    }
    // Timeout is to make sure the update callback is finalized.
    setTimeout(() => {
      navigateBack(true);
    }, 2000);
  };

  if (loading || !data) {
    return (
      <div className="review-page">
        <ILLoadingIndicator fullSize isLoading />
      </div>
    );
  }

  if (!loading && data) {
    return (
      <div className="review-page">
        <TPReviewHeaderOrganismContainer
          isFirst={selectedStudentIndex === 0}
          isLast={
            selectedStudentIndex === data.reviewTestPerStudent?.studentWithAnswers?.length - 1
          }
          loadingState={loadingState}
          isStudentReview
          goBack={goBack}
          goNext={goNext}
          exitReview={handleBackClick}
        />
        <ReviewPerStudents
          reviewStudents={data.reviewTestPerStudent}
          isSidebarOpen={isSidebarOpen}
          selectedStudentIndex={selectedStudentIndex}
          changeSelectedStudent={changeSelectedStudent}
          toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
          finishReview={finishReview}
        />
      </div>
    );
  }

  return null;
}

export default ReviewPerStudentsContainer;
