import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ILTableButtonAtom from '../../../components/ILTableOrganism/ILTableButtonAtom';
import { FileType } from '../../../models/enums/FileType';
import { IconType } from '../../../components/ILIconAtom';
import './style.scss';

interface Props {
  mgrRecipeId: string;
  recipeId: string;
  title: string;
  isConceptTest: boolean;
  openDetails: (id: string) => void;
  planTest: (id: string) => void;
  deleteTest: (id: string) => void;
  downloadFile: (recipeId: string, title: string, type: string) => void;
}

function TPMyTestOptionsMolecule({
  mgrRecipeId,
  recipeId,
  title,
  isConceptTest,
  openDetails,
  planTest,
  deleteTest,
  downloadFile,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="option-button-container">
      <div className="option-buttons">
        {recipeId !== '0' && (
          <>
            <ILTableButtonAtom
              type={IconType.Eye}
              title={t('myTestPage:view_details')}
              handleClick={() => openDetails(mgrRecipeId)}
            />
            <ILTableButtonAtom
              type={IconType.PdfFile}
              title={t('testsPage:download_pdf')}
              handleClick={() => downloadFile(recipeId, title, FileType.Pdf)}
            />
            {false && (
              <ILTableButtonAtom
                type={IconType.WordFile}
                title={t('testsPage:download_doc')}
                handleClick={() => downloadFile(recipeId, title, FileType.Docx)}
              />
            )}
            {!isConceptTest && (
              <ILTableButtonAtom
                type={IconType.Calendar}
                title={t('myTestPage:plan_test')}
                handleClick={() => planTest(mgrRecipeId)}
              />
            )}
          </>
        )}
        <ILTableButtonAtom
          type={IconType.Delete}
          title={t('myTestPage:delete_test')}
          handleClick={() => deleteTest(mgrRecipeId)}
        />
      </div>
    </div>
  );
}


export default TPMyTestOptionsMolecule;
