import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';
import { AnswerValue } from '../../../../../models/dto/ReviewTest';
import { client } from '../../../../../graphql/ApolloClientService';
import { GET_GIVEN_ANSWER_FRAGMENT } from '../../../../../graphql/reviewTest/reviewTestQuery';
import {
  isAnswerReviewed,
  updateCandidateResponseWithScore,
} from '../../../utils/reviewDataMapper';
import TPReviewScoreMolecule from '../TPReviewScoreMolecule';
import '../style.scss';

type Props = {
  candidateResponses: AnswerValue[];
  itemCheckId: string;
};

function TPReviewStudentBlockOrganism({ candidateResponses, itemCheckId }: Props): ReactElement {
  const { t } = useTranslation();

  const updateScore = (key: string, score: number, responseIndex: number) => {
    const newCandidateResponses = updateCandidateResponseWithScore(
      candidateResponses,
      key,
      score,
      responseIndex
    );

    client.writeFragment({
      id: `GivenAnswer:{"itemCheckId":"${itemCheckId}"}`,
      fragment: GET_GIVEN_ANSWER_FRAGMENT,
      data: {
        candidateResponses: newCandidateResponses,
        isReviewed: isAnswerReviewed(newCandidateResponses[responseIndex].criteria),
      },
    });
  };

  return (
    <>
      {candidateResponses.map((candidateResponse, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="review-answer">
          <h4>{t('reviewPage:student_answer')}</h4>
          <div
            className="given-answer"
            dangerouslySetInnerHTML={{ __html: sanitize(candidateResponse.givenAnswer) }}
          />
        </div>
      ))}
      <div className="review-scoring">
        {candidateResponses.map((candidateResponse, index) =>
          candidateResponse.criteria.map(criteria => (
            <TPReviewScoreMolecule
              key={criteria.paraginKey}
              updateCriteriaScore={updateScore}
              criteria={criteria}
              responseIndex={index}
            />
          ))
        )}
      </div>
    </>
  );
}

export default TPReviewStudentBlockOrganism;
