import axios, { AxiosRequestConfig, AxiosInstance, AxiosError } from 'axios';
import 'es6-promise/auto';
import { getToken } from '../../services/JwtService';

export interface RetryConfig extends AxiosRequestConfig {
  maxRetry: number;
  maxTimeout: number;
}

const defaultRetryConfig: RetryConfig = {
  maxRetry: 3,
  maxTimeout: 1500,
};

export function getAuthenticationInterceptor(axiosInstance: AxiosInstance) {
  return axiosInstance.interceptors.response.use(undefined, async (err: AxiosError) => {
    if (err.response) {
      if (err.response.status === undefined) {
        return Promise.reject(err);
      }
      if (err.response.status === 498 || err.response.status === 401) {
        const config: any = { ...defaultRetryConfig, ...err.config };
        config.retryCount = config.retryCount || 0;
        if (config.retryCount >= config.maxRetry) {
          return Promise.reject(err);
        }
        config.retryCount += 1;
        return getToken().then(jwtToken => {
          config.headers.Authorization = `Bearer ${jwtToken}`;
          config.headers.auth = `Bearer ${jwtToken}`;
          return axios(config);
        });
      }
      return Promise.reject(err);
    }
  });
}
