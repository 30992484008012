import { PlanErrorTypes } from '../TPPlanningFooterMolecule/PlanErrorTypes';
import { Caesura, Plan } from '../../../models/state/Plan';
import { ScheduleType } from '../../../models/enums/ScheduleType';
import {
  createDateTime,
  getEndOfNextSchoolYear,
  getToday,
  getTomorrow,
} from '../../../utils/DateTimeMapper';

const isValidTime = (time: string): boolean => {
  // HH:MM 24-hour format, mandatory leading 0
  const timeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  return !!time.match(timeRegex);
};

const hasEmptyField = (caesura: Caesura): boolean =>
  Object.keys(caesura).some((key: string) => !caesura[+key].score || !caesura[+key].grade);

const isValidPercentage = (caesura: Caesura): boolean => {
  // REGEX: 0-100
  const regex = /^[0-9]$|^[1-9][0-9]$|^(100)$/;
  return Object.keys(caesura).every((key: string) => regex.test(caesura[+key].score));
};

const isValidGrade = (caesura: Caesura): boolean => {
  // REGEX: 1.0-10
  const regex = /^[1-9]$|^[1-9]([.,])[0-9]$|^(10)$/;
  return Object.keys(caesura).every((key: string) => regex.test(caesura[+key].grade));
};

const isScoresAscending = (caesura: Caesura): boolean =>
  +caesura[4].score > +caesura[3].score &&
  +caesura[3].score >= +caesura[2].score &&
  +caesura[2].score > +caesura[1].score;

const isGradeAscending = (caesura: Caesura): boolean =>
  +caesura[4].grade > +caesura[3].grade &&
  +caesura[3].grade >= +caesura[2].grade &&
  +caesura[2].grade > +caesura[1].grade;

const isValidPin = (pin: string): boolean =>
  // REGEX: 5 digit number
  /^\d{5}$/.test(pin);

export const validateCaesura = (caesura: Caesura): PlanErrorTypes[] => {
  const caesuraErrors: PlanErrorTypes[] = [];
  if (hasEmptyField(caesura)) {
    caesuraErrors.push(PlanErrorTypes.CaesuraHasEmptyField);
  } else if (!isValidPercentage(caesura) || !isValidGrade(caesura)) {
    if (!isValidPercentage(caesura)) {
      caesuraErrors.push(PlanErrorTypes.InvalidPercentage);
    }
    if (!isValidGrade(caesura)) {
      caesuraErrors.push(PlanErrorTypes.InvalidGrade);
    }
  } else {
    if (!isScoresAscending(caesura)) {
      caesuraErrors.push(PlanErrorTypes.ScoresNotAscending);
    }
    if (!isGradeAscending(caesura)) {
      caesuraErrors.push(PlanErrorTypes.GradesNotAscending);
    }
  }
  return caesuraErrors;
};

export const validatePlan = (plan: Plan, isEdit: boolean): PlanErrorTypes[] => {
  const newErrors: PlanErrorTypes[] = [];
  const today = getToday();
  const tomorrow = getTomorrow();
  const validEndDate = getEndOfNextSchoolYear();

  if (plan.studentGroups.groupIds.length < 1 && plan.studentGroups.adHocStudentGroups.length < 1) {
    newErrors.push(PlanErrorTypes.NoClassOrStudent);
  }

  if (!plan.scheduleType) {
    newErrors.push(PlanErrorTypes.NoSchedule);
  } else if (plan.scheduleType === ScheduleType.Period) {
    if (!plan.startDate) {
      newErrors.push(PlanErrorTypes.NoStartDate);
    } else if (plan.startDate < today && !isEdit) {
      newErrors.push(PlanErrorTypes.StartBeforeToday);
    } else if (plan.startDate > validEndDate) {
      newErrors.push(PlanErrorTypes.StartAfterEndOfSchoolYear);
    }
    if (!plan.endDate) {
      newErrors.push(PlanErrorTypes.NoEndDate);
    } else if (plan.endDate < tomorrow) {
      newErrors.push(PlanErrorTypes.EndBeforeTomorrow);
    } else if (plan.endDate > validEndDate) {
      newErrors.push(PlanErrorTypes.EndAfterEndOfSchoolYear);
    } else if (plan.startDate && plan.endDate <= plan.startDate) {
      newErrors.push(PlanErrorTypes.EndBeforeStartDate);
    }
  } else if (plan.scheduleType === ScheduleType.Hour) {
    if (!plan.date || !isValidTime(plan.startTime) || !isValidTime(plan.endTime)) {
      newErrors.push(PlanErrorTypes.NoDateTime);
    } else {
      const startDateTime = createDateTime(plan.date, plan.startTime);
      const endDateTime = createDateTime(plan.date, plan.endTime);
      if (startDateTime! < today || endDateTime! < today) {
        newErrors.push(PlanErrorTypes.DateBeforeToday);
      } else if (startDateTime! > validEndDate || endDateTime! > validEndDate) {
        newErrors.push(PlanErrorTypes.DateAfterEndOfSchoolYear);
      } else if (endDateTime! < startDateTime!) {
        newErrors.push(PlanErrorTypes.EndBeforeStartTime);
      }
    }
  }

  if (plan.hasPin) {
    if (!isValidPin(plan.pin)) {
      newErrors.push(PlanErrorTypes.InvalidPin);
    }
  }

  newErrors.push(...validateCaesura(plan.caesura));

  return newErrors;
};
