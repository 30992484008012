import React, { ElementType, ReactElement } from 'react';
import ReactTable, { Column, Filter, SortingRule, SubComponentFunction } from 'react-table';
import 'react-table/react-table.css';
import './ILTableOrganism.scss';
import { ILLoadingIndicator } from '../ILLoadingIndicator';

interface Props<Type> {
  data: Type[];
  page: number | undefined;
  pageSize: number | undefined;
  filtered: Filter[];
  isLoading?: boolean | false;
  columns: Column[];
  noDataText: string;
  sortable: boolean;
  filterable: boolean;
  defaultPageSize?: number;
  paginationComponent?: ElementType;
  pageSizeOptions?: number[];
  defaultSorted?: SortingRule[];
  className?: string;
  subComponent?: SubComponentFunction;
  handlePageChange: (page: number) => void;
  handlePageSizeChange: (pageSize: number) => void;
  handleFiltersChange: (filtered: Filter[]) => void;
}

function ILTableOrganism<Type>({
  data,
  page,
  pageSize,
  filtered,
  columns,
  noDataText,
  sortable,
  isLoading,
  filterable,
  defaultPageSize,
  paginationComponent,
  pageSizeOptions,
  defaultSorted,
  className,
  subComponent,
  handlePageChange,
  handlePageSizeChange,
  handleFiltersChange,
}: Props<Type>): ReactElement {
  if (isLoading) {
    return <ILLoadingIndicator fullSize />;
  }
  return (
    <ReactTable
      data={data}
      page={page}
      pageSize={pageSize}
      filtered={filtered}
      columns={columns}
      resizable={false}
      sortable={sortable}
      filterable={filterable}
      defaultPageSize={defaultPageSize}
      pageSizeOptions={pageSizeOptions}
      minRows={1}
      PaginationComponent={paginationComponent}
      noDataText={noDataText}
      onPageChange={handlePageChange}
      onPageSizeChange={handlePageSizeChange}
      onFilteredChange={handleFiltersChange}
      defaultSorted={defaultSorted}
      className={className}
      SubComponent={subComponent}
    />
  );
}
export default ILTableOrganism;
