import React, { ReactElement } from 'react';
import moment from 'moment';
import 'moment/locale/nl';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { useTranslation } from 'react-i18next';
import { DateRangePicker } from 'react-dates';
import { DateRange } from '../../../models/state/Plan';
import '../DatePicker.scss';

interface Props {
  rangeStartDate: moment.Moment | null;
  rangeEndDate: moment.Moment | null;
  onDateRangeChange: (newRange: DateRange) => void;
  focusedInput: 'startDate' | 'endDate' | null;
  disabled: boolean;
  onFocusChange: (newFocusedInput: 'startDate' | 'endDate' | null) => void;
}

function ILDateRangeSelectMolecule({
  rangeStartDate,
  rangeEndDate,
  onDateRangeChange,
  focusedInput,
  disabled,
  onFocusChange,
}: Props): ReactElement {
  const { t } = useTranslation();

  return (
    <div className="picker-container">
      <p>{t('datePicker:date')}</p>
      <DateRangePicker
        startDate={rangeStartDate}
        startDateId="drp_start_id"
        endDate={rangeEndDate}
        endDateId="drp_end_id"
        onDatesChange={newDates => onDateRangeChange(newDates)}
        startDatePlaceholderText={t('datePicker:start_date')}
        endDatePlaceholderText={t('datePicker:end_date')}
        focusedInput={focusedInput}
        onFocusChange={newFocusedInput => onFocusChange(newFocusedInput)}
        hideKeyboardShortcutsPanel={true}
        disabled={disabled}
      />
    </div>
  );
}

export default ILDateRangeSelectMolecule;
