import { TFunction } from 'i18next';
import {
  TableColumn,
  TableDataType,
  TableFilterType,
  TableHeaderType,
} from '../../../components/ILTableOrganism/ILTableColumn/createColumns';
import { filterBySelectedPeriod, getDatePeriodOptions } from '../../../utils/tableDateFilters';
import {
  getStatusOptions,
  getStudentStatusOptions,
} from '../../../components/TPStatusAtom/statusMapper';
import {
  renderGradesColumn,
  renderMomentColumn,
  renderScoresColumn,
  renderStatusColumn,
  renderStudentGradesColumn,
} from './renderCustomTableColumns';
import { PlanningResult } from '../../../models/dto/PlanningResult';
import { Status } from '../../../components/TPStatusAtom/Status';
import { GradeType } from '../../../models/enums/GradeType';
import { AlphabeticGradeType } from '../../../models/enums/AlphabeticGradeType';
import { PlanningResultStudent } from '../../../models/dto/PlanningResultStudent';
import { filterStrictBySelectedValue } from '../../../components/ILTableOrganism/utils/rowFiltering';
import { AssignmentType } from '../../../models/enums/AssignmentType';

export const testTitleColumn = (t: TFunction): TableColumn => ({
  key: 'title',
  headerTitle: t('resultsPage:title'),
  columnData: TableDataType.Text,
  header: TableHeaderType.Sortable,
  filter: TableFilterType.Text,
  filterDefaultText: t('resultsPage:type_title'),
});

export const dateColumn = (t: TFunction): TableColumn => ({
  key: 'date',
  headerTitle: t('resultsPage:rounded_to'),
  columnData: TableDataType.Date,
  header: TableHeaderType.Sortable,
  filter: TableFilterType.CustomSelect,
  customFilterOptions: getDatePeriodOptions(t),
  customFilterMethod: filterBySelectedPeriod,
  filterDefaultText: t('resultsPage:all_date'),
  width: 190,
});

export const statusColumn = (t: TFunction, isStudentStatus: boolean): TableColumn => ({
  key: 'status',
  headerTitle: t('resultsPage:status'),
  columnData: TableDataType.Custom,
  header: TableHeaderType.NotSortable,
  filter: TableFilterType.CustomSelect,
  filterDefaultText: t('resultsPage:all'),
  customFilterOptions: isStudentStatus ? getStudentStatusOptions(t) : getStatusOptions(t),
  customFilterMethod: filterStrictBySelectedValue,
  customCellRenderer: renderStatusColumn,
  width: 150,
});

export const gradeColumn = (t: TFunction): TableColumn => ({
  key: 'grade',
  headerTitle: t('resultsPage:result'),
  columnData: TableDataType.Custom,
  header: TableHeaderType.Sortable,
  filter: TableFilterType.None,
  width: 100,
  customCellRenderer: renderGradesColumn,
  customAccessor: (row: PlanningResult) => {
    if (row.status === Status.Released && row.assignmentType !== AssignmentType.ReferenceTest) {
      return row.gradeType === GradeType.Numeric ? row.grade : 15;
    }
    return -10;
  },
});

export const studentGradeColumn = (t: TFunction): TableColumn => ({
  key: 'grade',
  headerTitle: t('resultsPage:result'),
  columnData: TableDataType.Custom,
  header: TableHeaderType.Sortable,
  filter: TableFilterType.None,
  width: 100,
  customCellRenderer: renderStudentGradesColumn,
  customAccessor: (row: PlanningResultStudent) => {
    if (row.status === Status.Released || row.assignmentType !== AssignmentType.ReferenceTest) {
      if (row.gradeType === GradeType.Alphabetic) {
        return row.alphabeticGrade === AlphabeticGradeType.Passed ? 12 : 11;
      }
      return row.grade;
    }
    return -10;
  },
});

const setScoreAccessor = (row: PlanningResult, isTeacher: boolean): number => {
  if (isTeacher) {
    return row.status === Status.Started ? -1 : row.score;
  }
  return row.status !== Status.Released ? -1 : row.score;
};

export const scoreColumn = (t: TFunction, isTeacher: boolean): TableColumn => ({
  key: 'score',
  headerTitle: t('resultsPage:score'),
  columnData: TableDataType.Custom,
  header: TableHeaderType.Sortable,
  filter: TableFilterType.None,
  width: 100,
  customCellRenderer: (row: PlanningResult) => renderScoresColumn(row, isTeacher),
  customAccessor: (row: PlanningResult) => setScoreAccessor(row, isTeacher),
});

export const testMomentsColumn = (t: TFunction): TableColumn => ({
  key: 'numberOfPlannings',
  headerTitle: t('resultsPage:test_moments'),
  columnData: TableDataType.Custom,
  header: TableHeaderType.NotSortable,
  filter: TableFilterType.None,
  width: 130,
  customCellRenderer: renderMomentColumn,
});
