import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useIsNlpNavigationEnabled } from '../../utils/nlpNavigation';

import ILIconAtom, { IconType } from '../ILIconAtom';
import './Breadcrumb.scss';

interface Props {
  crumbs: BreadcrumbPart[];
  backNavigation?: () => void;
}

export type BreadcrumbPart = {
  name: string;
  to?: string;
  state?: any;
  nlpUrl?: string;
};

function Breadcrumb({ crumbs, backNavigation }: Props): ReactElement {
  const { t } = useTranslation();
  const isNlpNavigationEnabled = useIsNlpNavigationEnabled();

  const butLastCrumbs = [...crumbs];
  const lastCrumb = butLastCrumbs.length > 0 ? butLastCrumbs.pop() : null;

  const goBackHandler =
    backNavigation ||
    (() => {
      window.history.back();
    });

  const showCrumb = (crumb: BreadcrumbPart) => {
    if (crumb.nlpUrl && isNlpNavigationEnabled) {
      return <a href={crumb.nlpUrl}>{crumb.name}</a>;
    }
    if (crumb.to) {
      return (
        <Link
          to={{
            pathname: crumb.to,
            state: crumb.state ? crumb.state : {},
          }}
        >
          {crumb.name}
        </Link>
      );
    }
    return <span>{crumb.name}</span>;
  };

  return (
    <div className="breadcrumb">
      <ILIconAtom className="breadcrumb__arrow" type={IconType.NavigationBack} />
      <button className="breadcrumb__back" onClick={goBackHandler}>
        {t('breadcrumb:back')}
      </button>
      <span className="breadcrumb__separator">|</span>
      {butLastCrumbs.map((crumb, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <span className="breadcrumb__crumb" key={index}>
          {showCrumb(crumb)}
          <ILIconAtom type={IconType.NavigationNext} />
        </span>
      ))}
      {lastCrumb && <span className="breadcrumb__crumb">{showCrumb(lastCrumb)}</span>}
    </div>
  );
}
export default Breadcrumb;
