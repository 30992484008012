import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { useTranslation } from 'react-i18next';
import { State } from '../../../../store';
import { setPinAction } from '../../../../store/planning/actions';
import { usePlanningContext } from '../../PlanningContext';
import ILSecretInputAtom from '../../../../components/ILSecretInputAtom';

interface Props {
  pin: string;
  dispatchSetPinAction: (pin: string) => void;
}

const mapStateToProps = (state: State) => ({
  pin: state.plan.pin,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchSetPinAction: (pin: string) => {
    dispatch(setPinAction(pin));
  },
});

function TPPinInputAtomContainer({ pin, dispatchSetPinAction }: Props) {
  const { t } = useTranslation();
  const { isEditEnabled } = usePlanningContext();

  const handlePinChange = (newPin: string) => {
    if (isEditEnabled && newPin.length <= 5) {
      dispatchSetPinAction(newPin);
    }
  };

  return (
    <ILSecretInputAtom
      pin={pin}
      placeholder={t('planning:pin_placeholder')}
      isDisabled={!isEditEnabled}
      handleInputChange={handlePinChange}
    />
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TPPinInputAtomContainer);
