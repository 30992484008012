export const formatGradeValue = (gradeValue: number | string): string =>
  gradeValue === 10
    ? `${gradeValue}`
    : gradeValue.toLocaleString('nl-NL', { maximumFractionDigits: 1, minimumFractionDigits: 1 });

export const formatFloatValue = (value: number): string =>
  value.toLocaleString('nl-NL', { maximumFractionDigits: 1 });

export const formatFloatValueWithDigits = (value: number, digits: number): string =>
  value.toLocaleString('nl-NL', { maximumFractionDigits: digits });

export const formatStringGradeValue = (gradeString: string): string =>
  gradeString === '10,0' ? '10' : gradeString;
