/* eslint-disable @typescript-eslint/naming-convention */
import 'es6-promise/auto';
import axios, { Method } from 'axios';
import { getAuthenticationInterceptor } from './AxiosInterceptors';
import { getToken } from '../../services/JwtService';

const axiosInstance = axios.create();

getAuthenticationInterceptor(axiosInstance);

const setHeaders = (jwt: string) => ({
  auth: `Bearer ${jwt}`,
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  'Access-Control-Allow-Origin': '*',
  Expires: -1,
});

export async function getData(url: string) {
  const jwt = await getToken();

  return axiosInstance
    .get(url, {
      headers: setHeaders(jwt),
    })
    .then(response => response.data);
}

export async function deleteData(url: string) {
  const jwt = await getToken();
  return axiosInstance.delete(url, { headers: setHeaders(jwt) });
}

export async function createRequest(method: Method, url: string, data?: unknown) {
  const jwt = await getToken();
  return axiosInstance({ method, url, data, headers: setHeaders(jwt) });
}
