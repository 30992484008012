import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_ARRANGED_TEST } from '../../graphql/arrangeTest/arrangeTestQueries';
import { resetArrangedTest } from '../../graphql/arrangeTest/arrangeTestOperations';
import { removeCachedQuestions } from '../../graphql/questions/questionOperations';
import { useGetMethod } from '../../graphql/method/methodOperations';
import { removeCachedFilters } from '../../graphql/arrangeTest/filters/filterOperations';
import { useGetAuthenticated } from '../../graphql/authenticated/authenticatedOperations';
import { useIsNlpNavigationEnabled, useNlpRoutes } from '../../utils/nlpNavigation';
import { useContextNavigation } from '../../utils/useContextNavigation';

import { createArrangedTestDto, createQuestionsPayload } from './utils/mapArrangeTest';
import { createRequest } from '../../components/ILAjaxEther';
import { getTestPageByKey, myTestMenuItem } from '../../components/ILMenuMolecule/teacherMenu';
import { WEB_API_ARRRANGE_TEST_BASE_URL } from '../../constants/ArrangeTestContstants';
import { MenuItem } from '../../components/ILMenuMolecule/ILMenuMolecule';
import { MethodType } from '../../components/ILAjaxEther/MethodType';
import { ConfirmModalInfo } from '../../components/ILConfirmationModalMolecule/ILConfirmationModalMolecule';
import { ArrangedTest } from '../../models/state/arrangeTests/ArrangedTest';
import { DidacticLevel } from '../../models/enums/DidacticLevel';

import TPBlueHeaderOrganismContainer from '../../components/TPBlueHeaderOrganism/TPBlueHeaderOrganismContainer';
import ILWizardOrganism from '../../components/ILWizardOrganism';
import ILWizardStep from '../../components/ILWizardOrganism/ILWizardStep';
import OrderQuestionsStep from './OrderQuestionsStep';
import ILConfirmationModalMolecule from '../../components/ILConfirmationModalMolecule';
import PageNotFound from '../PageNotFound';
import SelectFilterStepContainer from './SelectFiltersStep/SelectFiltersStepContainer';
import SelectQuestionsStepContainer from './SelectQuestionsStep';
import { usePageTracking } from '../../utils/usePageTracking';
import { cachePersistor } from '../../graphql/ApolloClientService';

const resetWizardData = () => {
  resetArrangedTest();
  removeCachedQuestions();
  removeCachedFilters();
};

function ArrangeTest(): ReactElement {
  const arrangedTestQueryResult = useQuery(GET_ARRANGED_TEST);
  const { selectedSchoolId } = useGetAuthenticated();
  const history = useHistory();
  const location = useLocation<{ key: string }>();
  const method = useGetMethod();

  const isNlpNavigationEnabled = useIsNlpNavigationEnabled();
  const nlpMyTestsUrl = useNlpRoutes('myTests');
  const myTestsPath = useContextNavigation('/toetsen/mijn-toetsen');

  const { t } = useTranslation();
  const noTitleMessage = (
    <span>
      {t('testArrange:missing_title_description')}
      <i>{` ${t('myTestPage:my_tests')}.`}</i>
    </span>
  );

  const [isInitLoad, setIsInitLoad] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [modalSettings, setModalSettings] = useState<ConfirmModalInfo>({
    itemId: '',
    display: false,
    showLoading: false,
  });

  usePageTracking('content', 'create');

  const updateTestArrange = useCallback(
    (finalized: boolean, arrangedTest: ArrangedTest) => {
      if (finalized && (arrangedTest.title.trim() === '' || arrangedTest.title.length > 100)) {
        setModalSettings({
          ...modalSettings,
          display: true,
        });
      } else if (!isSaving && method?.structureId) {
        setIsSaving(true);
        createRequest(
          MethodType.Save,
          `${WEB_API_ARRRANGE_TEST_BASE_URL}/setQuestions/${arrangedTest.mgrRecipeId}`,
          createQuestionsPayload(arrangedTest)
        )
          .then(() => {
            createRequest(
              MethodType.Save,
              `${WEB_API_ARRRANGE_TEST_BASE_URL}/updateRecipe/${arrangedTest.mgrRecipeId}`,
              createArrangedTestDto(arrangedTest, selectedSchoolId, method.structureId, finalized)
            )
              .then(() => {
                if (finalized) {
                  if (isNlpNavigationEnabled && nlpMyTestsUrl) {
                    cachePersistor.purge().then(() => {
                      window.location.href = nlpMyTestsUrl;
                    });
                  } else {
                    history.push(myTestsPath);
                  }
                }
                setIsSaving(false);
              })
              .catch(() => {
                setIsSaving(false);
              });
          })
          .catch(() => {
            setIsSaving(false);
          });
      }
    },
    [
      isSaving,
      method,
      modalSettings,
      selectedSchoolId,
      isNlpNavigationEnabled,
      nlpMyTestsUrl,
      history,
      myTestsPath,
    ]
  );

  useEffect(
    () => () => {
      if (!isNlpNavigationEnabled) {
        resetWizardData();
      }
    },
    [isNlpNavigationEnabled]
  );

  const getReturnPage = useCallback(
    (): MenuItem => (location.state?.key ? getTestPageByKey(location.state.key) : myTestMenuItem),
    [location]
  );

  const closeModal = () => {
    setModalSettings({
      ...modalSettings,
      display: false,
    });
  };

  if (method?.structureId) {
    if (!method.arrangeTest) {
      return <Route component={PageNotFound} />;
    }

    if (
      method.arrangeTest &&
      !arrangedTestQueryResult.loading &&
      arrangedTestQueryResult.data?.arrangedTest
    ) {
      const { arrangedTest } = arrangedTestQueryResult.data;
      return (
        <>
          <TPBlueHeaderOrganismContainer
            title={t('testArrange:arrange_test')}
            menuItem={getReturnPage()}
          />
          <div className="main">
            <ILWizardOrganism
              finish={() => {
                updateTestArrange(true, arrangedTest);
              }}
            >
              <ILWizardStep key={0} name={t('testArrange:step1')}>
                <SelectFilterStepContainer
                  categoryIds={method.categoryIds}
                  isMultiLingual={method.isMultiLingual}
                  isVe={method.didactic_level === DidacticLevel.VocationalEducation}
                  arrangedTest={arrangedTest}
                  initLoad={isInitLoad}
                  setInitLoaded={() => setIsInitLoad(false)}
                />
              </ILWizardStep>
              <ILWizardStep key={1} name={t('testArrange:step2')}>
                <SelectQuestionsStepContainer
                  createArrangedTestDto={createArrangedTestDto}
                  categoryIds={method.categoryIds}
                  selectedSchoolId={selectedSchoolId}
                  methodId={method.structureId}
                  isVe={method.didactic_level === DidacticLevel.VocationalEducation}
                />
              </ILWizardStep>
              <ILWizardStep key={2} name={t('testArrange:step3')}>
                <OrderQuestionsStep
                  isSaving={isSaving}
                  isVe={method.didactic_level === DidacticLevel.VocationalEducation}
                />
              </ILWizardStep>
            </ILWizardOrganism>
          </div>
          <ILConfirmationModalMolecule
            modalInfo={modalSettings}
            title={t('testArrange:missing_or_too_long_title')}
            description={noTitleMessage}
            customCancelText={t('testArrange:close')}
            onCancel={closeModal}
          />
        </>
      );
    }
  }
  return <></>;
}

export default ArrangeTest;
