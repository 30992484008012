import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { usePlanningContext } from './PlanningContext';
import { AlertType } from '../../components/ILAlertMessageMolecule/AlertType';
import { MenuItem } from '../../components/ILMenuMolecule/ILMenuMolecule';
import { ILLoadingIndicator } from '../../components/ILLoadingIndicator';
import ILAlertMessageMolecule from '../../components/ILAlertMessageMolecule';
import PlanningParticipants from './TPPlanningParticipantsOrganism';
import PlanningSchedule from './TPPlanningScheduleOrganism';
import PlanningResult from './TPPlanningResultOrganism';
import PlanningOptions from './TPPlanningOptionsOrganism';
import PlanningCaesura from './TPPlanningCaesuraOrganism';
import PlanningHeader from './TPPlanningHeaderOrganism';
import PlanningFooter from './TPPlanningFooterMolecule';
import PlanStep from './PlanStep';
import './Planning.scss';

interface Props {
  isLoading: boolean;
  isRefTest: boolean;
  returnPage: MenuItem;
}

function Planning({ isLoading, isRefTest, returnPage }: Props): ReactElement {
  const { t } = useTranslation();
  const { isEdit, isEditEnabled } = usePlanningContext();

  const renderSteps = () => (
    <>
      <PlanStep
        key={0}
        title={t('planning:student_selection_title')}
        help={t('planning:student_selection_help')}
      >
        <PlanningParticipants />
      </PlanStep>
      <PlanStep key={1} title={t('planning:schedule_title')}>
        <PlanningSchedule />
      </PlanStep>
      {!isRefTest && (
        <>
          <PlanStep key={2} title={t('planning:result_title')} help={t('planning:result_help')}>
            <PlanningResult />
          </PlanStep>
          <PlanStep key={3} title={t('planning:caesura_title')} help={t('planning:caesura_help')}>
            <PlanningCaesura />
          </PlanStep>
        </>
      )}
      <PlanStep key={4} title={t('planning:options')} last={true}>
        <PlanningOptions />
      </PlanStep>
    </>
  );

  const renderLoading = () => (
    <div className="main">
      <ILLoadingIndicator isLoading={isLoading} fullSize />
    </div>
  );

  return isLoading ? (
    renderLoading()
  ) : (
    <>
      <PlanningHeader returnPage={returnPage} />
      <div className="main">
        {isEdit && !isEditEnabled && (
          <div className="planning-alert">
            <ILAlertMessageMolecule type={AlertType.Error} message={t('planning:plan_started')} />
          </div>
        )}
        <div className={`planning-container${isEditEnabled ? '' : ' planning-disabled'}`}>
          {renderSteps()}
        </div>
        <PlanningFooter returnPage={returnPage} />
      </div>
    </>
  );
}

export default Planning;
