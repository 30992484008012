import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FilterObject, FilterType } from '../../../models/state/arrangeTests/Filters';
import ILConfirmationModalMolecule, {
  ConfirmModalInfo,
} from '../../../components/ILConfirmationModalMolecule/ILConfirmationModalMolecule';
import ILWizardNavigationMolecule from '../../../components/ILWizardOrganism/ILWizardNavigationMolecule';
import TPFilterSelectMoleculeContainer from './TPFilterSelectMolecule';
import './SelectFiltersStep.scss';

interface Props {
  hasSelectedQuestions: boolean;
  filters: FilterObject[];
  availableFilters: FilterType[];
  isLoading: boolean;
  onUpdate: (type: FilterType, values: string[]) => void;
  resetStoredData: () => void;
}

interface ModalConfirmData {
  values: string[];
  type: FilterType;
}

function SelectFiltersStep({
  hasSelectedQuestions,
  filters,
  availableFilters,
  isLoading,
  resetStoredData,
  onUpdate,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [lastSelected, setLastSelected] = useState(-1);
  const [modalSettings, setModalSettings] = useState<ConfirmModalInfo>({
    itemId: '',
    showLoading: false,
    display: false,
    confirmData: undefined,
  });

  const closeModal = () => {
    setModalSettings({
      ...modalSettings,
      display: false,
      confirmData: undefined,
    });
  };

  const shouldShowConfirmModal = (selectedFilterType: FilterType, data: string[]): boolean => {
    if (hasSelectedQuestions && availableFilters.length > 0) {
      if (selectedFilterType === availableFilters[availableFilters.length - 1]) {
        const oldFilter = filters.find((filter: FilterObject) => filter.key === selectedFilterType);
        if (oldFilter) {
          return oldFilter?.selectedValues?.length > data.length;
        }
      } else {
        return true;
      }
    }
    return false;
  };

  const handleConfirm = (data: ModalConfirmData) => {
    onUpdate(data.type, data.values);
    closeModal();
    resetStoredData();
  };

  const handleUpdate = (type: FilterType, values: string[], index: number) => {
    if (shouldShowConfirmModal(type, values)) {
      setModalSettings({
        ...modalSettings,
        display: true,
        confirmData: {
          values,
          type,
        },
      });
    } else {
      setLastSelected(index);
      onUpdate(type, values);
    }
  };

  const mapFilters = () =>
    filters.map((filter: FilterObject, index) => {
      if (availableFilters.some((type: FilterType) => filter.key === type)) {
        return (
          <TPFilterSelectMoleculeContainer
            key={filter.key}
            filter={filter}
            isLoading={isLoading && index > lastSelected}
            handleFilterChange={(type: FilterType, values: string[]) =>
              handleUpdate(type, values, index)
            }
          />
        );
      }
      return null;
    });

  if (filters && filters.length > 0) {
    return (
      <>
        <section className="filter-step">
          <h1>{t('filterStep:instruction')}</h1>
          {mapFilters()}
        </section>
        <ILConfirmationModalMolecule
          modalInfo={modalSettings}
          title={t('filterStep:deselect_modal_title')}
          description={t('filterStep:deselect_modal_description')}
          onCancel={closeModal}
          onAccept={handleConfirm}
        />
        <ILWizardNavigationMolecule />
      </>
    );
  }
  return <></>;
}

export default SelectFiltersStep;
