import {
  Actions,
  SetTableFiltersAction,
  SetTablePageAction,
  SetTablePageSizeAction,
} from './actions';
import { Tables } from '../../models/state/Tables';

const initialState: Tables = {};

export default (
  state = initialState,
  action: SetTablePageAction | SetTablePageSizeAction | SetTableFiltersAction
): Tables => {
  switch (action.type) {
    case Actions.SET_TABLE_PAGE: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          page: action.page,
        },
      };
    }
    case Actions.SET_TABLE_PAGE_SIZE: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          pageSize: action.pageSize,
        },
      };
    }
    case Actions.SET_TABLE_FILTERS: {
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          filtered: action.filtered,
        },
      };
    }
    default: {
      return state;
    }
  }
};
