import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ILInformationModalMolecule from '../../../../../components/ILModalMolecule/ILInformationModalMolecule';
import './style.scss';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
};

function TPCloseInfoModalOrganism({ isOpen, handleClose }: Props): ReactElement {
  const { t } = useTranslation();

  const closeInfoElement: JSX.Element = (
    <div className="close-test-description">
      <p>{t('resultsPage:test_completed')}</p>
      <p>{t('resultsPage:can_take_few_moments')}</p>
      <p>{t('resultsPage:try_again')}</p>
    </div>
  );

  return (
    <ILInformationModalMolecule
      isOpen={isOpen}
      onClose={handleClose}
      className="close-info-modal"
      title={t('resultsPage:close_tests')}
      subComponent={closeInfoElement}
    />
  );
}

export default TPCloseInfoModalOrganism;
