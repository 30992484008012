import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { useContextNavigation } from '../../../utils/useContextNavigation';
import ILIcon, { IconType } from '../../ILIconAtom/ILIconAtom';
import { MenuItem } from '../ILMenuMolecule';
import '../style.scss';

interface Props {
  menuItem: MenuItem;
}

export const ILTpMenuLink: React.FC<Props> = ({ menuItem }) => {
  const { t } = useTranslation();
  const menuContextRoute = useContextNavigation(menuItem.url);

  return (
    <NavLink
      exact={menuItem.url === '/'}
      to={menuContextRoute}
      id="link"
      className={`menu-link${menuItem.items ? ' disabled-link' : ''}`}
      activeClassName={`menu-link-active${menuItem.items ? ' disabled-link' : ''}`}
    >
      {t(`menu:${menuItem.resourceKey}`)}
      {menuItem.items && (
        <span className="icon">
          <ILIcon type={IconType.Expand} size={10} />
        </span>
      )}
    </NavLink>
  );
};
