import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { sanitize } from 'dompurify';
import { client } from '../../../../../graphql/ApolloClientService';
import { GET_STUDENT_GIVEN_ANSWER_FRAGMENT } from '../../../../../graphql/reviewTest/reviewTestQuery';
import {
  getMaxPoints,
  isAnswerReviewed,
  updateCandidateResponseWithScore,
} from '../../../utils/reviewDataMapper';
import {
  QuestionWithAnswerBlock,
  ReviewQuestion,
  StudentGivenAnswer,
} from '../../../../../models/dto/ReviewTest';
import { QuestionType } from '../../../../../models/state/Question';
import TPReviewScoreMolecule from '../TPReviewScoreMolecule';
import ILQuestionItemMolecule from '../../../../../components/ILQuestionItemMolecule';
import '../style.scss';

type Props = {
  question?: ReviewQuestion;
  studentGivenAnswer: StudentGivenAnswer;
};

function TPReviewQuestionBlockOrganism({ question, studentGivenAnswer }: Props): ReactElement {
  const { t } = useTranslation();
  const maxPoints = getMaxPoints(studentGivenAnswer.candidateResponses);

  const updateScore = (key: string, score: number, responseIndex: number) => {
    const newCandidateResponses = updateCandidateResponseWithScore(
      studentGivenAnswer.candidateResponses,
      key,
      score,
      responseIndex
    );

    client.writeFragment({
      id: `StudentGivenAnswer:{"itemCheckId":"${studentGivenAnswer.itemCheckId}"}`,
      fragment: GET_STUDENT_GIVEN_ANSWER_FRAGMENT,
      data: {
        candidateResponses: newCandidateResponses,
        isReviewed: isAnswerReviewed(newCandidateResponses[responseIndex].criteria),
      },
    });
  };

  return (
    <>
      <p className="max-score">
        <b>{`${t('reviewPage:max')}: `}</b>
        {`${maxPoints} ${maxPoints === 1 ? t('reviewPage:point') : t('reviewPage:points')}`}
      </p>
      {question && (
        <ILQuestionItemMolecule
          type={QuestionType.ExtendedText}
          blocks={question.blocks.map((block: QuestionWithAnswerBlock, index: number) => ({
            identifier: `${question.id}_block_${index}`,
            type: block.type,
            html: block.html,
          }))}
        />
      )}
      <div className="review-answer">
        <h4>{t('reviewPage:correct_answer')}</h4>
        <div dangerouslySetInnerHTML={{ __html: sanitize(studentGivenAnswer.correctAnswer) }} />
      </div>
      {studentGivenAnswer.candidateResponses.map((candidateResponse, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} className="review-answer">
          <h4>{t('reviewPage:student_answer')}</h4>
          <div
            className="given-answer"
            dangerouslySetInnerHTML={{ __html: sanitize(candidateResponse.givenAnswer) }}
          />
        </div>
      ))}
      <div className="review-scoring">
        {studentGivenAnswer.candidateResponses.map((candidateResponse, index) =>
          candidateResponse.criteria.map(criteria => (
            <TPReviewScoreMolecule
              key={criteria.paraginKey}
              updateCriteriaScore={updateScore}
              criteria={criteria}
              responseIndex={index}
            />
          ))
        )}
      </div>
    </>
  );
}

export default TPReviewQuestionBlockOrganism;
