import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { arrangeTestQuestionsCap } from '../../../api/settingsLoader';

import { QuestionType, Question } from '../../../models/state/Question';
import { SelectedQuestion } from '../../../models/state/arrangeTests/ArrangedTest';

import { useWizardContext } from '../../../components/ILWizardOrganism/ILWizardOrganism';

import ILQuestionItemMolecule from '../../../components/ILQuestionItemMolecule';
import ILWizardNavigationMolecule from '../../../components/ILWizardOrganism/ILWizardNavigationMolecule';
import TPArrangedTestDetailsMolecule from '../../../components/TPArrangedTestDetailsOrganism';
import ILPaginationMolecule from '../../../components/ILPaginationMolecule';
import ILIconAtom, { IconType } from '../../../components/ILIconAtom';
import TPQuestionMetaMolecule from '../../../components/TPQuestionMetaMolecule';
import TPQuestionFilterOrganism from './TPQuestionFilterOrganism';
import ILInformationModalMolecule from '../../../components/ILModalMolecule/ILInformationModalMolecule';

import './style.scss';
import '../../../assets/theme/tp-buttons.scss';

interface Props {
  questions: Question[];
  numberOfQuestions: number;
  handleAddOrRemove: (question: Question) => void;
  selectedQuestions: SelectedQuestion[];
}

function SelectQuestionsStep({
  questions,
  numberOfQuestions,
  selectedQuestions,
  handleAddOrRemove,
}: Props): ReactElement {
  const { t } = useTranslation();
  const { goTo } = useWizardContext();
  const checkIfSelected = (id: number) =>
    selectedQuestions.some((item: SelectedQuestion) => item.id === id);

  const mapQuestionsList = (questionList: Question[]) =>
    questionList.map((questionItem: Question) => {
      const isSelected = checkIfSelected(questionItem.id);
      return (
        <React.Fragment key={questionItem.identifier}>
          <div className={isSelected ? 'question-list-item selected' : 'question-list-item'}>
            <p className="score">
              {`${t('selectQuestionsStep:score')} `}
              <span>
                {`${questionItem.maxScore.toString().replace('.', ',')} ${t(
                  `selectQuestionsStep:${questionItem.maxScore > 1 ? 'points' : 'point'}`
                )}`}
              </span>
            </p>
            <ILQuestionItemMolecule
              blocks={questionItem.blocks}
              type={questionItem.type as QuestionType}
            />
          </div>
          <div className="question-list-item-footer">
            <div
              className={
                isSelected
                  ? 'question-list-item-footer__meta selected'
                  : 'question-list-item-footer__meta'
              }
            >
              <TPQuestionMetaMolecule properties={questionItem.properties} />
            </div>
            <div className="question-list-item-footer__actions">
              <button
                className={`tp-button ${
                  isSelected
                    ? 'tp-button-red tp-button-with-icon remove-button'
                    : 'tp-button-primary add-button'
                }`}
                onClick={() => handleAddOrRemove(questionItem)}
              >
                {isSelected ? (
                  <>
                    <ILIconAtom type={IconType.Delete} size={17} />
                    {t('selectQuestionsStep:remove')}
                  </>
                ) : (
                  t('selectQuestionsStep:add')
                )}
              </button>
            </div>
          </div>
        </React.Fragment>
      );
    });

  if (numberOfQuestions > arrangeTestQuestionsCap) {
    return (
      <ILInformationModalMolecule
        isOpen={true}
        title={t('selectQuestionsStep:too_many_questions')}
        subText={t('selectQuestionsStep:too_many_questions_description')}
        onClose={() => goTo(0, true)}
      />
    );
  }
  return (
    <div className="select-questions-wrapper">
      <div className="questions-list">
        <TPQuestionFilterOrganism />
        <h2>
          <b>{questions.length}</b>
          {` ${t('selectQuestionsStep:questions_found')}`}
        </h2>
        <ILPaginationMolecule
          items={mapQuestionsList(questions)}
          minItemPerStep={5}
          maxItemsPerStep={20}
          itemsPerPageStep={5}
          showTopNavigation={true}
        />
      </div>
      <div className="sidebar-wrapper">
        <div className="sticky-sidebar">
          <TPArrangedTestDetailsMolecule />
          <ILWizardNavigationMolecule />
        </div>
      </div>
    </div>
  );
}

export default SelectQuestionsStep;
