import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { client } from '../../graphql/ApolloClientService';
import { useGetMethod } from '../../graphql/method/methodOperations';
import {
  resetDataToSave,
  resetReviewTestPerQuestionData,
  resetReviewTestPerStudentData,
} from '../../graphql/reviewTest/reviewTestOperations';
import {
  GET_REVIEW_TEST,
  GET_REVIEW_TEST_PER_STUDENT,
  UPDATE_SCORES,
} from '../../graphql/reviewTest/reviewTestQuery';
import { useIsNlpNavigationEnabled, useNlpGroupDashboardRoutes, useNlpRoutes } from '../../utils/nlpNavigation';
import {
  getReviewQuestionItemsToSave,
  getStudentReviewItemsToSave,
} from './utils/reviewDataMapper';
import {
  ReviewTestPerStudentResponse,
  ReviewTestResponse,
  StudentWithAnswers,
} from '../../models/dto/ReviewTest';
import { ReviewItemsToSave, UpdateScoresRequest } from '../../models/dto/updateScoresRequest';
import { LoadingState } from '../../components/ILLoadingStatusIndicator/ILLoadingStatusIndicator';
import { ILLoadingIndicator } from '../../components/ILLoadingIndicator';
import ReviewPerQuestionsContainer from './ReviewPerQuestions';
import ReviewPerStudentsContainer from './ReviewPerStudents';
import { useGetAuthenticated } from '../../graphql/authenticated/authenticatedOperations';
import { usePageTracking } from '../../utils/usePageTracking';

enum ReviewPageType {
  ReviewPerQuestion = 'reviewPerQuestion',
  ReviewPerStudent = 'reviewPerStudent',
}

function ReviewWrapper(): ReactElement | null {
  const history = useHistory();
  const { selectedSchoolId } = useGetAuthenticated();
  const location = useLocation<{ returnUrl?: string }>();
  const method = useGetMethod();
  const { planId } = useParams<{ planId: string }>();
  const groupId = new URLSearchParams(location.search).get('groupId')

  const isNlpNavigationEnabled = useIsNlpNavigationEnabled();
  const nlpNotificationsPage = useNlpRoutes('notifications');
  const nlpGroupResultDashboardPage = useNlpGroupDashboardRoutes(planId, false, groupId || '');
  const nlpGroupResultDashboardPendingPage = useNlpGroupDashboardRoutes(planId, true, groupId || '');

  const [isCacheCleared, setIsCacheCleared] = useState<boolean>(false);
  const [loadingState, setLoadingState] = useState<LoadingState>(LoadingState.None);
  const [pageType, setPageType] = useState<ReviewPageType>();
  const [updateScores] = useMutation<UpdateScoresRequest>(UPDATE_SCORES);

  usePageTracking('result', 'review', planId);

  useEffect(() => {
    if (location.pathname) {
      location.pathname.includes('/toets-nakijken-per-vraag')
        ? setPageType(ReviewPageType.ReviewPerQuestion)
        : setPageType(ReviewPageType.ReviewPerStudent);
    }
  }, [location]);

  const sendScoresToServer = useCallback(
    (dataToSave: ReviewItemsToSave[], correctorId: number, recipeId: string) => {
      setLoadingState(LoadingState.Saving);
      updateScores({
        variables: {
          correctorId,
          recipeId,
          reviewItems: dataToSave,
        },
        update() {
          dataToSave.forEach(item => {
            resetDataToSave(item.id);
          });
          setLoadingState(LoadingState.Saved);
        },
      });
    },
    [updateScores]
  );

  const sendStudentScoresToServer = useCallback(
    (studentData: ReviewTestPerStudentResponse) => {
      const saveAll = studentData.reviewTestPerStudent.percentageReviewed === 100;
      const dataToSave = getStudentReviewItemsToSave(studentData, saveAll);
      if (dataToSave.length > 0) {
        sendScoresToServer(
          dataToSave,
          studentData.reviewTestPerStudent.correctorId,
          studentData.reviewTestPerStudent.recipeId
        );
      }
    },
    [sendScoresToServer]
  );

  const sendQuestionScoresToServer = useCallback(
    (questionData: ReviewTestResponse) => {
      const saveAll = questionData.reviewTest.percentageReviewed === 100;
      const dataToSave = getReviewQuestionItemsToSave(questionData, saveAll);
      if (dataToSave.length > 0) {
        sendScoresToServer(
          dataToSave,
          questionData.reviewTest.correctorId,
          questionData.reviewTest.recipeId
        );
      }
    },
    [sendScoresToServer]
  );

  // In this hook we handle the uncleared cached data when we open the page
  // FLOW OF CLEANING THE CACHE:
  // Check if other page has cache
  //    1. : There is cache for other page -> check if there is unsaved data in the cache
  //      1.1: Unsaved data -> SAVE DATA, CLEAR BOTH CACHE -> DONE
  //      1.2: No unsaved data ->CLEAR CACHE OF OTHER PAGE, Check if active page has cache
  //        1.2.1: Active page has cache -> SAVE, CLEAR CACHE -> DONE
  //        1.2.2: Active page has no cache -> DONE
  //   2.: No cache for other page -> Check if active page has cache
  //      2.1: Active page has cache -> SAVE, CLEAR CACHE -> DONE
  //      2.2: Active page has no cache -> DONE
  useEffect(() => {
    if (planId && method?.structureId && selectedSchoolId) {
      const getCachedReviewPerStudentData = (): ReviewTestPerStudentResponse | null => {
        try {
          return client.readQuery({
            variables: {
              planId,
              selectedSchoolId,
              methodId: method?.structureId,
            },
            query: GET_REVIEW_TEST_PER_STUDENT,
          });
        } catch {
          return null;
        }
      };

      const getCachedReviewPerQuestionData = (): ReviewTestResponse | null => {
        try {
          return client.readQuery({
            variables: {
              planId,
              selectedSchoolId,
              methodId: method?.structureId,
            },
            query: GET_REVIEW_TEST,
          });
        } catch {
          return null;
        }
      };

      const hasUnsavedReviewPerStudentData = (cachedData: ReviewTestPerStudentResponse): boolean =>
        cachedData.reviewTestPerStudent.studentWithAnswers.some(
          (studentAnswer: StudentWithAnswers) =>
            studentAnswer.studentGivenAnswers.some(studentGivenAnswer =>
              studentGivenAnswer.candidateResponses.some(
                candidateResponse => candidateResponse.valueChanged
              )
            )
        );

      const hasUnsavedReviewPerQuestionData = (cachedData: ReviewTestResponse): boolean =>
        cachedData.reviewTest.questionsWithAnswers.some(questionWithAnswers =>
          questionWithAnswers.givenAnswers.some(givenAnswer =>
            givenAnswer.candidateResponses.some(candidateResponse => candidateResponse.valueChanged)
          )
        );

      if (!isCacheCleared && pageType && method && planId && selectedSchoolId) {
        // Check the page type, do the correct flow according to the review page type
        if (pageType === ReviewPageType.ReviewPerQuestion) {
          const handleActivePageCachedData = () => {
            // Check if active page has cache
            const activePageCachedData = getCachedReviewPerQuestionData();
            if (activePageCachedData) {
              // Active page has cache -> SAVE, CLEAR CACHE -> DONE
              sendQuestionScoresToServer(activePageCachedData);
              resetReviewTestPerQuestionData();
            }
            // Active page has no cache -> DONE
            setIsCacheCleared(true);
          };

          // Check if other page has cache
          const otherPageCachedData = getCachedReviewPerStudentData();
          if (otherPageCachedData) {
            // 1. There is cache for other page -> check if there is unsaved data in the cache
            const hasUnsavedData = hasUnsavedReviewPerStudentData(otherPageCachedData);
            if (hasUnsavedData) {
              // 1.1: Unsaved data -> SAVE DATA, CLEAR BOTH CACHE -> done
              sendStudentScoresToServer(otherPageCachedData);
              resetReviewTestPerStudentData();
              resetReviewTestPerQuestionData();
              setIsCacheCleared(true);
            } else {
              // 1.2: No unsaved data -> CLEAR CACHE OF OTHER PAGE, Check if active page has cache
              resetReviewTestPerStudentData();
              handleActivePageCachedData();
            }
          } else {
            // 2.: No cache for other page -> Check if active page has cache
            handleActivePageCachedData();
          }
        } else {
          const handleActivePageCachedData = () => {
            const activePageCachedData = getCachedReviewPerStudentData();
            if (activePageCachedData) {
              sendStudentScoresToServer(activePageCachedData);
              resetReviewTestPerStudentData();
            }
            setIsCacheCleared(true);
          };

          const otherPageCachedData = getCachedReviewPerQuestionData();
          if (otherPageCachedData) {
            const hasUnsavedData = hasUnsavedReviewPerQuestionData(otherPageCachedData);
            if (hasUnsavedData) {
              sendQuestionScoresToServer(otherPageCachedData);
              resetReviewTestPerQuestionData();
              resetReviewTestPerStudentData();
              setIsCacheCleared(true);
            } else {
              resetReviewTestPerQuestionData();
              handleActivePageCachedData();
            }
          } else {
            handleActivePageCachedData();
          }
        }
      }
    }
  }, [
    isCacheCleared,
    pageType,
    location,
    method,
    planId,
    sendQuestionScoresToServer,
    sendStudentScoresToServer,
    selectedSchoolId,
  ]);

  const navigateBack = (isReviewFinished: boolean) => {
    if (isNlpNavigationEnabled) {
      window.location.href = isReviewFinished ? nlpGroupResultDashboardPendingPage : nlpGroupResultDashboardPage;
    }
    // We cant use history.goBack here as it conflicts with the cache evict.
    else if (location.state?.returnUrl) {
      history.push(location.state.returnUrl);
    } else {
      if (isNlpNavigationEnabled) {
        window.location.href = nlpNotificationsPage;
      }
      history.push('/');
    }
  };

  if (!isCacheCleared || !pageType) {
    return <ILLoadingIndicator isLoading fullSize />;
  }
  if (isCacheCleared && pageType === ReviewPageType.ReviewPerQuestion) {
    return (
      <ReviewPerQuestionsContainer
        selectedSchoolId={selectedSchoolId}
        loadingState={loadingState}
        sendScoresToServer={sendQuestionScoresToServer}
        navigateBack={navigateBack}
      />
    );
  }
  if (isCacheCleared && pageType === ReviewPageType.ReviewPerStudent) {
    return (
      <ReviewPerStudentsContainer
        selectedSchoolId={selectedSchoolId}
        loadingState={loadingState}
        sendScoresToServer={sendStudentScoresToServer}
        navigateBack={navigateBack}
      />
    );
  }
  return null;
}

export default ReviewWrapper;
