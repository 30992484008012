import React, { ReactElement } from 'react';
import './style.scss';

type Props = {
  activeTabIndex: number;
  changeTab: (index: number) => void;
  tabTitles: string[];
};

function ILTabHeaderMolecule({ activeTabIndex, changeTab, tabTitles }: Props): ReactElement {
  const renderHeaderElements = (): JSX.Element[] =>
    tabTitles.map((title: string, index: number) => (
      <div
        key={`tab-${title}`}
        className={`tab${activeTabIndex === index ? ' tab-selected' : ''}`}
        onClick={() => changeTab(index)}
      >
        {title}
      </div>
    ));

  return <div className="tab-bar">{renderHeaderElements()}</div>;
}

export default ILTabHeaderMolecule;
