/* eslint-disable @typescript-eslint/naming-convention */
import { Dispatch } from 'redux';
import { getData } from '../../components/ILAjaxEther';
import { webApiUrl } from '../../api/settingsLoader';
import { Groups } from '../../models/state/Groups';

export enum GetStudentGroupsActionType {
  GET_STUDENT_GROUPS_REQUEST = 'GET_STUDENT_GROUPS_REQUEST',
  GET_STUDENT_GROUPS_SUCCESS = 'GET_STUDENT_GROUPS_SUCCESS',
  GET_STUDENT_GROUPS_ERROR = 'GET_STUDENT_GROUPS_ERROR',
}

export type StudentGroupsActionTypes =
  | GetStudentGroupsRequestAction
  | GetStudentGroupsSuccessAction
  | GetStudentGroupsErrorAction;

export interface GetStudentGroupsRequestAction {
  type: GetStudentGroupsActionType.GET_STUDENT_GROUPS_REQUEST;
}

export interface GetStudentGroupsSuccessAction {
  type: GetStudentGroupsActionType.GET_STUDENT_GROUPS_SUCCESS;
  studentGroups: Groups[];
}

export interface GetStudentGroupsErrorAction {
  type: GetStudentGroupsActionType.GET_STUDENT_GROUPS_ERROR;
}

export const getStudentGroupsRequest = (): GetStudentGroupsRequestAction => ({
  type: GetStudentGroupsActionType.GET_STUDENT_GROUPS_REQUEST,
});

export const getStudentGroupsSuccess = (
  studentGroups: Groups[]
): GetStudentGroupsSuccessAction => ({
  studentGroups,
  type: GetStudentGroupsActionType.GET_STUDENT_GROUPS_SUCCESS,
});

export const getStudentGroupsError = (): GetStudentGroupsErrorAction => ({
  type: GetStudentGroupsActionType.GET_STUDENT_GROUPS_ERROR,
});

export const studentGroupsRequest = (schoolId: string) => (dispatch: Dispatch): void => {
  dispatch(getStudentGroupsRequest());
  getData(`${webApiUrl}userGroup/GetGroupsAndProfiles/${schoolId}`)
    .then((data: Groups[]) => dispatch(getStudentGroupsSuccess(data)))
    .catch(() => dispatch(getStudentGroupsError()));
};

export default (state: Groups[] = [], action: StudentGroupsActionTypes): Groups[] => {
  if (action.type === GetStudentGroupsActionType.GET_STUDENT_GROUPS_SUCCESS) {
    return action.studentGroups;
  } 
  return state;
};
