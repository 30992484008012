import React, { useCallback, useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/nl';
import { initPlanAction, loadPlanAction, resetPlanAction } from '../../store/planning/actions';
import { useGetMethod } from '../../graphql/method/methodOperations';
import { useGetAuthenticated } from '../../graphql/authenticated/authenticatedOperations';
import { getData } from '../../components/ILAjaxEther';
import { convertArrayToCaesura, createPlanObject } from './utils/planMapper';
import { createDateTime } from '../../utils/DateTimeMapper';
import { State } from '../../store';
import { Origin } from '../../models/enums/Origin';
import { TestType } from '../../models/enums/TestType';
import { Caesura, Plan } from '../../models/state/Plan';
import { ScheduleType } from '../../models/enums/ScheduleType';
import { Status } from '../../components/TPStatusAtom/Status';
import { MenuItem } from '../../components/ILMenuMolecule/ILMenuMolecule';
import { TestDetails } from '../../models/dto/TestDetails';
import { PlanPraResponse } from '../../models/dto/PlanPraResponse';
import { PlanningInfoResponse } from '../../models/dto/PlanningInfoResponse';
import {
  PRA_PLAN_BASE_URL,
  WEB_API_CONTENT_BASE_URL,
  WEB_API_PLAN_INFO_BASE_URL,
} from '../../constants/PlanningResourcesContstants';
import {
  chapterTestMenuItem,
  levelTestMenuItem,
  myTestMenuItem,
  testAdministration,
} from '../../components/ILMenuMolecule/teacherMenu';
import PlanningContext, { PlanningContextValue } from './PlanningContext';
import Planning from './Planning';
import { usePageTracking } from '../../utils/usePageTracking';

interface MatchParams {
  testId?: string;
  planId?: string;
  origin?: Origin;
  status?: Status;
}

interface Props {
  classification: string;
  dispatchResetPlanAction: () => void;
  dispatchInitPlanAction: (
    id: string,
    title: string,
    classification: string,
    caesura: Caesura
  ) => void;
  dispatchLoadPlanAction: (plan: Plan) => void;
}

const mapStateToProps = (state: State) => ({
  classification: state.plan.classification,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  dispatchResetPlanAction: () => {
    dispatch(resetPlanAction());
  },
  dispatchInitPlanAction: (id: string, title: string, classification: string, caesura: Caesura) => {
    dispatch(initPlanAction(id, title, classification, caesura));
  },
  dispatchLoadPlanAction: (plan: Plan) => {
    dispatch(loadPlanAction(plan));
  },
});

function PlanningContainer({
  classification,
  dispatchResetPlanAction,
  dispatchInitPlanAction,
  dispatchLoadPlanAction,
}: Props) {
  const { testId, planId, origin, status } = useParams<MatchParams>();
  const method = useGetMethod();
  const { selectedSchoolId } = useGetAuthenticated();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isEditEnabled, setIsEditEnabled] = useState(true);
  const planningContextValue: PlanningContextValue = {
    isEdit,
    isEditEnabled,
  };

  usePageTracking('planning', planId ? 'edit' : 'create', testId);

  const getIsRefTest = useCallback(
    (): boolean => (classification ? classification === TestType.Ref : false),
    [classification]
  );

  const setEnabledState = useCallback(
    (plan: Plan, isDirectClosed: boolean) => {
      if (plan.scheduleType === ScheduleType.Direct) {
        setIsEditEnabled(isDirectClosed && status === Status.Planned);
      } else if (plan.scheduleType === ScheduleType.Period) {
        if (plan.startDate && plan.startDate < moment()) {
          setIsEditEnabled(false);
        }
      } else if (plan.scheduleType === ScheduleType.Hour) {
        if (createDateTime(plan.date, plan.startTime)! < moment()) {
          setIsEditEnabled(false);
        }
      }
    },
    [status]
  );

  useEffect(() => {
    if (method?.structureId && selectedSchoolId) {
      dispatchResetPlanAction();
      if (testId) {
        let url: string;
        if (origin === Origin.Ats) {
          url = `${WEB_API_CONTENT_BASE_URL}/${testId}/${method.structureId}/${selectedSchoolId}`;
        } else {
          url = `${WEB_API_CONTENT_BASE_URL}/${testId}`;
        }
        getData(url).then((response: TestDetails) => {
          dispatchInitPlanAction(
            response.id,
            response.title,
            response.classification,
            convertArrayToCaesura(response.caesura)
          );
          setHasLoaded(true);
        });
      } else if (planId) {
        setIsEdit(true);
        getData(`${PRA_PLAN_BASE_URL}/${planId}`).then((praResponse: PlanPraResponse) => {
          getData(`${WEB_API_PLAN_INFO_BASE_URL}/${planId}`).then(
            (infoResponse: PlanningInfoResponse) => {
              const contentUrl =
                origin === Origin.Ats
                  ? `${WEB_API_CONTENT_BASE_URL}/${praResponse.plannedContent[0].arrangedContentId}/${method.structureId}/${selectedSchoolId}`
                  : `${WEB_API_CONTENT_BASE_URL}/${praResponse.plannedContent[0].arrangedContentId}`;
              getData(contentUrl).then((contentResponse: TestDetails) => {
                const newPlan = createPlanObject(contentResponse, praResponse, infoResponse);
                setEnabledState(newPlan, infoResponse.closed);
                dispatchLoadPlanAction(newPlan);
                setHasLoaded(true);
              });
            }
          );
        });
      }
    }
  }, [
    selectedSchoolId,
    dispatchInitPlanAction,
    dispatchLoadPlanAction,
    dispatchResetPlanAction,
    method,
    planId,
    setEnabledState,
    testId,
    origin,
  ]);

  const getReturnPage = useCallback((): MenuItem => {
    if (isEdit) {
      return testAdministration;
    }
    if (origin === Origin.Ats) {
      return myTestMenuItem;
    }
    return classification === TestType.Ref ? levelTestMenuItem : chapterTestMenuItem;
  }, [isEdit, origin, classification]);

  return (
    <PlanningContext.Provider value={planningContextValue}>
      <Planning isRefTest={getIsRefTest()} isLoading={!hasLoaded} returnPage={getReturnPage()} />
    </PlanningContext.Provider>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanningContainer);
