import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Origin } from '../../models/enums/Origin';
import { DashboardResult } from '../../models/dto/DashboardResult';
import { ILLoadingIndicator } from '../../components/ILLoadingIndicator';
import Breadcrumb, { BreadcrumbPart } from '../../components/Breadcrumb/Breadcrumb';
import ILViewAnswersFromCollectionContainer from '../../components/ILViewAnswersOrganism/ILViewAnswersFromCollectionContainer';
import { useNlpGroupDashboardRoutes, useNlpRoutes } from '../../utils/nlpNavigation';
import { useContextNavigation } from '../../utils/useContextNavigation';
import TPStudentResultSelectorContainer from './TPStudentResultSelector/TPStudentResultSelectorContainer';
import './style.scss';

type Props = {
  result: DashboardResult;
  planId: string;
  classId?: string;
};

function ResultsPerQuestion({ result, planId, classId }: Props): ReactElement | null {
  const { t } = useTranslation();
  const [selectedStudent, setSelectedStudent] = useState<string>();

  const nlpClassedOverviewUrl = useNlpRoutes('classesOverview');
  const nlpClassResultsUrl = useNlpRoutes(
    'classResults',
    classId || result?.studentResults[0].groupId
  );
  const nlpAllResultsUrl = useNlpRoutes('allResults');
  const classResultDashboardPath = useContextNavigation(
    `/resultaat/klas/${classId}/${result.planningId}`
  );

  const nlpGroupResultDashboardPage = useNlpGroupDashboardRoutes(planId, false, classId);

  const allResultDashboardPath = useContextNavigation(`/resultaat/alle/${result.planningId}`);

  const getPageTitle = () =>
    `${t('resultsPerQuestion:answers')} ${
      classId && result.groupNames[0] ? `${result.groupNames[0]} - ` : ''
    } ${result.title || ''}`;

  const getDashboardTitle = (): string => {
    if (classId) {
      return `${t('resultsPage:result')} ${result.groupNames[0] || t('resultsPage:class')} - ${
        result.title || ''
      }`;
    }
    return `${t('resultsPage:result')} ${result.title || ''}`;
  };

  const getBreadCrumbs = (): BreadcrumbPart[] =>
    classId
      ? [
          {
            name: t('resultsPage:test_results_classes'),
            to: '/resultaten/klassen',
            nlpUrl: nlpClassedOverviewUrl,
          },
          {
            name: result.groupNames[0] || '',
            to: `/resultaten/klas/${classId || result?.studentResults[0].groupId}`,
            nlpUrl: nlpClassResultsUrl,
            state: {
              name: result.groupNames[0],
            },
          },
          {
            name: getDashboardTitle() || '',
            to: classResultDashboardPath,
            nlpUrl: nlpGroupResultDashboardPage
          },
          {
            name: getPageTitle() || '',
          },
        ]
      : [
          {
            name: t('resultsPage:all_results_title'),
            to: '/resultaten/alle',
            nlpUrl: nlpAllResultsUrl,
          },
          {
            name: getDashboardTitle() || '',
            to: allResultDashboardPath,
            nlpUrl: nlpGroupResultDashboardPage
          },
          {
            name: getPageTitle() || '',
          },
        ];

  return (
    <div className="main">
      <Breadcrumb crumbs={getBreadCrumbs()} />
      <h1>{getPageTitle()}</h1>
      <div className="score-per-question">
        <TPStudentResultSelectorContainer
          studentResults={result?.studentResults ? result.studentResults : []}
          handleChange={setSelectedStudent}
          assignmentId={result.origin === Origin.Ats ? result.assignmentId : null}
          selectedStudent={selectedStudent}
          planId={result.planningId}
        />
        <div className="view-answers-preserve">
          {selectedStudent ? (
            <ILViewAnswersFromCollectionContainer
              resultId={selectedStudent}
              planId={result.planningId}
            />
          ) : (
            <ILLoadingIndicator fullSize />
          )}
        </div>
      </div>
    </div>
  );
}

export default ResultsPerQuestion;
