import React, { ReactElement } from 'react';
import { IconType } from '../../ILIconAtom';
import ILIconAtom from '../../ILIconAtom/ILIconAtom';

interface Props {
  headerTitle: string;
  showTooltip?: boolean;
}

function ILTableSortingHeaderMolecule({ headerTitle, showTooltip }: Props): ReactElement {
  return (
    <div className="sort-header-container">
      <span className="sort-header-title" title={showTooltip ? headerTitle : undefined}>
        {headerTitle}
      </span>
      <div className="sort-icon-container">
        <ILIconAtom type={IconType.Collapse} size={7} />
        <ILIconAtom type={IconType.Expand} size={7} />
      </div>
    </div>
  );
}

export default ILTableSortingHeaderMolecule;
