import React, { ReactElement } from 'react';
import '../../../assets/theme/tp-buttons.scss';

type Props = {
  text: string;
  handleClick: () => void;
};

function ILTableTextButtonAtom({ text, handleClick }: Props): ReactElement {
  return (
    <button
      className="tp-button tp-button-primary-light tp-button-thin text-button"
      onClick={() => handleClick()}
    >
      {text}
    </button>
  );
}

export default ILTableTextButtonAtom;
