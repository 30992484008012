import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TableColumn,
  TableDataType,
  TableFilterType,
  TableHeaderType,
} from '../../../../../components/ILTableOrganism/ILTableColumn/createColumns';
import { PlanningResultStudent } from '../../../../../models/dto/PlanningResultStudent';
import { IconType } from '../../../../../components/ILIconAtom';
import { Status } from '../../../../../components/TPStatusAtom/Status';
import {
  dateColumn,
  scoreColumn,
  statusColumn,
  studentGradeColumn,
  testTitleColumn,
} from '../../../utils/resultColumns';
import ILTableOrganism from '../../../../../components/ILTableOrganism/ILTableOrganismContainer';
import ILTableButtonAtom from '../../../../../components/ILTableOrganism/ILTableButtonAtom';

type Props = {
  tableData: PlanningResultStudent[];
  openResult: (planId: string) => void;
  isLoading: boolean;
  isTeacher: boolean;
};

function TPStudentAllResultsTable({
  tableData,
  openResult,
  isLoading,
  isTeacher,
}: Props): ReactElement {
  const { t } = useTranslation();

  const renderResultsActions = (rowData: PlanningResultStudent): JSX.Element =>
    rowData.status === Status.Released ? (
      <ILTableButtonAtom
        type={IconType.Stats}
        title={t('resultsPage:view_results')}
        handleClick={() => openResult(rowData.planningId)}
      />
    ) : (
      <></>
    );

  const resultsColumns: TableColumn[] = [
    testTitleColumn(t),
    dateColumn(t),
    statusColumn(t, true),
    studentGradeColumn(t),
    scoreColumn(t, isTeacher),
    {
      key: 'options',
      columnData: TableDataType.Custom,
      header: TableHeaderType.Empty,
      filter: TableFilterType.None,
      width: 80,
      customCellRenderer: renderResultsActions,
    },
  ];

  return (
    <ILTableOrganism
      data={tableData}
      columns={resultsColumns}
      id="student-all-results"
      isLoading={isLoading}
      noDataText={t('resultsPage:no_results')}
      sortable={true}
      filterable={true}
      shouldReload={true}
      defaultSorted={[
        {
          id: 'date',
          desc: true,
        },
      ]}
    />
  );
}

export default TPStudentAllResultsTable;
