import React, { ReactElement, useEffect, useRef } from 'react';
import './style.scss';

interface Props {
  url: string;
  key?: string;
}

function ILFrameAtom({ url, key }: Props): ReactElement {
  const ref = useRef<HTMLIFrameElement>(null);
  useEffect(() => {
    if (ref.current && ref.current.contentWindow) {
      ref.current.contentWindow.location.replace(url);
    }
  }, [url, key]);

  return (
    <>
      <iframe ref={ref} id="frame-viewer" title={url} name={url} />
    </>
  );
}

export default ILFrameAtom;
