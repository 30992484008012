import React, { ReactElement } from 'react';
import { useGetMethod } from '../../graphql/method/methodOperations';
import { DidacticLevel } from '../../models/enums/DidacticLevel';
import { QuestionProperty } from '../../models/state/Question';
import { parseArrayToString } from '../../utils/DataObjectMapper';
import './style.scss';

interface Props {
  properties: QuestionProperty[];
}

function TPQuestionMetaMolecule({ properties }: Props): ReactElement | null {
  const method = useGetMethod();

  const getQuestionMetaByKey = (key: string): JSX.Element => {
    const meta = properties.find((property: QuestionProperty) => property.key === key);
    return meta ? <li>{parseArrayToString(meta.value, true)}</li> : <></>;
  };

  return method ? (
    <ul className="question-meta">
      {getQuestionMetaByKey(
        method.didactic_level === DidacticLevel.VocationalEducation ? 'niveau' : 'niveau_vo'
      )}
      {getQuestionMetaByKey('kti')}
      {getQuestionMetaByKey('domein')}
    </ul>
  ) : null;
}

export default TPQuestionMetaMolecule;
