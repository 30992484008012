import { AnswerValue } from '../../models/dto/ReviewTest';
import { cache } from '../cache';

export const resetReviewTestPerQuestionData = (): void => {
  cache.evict({ fieldName: 'reviewTest', broadcast: false });
  cache.gc();
};

export const resetReviewTestPerStudentData = (): void => {
  cache.evict({ fieldName: 'reviewTestPerStudent', broadcast: false });
  cache.gc();
};

export const resetDataToSave = (itemCheckId: string): void => {
  cache.modify({
    id: `GivenAnswer:{"itemCheckId":"${itemCheckId}"}`,
    fields: {
      candidateResponses(existing: AnswerValue[]) {
        return existing.map((answerValue: AnswerValue) => ({
          ...answerValue,
          valueChanged: false,
        }));
      },
    },
  });
};
