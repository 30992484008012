import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ILIconAtom, { IconType } from '../../../../components/ILIconAtom/ILIconAtom';
import ILDropDownSelectOrganism from '../../../../components/ILDropDownSelectOrganism';

import '../../../../assets/theme/tp-buttons.scss';
import './style.scss';

import { QuestionFilters } from '../../../../models/state/arrangeTests/ArrangedTest';
import { sortTexts, sortKti } from '../../../../utils/Sorting';
import { SelectType } from '../../../../models/enums/SelectType';

type Props = {
  updateQuestionFilters: (
    ktiFilters: string[],
    domainFilters: string[],
    questionTypeFilters: string[]
  ) => void;
  filterOptions: QuestionFilters;
  selectedFilters: QuestionFilters;
};

function TPQuestionFilterOrganism({
  updateQuestionFilters,
  selectedFilters,
  filterOptions,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedKti, setSelectedKti] = useState<string[]>(selectedFilters.kti || []);
  const [selectedDomains, setSelectedDomains] = useState<string[]>(selectedFilters.domain || []);
  const [selectedQuestionTypes, setSelectedQuestionTypes] = useState<string[]>(
    selectedFilters.type || []
  );

  const handleUpdate = () => {
    updateQuestionFilters(selectedKti, selectedDomains, selectedQuestionTypes);
    setIsOpen(false);
  };

  const discardAndClose = () => {
    setSelectedDomains(selectedFilters.domain);
    setSelectedKti(selectedFilters.kti);
    setSelectedQuestionTypes(selectedFilters.type);
    setIsOpen(false);
  };

  const handleSelectionChange = (selectionList: string[], value: string): string[] => {
    if (selectionList.some((listValue: string) => listValue === value)) {
      return selectionList.filter((listValue: string) => listValue !== value);
    }
    return [...selectionList, value];
  };

  const addOrRemoveKti = (optionId: string) => {
    setSelectedKti(handleSelectionChange(selectedKti, optionId));
  };

  const addOrRemoveDomains = (optionId: string) => {
    setSelectedDomains(handleSelectionChange(selectedDomains, optionId));
  };

  const addOrRemoveQuestionType = (optionId: string) => {
    setSelectedQuestionTypes(handleSelectionChange(selectedQuestionTypes, optionId));
  };

  return (
    <>
      <div className={`question-filter${isOpen ? ' opened-filter' : ''}`}>
        <div
          className="filter-header"
          onClick={() => (!isOpen ? setIsOpen(true) : discardAndClose())}
        >
          <ILIconAtom type={IconType.Filter} size={16} />
          <span className="filter-header__title">{t('selectQuestionsStep:filter_questions')}</span>
          <ILIconAtom type={IconType.Expand} size={10} />
        </div>
        <div className="filter-container">
          <div className="filter-container__main">
            <ILDropDownSelectOrganism
              options={filterOptions.domain.slice().sort((a: string, b: string) => sortTexts(a, b))}
              name={t('selectQuestionsStep:domain')}
              type={SelectType.Multiple}
              translationKey="selectQuestionsStep"
              addOrRemove={addOrRemoveDomains}
              selectedOptions={selectedDomains}
              isActive={isOpen}
            />
            <ILDropDownSelectOrganism
              options={filterOptions.kti.slice().sort((a: string, b: string) => sortKti(a, b))}
              name={t('selectQuestionsStep:cognitive_level')}
              type={SelectType.Multiple}
              addOrRemove={addOrRemoveKti}
              selectedOptions={selectedKti}
              isActive={isOpen}
            />
            <ILDropDownSelectOrganism
              options={filterOptions.type}
              name={t('selectQuestionsStep:question_type')}
              type={SelectType.Multiple}
              translationKey="selectQuestionsStep"
              addOrRemove={addOrRemoveQuestionType}
              selectedOptions={selectedQuestionTypes}
              isActive={isOpen}
            />
          </div>
          <div className="filter-container__footer">
            <button className="tp-button tp-button-primary" onClick={() => handleUpdate()}>
              {t('selectQuestionsStep:update_filter')}
            </button>
          </div>
        </div>
      </div>
      {isOpen && <div className="filter-overlay" onClick={() => discardAndClose()} />}
    </>
  );
}

export default TPQuestionFilterOrganism;
