import { TypePolicies } from '@apollo/client';

export const reviewedPlansCache: TypePolicies = {
  Query: {
    fields: {
      reviewedPlans: {
        read(reviewedPlans = []) {
          return reviewedPlans;
        },
      },
      reviewMoment: {
        read(reviewMoment = '') {
          return reviewMoment;
        },
      },
    },
  },
};
