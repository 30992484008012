import React from 'react';
import { useTranslation } from 'react-i18next';
import { NLPLocationKey, useNlpRoutes } from '../../../../utils/nlpNavigation';
import '../style.scss';

interface Props {
  resourceKey: string;
  nlpKey: NLPLocationKey;
}

export const ILNlpSubLink: React.FC<Props> = ({ resourceKey, nlpKey }) => {
  const { t } = useTranslation();
  const menuRoute = useNlpRoutes(nlpKey);

  return (
    <a className="drop-down-menu-item" href={menuRoute}>
      {t(`menu:${resourceKey}`)}
    </a>
  );
};
