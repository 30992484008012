import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { getPendingPlans } from '../../../../../graphql/reviewedPlans/reviewedPlansOperations';
import { AlertType } from '../../../../../components/ILAlertMessageMolecule/AlertType';
import { Status } from '../../../../../components/TPStatusAtom/Status';
import { DashboardResult } from '../../../../../models/dto/DashboardResult';
import ILAlertMessageMolecule from '../../../../../components/ILAlertMessageMolecule';
import ILAlertMessageButtonAtom from '../../../../../components/ILAlertMessageMolecule/ILAlertMessageButtonAtom';

type Props = {
  result: DashboardResult;
  closeTests: () => void;
  openReviewModal: () => void;
  adjustCaesura: () => void;
  releaseResults: () => void;
  reload: () => void;
};

function TPResultNotificationOrganism({
  result,
  closeTests,
  openReviewModal,
  adjustCaesura,
  releaseResults,
  reload,
}: Props): ReactElement {
  const { t } = useTranslation();

  const closingTestAlert: JSX.Element = (
    <ILAlertMessageMolecule
      type={AlertType.PayAttention}
      message={`${result.notFinishedStudents.length} ${t('resultsPage:from')} ${
        result.participants
      } ${t('resultsPage:close_test_alert')}`}
    >
      <ILAlertMessageButtonAtom text={t('resultsPage:close_tests')} handleClick={closeTests} />
    </ILAlertMessageMolecule>
  );

  const reviewTestAlert: JSX.Element = (
    <ILAlertMessageMolecule type={AlertType.PayAttention} message={t('resultsPage:review_alert')}>
      <ILAlertMessageButtonAtom text={t('resultsPage:review_test')} handleClick={openReviewModal} />
    </ILAlertMessageMolecule>
  );

  const pendingAlert: JSX.Element = (
    <ILAlertMessageMolecule type={AlertType.PayAttention} message={t('resultsPage:pending_alert')}>
      <ILAlertMessageButtonAtom text={t('resultsPage:refresh')} handleClick={reload} />
    </ILAlertMessageMolecule>
  );

  const releaseResultsAlert: JSX.Element = (
    <ILAlertMessageMolecule type={AlertType.PayAttention} message={t('resultsPage:release_alert')}>
      <ILAlertMessageButtonAtom
        text={t('resultsPage:adjust_caesura')}
        handleClick={adjustCaesura}
        isOptional={true}
      />
      <ILAlertMessageButtonAtom text={t('resultsPage:release')} handleClick={releaseResults} />
    </ILAlertMessageMolecule>
  );

  const renderClosingTestAlert = () => {
    if (!result.isAvailable && result.notFinishedStudents.length > 0) {
      return closingTestAlert;
    }
  };

  const renderReviewTestAlert = () => {
    const pendingPlans = getPendingPlans();
    const isPending = pendingPlans.includes(result.planningId);
    if (result.status === Status.NotReviewed) {
      return isPending ? pendingAlert : reviewTestAlert;
    }
  };

  const renderReleaseResultsAlert = () => {
    if (result.status === Status.NotReleased) {
      return releaseResultsAlert;
    }
  };

  return (
    <>
      {renderClosingTestAlert()}
      {renderReviewTestAlert()}
      {renderReleaseResultsAlert()}
    </>
  );
}

export default TPResultNotificationOrganism;
