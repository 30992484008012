import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CutOff } from '../CutOff';
import { CaesuraScores } from '../../../models/state/Plan';
import '../style.scss';

interface Props {
  cut: CutOff;
  caesuraScores: CaesuraScores;
  isEditEnabled: boolean;
  handleScoreChange: (id: number, score: string) => void;
  handleGradeChange: (id: number, grade: string) => void;
}

function TPCaesuraRowMolecule({
  cut,
  caesuraScores,
  isEditEnabled,
  handleScoreChange,
  handleGradeChange,
}: Props): ReactElement {
  const { t } = useTranslation();
  const [grade, setGrade] = useState(caesuraScores.grade.replace('.', ','));
  const [percentage, setPercentage] = useState(caesuraScores.score.replace('.', ','));
  const onGradeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isEditEnabled) {
      setGrade(event.target.value);
      handleGradeChange(cut.index, event.target.value.replace(',', '.'));
    }
  };
  const onPercentageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isEditEnabled && !cut.fixedPercentage) {
      setPercentage(event.target.value);
      handleScoreChange(cut.index, event.target.value.replace(',', '.'));
    }
  };

  return (
    <tr>
      <td>{t(`caesura:${cut.name}`)}</td>
      <td>
        <input
          name="grade"
          type="text"
          value={grade}
          placeholder=""
          readOnly={!isEditEnabled}
          onChange={event => onGradeChange(event)}
        />
      </td>
      <td>{t('caesura:at')}</td>
      <td>
        <input
          name="percentage"
          type="text"
          value={percentage}
          placeholder=""
          readOnly={!isEditEnabled || cut.fixedPercentage}
          onChange={event => onPercentageChange(event)}
        />
      </td>
    </tr>
  );
}

export default TPCaesuraRowMolecule;
