import React, { ReactElement, useState } from 'react';
import { createRequest } from '../../../../../components/ILAjaxEther';
import { MethodType } from '../../../../../components/ILAjaxEther/MethodType';
import { webApiUrl } from '../../../../../api/settingsLoader';
import { Caesura, CaesuraScores } from '../../../../../models/state/Plan';
import {
  convertArrayToCaesura,
  convertCaesuraToArray,
} from '../../../../Planning/utils/planMapper';
import { validateCaesura } from '../../../../Planning/utils/planValidation';
import { PlanErrorTypes } from '../../../../Planning/TPPlanningFooterMolecule/PlanErrorTypes';
import TPCaesuraModalOrganism from './TPCaesuraModalOrganism';

type Props = {
  isOpen: boolean;
  caesuraScores: CaesuraScores[];
  planningId: string;
  closeModal: () => void;
  reloadDashboardData: () => void;
};

function TPCaesuraModalOrganismContainer({
  isOpen,
  caesuraScores,
  planningId,
  closeModal,
  reloadDashboardData,
}: Props): ReactElement {
  const [errors, setErrors] = useState<PlanErrorTypes[]>([]);
  const [caesura, setCaesura] = useState<Caesura>(convertArrayToCaesura(caesuraScores));
  const [isLoading, setIsLoading] = useState(false);

  const updateScores = (id: number, score: string) =>
    setCaesura({
      ...caesura,
      [id]: { ...caesura[id], score },
    });

  const updateGrade = (id: number, grade: string) =>
    setCaesura({
      ...caesura,
      [id]: { ...caesura[id], grade },
    });

  const updateCaesura = (data: CaesuraScores[]) => {
    if (planningId) {
      setIsLoading(true);
      createRequest(MethodType.Save, `${webApiUrl}/planninginfo/updateCaesura/${planningId}`, data)
        .then(() => {
          closeModal();
          reloadDashboardData();
        })
        .finally(() => setIsLoading(false));
    }
  };

  const handleUpdateCaesuraClick = () => {
    const caesuraErrors = validateCaesura(caesura);
    setErrors(caesuraErrors);
    if (caesuraErrors.length === 0) {
      updateCaesura(convertCaesuraToArray(caesura));
    }
  };

  return (
    <TPCaesuraModalOrganism
      isOpen={isOpen}
      isLoading={isLoading}
      caesura={caesura}
      errors={errors}
      handleScoreChange={updateScores}
      handleGradeChange={updateGrade}
      handleCancelClick={closeModal}
      handleUpdateClick={handleUpdateCaesuraClick}
    />
  );
}

export default TPCaesuraModalOrganismContainer;
