/* eslint-disable @typescript-eslint/naming-convention */
export enum ContextActionTypes {
  TOGGLE_DISPLAY_MENU = 'TOGGLE_DISPLAY_MENU',
  TOGGLE_DISPLAY_FOOTER = 'TOGGLE_DISPLAY_FOOTER',
  TOGGLE_DISPLAY_NEWS_FEED = 'TOGGLE_DISPLAY_NEWS_FEED',
}

export interface ToggleDisplayMenuAction {
  displayMenu: boolean;
  type: ContextActionTypes.TOGGLE_DISPLAY_MENU;
}

export interface ToggleDisplayFooterAction {
  displayFooter: boolean;
  type: ContextActionTypes.TOGGLE_DISPLAY_FOOTER;
}
export interface ToggleDisplayNewsFeedAction {
  displayNewsFeed: boolean;
  type: ContextActionTypes.TOGGLE_DISPLAY_NEWS_FEED;
}

export const toggleDisplayMenuAction = (
  DisplayMenuPayload: boolean
): ToggleDisplayMenuAction => ({
  displayMenu: DisplayMenuPayload,
  type: ContextActionTypes.TOGGLE_DISPLAY_MENU,
});

export const toggleDisplayFooterAction = (
  DisplayFooterPayload: boolean
): ToggleDisplayFooterAction => ({
  displayFooter: DisplayFooterPayload,
  type: ContextActionTypes.TOGGLE_DISPLAY_FOOTER,
});

export const toggleDisplayNewsFeedAction = (
  DisplayNewsFeedPayload: boolean
): ToggleDisplayNewsFeedAction => ({
  displayNewsFeed: DisplayNewsFeedPayload,
  type: ContextActionTypes.TOGGLE_DISPLAY_NEWS_FEED,
});
