import React, { useRef, useEffect, ReactElement } from 'react';
import './style.scss';
import ILIconAtom, { IconType } from '../../ILIconAtom';
import ILQuestionSelector from '../ILQuestionSelector/ILQuestionSelector';

export interface MenuItem {
  isPassed: boolean;
  id: string;
}

enum Direction {
  Forward = 'forward',
  Back = 'back',
}

interface Props {
  handleChange: (index: number) => void;
  menuItems: MenuItem[];
  currentSelected: number;
}

function ILViewAnswersTopbarMolecule({
  handleChange,
  menuItems,
  currentSelected,
}: Props): ReactElement {
  const menuRef = useRef<HTMLUListElement>(null);
  const selectedItemRef = useRef<HTMLLIElement>(null);

  const onChange = (key: number) => {
    handleChange(key);
  };

  useEffect(() => {
    if (selectedItemRef?.current) {
      selectedItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [currentSelected]);

  const mapMenuItems = () =>
    menuItems.map((menuItem: MenuItem, key) => (
      <li key={menuItem.id} ref={currentSelected === key ? selectedItemRef : null}>
        <div
          className={`topbar-menu-item ${
            currentSelected === key ? 'topbar-menu-item-selected' : ''
          } ${menuItem.isPassed ? 'correct' : 'wrong'}`}
          onClick={() => handleChange(key)}
        >
          <div className="item-circle">{key + 1}</div>
        </div>
      </li>
    ));

  const scroll = (direction: Direction) => {
    if (direction === Direction.Back && currentSelected > 0) {
      handleChange(currentSelected - 1);
      if (menuRef.current) {
        menuRef.current.scrollLeft -= 65;
      }
    } else if (direction === Direction.Forward && currentSelected < menuItems.length - 1) {
      if (menuRef.current) {
        menuRef.current.scrollLeft += 65;
      }
      handleChange(currentSelected + 1);
    }
  };

  return (
    <div className="topbar-container">
      <div
        className={`navigation-control ${
          currentSelected === 0 ? 'navigation-control__disabled' : ''
        }`}
        onClick={() => scroll(Direction.Back)}
      >
        <ILIconAtom type={IconType.NavigationBack} />
      </div>
      <ul ref={menuRef}>{mapMenuItems()}</ul>
      <div
        className={`navigation-control ${
          currentSelected === menuItems.length - 1 ? 'navigation-control__disabled' : ''
        }`}
        onClick={() => scroll(Direction.Forward)}
      >
        <ILIconAtom type={IconType.NavigationNext} />
      </div>
      <ILQuestionSelector
        menuItems={menuItems}
        selectedItem={currentSelected}
        handleChange={onChange}
      />
    </div>
  );
}

export default ILViewAnswersTopbarMolecule;
