import React, { ReactElement } from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';
import ILModalMolecule from './ILModalMolecule';

type Props = {
  isOpen: boolean;
  className?: string;
  title?: string;
  subText?: string;
  subComponent?: JSX.Element;
  onClose: () => void;
};

function ILInformationModalMolecule({
  isOpen,
  className,
  title,
  subText,
  subComponent,
  onClose,
}: Props): ReactElement {
  const { t } = useTranslation();
  return (
    <ILModalMolecule
      isOpen={isOpen}
      className={className}
      title={title}
      subText={subText}
      handleClose={onClose}
    >
      {subComponent || <></>}
      <button onClick={onClose} className="tp-button tp-button-primary ok-btn">
        {t('modal:accept')}
      </button>
    </ILModalMolecule>
  );
}

export default ILInformationModalMolecule;
