import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { filterBySelectedPeriod, getDatePeriodOptions } from '../../utils/tableDateFilters';
import { mapDistinctClassToTableOption, mapGroupsToString } from '../../utils/DataObjectMapper';
import { formatToLocalDate, formatToLocalTime } from '../../utils/dateTimeFormatter';
import {
  filterBySelectedTextValue,
  filterStrictBySelectedValue,
} from '../../components/ILTableOrganism/utils/rowFiltering';
import { getPlanningStatusOptions } from '../../components/TPStatusAtom/statusMapper';
import {
  TableColumn,
  TableDataType,
  TableFilterType,
  TableHeaderType,
} from '../../components/ILTableOrganism/ILTableColumn/createColumns';
import { IconType } from '../../components/ILIconAtom';
import { ScheduleType } from '../../models/enums/ScheduleType';
import { Origin } from '../../models/enums/Origin';
import { Status } from '../../components/TPStatusAtom/Status';
import { PlanningOverview } from '../../models/dto/PlanningResult';
import ILConfirmationModalMolecule, {
  ConfirmModalInfo,
} from '../../components/ILConfirmationModalMolecule/ILConfirmationModalMolecule';
import TPStatusAtom from '../../components/TPStatusAtom';
import TPGroupHoverListMolecule from '../../components/TPGroupHoverListMolecule';
import ILTableButtonAtom from '../../components/ILTableOrganism/ILTableButtonAtom';
import ILTableOrganismContainer from '../../components/ILTableOrganism';
import './style.scss';

type Props = {
  data: PlanningOverview[];
  isLoading: boolean;
  deleteModalSettings: ConfirmModalInfo;
  toggleDirectPlan: (planId: string) => void;
  openResult: (planId: string) => void;
  openEditPlanning: (planId: string, origin: Origin, status: Status) => void;
  deletePlanning: () => void;
  openDeleteModal: (planId: string) => void;
  closeDeleteModal: () => void;
};

function TestAdministration({
  data,
  isLoading,
  deleteModalSettings,
  toggleDirectPlan,
  openResult,
  openDeleteModal,
  closeDeleteModal,
  openEditPlanning,
  deletePlanning,
}: Props): ReactElement {
  const { t } = useTranslation();

  const renderDateCell = (rowData: PlanningOverview) => {
    switch (rowData.planningType) {
      case ScheduleType.Direct:
        return (
          <span>{`${formatToLocalDate(rowData.startDate)} ${t('administrationPage:direct')}`}</span>
        );
      case ScheduleType.Period:
        return (
          <span>
            {`${t('administrationPage:from')} ${formatToLocalDate(rowData.startDate)} ${t(
              'administrationPage:to'
            )} ${formatToLocalDate(rowData.endDate)}`}
          </span>
        );
      case ScheduleType.Hour:
        return (
          <span>
            {`${formatToLocalDate(rowData.startDate)} ${t(
              'administrationPage:at'
            )} ${formatToLocalTime(rowData.startDate)} ${t('administrationPage:hour')}`}
          </span>
        );
      default:
        return <span />;
    }
  };

  const renderDirectPlanAction = (rowData: PlanningOverview) => (
    <div className="admin-action-section">
      {rowData.isAvailable ? (
        <ILTableButtonAtom
          type={IconType.OpenedLock}
          className="tp-button-success"
          title={t('administrationPage:close_plan')}
          handleClick={() => toggleDirectPlan(rowData.planningId)}
        />
      ) : (
        <ILTableButtonAtom
          type={IconType.ClosedLock}
          title={t('administrationPage:open_plan')}
          handleClick={() => toggleDirectPlan(rowData.planningId)}
        />
      )}
    </div>
  );

  const renderActionButtons = (rowData: PlanningOverview): JSX.Element => (
    <div className="admin-action-container">
      {rowData.planningType === ScheduleType.Direct && renderDirectPlanAction(rowData)}
      <div className="admin-action-section">
        <ILTableButtonAtom
          type={IconType.Stats}
          title={t('administrationPage:view_results')}
          isDisabled={
            rowData.status === Status.Planned ||
            (rowData.status === Status.Started && rowData.isAvailable)
          }
          handleClick={() => openResult(rowData.planningId)}
        />
        <ILTableButtonAtom
          type={IconType.Edit}
          title={t('administrationPage:open_edit')}
          handleClick={() => openEditPlanning(rowData.planningId, rowData.origin, rowData.status)}
        />
        <ILTableButtonAtom
          type={IconType.Delete}
          title={t('administrationPage:delete_plan')}
          handleClick={() => openDeleteModal(rowData.planningId)}
        />
      </div>
    </div>
  );

  const tableColumns: TableColumn[] = [
    {
      key: 'title',
      headerTitle: t('administrationPage:title'),
      columnData: TableDataType.Text,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.Text,
      filterDefaultText: t('administrationPage:type_title'),
    },
    {
      key: 'startDate',
      headerTitle: t('administrationPage:planned_on'),
      columnData: TableDataType.CustomDate,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.CustomSelect,
      customFilterOptions: getDatePeriodOptions(t),
      customFilterMethod: filterBySelectedPeriod,
      filterDefaultText: t('administrationPage:all_date'),
      customCellRenderer: renderDateCell,
    },
    {
      key: 'groups',
      headerTitle: t('administrationPage:class'),
      columnData: TableDataType.Custom,
      header: TableHeaderType.NotSortable,
      filter: TableFilterType.CustomSelect,
      filterDefaultText: t('administrationPage:all_classes'),
      customFilterOptions: mapDistinctClassToTableOption(data),
      customFilterMethod: filterBySelectedTextValue,
      customAccessor: (rowData: PlanningOverview) => mapGroupsToString(rowData.groups),
      customCellRenderer: (rowData: PlanningOverview): JSX.Element => (
        <TPGroupHoverListMolecule groups={rowData.groups} />
      ),
    },
    {
      key: 'status',
      headerTitle: t('administrationPage:status'),
      columnData: TableDataType.Custom,
      header: TableHeaderType.NotSortable,
      filter: TableFilterType.CustomSelect,
      filterDefaultText: t('administrationPage:all'),
      customFilterOptions: getPlanningStatusOptions(t),
      customFilterMethod: filterStrictBySelectedValue,
      customCellRenderer: (rowData: PlanningOverview) => <TPStatusAtom status={rowData.status} />,
      width: 150,
    },
    {
      key: 'completed',
      headerTitle: t('administrationPage:completed_by'),
      columnData: TableDataType.Custom,
      header: TableHeaderType.Sortable,
      filter: TableFilterType.CustomSelect,
      customCellRenderer: (rowData: PlanningOverview) => (
        <span>{`${rowData.completed} ${t('administrationPage:of')} ${rowData.attendees}`}</span>
      ),
      width: 150,
    },
    {
      key: 'actions',
      columnData: TableDataType.Custom,
      header: TableHeaderType.NotSortable,
      filter: TableFilterType.None,
      customCellRenderer: renderActionButtons,
      width: 170,
    },
  ];
  return (
    <div className="main">
      <h1>{t('administrationPage:test_administration')}</h1>
      <ILTableOrganismContainer
        id="test_administration"
        data={data}
        columns={tableColumns}
        isLoading={isLoading}
        noDataText={t('administrationPage:no_results')}
        sortable={true}
        filterable={true}
        defaultSorted={[
          {
            id: 'startDate',
            desc: true,
          },
        ]}
        shouldReload={true}
      />
      <ILConfirmationModalMolecule
        modalInfo={deleteModalSettings}
        title={t('administrationPage:delete_title')}
        description={t('administrationPage:delete_description')}
        customAcceptText={t('administrationPage:delete')}
        onCancel={closeDeleteModal}
        onAccept={deletePlanning}
      />
    </div>
  );
}

export default TestAdministration;
