import { useEffect } from 'react';
import { page } from '@infinitaslearning/module-data-analyticstracker';
import { useGetMethod } from '../graphql/method/methodOperations';
import { PageCategory, PageType } from '../services/AnalyticsService';

export const usePageTracking = (category: PageCategory, type: PageType, id?: string): void => {
  const method = useGetMethod();

  useEffect(() => {
    if (method) {
      page({ category, type, id, brand: { name: method?.title } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [method]);
};
