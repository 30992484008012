import { b64toBlob } from './ConvertData';

export const autoDownloadZipFile = (b64Data: string, fileName: string): void => {
  const a = document.createElement('a');
  const blobFile = b64toBlob(b64Data, 'application/zip');
  a.href = URL.createObjectURL(blobFile);
  a.setAttribute('download', `${fileName}.zip`);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const autoDownloadCsvFile = (csv: string, fileName: string): void => {
  const hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv);
  hiddenElement.target = '_blank';
  hiddenElement.download = `${fileName}.csv`;
  hiddenElement.click();
};
